import { useContext } from "react";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

function SectionHeading({
	title,
	count,
	searchQuery,
	setSearchQuery,
	middleComponent,
	searchTrad
}: {
	title?: string;
	count?: number;
	searchQuery?: string;
	setSearchQuery?: (value: string) => void;
	middleComponent?: JSX.Element;
	searchTrad?: string;
}) {
	const { lang } = useContext(AppContext);

	const formatToFrenchDate = (dateStr: string) => {
		const dateMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
		if (dateMatch) {
			const [_, year, month, day] = dateMatch;
			return `${day}/${month}/${year}`;
		}
		return dateStr;
	};

	return (
		<div className="my-5 flex w-[100%] flex-col gap-2 md:my-8 md:items-center md:justify-between lg:flex-row lg:gap-0">
			{/* Section Title */}
			{title && (
				<div className="flex w-full flex-row items-center gap-2 lg:w-auto">
					<h3 className="text-lg font-bold text-gloom md:text-xl">{title}</h3>
					{count != undefined && (
						<span className="rounded-full bg-primary px-3 text-sm text-background">
							{count}
						</span>
					)}
				</div>
			)}

			{/* Display custom component on header */}
			{middleComponent}

			{/* Search Bar */}
			{setSearchQuery && (
				<div className="relative mt-4 w-full rounded-md border border-gray-300 py-2 shadow-sm md:mt-0 lg:w-64 lg:max-w-xs">
					<label
						htmlFor="search"
						className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500"
					>
						{trad[lang].search}
					</label>
					<div className="relative mt-1 rounded-md">
						<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
							<HiOutlineMagnifyingGlass
								className="h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</div>
						<input
							type="text"
							name="searchinput"
							id="searchinput"
							className="block w-full rounded-md border-transparent py-0 pl-10 text-sm focus:border-transparent focus:ring-0"
							onChange={(e) => {
								let query = e.target.value;

								const dateMatch = query.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
								if (dateMatch) {
									const [_, day, month, year] = dateMatch;
									query = `${year}-${month}-${day}`;
								}

								setSearchQuery(query);
							}}
							value={formatToFrenchDate(searchQuery ?? "")}
							placeholder={searchTrad}
							autoComplete="off"
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default SectionHeading;
