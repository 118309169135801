import { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { startPrestation, stopPrestation } from "../../api/hook";
import { deleteDetections, updateLiveCredential } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";
import Modal from "../common/Modal";

const GlobalLiveConfig = ({
	idCalendrier,
	credential,
	date_fin_prestation,
	idPrestation,
	timezone,
	refetch
}: {
	idCalendrier: number;
	credential: string;
	date_fin_prestation: string;
	idPrestation: number;
	timezone: string;
	refetch: any;
}) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [localCredential, setLocalCredential] = useState(credential || "");
	const [loading, setLoading] = useState(false);
	const [openedStopPrestation, setOpenedStopPrestation] = useState(false);
	const [loadingCredential, setLoadingCredential] = useState(false);
	const [openedDeleteDetectionsModal, setOpenedDeleteDetectionsModal] =
		useState(false);
	const [openedConfirmSave, setOpenedConfirmSave] = useState(false);

	const saveCredential = async () => {
		try {
			if (!slug || !localCredential) return;
			setLoadingCredential(true);

			await updateLiveCredential(slug, localCredential);

			await refetch();

			Toast.success(trad[lang].saveCredentialSuccess);
		} catch (error) {
			Toast.error(trad[lang].saveCredentialError);
			throw error;
		} finally {
			setLoadingCredential(false);
		}
	};

	const confirmSaveStartPrestation = async () => {
		try {
			setLoading(true);

			await saveCredential();

			const result = await startPrestation(idCalendrier);

			if (result.message == "DIFFERENT_TIMEZONE") {
				Toast.error(trad[lang].liveTimezone);
				return;
			}

			await refetch();
			setOpenedConfirmSave(false);
		} catch (error) {
			Toast.error(trad[lang].saveCredentialError);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handleStartPrestation = async () => {
		try {
			if (localCredential != credential) {
				setOpenedConfirmSave(true);
				return;
			}
			setLoading(true);
			const result = await startPrestation(idCalendrier);

			if (result.message == "DIFFERENT_TIMEZONE") {
				Toast.error(trad[lang].liveTimezone);
				return;
			}

			await refetch();
		} catch (error) {
			Toast.error(trad[lang].verifyCredential);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handleStopPrestation = async () => {
		try {
			setLoading(true);
			await stopPrestation(idCalendrier);
			await refetch();
			setOpenedStopPrestation(false);
		} catch (error) {
			Toast.error(trad[lang].verifyCredential);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteDetection = async () => {
		try {
			if (!slug) return;
			await deleteDetections(slug);
			setOpenedDeleteDetectionsModal(false);
			Toast.success(trad[lang].deleteDetectionsSuccess);
		} catch (error) {
			Toast.error(trad[lang].deleteDetectionsError);
			throw error;
		}
	};

	return (
		<div className="relative w-full">
			<div
				className={`mb-5 grid w-full grid-cols-2 gap-5 rounded-md bg-gray-50 p-5 transition-opacity`}
			>
				<p className="absolute top-2 right-3 font-bold">{timezone}</p>
				<div className="col-span-2 mt-4 flex flex-col gap-2 lg:col-span-1 lg:mt-0">
					<p className="font-bold">{trad[lang].credentialDagLive}</p>

					<input
						type="text"
						value={localCredential}
						onChange={(e) => setLocalCredential(e.target.value)}
						className={`h-12 rounded-md border border-gray-300 pl-2 focus:outline-none ${
							!!idPrestation && !date_fin_prestation
								? "cursor-not-allowed opacity-50"
								: ""
						}`}
						disabled={!!idPrestation && !date_fin_prestation}
						title={
							!!idPrestation && !date_fin_prestation
								? "Prestation en cours"
								: ""
						}
					/>
				</div>
				<div className="col-span-2 flex flex-col gap-2 lg:col-span-1">
					<p className="font-bold"></p>

					<button
						onClick={saveCredential}
						className={`mt-auto flex h-12 w-fit flex-row items-center justify-center gap-2 rounded-md bg-primary px-4 text-white transition-colors hover:bg-primarymedium focus:outline-none ${
							!!idPrestation && !date_fin_prestation
								? "cursor-not-allowed opacity-50"
								: ""
						}`}
						disabled={loading || (!!idPrestation && !date_fin_prestation)}
						title={
							!!idPrestation && !date_fin_prestation
								? "Prestation en cours"
								: ""
						}
					>
						{loadingCredential ? (
							<>
								<AiOutlineLoading3Quarters className="animate-spin" />
								{trad[lang].loading}
							</>
						) : (
							trad[lang].save
						)}
					</button>
				</div>

				<div className="col-span-2">
					<hr />
				</div>

				<div className="col-span-2 flex flex-col items-center justify-center gap-2">
					<p className="font-bold">
						{idPrestation && !date_fin_prestation
							? `Prestation en cours : ${idPrestation}`
							: "Aucune prestation en cours"}
					</p>

					<div className="flex gap-5">
						<button
							onClick={() =>
								idPrestation && !date_fin_prestation
									? setOpenedStopPrestation(true)
									: handleStartPrestation()
							}
							className={`mt-auto flex h-12 w-fit flex-row items-center justify-center gap-2 rounded-md px-4 text-sm transition-colors focus:outline-none md:uppercase ${
								idPrestation && !date_fin_prestation
									? "border border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
									: "border border-green-700 text-green-700 hover:bg-green-700 hover:text-white"
							}`}
							disabled={loading}
						>
							{loading ? (
								<>
									<AiOutlineLoading3Quarters className="animate-spin" />{" "}
									{trad[lang].loading}
								</>
							) : idPrestation && !date_fin_prestation ? (
								trad[lang].stop
							) : (
								trad[lang].start
							)}
						</button>

						<button
							className="flex h-full flex-row items-center gap-1 rounded-md border border-orange-600 py-3 px-3 text-sm text-orange-600 duration-150 hover:bg-orange-600 hover:text-white md:uppercase"
							onClick={() => setOpenedDeleteDetectionsModal(true)}
						>
							{trad[lang].deleteDetections}
						</button>
					</div>
				</div>
			</div>

			{openedDeleteDetectionsModal && (
				<Modal
					style={{
						width: "50%",
						height: "30%"
					}}
					closeModal={() => setOpenedDeleteDetectionsModal(false)}
					titleButton={trad[lang].confirm}
					cancelBtn
					deleteBtn
					onClickCancelButton={() => setOpenedDeleteDetectionsModal(false)}
					titleCancelButton={trad[lang].cancel}
					onClickButton={handleDeleteDetection}
					children={<h2>{trad[lang].deleteAllDetections}</h2>}
					rounded
				/>
			)}

			{openedStopPrestation && (
				<Modal
					style={{
						width: "50%",
						height: "30%"
					}}
					closeModal={() => setOpenedStopPrestation(false)}
					titleButton={trad[lang].yes}
					cancelBtn={loading ? false : true}
					button={loading ? false : true}
					onClickCancelButton={() => setOpenedStopPrestation(false)}
					titleCancelButton={trad[lang].no}
					onClickButton={handleStopPrestation}
					children={
						loading ? (
							<AiOutlineLoading3Quarters className="animate-spin" />
						) : (
							<h2>Êtes vous sûr de vouloir arrêter la prestation ?</h2>
						)
					}
					rounded
				/>
			)}

			{openedConfirmSave && (
				<Modal
					style={{
						width: "50%",
						height: "30%"
					}}
					closeModal={() => setOpenedConfirmSave(false)}
					titleButton={trad[lang].yes}
					cancelBtn={loading ? false : true}
					button={loading ? false : true}
					onClickCancelButton={() => setOpenedConfirmSave(false)}
					titleCancelButton={trad[lang].no}
					onClickButton={confirmSaveStartPrestation}
					children={
						loading ? (
							<AiOutlineLoading3Quarters className="animate-spin" />
						) : (
							<h2>{trad[lang].confirmStartSaveCredential}</h2>
						)
					}
					rounded
				/>
			)}
		</div>
	);
};

export default GlobalLiveConfig;
