import "chart.js/auto";
import { ChartData } from "chart.js/auto";
import { useContext, useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventIdentityConfig, getEventStatistics } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function Statistics() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [chartData1, setChartData1] =
		useState<ChartData<"line", number[], unknown>>();
	const [maxY, setMaxY] = useState(0);

	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data: Statistics, isLoading: LoadingStats } = useQuery({
		queryKey: ["stats", slug],
		queryFn: () => getEventStatistics(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (Statistics && Statistics.subsOverTime) {
			const chartData = {
				labels: [],
				datasets: []
			};

			const colors = [
				"rgba(255, 99, 132, 1)",
				"rgba(54, 162, 235, 1)",
				"rgba(255, 206, 86, 1)",
				"rgba(75, 192, 192, 1)",
				"rgba(153, 102, 255, 1)",
				"rgba(255, 159, 64, 1)",
				"rgba(177, 177, 177, 1)",
				"#57E0B4",
				"#4D6061",
				"#61614D",
				"#E0DE41",
				"#614D51",
				"rgba(255, 99, 132, 1)",
				"rgba(54, 162, 235, 1)",
				"rgba(255, 206, 86, 1)",
				"rgba(75, 192, 192, 1)",
				"rgba(153, 102, 255, 1)"
			];

			Statistics.subsOverTime.courses.forEach((course: any, index: number) => {
				// Add date
				course.registrations.forEach((registration: any) => {
					(chartData.labels as string[]).push(registration.date);
				});
			});

			// Sort uniq dates
			chartData.labels = [...new Set(chartData.labels)].sort();

			let max = 0;

			// Add dataset for each course, add data for each date
			Statistics.subsOverTime.courses.forEach((course: any, index: number) => {
				const dataset = {
					label: "Inscriptions sur " + course.courseName + " ",
					data: [],
					fill: false,
					borderColor: colors[index],
					backgroundColor: colors[index]
				};

				// Add data for each date
				chartData.labels.forEach((date: string) => {
					const registration = course.registrations.find(
						(registration: any) => registration.date === date
					);
					if (registration) {
						(dataset.data as number[]).push(registration.registrants);
						max = Math.max(
							max,
							registration.registrants +
								Math.round(registration.registrants / 10)
						);
					} else {
						(dataset.data as number[]).push(0);
					}
				});

				(chartData.datasets as any[]).push(dataset);
			});

			// Convert dates likes 2023-10-31 to 31/10
			(chartData.labels as string[]) = chartData.labels.map((date: string) => {
				const dateSplit = date.split("-");
				return `${dateSplit[2]}/${dateSplit[1]}`;
			});

			setChartData1(chartData);
			setMaxY(max);
		}
	}, [Statistics]);

	const tables = useMemo(
		() => [
			{ title: "Encaissement - Inscriptions", key: ["sub_details"] },
			{ title: "Bons de Reductions - Inscriptions", key: ["event_discounts"] },
			{ title: "Encaissement - Options", key: ["options_details"] },
			{
				title: "Encaissement - Frais d'inscription",
				key: ["fees_exclued_details", "fees_inclued_details"]
			},
			{ title: "Encaissement - Licence FFTRI", key: ["fftri_details"] },
			{ title: "Remboursements - Inscriptions", key: ["refund_details"] },
			{ title: "Remboursements - Options", key: ["refund_options_details"] },
			{ title: "Frais de remboursement", key: ["fees_refund_details"] }
		],
		[lang]
	);

	if (LoadingStats || EventLoading) {
		return <PageLoader menu_key="statistics" />;
	}

	return (
		<Layout
			active_key="statistics"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: EventInfos.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "statistics", to: "#", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{EventInfos.name}
			</h1>

			<h2 className="mb-2 text-xl font-bold">
				{trad[lang].subscription_history_by_run}
			</h2>
			{/* Chart */}
			{chartData1 && (
				<div className="mb-2 w-full">
					<Line
						data={chartData1}
						options={{
							responsive: true,
							aspectRatio: window.innerWidth < 1000 ? 2 : 3,
							maintainAspectRatio: true,
							scales: {
								x: { stacked: true },
								y: { stacked: false, min: 0, max: maxY }
							},
							plugins: {
								legend: {
									display: true,
									labels: {
										padding: 6,
										boxWidth: 20,
										boxHeight: 8,
										usePointStyle: true,
										font: { size: 15, family: "Montserrat" }
									}
								},
								tooltip: {
									titleFont: { size: 16, family: "Montserrat" },
									bodyFont: { size: 16, family: "Montserrat" }
								}
							}
						}}
					/>
				</div>
			)}

			<h2 className="text-xl font-bold">Inscriptions</h2>

			<div className="mt-4 overflow-x-auto border border-gray-200">
				<table className="w-full divide-y divide-gray-200 ">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].run}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right lg:table-cell"
							>
								{trad[lang].total_subs}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
							>
								{trad[lang].total_subs_online}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"
							>
								{trad[lang].total_subs_manual}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right sm:table-cell"
							>
								{trad[lang].total_subs_imported}
							</th>
							<th
								scope="col"
								className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"
							>
								{trad[lang].total_subs_in_place}
							</th>
						</tr>
					</thead>
					<tbody>
						{Statistics.subs.map((item: any, index: number) => (
							<tr className={index % 2 ? "bg-gray-50" : ""} key={index}>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{item.nom}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 lg:table-cell">
									{item.quantity}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									{item.sub_online}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 md:table-cell">
									{item.sub_manual}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 sm:table-cell">
									{item.sub_import}
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 md:table-cell">
									{item.sub_in_place}
								</td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-gray-50">
						<tr>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								Total
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right lg:table-cell">
								{Statistics.subs.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.quantity),
									0
								)}
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
								{Statistics.subs.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.sub_online),
									0
								)}
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell">
								{Statistics.subs.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.sub_manual),
									0
								)}
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right sm:table-cell">
								{Statistics.subs.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.sub_import),
									0
								)}
							</td>
							<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell">
								{Statistics.subs.reduce(
									(acc: number, cur: any) => acc + parseInt(cur.sub_in_place),
									0
								)}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>

			{tables.map((table) =>
				table.key
					.map((key) => Statistics[key].length)
					.reduce((acc: number, cur: number) => acc + cur, 0) > 0 ? (
					<>
						<h2 className="mt-6 text-xl font-bold">{table.title}</h2>

						<div className="mt-4 overflow-x-auto border border-gray-200">
							<table className="w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
									>
										Designation
									</th>
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"
									>
										Quantité
									</th>
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"
									>
										Prix Unitaire TTC
									</th>
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
									>
										Prix Total TTC
									</th>
								</thead>
								<tbody>
									{table.key
										.map((key) =>
											Statistics[key].map((item: any, index: number) => (
												<tr
													className={index % 2 ? "bg-gray-50" : ""}
													key={index}
												>
													<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
														{item.Designation}
													</td>
													<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 md:table-cell">
														{item.Quantity}
													</td>
													<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 md:table-cell">
														{`${parseFloat(item.total_cmd_bdd_ttc).toFixed(
															2
														)} €`}
													</td>
													<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
														{`${parseFloat(item.TotalTTC).toFixed(2)} €`}
													</td>
												</tr>
											))
										)
										.flat(1)}
								</tbody>
								<tfoot className="bg-gray-50">
									<tr>
										<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											Total
										</td>
										<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell">
											{table.key
												.map((key) => Statistics[key])
												.flat(1)
												.reduce(
													(acc: number, cur: any) =>
														acc + parseInt(cur.Quantity),
													0
												)}
										</td>
										<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"></td>
										<td className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											{`${table.key
												.map((key) => Statistics[key])
												.flat(1)
												.reduce(
													(acc: number, cur: any) =>
														acc + parseFloat(cur.TotalTTC),
													0
												)
												.toFixed(2)} €`}
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</>
				) : null
			)}
		</Layout>
	);
}

export default Statistics;
