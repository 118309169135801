import { useContext } from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_solo.png";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import LangToggle from "../common/LangToggle";
import Navigation from "../sidebar/Navigation";
import UserMenu from "../sidebar/UserMenu";

function Sidebar({
	active_key,
	forceReduced
}: {
	active_key: string;
	forceReduced: boolean;
}) {
	const { lang, reduced, setReduced } = useContext(AppContext);

	const isReduced = forceReduced || reduced;

	return (
		<div
			className={`relative hidden h-screen ${
				isReduced ? "w-16" : "w-64"
			} col-start-1 row-span-2 flex-grow flex-col border-r border-gray-200 bg-darkerBg pt-4 pb-4 transition-all md:flex`}
		>
			{!forceReduced && (
				<button
					className="absolute -right-6 z-[5] flex h-8 w-6 items-center justify-center rounded-r-md border-r border-t border-b border-gray-200 bg-darkerBg"
					onClick={() => setReduced((old) => !old)}
				>
					{isReduced ? <HiChevronDoubleRight /> : <HiChevronDoubleLeft />}
				</button>
			)}

			{/* Logo */}
			<div className="flex flex-col items-center">
				<img
					className={isReduced ? "h-auto w-14" : "h-16 w-auto"}
					src={Logo}
					alt="Logo Chronométrage.com"
				/>
				{!isReduced && (
					<span className="text-xl font-bold text-primary">
						Chronometrage.com
					</span>
				)}
			</div>

			<div className="mt-2 flex flex-grow flex-col">
				{/* User menu */}
				<UserMenu reduced={isReduced} />

				{/* Navigation */}
				<Navigation reduced={isReduced} active_key={active_key} />
			</div>

			<div className="mb-3 flex w-full flex-col gap-2 text-center text-sm">
				<a
					href="https://content.dag-system.com/chronometrage/quickstart.pdf"
					target="_blank"
					className={`${isReduced ? "hidden" : ""}`}
				>
					{trad[lang].quickstart}
				</a>

				<a
					href="https://content.dag-system.com/chronometrage/features.pdf"
					target="_blank"
					className={`${isReduced ? "hidden" : ""}`}
				>
					{trad[lang].features}
				</a>
				{!isReduced && (
					<>
						<a
							href="https://dashboard-v1.chronometrage.com/uploads/cgv/organisateurs.pdf"
							target="_blank"
						>
							{trad[lang].cgu}
						</a>
						<a
							href="https://dashboard-v1.chronometrage.com/uploads/cgu/conditions_g%C3%A9n%C3%A9rales_ventes.pdf"
							target="_blank"
						>
							{trad[lang].cgv}
						</a>
					</>
				)}
				<Link to="/version">
					{isReduced
						? `v ${process.env.REACT_APP_VERSION}`
						: `Version ${process.env.REACT_APP_VERSION}`}
				</Link>
			</div>

			{/* Lang Toggle */}
			<div
				className={`flex w-full flex-row ${
					isReduced ? "justify-center" : "justify-end"
				} px-4`}
			>
				<LangToggle reduced={isReduced} />
			</div>
		</div>
	);
}

export default Sidebar;
