export const BASE_RUN_LIGHT = {
	id: 0,
	name: "",
	availablePlaces: "",
	startDate: "",
	endDate: "",
	teamNumber: null,
	sameBibNumber: false,
	inscription_visible: null,
	form: {}
};

export const BASE_RUN = {
	index: 0,
	id: 0,
	name: "",
	availablePlaces: "",
	startDate: new Date(),
	endDate: new Date(),
	teamNumber: 1,
	sameBibNumber: false,
	inscription_visible: null,
	delete: false,
	prices: [],
	form: {}
};

export const BASE_PRICE = {
	index: 0,
	id_tarif: 0,
	ageControl: 0,
	designation: "",
	minAge: 0,
	maxAge: 0,
	startDate: new Date(),
	endDate: new Date(),
	free: 0,
	feesType: 0,
	price: "0",
	amountClientFixedFees: "0",
	amountClientPercentageFees: 0,
	delete: false,
	form: {}
};
