import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import Layout from "../../components/navigation/Layout";

const Licences = () => {
	return (
		<Layout
			active_key="admin"
			bottomBar={<BottomBarNavigation back_to="/" />}
			full
		>
			<iframe
				src="https://web-test.dag-system.com/licence"
				className="h-full w-full"
			/>
		</Layout>
	);
};

export default Licences;
