import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useMemo,
	useRef
} from "react";
import { findUser } from "../api/auth";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Announcement } from "../types/Announcement";
import { User } from "../types/User";

type UserContextType = {
	user?: User;
	setUser: Dispatch<SetStateAction<User | undefined>>;
	userIsAdmin: boolean;
	permissions: any;
	permissionsRef: React.MutableRefObject<any>;
	announcements: Announcement[];
	setAnnouncements: Dispatch<SetStateAction<Announcement[]>>;

	getUser: () => Promise<User | undefined>;
};

export const UserContext = createContext<UserContextType>(
	{} as UserContextType
);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useLocalStorage("user", null);
	const permissionsRef = useRef<any>({});
	const [announcements, setAnnouncements] = React.useState<Announcement[]>([]);

	const userIsAdmin: boolean = useMemo(() => {
		if (!user) return false;
		return user.roles?.includes("ROLE_ADMIN");
	}, [user]);

	const getUser = async () => {
		try {
			const response = await findUser(true);

			if (response) {
				setUser(response.user);
				setAnnouncements(response.announcements);
				if (process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER) {
					localStorage.setItem(
						process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER,
						response.user.IDPersonne
					);
				}
				permissionsRef.current = response.permissions;
				return response.user;
			} else {
				setUser(undefined);
				return undefined;
			}
		} catch (error) {
			localStorage.removeItem("newConnexion");
			localStorage.removeItem("token");
			if (process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER) {
				localStorage.removeItem(process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER);
			}

			setUser(null);
		}
	};

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				userIsAdmin,
				getUser,
				permissions: permissionsRef.current,
				permissionsRef: permissionsRef,
				announcements,
				setAnnouncements
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;

export const useAuth = () => {
	return useContext(UserContext);
};
