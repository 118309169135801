import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("fr");

const DateInput = ({
	handleChange,
	value = null,
	label
}: {
	handleChange: (val: Dayjs | null) => void;
	value: Dayjs | null;
	error?: boolean;
	seconds?: boolean;
	timezone?: string;
	label?: string;
}) => {
	const { lang } = useContext(AppContext);

	const style = `rounded-md`;

	const format = "DD/MM/YYYY";

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
			<DateTimeField
				ampm={false}
				value={dayjs(value)}
				className={style}
				format={format}
				minutesStep={1}
				onChange={handleChange}
				slotProps={{ textField: { error: false } }}
				label={label}
			/>
		</LocalizationProvider>
	);
};

export default DateInput;
