import { Dialog, Transition } from "@headlessui/react";
import { Tooltip, Typography } from "@material-tailwind/react";
import React, { Fragment, useEffect } from "react";
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getRoles, setAccess, updateAccess } from "../../api/accesses";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { classNames } from "../../utils/Classes";
import { REGEXP } from "../../utils/RegExp";
import Toast from "../../utils/Toasts";

function PermissionsModal({
	lang,
	open,
	setOpen,
	refetch,
	inviteBeingEdited,
	setInviteBeingEdited
}: {
	lang: ILang;
	open: any;
	setOpen: any;
	refetch: any;
	inviteBeingEdited: any;
	setInviteBeingEdited: Function;
}) {
	const { slug } = useParams();
	const [activeAccordionId, setActiveAccordionId] = React.useState(-1);
	const [isOrgaChecked, setIsOrgaChecked] = React.useState(false);
	const [error, setError] = React.useState<string>();
	const [emailError, setEmailError] = React.useState<string>();
	const [roleError, setRoleError] = React.useState<string>();
	const [jsonRoles, setJsonRoles] = React.useState<any>({});
	const [isSendingInvite, setIsSendingInvite] = React.useState(false);
	const [isWaitingModif, setIsWaitingModif] = React.useState(
		inviteBeingEdited !== null
	);
	const ORGANISATEUR_ID: number = 3;

	const { isLoading: isLoadingRoles } = useQuery({
		queryKey: ["roles", slug],
		queryFn: () => getRoles(),
		enabled: true,
		onSuccess: (data) => {
			if (inviteBeingEdited !== null) {
				try {
					setJsonRoles(JSON.parse(JSON.stringify(inviteBeingEdited)));
				} catch (e) {
					setInviteBeingEdited(null);
					setJsonRoles(data);
					setIsWaitingModif(false);
				}
			} else setJsonRoles(data);
		},
		refetchOnWindowFocus: false,
		refetchOnReconnect: false
	});

	useEffect(() => {
		setIsOrgaChecked(
			jsonRoles?.roles?.find((item: any) => item.id === ORGANISATEUR_ID)
				?.active || false
		);
	}, [jsonRoles]);

	const onClickRoleAccordion = function (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id: number
	) {
		if (id === activeAccordionId) setActiveAccordionId(-1);
		else setActiveAccordionId(id);
	};

	const onClickInvite = async function () {
		let res: any;
		if (!slug) return;

		// Édition d'une invitation
		if (inviteBeingEdited !== null) {
			try {
				setIsSendingInvite(true);
				res = await updateAccess(slug, jsonRoles);
				Toast.success("Permissions modifiées pour " + jsonRoles.mail);
				setOpen(false);
				if (refetch) refetch();
			} catch (e: any) {
				setError(e.response?.data?.message || "");
				Toast.error(e.response?.data?.message || "");
			}
			setIsSendingInvite(false);
		}
		// Nouvelle invitation
		else {
			// Check if one role is selected
			let oneSelected: boolean = hasOneRoleSelected(jsonRoles);
			if (!oneSelected) return;

			// Check if mail is valid
			let mailValid: boolean = isMailValid(jsonRoles.mail);
			if (!mailValid) return;

			try {
				setIsSendingInvite(true);
				res = await setAccess(slug, jsonRoles);
				Toast.success("Invitation envoyée à " + jsonRoles.mail);
				setOpen(false);
				if (refetch) refetch();
			} catch (e: any) {
				setError(e.response?.data?.message || "");
				Toast.error(e.response?.data?.message || "");
			}
			setIsSendingInvite(false);
		}
	};

	const hasOneRoleSelected = function (json: any): boolean {
		let noRoleSelected: boolean = true;
		for (let role of jsonRoles.roles) {
			if (role.active) {
				noRoleSelected = false;
				break;
			}
		}
		setRoleError(noRoleSelected ? "Veuillez sélectionner un rôle" : undefined);
		return !noRoleSelected;
	};

	const isMailValid = function (mail: string): boolean {
		let valid: boolean = mail !== null && REGEXP.mail.test(mail);
		setEmailError(!valid ? "L'email n'est pas valide" : undefined);
		return valid;
	};

	const onCheckboxClick = function (
		e: React.ChangeEvent<HTMLInputElement>,
		id: number
	) {
		let role: any = jsonRoles.roles.find((item: any) => item.id === id);
		role.active = e.target.checked;
		if (role.id === ORGANISATEUR_ID && e.target.checked === true)
			jsonRoles.roles.forEach((item: any) => {
				if (item.givable === true) item.active = true;
			});
		setJsonRoles({ ...jsonRoles });
		setIsWaitingModif(false);
		if (inviteBeingEdited === null) hasOneRoleSelected(jsonRoles);
	};

	const onInputChange = function (email: string, focusingOut: boolean = false) {
		setError(undefined);
		setJsonRoles({ ...jsonRoles, mail: email });
		if (focusingOut) {
			if (email) isMailValid(email);
		}
	};

	const roleDroits = [
		{
			id: 1,
			title: trad[lang].subscription,
			droits: [
				{
					label: trad[lang].rights_add_subscription,
					infos: trad[lang].rights_add_subscription_infos
				},
				{
					label: trad[lang].rights_import_subscriptions,
					infos: trad[lang].rights_import_subscriptions_infos
				},
				{
					label: trad[lang].rights_export_subscriptions,
					infos: trad[lang].rights_export_subscriptions_infos
				},
				{
					label: trad[lang].rights_validate_documents,
					infos: trad[lang].rights_validate_documents_infos
				},
				{
					label: trad[lang].rights_send_mails
				}
			]
		},
		{ id: 2, droits: [] },
		{
			id: 3,
			title: trad[lang].organizer,
			droits: [
				{
					label: trad[lang].rights_subscription_live
				},
				{
					label: trad[lang].rights_run_config
				},
				{
					label: trad[lang].rights_rib_config
				},
				{
					label: trad[lang].rights_contact_config
				}
			]
		},
		{
			id: 4,
			title: trad[lang].live_chrono,
			droits: [
				{
					label: trad[lang].rights_runner_management,
					infos: trad[lang].rights_runner_management_infos
				},
				{
					label: trad[lang].rights_dossard_assign,
					infos: trad[lang].rights_dossard_assign_infos
				}
			]
		}
	];

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
								<div className="mb-auto mt-auto bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="mt-3 text-center sm:mt-0">
										<Dialog.Title
											as="h1"
											className="text-base font-semibold leading-6 text-gray-900"
										>
											{inviteBeingEdited !== null
												? "Modifier les accès"
												: "Inviter un utilisateur"}
										</Dialog.Title>
										<div className="mt-2"></div>
									</div>
								</div>

								{isLoadingRoles ? (
									<div className="flex flex-row justify-center">
										<AiOutlineLoading3Quarters
											size={24}
											className="animate-spin"
										/>
									</div>
								) : (
									<>
										{/* Email */}
										<div className="mt-6 flex w-full flex-row items-center px-6">
											<label className="block text-left text-sm font-medium text-gray-700">
												{trad[lang].email_label}
											</label>
											<div className="relative mt-1 ml-6 w-full">
												{(emailError || roleError || error) && (
													<label className="absolute -top-6 select-none text-sm text-red-500">
														{emailError || roleError
															? emailError || roleError
															: error}
													</label>
												)}
												<input
													type="text"
													disabled={inviteBeingEdited !== null}
													className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-gray-300 focus:ring-transparent disabled:cursor-default disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-500"
													placeholder={
														inviteBeingEdited !== null ? "" : "example@mail.com"
													}
													value={jsonRoles.mail}
													onChange={(e) => {
														onInputChange(e.target.value);
													}}
													onBlur={(e) => {
														onInputChange(e.target.value, true);
													}}
												/>
											</div>
										</div>

										{/* Droits */}
										<div className="mt-6 flex w-full flex-row px-6">
											<label className="block text-left text-sm font-medium text-gray-700">
												Droits
											</label>
											<div className="flex flex-col gap-y-2 px-6">
												{jsonRoles?.roles
													?.filter((item: any) => item.givable === true)
													.map((role: any, index: number) => (
														<Fragment key={index}>
															<div
																className={`flex items-center ${
																	role.id !== ORGANISATEUR_ID && isOrgaChecked
																		? "text-gray-500"
																		: "text-primary"
																}`}
																key={index}
															>
																<input
																	id="acceptCGV"
																	name="acceptCGV"
																	type="checkbox"
																	disabled={
																		role.id !== ORGANISATEUR_ID && isOrgaChecked
																	}
																	className="h-5 w-5 cursor-pointer rounded border-primary text-primary focus:ring-0 disabled:cursor-default disabled:border-gray-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:opacity-50"
																	checked={role.active}
																	onChange={(e) => {
																		onCheckboxClick(e, role.id);
																	}}
																/>
																<button
																	className="ml-2 flex cursor-pointer flex-row text-base disabled:cursor-default"
																	onClick={(e) => {
																		onClickRoleAccordion(e, index);
																	}}
																>
																	<label className="select-none">
																		{roleDroits.find(
																			(item) => item.id == role.id
																		)?.title || role.label}
																	</label>
																	{role.droits &&
																		role.droits.length > 0 &&
																		(activeAccordionId === index ? (
																			<BiChevronUp className="mr-auto ml-2 mt-0.5 h-5 w-5" />
																		) : (
																			<BiChevronDown className="mr-auto ml-2 mt-0.5 h-5 w-5" />
																		))}
																</button>
															</div>
															{activeAccordionId === index && (
																<>
																	{roleDroits
																		.find((item) => item.id == role.id)
																		?.droits.map((droit: any, idx: number) => (
																			<div
																				className="ml-10 flex items-center"
																				key={idx}
																			>
																				<RxDotFilled />
																				<label className="ml-2 block text-sm text-gray-600">
																					{droit.label}
																				</label>
																				{droit.infos && (
																					<Tooltip
																						className="text-md z-50 text-center"
																						content={
																							<Typography>
																								{droit.infos}
																							</Typography>
																						}
																					>
																						<p>
																							<AiOutlineInfoCircle className="ml-2 -mt-0 h-4 w-4 text-gray-500" />
																						</p>
																					</Tooltip>
																				)}
																			</div>
																		))}
																</>
															)}
														</Fragment>
													))}
											</div>
										</div>

										{/* Confirm button */}
										<div className="mt-5 p-3 sm:mt-5 sm:flex sm:flex-row-reverse">
											<button
												type="button"
												disabled={
													emailError !== undefined ||
													roleError !== undefined ||
													isSendingInvite ||
													isWaitingModif
												}
												className={classNames(
													true
														? "bg-secondary hover:bg-primary"
														: "bg-gray-300",
													"inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
													"disabled:bg-gray-300 disabled:opacity-50"
												)}
												onClick={onClickInvite}
											>
												{inviteBeingEdited !== null ? "Modifier" : "Inviter"}
											</button>
										</div>
									</>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default PermissionsModal;
