import { Dispatch, SetStateAction } from "react";
import { Tabs } from "../../types/Tabs";
import { classNames } from "../../utils/Classes";

function TabsBtn({
	selectedTab,
	setSelectedTab,
	tabs
}: {
	selectedTab: string;
	setSelectedTab: Dispatch<SetStateAction<any>>;
	tabs: Tabs[];
}) {
	return (
		<div
			className="mx-auto flex w-full max-w-4xl flex-col rounded-lg shadow sm:flex-row sm:divide-x sm:divide-gray-200"
			aria-label="Tabs"
		>
			{tabs.map((tab, tabIdx) => (
				<div
					key={tab.name}
					className={classNames(
						"group relative w-full cursor-pointer overflow-hidden whitespace-nowrap bg-white px-8 py-3 text-center text-sm font-medium hover:bg-gray-50",
						tab.value == selectedTab
							? "text-primary"
							: "text-gray-500 hover:text-gray-700",
						tabIdx === 0 ? "sm:rounded-l-lg" : "",
						tabIdx === tabs.length - 1
							? "rounded-r-lg rounded-l-lg sm:rounded-l-none"
							: ""
					)}
					aria-current={tab.value == selectedTab ? "page" : undefined}
					onClick={() => setSelectedTab(tab.value)}
				>
					<p>{tab.name}</p>
					<span
						aria-hidden="true"
						className={classNames(
							tab.value == selectedTab ? "bg-primary" : "bg-transparent",
							"absolute inset-x-0 bottom-0 z-0 h-0.5"
						)}
					/>
				</div>
			))}
		</div>
	);
}

export default TabsBtn;
