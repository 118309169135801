import { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useQuery } from "react-query";
import Select from "react-tailwindcss-select";
import {
	getAllEvents,
	getCurrentEventFinance,
	getEventStatisticsById,
	getRapportEvent,
	getSharesEvent
} from "../../api/event";
import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import Layout from "../../components/navigation/Layout";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

function Finances() {
	const { lang } = useContext(AppContext);
	const [event, setEvent] = useState<any>();

	const { data = [], isLoading } = useQuery({
		queryKey: "all_events",
		queryFn: getAllEvents,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false
	});

	const { data: rapport = [], isLoading: RapportLoading } = useQuery({
		queryKey: ["rapport", event],
		queryFn: () => getRapportEvent(event?.value),
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: !!event?.value
	});

	const { data: shares = [], isLoading: SharesLoading } = useQuery({
		queryKey: ["shares", event],
		queryFn: () => getSharesEvent(event?.value),
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: !!event?.value
	});

	const { data: Statistics, isLoading: LoadingStats } = useQuery({
		queryKey: ["stats", event],
		queryFn: () => getEventStatisticsById(event?.value),
		refetchOnWindowFocus: false,
		enabled: !!event?.value
	});

	const { data: EventFinances } = useQuery({
		queryKey: ["current_event_finances"],
		queryFn: ({ signal }) => getCurrentEventFinance(signal),
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false
	});

	const total_event =
		rapport.length > 0
			? parseFloat(rapport[rapport.length - 1].cumul_evenement).toFixed(2)
			: 0;

	const total_subscriptions = Statistics?.subs?.reduce(
		(acc: number, cur: any) => acc + parseInt(cur.quantity),
		0
	);

	const total_manual = Statistics?.subs?.reduce(
		(acc: number, cur: any) => acc + parseInt(cur.sub_manual),
		0
	);
	const total_import = Statistics?.subs?.reduce(
		(acc: number, cur: any) => acc + parseInt(cur.sub_import),
		0
	);

	const refDate = new Date();
	refDate.setMonth(refDate.getMonth() - 3);

	return (
		<Layout
			active_key="admin"
			bottomBar={<BottomBarNavigation back_to="/admin" />}
		>
			{/* Title */}
			<h1 className="mt-8 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
				{trad[lang].finances}
			</h1>
			<h2 className="md:text-1xl mb-6 text-2xl text-gloom md:mb-8">
				{EventFinances?.nb_events > 0
					? `Cumul C.A. ${
							EventFinances?.nb_events
					  } événement(s) non facturé(s) : ${
							EventFinances?.TotalTTC
					  } € TTC (se déroulant après le ${refDate.toLocaleString("fr-FR", {
							year: "2-digit",
							month: "2-digit",
							day: "2-digit"
					  })})`
					: ""}
			</h2>

			<div className="w-full">
				<Select
					placeholder={trad[lang].placeholder_select}
					searchInputPlaceholder={trad[lang].placeholder_search}
					noOptionsMessage={trad[lang].select_no_options}
					value={event}
					onChange={(val) => setEvent(val)}
					options={data.map((item: any) => ({
						value: item.idCalendrier,
						label: `${item.name} - #${item.idCalendrier}`
					}))}
					primaryColor="sky"
					isSearchable
					isClearable
					loading={isLoading}
					isDisabled={RapportLoading}
				/>

				{!!event?.value && (
					<h2 className="mt-6 text-xl">
						{`${
							event.label
						} - Total Course (TTC) : ${total_event} € - Part Apporteur (HT) : ${
							shares[0]?.TotalHT || 0
						} € - Inscriptions : ${total_subscriptions} dont ${total_manual} manuelle et ${total_import} importée`}
					</h2>
				)}

				{(RapportLoading || SharesLoading) && (
					<div className="mt-4 flex w-full items-center justify-center">
						<AiOutlineLoading3Quarters size={45} className="animate-spin" />
					</div>
				)}

				{rapport.length > 0 && (
					<table className="mt-4 w-full divide-y divide-gray-200">
						<thead className="rounded-lg bg-gray-50">
							{Object.keys(rapport[0])
								.filter((item) => item !== "LibelleTransaction")
								.map((key) => (
									<th
										scope="col"
										className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
										key={key}
									>
										{key}
									</th>
								))}
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{rapport.map((item: any, index: number) => (
								<tr
									className={classNames(index % 2 ? "bg-gray-50" : "")}
									key={index}
								>
									{Object.entries(item)
										.filter(([key, value]) => key !== "LibelleTransaction")
										.map(([key, value]: [string, any]) => (
											<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
												<div className="flex items-center gap-x-2">
													{key == "DateCommande"
														? new Date(value).toLocaleString(undefined, {
																year: "2-digit",
																month: "2-digit",
																day: "2-digit",
																hour: "2-digit",
																minute: "2-digit",
																second: "2-digit"
														  })
														: value}
												</div>
											</td>
										))}
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</Layout>
	);
}

export default Finances;
