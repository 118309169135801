import { useContext } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getEventRunList } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function LiveEvent() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const { data, isLoading } = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const config_options = [
		// {
		//   link: `/${slug}/detections`,
		//   text: trad[lang].dashboardDetections,
		// },
		{
			link: `/${slug}/live-details`,
			text: trad[lang].global_configuration
		},
		{
			link: `/${slug}/manage-runners`,
			text: trad[lang].handleRunners
		},
		{
			link: `/${slug}/manage-detections`,
			text: trad[lang].handleDetections
		},
		{
			link: `/${slug}/live-positions`,
			text: trad[lang].live_positions
		}
	];

	if (isLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout
			active_key="live"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data?.nom,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "live_results", to: "/live", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-24 text-2xl font-bold text-gloom md:mb-8 md:mt-16 md:text-3xl">{`${data?.nom} - ${trad[lang].liveResults}`}</h1>

			<h2 className="w-full text-lg font-bold text-gloom md:text-xl">
				{trad[lang].config_title}
			</h2>

			<div className="flex w-full flex-row flex-wrap gap-3 py-3">
				{config_options.map((item, index) => (
					<Link
						to={item.link}
						key={index}
						className="medium w-full select-none rounded-md bg-primary py-2 px-8 text-center text-white transition-colors hover:bg-primarymedium disabled:cursor-not-allowed disabled:bg-gray-400 disabled:opacity-40 disabled:hover:bg-gray-400 sm:w-fit"
					>
						{item.text}
					</Link>
				))}
			</div>
		</Layout>
	);
}

export default LiveEvent;
