import { useContext, useState } from "react";
import { FaFileUpload, FaMapMarkerAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventRunList } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import LiveTrackingUrlModal from "../components/modals/LiveTrackingUrlModal";
import UploadResultsModal from "../components/modals/UploadResultsModal";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";
import { classNames } from "../utils/Classes";

function ResultsEvent() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const [syncing, setSyncing] = useState<boolean>(false);
	const [folomiDesyncList, setFolomiDesyncList] = useState<any>(null);
	const [oldIdCourse, setOldIdCourse] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [uploadModalOpened, setUploadModalOpened] = useState(false);
	const [liveTrackingModalOpened, setLiveTrackingModalOpened] = useState(false);

	const [raceEdit, setRaceEdit] = useState<any>(null);

	const {
		data,
		isLoading,
		refetch: refetchRuns
	} = useQuery({
		queryKey: ["event_routes", slug],
		queryFn: () => getEventRunList(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	if (isLoading) {
		return <PageLoader menu_key="results" />;
	}

	return (
		<Layout
			active_key="results"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data?.nom,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "results", to: "/results", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-24 text-2xl font-bold text-gloom md:mb-8 md:mt-16 md:text-3xl">{`${data?.nom} - ${trad[lang].menuResults}`}</h1>

			<h2 className="mb-2 w-full text-lg font-bold text-gloom md:text-xl">
				{trad[lang].runs_title}
			</h2>

			{/* Par défaut */}
			<div
				key={-1}
				className={classNames(
					"flex w-full flex-row items-center justify-between gap-4 border-b p-2"
				)}
			>
				<div className="flex items-center justify-start"></div>
			</div>
			{data?.calendrier_child?.map((item: any, index: number) => (
				<div
					key={item.id}
					className={classNames(
						"flex w-full flex-col gap-4 border-b p-2 sm:flex-row sm:items-center sm:justify-between",
						item.index % 2 ? "bg-gray-100" : ""
					)}
				>
					<div className="flex items-center justify-start">
						<h3>{item.nom}</h3>
					</div>

					<div className="ml-auto flex flex-row gap-x-2">
						<button
							className="flex select-none flex-row gap-2 rounded-md bg-primary py-2 px-3 text-white hover:bg-primarymedium"
							onClick={() => {
								setRaceEdit(item);
								setLiveTrackingModalOpened(true);
							}}
						>
							<FaMapMarkerAlt size={20} />
							{trad[lang].configure_live}
						</button>
						<button
							className="flex select-none flex-row gap-2 rounded-md bg-primary py-2 px-3 text-white hover:bg-primarymedium"
							onClick={() => {
								setRaceEdit(item);
								setUploadModalOpened(true);
							}}
						>
							<FaFileUpload size={20} />
							{trad[lang].configure_results}
						</button>
					</div>
				</div>
			))}

			{uploadModalOpened && (
				<UploadResultsModal
					open={uploadModalOpened}
					setOpen={setUploadModalOpened}
					raceSlug={raceEdit?.slug}
				/>
			)}

			{liveTrackingModalOpened && (
				<LiveTrackingUrlModal
					open={liveTrackingModalOpened}
					setOpen={setLiveTrackingModalOpened}
					refetch={refetchRuns}
					raceSlug={raceEdit?.slug}
					raceData={raceEdit}
				/>
			)}
		</Layout>
	);
}

export default ResultsEvent;
