import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import "regenerator-runtime";
import { bibPlan } from "../../api/event";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

import { Tooltip, Typography } from "@material-tailwind/react";
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { ILang } from "../../types/Lang";
import { BIB_PLAN_WAYS, DEFAULT_BIB_PLAN_VALUES } from "../../utils/BibPlan";
import Toast from "../../utils/Toasts";

function BibPlanModal({
	open,
	setOpen,
	lang,
	refetch,
	calendar,
	setChoosedCalendar
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	refetch: any;
	calendar: any;
	setChoosedCalendar: any;
}) {
	const init: any = DEFAULT_BIB_PLAN_VALUES;
	init.affectationWay = parseInt(
		calendar.configuration_calendrier.typeAffectationDossard
	);
	init.minBib = parseInt(calendar.configuration_calendrier.numeroPuceMini);
	init.maxBib = parseInt(calendar.configuration_calendrier.numeroPuceMaxi);
	init.exclusion = calendar.configuration_calendrier.exclusion_numero_dossard;

	const { slug } = useParams();
	const [form, setForm] = useState<any>(DEFAULT_BIB_PLAN_VALUES);
	const onlyDigitsRegex = new RegExp("^[0-9\b]+$");
	const [loading, setLoading] = useState<boolean>(false);

	const handleChange = (value: any, key: string) => {
		try {
			setForm((old: any) => ({ ...old, [key]: value }));

			// Handle upgrade maxBib if bibMax < bibMin when minBib dynamic change
			if (key == "affectationWay" && parseInt(value) == 1) {
				setForm((old: any) => ({ ...old, maxBib: form.minBib + 1 }));
			}

			if (key == "affectationWay" && parseInt(value) == 0) {
				setForm((old: any) => ({ ...old, maxBib: 0 }));
				setForm((old: any) => ({ ...old, minBib: 0 }));
			}

			// Change to min_max
			if (key == "minBib" && parseInt(value) >= parseInt(form.maxBib)) {
				setForm((old: any) => ({ ...old, maxBib: parseInt(value) + 1 }));
			}

			if (key == "orderAlphabetic" && value) {
				setForm((old: any) => ({ ...old, reAffect: true }));
			}

			if (key == "reAffect" && !value) {
				setForm((old: any) => ({ ...old, orderAlphabetic: false }));
			}
		} catch (error) {
			console.error(error);
		}
	};

	// Handle send infos to API
	const save = async () => {
		try {
			setLoading(true);
			// max < min
			if (parseInt(form.maxBib) < parseInt(form.minBib)) {
				Toast.error(trad[lang].maxInfMin);
				setLoading(false);
				return;
			}

			// API Call
			const result = await bibPlan(calendar.slug as string, calendar.id, form);

			if (result) {
				Toast.success(trad[lang].updatedBib);
				setLoading(false);
				refetch();
				setChoosedCalendar({});
				setOpen(false);
			} else {
				setLoading(false);
				Toast.error(trad[lang].errorBib);
			}
		} catch (error) {
			// Toast error
			setLoading(false);
			console.error(error);
			Toast.error(trad[lang].errorBib);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={setOpen}
				>
					<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block max-h-[80vh] transform overflow-y-auto overflow-x-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle md:max-w-2xl lg:max-w-4xl">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="mb-6 w-full sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{trad[lang].bibPlan} : {calendar.nom}
										</Dialog.Title>
									</div>

									{/* PLAN */}
									<div className="w-full">
										{
											<div key="list">
												{/* WAY OF NUMBERING */}
												<div className="my-6">
													<label className="mb-1 text-lg" htmlFor={"bibWay"}>
														{trad[lang].bibPlanAffectation}
													</label>

													<select
														id="affectationWay"
														className="w-full rounded-md"
														onChange={(e) =>
															handleChange(e.target.value, "affectationWay")
														}
														defaultValue={
															BIB_PLAN_WAYS[
																parseInt(
																	calendar.configuration_calendrier
																		.typeAffectationDossard
																)
															].value
														}
														value={form.affectationWay}
													>
														{BIB_PLAN_WAYS.map((item: any, index: number) => (
															<option
																id={"affectationWay"}
																value={item.value}
																key={item.value}
															>
																{item.label[lang]}
															</option>
														))}
													</select>
												</div>
												{/* MIN && MAX */}
												<div className="flew row my-6 flex w-full justify-start gap-4">
													{form.affectationWay != 0 && (
														<div className="flex w-[48%] flex-col">
															<label
																className="mb-1 text-lg"
																htmlFor={"minBib"}
															>
																{trad[lang].minBib}
															</label>
															<input
																type="number"
																min={0}
																name="minBib"
																id="minBib"
																value={form.minBib}
																onChange={(e) =>
																	onlyDigitsRegex.test(e.target.value)
																		? handleChange(e.target.value, "minBib")
																		: handleChange(0, "minBib")
																}
															/>
														</div>
													)}
													{form.affectationWay != 0 &&
														form.affectationWay != 2 && (
															<div className="flex w-[48%]  flex-col">
																<label
																	className="mb-1 text-lg"
																	htmlFor={"maxBib"}
																>
																	{trad[lang].maxBib}
																</label>
																<input
																	type="number"
																	min={0}
																	name="maxBib"
																	id="maxBib"
																	value={form.maxBib}
																	onChange={(e) =>
																		onlyDigitsRegex.test(e.target.value)
																			? handleChange(e.target.value, "maxBib")
																			: handleChange(0, "maxBib")
																	}
																/>
															</div>
														)}
												</div>
												{/* TO EXCLUDE */}
												<div className="w-full">
													<label
														className="flex-inline mb-1 flex text-lg"
														htmlFor={"exclusion"}
													>
														{trad[lang].bibExclusion}
														<Tooltip
															className="text-md z-50 w-80 text-start"
															content={
																<Typography>
																	<p>Exemple : </p> <br />
																	<p>{trad[lang].bib_plan_exemple_1}</p> <br />
																	<p>{trad[lang].bib_plan_exemple_2}</p> <br />
																	<p>{trad[lang].bib_plan_exemple_3}</p> <br />
																	<p>{trad[lang].bib_plan_exemple_4}</p> <br />
																</Typography>
															}
														>
															<p className=" my-auto ml-1">
																<AiOutlineInfoCircle />
															</p>
														</Tooltip>
													</label>
													<textarea
														id="exclusion"
														className="min-h-full w-full rounded-md"
														placeholder={trad[lang].bibExlusionPlaceholder}
														inputMode="text"
														onChange={(e) =>
															handleChange(e.target.value, "exclusion")
														}
														value={form.exclusion}
														defaultValue={
															calendar.configuration_calendrier.numeroPuceMax
														}
													/>
												</div>
												{/* CHECKBOX */}
												<div className="my-4">
													<input
														className="mr-2"
														type="checkbox"
														onChange={(e) =>
															handleChange(e.target.checked, "reAffect")
														}
														checked={form.reAffect}
														value={form.reAffect}
														id="reAffect"
													/>
													<label
														className="m-auto text-lg"
														htmlFor={"reAffect"}
													>
														{trad[lang].reaffectAllbibs}
													</label>
												</div>
												<div className="my-4">
													<input
														className="mr-2"
														type="checkbox"
														onChange={(e) =>
															handleChange(e.target.checked, "keepBibs")
														}
														checked={form.keepBibs}
														value={form.keepBibs}
														id="keepBibs"
													/>
													<label className="m-auto text-lg" htmlFor="keepBibs">
														{trad[lang].keepBibs}
													</label>
												</div>
												<div className="my-4">
													<input
														className="mr-2"
														type="checkbox"
														onChange={(e) =>
															handleChange(e.target.checked, "applyToPending")
														}
														checked={form.applyToPending}
														value={form.applyToPending}
														id="applyToPending"
													/>
													<label
														className="m-auto text-lg"
														htmlFor="applyToPending"
													>
														{trad[lang].applyToPending}
													</label>
												</div>
												<div className="">
													<input
														className="mr-2"
														type="checkbox"
														onChange={(e) =>
															handleChange(e.target.checked, "orderAlphabetic")
														}
														checked={form.orderAlphabetic}
														value={form.orderAlphabetic}
														id="orderAlphabetic"
													/>
													<label
														className="m-auto text-lg"
														htmlFor={"orderAlphabetic"}
													>
														{trad[lang].orderAlphabetic}
													</label>
												</div>
											</div>
										}
									</div>
								</div>

								<div className="mt-16 gap-4 sm:mt-14 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										disabled={loading}
										className={classNames(
											`${
												loading
													? "border-gray-300 bg-gray-400 focus:border-gray-300 "
													: "bg-secondary hover:bg-primary "
											}` +
												"inline-flex w-full items-center justify-center gap-2 rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:w-auto sm:text-sm"
										)}
										onClick={save}
									>
										{loading ? (
											<>
												<AiOutlineLoading3Quarters className="animate-spin" />
												{trad[lang].save}
											</>
										) : (
											trad[lang].save
										)}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
									>
										{trad[lang].cancel}
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default BibPlanModal;
