import { Tooltip, Typography } from "@material-tailwind/react";
import { useContext, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { importEventChoice } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const ListChoiceImport = ({
	id,
	refetch,
	close
}: {
	id: number;
	refetch: () => Promise<any>;
	close?: () => void;
}) => {
	const { slug, slug_race } = useParams();
	const { lang } = useContext(AppContext);
	const [textList, setTextList] = useState("");

	const importList = async () => {
		try {
			if (!slug || !slug_race) return;

			const data = textList
				.split("\n")
				.join(";")
				.split(";")
				.map((item) => item.trim())
				.filter((item) => item);

			const filteredData = data.filter(
				(item, index, self) => self.indexOf(item) === index
			);

			if (filteredData.length == 0) {
				Toast.error(trad[lang].empty_list_text);
				return;
			}

			await importEventChoice(
				slug,
				slug_race,
				id,
				filteredData.map((item) => ({ name: item }))
			);

			refetch();

			if (close != undefined) {
				close();
			}
		} catch (error) {
			throw error;
		}
	};

	return (
		<div className="flex w-full flex-col items-center gap-4">
			<h3 className="flex w-full flex-row items-center gap-2">
				{trad[lang].add_list_choice}
				<Tooltip
					className="text-md z-50 text-center"
					content={<Typography>{trad[lang].lists_text_desc}</Typography>}
				>
					<p>
						<AiOutlineInfoCircle size={18} />
					</p>
				</Tooltip>
			</h3>
			<textarea
				value={textList}
				onChange={(e) => setTextList(e.target.value)}
				className="h-36 w-full resize-none rounded-md border border-gray-300 pl-2 focus:outline-none"
			/>

			<div
				className={close != undefined ? "flex w-full justify-start gap-4" : ""}
			>
				{close != undefined && (
					<button
						className="w-fit rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300"
						onClick={close}
					>
						{trad[lang].close}
					</button>
				)}

				<button
					type="button"
					className="w-fit rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-colors hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300"
					onClick={() => importList()}
				>
					{trad[lang].import_list}
				</button>
			</div>
		</div>
	);
};

export default ListChoiceImport;
