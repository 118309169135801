import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { findUser } from "../api/auth";
import trad from "../lang/traduction";
import Toast from "../utils/Toasts";
import { AppContext } from "./AppContext";
import { UserContext } from "./UserContext";

type SessionContextType = {};

export const SessionContext = createContext<SessionContextType>(
	{} as SessionContextType
);

const SessionProvider = () => {
	const { lang } = useContext(AppContext);
	const { setUser, user, permissionsRef, setAnnouncements } =
		useContext(UserContext);

	useQuery({
		queryKey: ["test_user"],
		// Do not refresh all permissions for someone that have a ton of events
		queryFn: () =>
			findUser(
				permissionsRef.current && Object.keys(permissionsRef.current).length < 3
			),
		cacheTime: 0,
		refetchInterval: process.env.REACT_APP_USER_REFETCH_INTERVAL
			? parseInt(process.env.REACT_APP_USER_REFETCH_INTERVAL)
			: 30000,
		refetchIntervalInBackground: true,
		refetchOnWindowFocus: false,
		retry: false,
		onError: () => {
			localStorage.removeItem("newConnexion");
			localStorage.removeItem("token");
			if (process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER) {
				localStorage.removeItem(process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER);
			}
			Toast.error(trad[lang].login_expired);
			setUser(undefined);
		},
		onSuccess: (data: any) => {
			if (data && data.permissions && permissionsRef) {
				if (
					JSON.stringify(data.permissions) !=
					JSON.stringify(permissionsRef.current)
				) {
					permissionsRef.current = data.permissions;
				}
			}
			if (data && data.announcements) setAnnouncements(data.announcements);
		},
		enabled: !!user
	});

	return <SessionContext.Provider value={{}}></SessionContext.Provider>;
};

export default SessionProvider;
