const padNumber = (str: string | number, size: number) => {
	let new_str = str.toString();

	while (new_str.length < size) {
		new_str = "0" + new_str;
	}

	return new_str;
};

export { padNumber };
