import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import {
	AiFillEye,
	AiOutlineClose,
	AiOutlineCloseCircle,
	AiOutlineFileAdd
} from "react-icons/ai";
import { GrDocumentVerified } from "react-icons/gr";
import { HiOutlineXMark } from "react-icons/hi2";
import { IoMdAddCircle } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { useQuery } from "react-query";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { getRaceResultFiles, updateRaceResultFiles } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { fileToB64 } from "../../utils/Base64";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";

function UploadResultsModal({
	open,
	setOpen,
	raceSlug
}: {
	open: boolean;
	setOpen: any;
	raceSlug: string;
}) {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [openedFile, setOpenedFile] = useState<any>({});
	const closeFile = () => setOpenedFile((old: any) => ({}));
	const [form, setForm] = useState<any[]>([]);

	const {
		data: dataFiles,
		isLoading: loadingDataFiles,
		isError
	} = useQuery({
		queryKey: ["getRaceResultFiles", raceSlug],
		queryFn: () => getRaceResultFiles(raceSlug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!raceSlug
	});

	useEffect(() => {
		if (dataFiles) {
			setForm(dataFiles);
		}
	}, [dataFiles]);

	const onClose = function () {
		setOpen(false);
		setForm([]);
	};

	// HANDLE SAVE BUTTON
	const onSaveClick = async () => {
		try {
			await updateRaceResultFiles(raceSlug, form);
			Toast.success(trad[lang].saveOk);
			onClose();
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].saveError);
		}
	};

	const onDeleteEntry = function (index: number) {
		const newForm = form.filter((_, i) => i !== index);
		setForm([...newForm]);
	};

	const onAddEntry = function () {
		const newForm = form;
		newForm.push({ file: null });
		setForm([...newForm]);
	};

	const onFileSelected = async (files: FileList | null, index: number) => {
		try {
			// Remove file (trash icon clicked)
			if (!files) {
				if (form[index] && form[index].file) {
					delete form[index].file;
					setForm([...form]);
				}
				return;
			}

			const file = files[0];

			// If not PDF, return
			if (file.type != "application/pdf") {
				Toast.error(trad[lang].file_error);
				return;
			}

			// Reset input
			(
				document.querySelector(`#${"upload" + index}`) as HTMLInputElement
			).value = "";

			// Store in form
			const fileb64 = await fileToB64(file);
			if (form[index]) {
				form[index].file = fileb64;
				form[index].name = file.name;
				form[index].filetype = file.type;
			} else
				form[index] = { name: file.name, file: fileb64, filetype: file.type };
			setForm([...form]);
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].file_error);
		}
	};

	const resizeIframe = () => {
		const iframe: HTMLIFrameElement | null =
			document.querySelector("#document_file");
		if (!iframe) return;

		const scroll_height = iframe?.contentWindow?.document.body.scrollHeight;
		const scroll_width = iframe?.contentWindow?.document.body.scrollHeight;

		if (!scroll_height || !scroll_width) return;

		iframe.height = scroll_height?.toString();
		iframe.width = scroll_width?.toString();
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative mt-auto mb-auto inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:p-6 sm:align-middle">
							<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<button
									type="button"
									className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
									onClick={() => onClose()}
								>
									<span className="sr-only">Close</span>
									<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mb-6 w-full sm:items-start">
								<div className="mt-3 w-full px-6 text-center sm:mt-0 sm:text-left">
									<Dialog.Title
										as="h3"
										className="text-center text-xl font-bold leading-6 text-gray-900"
									>
										{trad[lang].configure_results}
									</Dialog.Title>
								</div>

								<div className="grid grid-cols-1 px-8">
									{form.map((fileData, index) => (
										<div key={index}>
											<div className="mt-8 flex flex-row items-center justify-center text-center">
												<div className="mt-8 flex-col items-center justify-center text-center">
													{!fileData || !fileData.file ? (
														<label
															className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
															htmlFor={"upload" + index}
														>
															<div>
																<AiOutlineFileAdd color="#ffffff" size={40} />
																<label className="font-medium text-white">
																	PDF
																</label>
															</div>
														</label>
													) : (
														<label
															className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-md bg-cyan-700 bg-opacity-30 transition-colors hover:bg-cyan-600 hover:bg-opacity-30"
															htmlFor={"upload" + index}
														>
															<GrDocumentVerified
																size={40}
																className="GRwhite"
															/>
														</label>
													)}

													<input
														type="file"
														id={"upload" + index}
														onChange={(e) => {
															onFileSelected(e.target.files, index);
														}}
														hidden
														disabled={false}
														accept="application/pdf"
													/>

													<div className="mt-2 ml-4 flex flex-row gap-x-2">
														{/* BUTTONS */}
														{fileData && fileData.file && (
															<>
																{/* EYE */}
																<button
																	className="flex h-7 w-7 items-center justify-center rounded-full bg-cyan-700 bg-opacity-30"
																	onClick={() => {
																		// If file start with http, it's a link, open in tab (to avoid CORS from media server)
																		let file = fileData.file;
																		if (file.startsWith("http")) {
																			window.open(fileData.file, "_blank");
																		} else if (setOpenedFile) {
																			setOpenedFile({
																				file: fileData.file
																			});
																		}
																	}}
																>
																	<AiFillEye className="h-5 w-5 fill-white" />
																</button>
																{/* EDIT */}
																<label
																	htmlFor={"upload" + index}
																	className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-cyan-700 bg-opacity-30"
																>
																	<MdOutlineEdit className="h-5 w-5 fill-white" />
																</label>
															</>
														)}
													</div>
												</div>
												<div className="ml-4 mt-5 flex flex-col text-left">
													<p className="text-sm font-medium text-gray-900">
														{trad[lang].document_title}
													</p>
													<input
														type="text"
														className="mt-1 w-52 rounded-md border-gray-300 shadow-sm focus:border-cyan-700 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
														placeholder={trad[lang].ranking}
														onChange={(e) => {
															form[index].title = e.target.value;
															setForm([...form]);
														}}
														value={fileData.title}
													/>
												</div>
												{/* DELETE */}
												<button
													className="mb-auto mt-7 -ml-6 flex h-7 w-7 items-center justify-center rounded-full align-top"
													onClick={() => {
														onDeleteEntry(index);
													}}
												>
													{/* <IoMdCloseCircle className="h-7 w-7 fill-red-500" /> */}
													<AiOutlineCloseCircle className="h-7 w-7 fill-red-500" />
													{/* <AiOutlineDelete className="h-5 w-5 fill-white" /> */}
												</button>
												{/* SEPARATOR HORIZONTAL */}
											</div>
											<hr className="mt-8 w-96 border-gray-300" />
										</div>
									))}
									{/* ADD BUTTON, JUST A BUTTON WITH ADD LABEL, NO UPLOAD */}
									<button
										className="mt-8 flex flex-col items-center justify-center text-center"
										onClick={() => {
											onAddEntry();
										}}
									>
										<IoMdAddCircle className="h-11 w-11 fill-primary" />
									</button>
								</div>
							</div>
							<div className="mt-3 ml-auto">
								<button
									className={classNames(
										"ml-auto flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
									)}
									onClick={onSaveClick}
								>
									{trad[lang].save}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
				{openedFile && openedFile.file && (
					<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
						<div className="max-h-[100vh] w-full max-w-[300px] rounded-md border bg-white sm:max-w-[760px] md:max-w-[1200px]">
							<div className="flex items-start justify-between rounded-t border-b p-4">
								<h3 className="text-xl font-semibold text-gray-900">
									{trad[lang].manage_documents}
								</h3>
								<button
									type="button"
									className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
									onClick={closeFile}
								>
									<AiOutlineClose size={16} />
									<span className="sr-only">Close modal</span>
								</button>
							</div>

							<div className="flex w-full items-center justify-center text-center">
								{openedFile.file && openedFile.file.includes("data:image") ? (
									<img
										src={openedFile.file}
										className="m-auto h-[80vh] w-full"
									/>
								) : (
									<iframe
										src={openedFile.file}
										id="document_file"
										className="h-[80vh] w-full"
										onLoad={resizeIframe}
										seamless={true}
									/>
								)}
							</div>

							<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
								<button
									type="button"
									className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
									onClick={closeFile}
								>
									{trad[lang].close_documents}
								</button>
							</div>
						</div>
					</div>
				)}
			</Dialog>
		</Transition.Root>
	);
}

export default UploadResultsModal;
