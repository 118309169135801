import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	getEventIdentityConfig,
	getEventRunsConfig,
	getEventSubscriptionFormFields
} from "../../../api/event";
import BreadCrumb from "../../../components/common/BreadCrumb";
import PageLoader from "../../../components/common/PageLoader";
import EventConfigurationBottomBar from "../../../components/event_configuration/EventConfigurationBottomBar";
import SportEventRunModal from "../../../components/event_configuration/Sport/SportEventRunModal";
import SportRunModule from "../../../components/event_configuration/Sport/SportRunModule";
import { AccessKeys } from "../../../components/navigation/AccessGuard";
import Layout from "../../../components/navigation/Layout";
import { BASE_RUN_LIGHT } from "../../../config/EventForms/Sport/SportBase";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IRunFormConfig } from "../../../types/EventForms/ISportRun";
import Toast from "../../../utils/Toasts";

const SportEventRuns = () => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const navigate = useNavigate();
	const { state } = useLocation();

	// Add button div ref
	const addButtonRef = useRef<any>(null);
	const [isDisplayOrderUpdated, setIsDisplayOrderUpdated] =
		useState<boolean>(false);

	// Gestion de l'édition/création de la course
	const [openedRun, setOpenedRun] = useState<any>(null);

	useEffect(() => {
		if (isDisplayOrderUpdated) setIsDisplayOrderUpdated(false);
	}, [isDisplayOrderUpdated]);

	// Si un slug est présent, récupération des infos existante
	const {
		data: RunsInfos = [],
		isLoading: RunsLoading,
		refetch: RefetchRuns
	} = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data: RunFields = [] } = useQuery<IRunFormConfig[]>({
		queryKey: ["run_fields"],
		queryFn: getEventSubscriptionFormFields,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false
	});

	const {
		data: EventInfos,
		isLoading: EventLoading,
		isError
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const openRun = () =>
		setOpenedRun({
			...BASE_RUN_LIGHT,
			form: Object.fromEntries(
				RunFields.map((item) => [
					item.type.nom_champs,
					{
						visible: item.type.visible,
						required: item.type.obligatoire,
						id: item.type.id
					}
				])
			)
		});

	const editRun = (run: any) => setOpenedRun(run);

	const handleGoBack = () => {
		if (state?.newEvent) {
			navigate(`/${slug}/event-configuration/sport`, {
				state: state
			});
		} else {
			navigate(`/${slug}/event-details`);
		}
	};

	const saveAndNext = async () =>
		navigate(`/${slug}/event-details`, {
			state: state
		});

	useEffect(() => {
		if (slug && isError) {
			Toast.error(trad[lang].event_does_not_exist);
			navigate(`/`);
		}
	}, [isError]);

	if (RunsLoading || EventLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout
			active_key="configuration"
			bottomBar={
				<>
					<div
						ref={addButtonRef}
						className="flex w-full items-center justify-center bg-white p-5 md:static md:my-0 md:flex md:pl-8"
					>
						<button
							className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
							onClick={() => openRun()}
						>
							{trad[lang].event_add_run}
						</button>
					</div>

					<EventConfigurationBottomBar
						slug={slug}
						loading={false}
						stepValid={true}
						goBack={handleGoBack}
						saveAndNext={saveAndNext}
						tradKey="save_and_finish"
						permissionsKey={AccessKeys.EVENT_RUNS_UPDATE}
					/>
				</>
			}
			full
		>
			{slug && !state?.newEvent && (
				<div className="px-5">
					<BreadCrumb
						items={[
							{
								key: "event",
								text: EventInfos.name,
								to: `/${slug}/event-details`,
								active: false
							},
							{ key: "event_runs", to: "#", active: true }
						]}
					/>
				</div>
			)}

			{state?.newEvent && (
				<div className="px-5">
					<BreadCrumb
						items={[
							{ key: "dashboard", to: "/", active: false },
							{
								key: "event_configuration",
								to: "/event-configuration",
								active: false
							},
							{
								key: "event_identity",
								to: `/${slug}/event-configuration/sport`,
								active: false
							},
							{ key: "event_runs", to: "#", active: true }
						]}
					/>
				</div>
			)}

			{/* Title */}
			<h1 className="mb-6 mt-6 px-5 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
				{trad[lang].sport_event_runs}
			</h1>

			{/* Runs sorted by displayOrder id */}

			{RunsInfos.sort((a: any, b: any) => a.displayOrder - b.displayOrder).map(
				(run: any) => (
					<SportRunModule
						run={run}
						runs={RunsInfos}
						refetch={RefetchRuns}
						index={run.index}
						key={run.index}
						editRun={editRun}
						formFields={RunFields}
						timezone={EventInfos.timezone}
						setIsDisplayOrderUpdated={setIsDisplayOrderUpdated}
					/>
				)
			)}

			{openedRun != null && (
				<SportEventRunModal
					eventInfos={EventInfos}
					eventType={EventInfos.eventTypeCategory}
					run={openedRun}
					setRun={setOpenedRun}
					refetch={RefetchRuns}
					timezone={EventInfos.timezone}
				/>
			)}
		</Layout>
	);
};

export default SportEventRuns;
