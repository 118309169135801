import { HookLive } from "./APIClient";

export const startPrestation = async (idCalendrier: number) => {
	try {
		// await generateSegments(idCalendrier.toString());
		const { data } = await HookLive.put(`/start/${idCalendrier}`);

		return data;
	} catch (error) {
		console.warn("[startPrestation error]", error);
		throw error;
	}
};

export const stopPrestation = async (idCalendrier: number) => {
	try {
		const { data } = await HookLive.put(`/stop/${idCalendrier}`);

		return data;
	} catch (error) {
		console.warn("[startPrestation error]", error);
		throw error;
	}
};

export const postMessage = async (
	idCalendrier: number,
	message: any,
	transmission = false
) => {
	try {
		const { data } = await HookLive.post(
			`/?idCalendrier=${idCalendrier}&awaited=true&transmission=${transmission}`,
			message
		);

		return data;
	} catch (error) {
		console.warn("[updateDetection error]", error);
		throw error;
	}
};
