import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import "regenerator-runtime";
import { deleteAllDetection } from "../../api/detection";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";

function DeleteDetectionModal({
	open,
	setOpen,
	lang,
	refetch,
	selectedLibelle,
	name,
	prestation,
	races
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	refetch: any;
	selectedLibelle?: string;
	name?: string;
	prestation?: string;
	races?: any;
}) {
	const { slug } = useParams();

	const handleDeletion = async () => {
		try {
			if (!prestation || !slug) {
				Toast.error(trad[lang].deletionError);
				return;
			}

			await deleteAllDetection(slug, races);
			await refetch();
			setOpen(false);
			Toast.success(trad[lang].deletionSuccess);

			return;
		} catch (error) {
			Toast.error(trad[lang].deletionError);
		}
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={setOpen}
				>
					<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle md:max-w-2xl lg:max-w-4xl">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="mb-6 w-full sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{selectedLibelle
												? `${trad[lang].deleteTitle} : ${selectedLibelle}`
												: `${trad[lang].all} : ${name}`}
										</Dialog.Title>
									</div>

									{/* Detections */}
									<div className="w-full">
										<p className="mt-6">{trad[lang].deleteAllDetections}</p>
									</div>
								</div>
								<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className={classNames(
											"mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
										)}
										onClick={() => handleDeletion()}
									>
										{trad[lang].delete}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
									>
										{trad[lang].cancel}
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default DeleteDetectionModal;
