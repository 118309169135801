export const calculateStatus = (subscription: any): number => {
	const { valid, status, ide_type_inscription } = subscription;
	if (valid == 0 && status == 1) return 0;
	if (valid == 1 && status == 1) return 1;
	if (status == 0 && ide_type_inscription == 1 && valid != 2) return 3;
	if (valid == 2 || status == 0) return 2;
	if (valid == 3) return 0;

	return -1;
};
