// Way of numbering bib plan
export const BIB_PLAN_WAYS: any = [
	{
		value: 0,
		label: {
			fr: "Manuelle",
			en: "Manual"
		}
	},
	{
		value: 1,
		label: {
			fr: "Dossard minimum jusqu'au dossard maximum",
			en: "From min to max"
		}
	},
	{
		value: 2,
		label: {
			fr: "Incrémentation du numéro de dossard pour toutes les courses de l'édition",
			en: "Increment bib for all races"
		}
	}
];

export const DEFAULT_BIB_PLAN_VALUES = {
	affectationWay: 1,
	minBib: 0,
	maxBib: 0,
	exclusion: "",
	reAffect: true,
	keepBibs: true,
	applyToPending: false,
	orderAlphabetic: false
};
