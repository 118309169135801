import { Fragment, useEffect, useMemo, useState } from "react";
import { AiOutlineAim } from "react-icons/ai";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import { useQuery } from "react-query";
import { getAppInfos } from "../../api/event";
import {
	CustomEndMarker,
	CustomStartEndMarker,
	CustomStartMarker
} from "./CustomMarker";

const AppMap = ({
	id,
	mapRef,
	children,
	polylines = []
}: {
	id: number;
	mapRef: any;
	children?: React.ReactNode;
	polylines?: [number, number][][];
}) => {
	const [recentered, setRecentered] = useState(false);

	const { data: AppInfos } = useQuery({
		queryKey: ["app_infos", id],
		queryFn: () => getAppInfos(id),
		refetchOnWindowFocus: false,
		enabled: !!id
	});

	const appPolylines = useMemo(() => {
		if (!AppInfos?.traces?.length) return [];

		return AppInfos.traces;
	}, [AppInfos?.traces]);

	const recenter = () => {
		if (!mapRef?.current) return;

		if (polylines.length > 0) {
			const polyline = polylines.flat(1);
			if (polyline.length > 1) {
				mapRef?.current.fitBounds(polylines.flat(1));
				setRecentered(true);
				return;
			}
		}

		if (AppInfos?.traces?.length > 0) {
			const traces = AppInfos.traces.map((trace: any) =>
				trace.positions.map((position: any) => [
					position.latitude,
					position.longitude
				])
			);

			mapRef?.current.fitBounds(traces.flat(1));
			setRecentered(true);
			return;
		}
	};

	const displayedPolylines = [
		...appPolylines.map((item: any, index: number) => ({
			id: `app_${index}`,
			isApp: true,
			color: "#000000",
			positions: item.positions.map((item: any) => [
				item.latitude,
				item.longitude
			])
		})),
		...polylines.map((item, index: number) => ({
			id: `user_${index}`,
			isApp: false,
			color: "#8884d8",
			positions: item
		}))
	];

	useEffect(() => {
		if (recentered) return;
		recenter();
	}, [mapRef?.current, AppInfos?.traces, polylines, recentered]);

	return (
		<div className="h-full w-full overflow-hidden rounded-md">
			<MapContainer
				center={[45.75, 4.85]}
				zoom={13}
				scrollWheelZoom={false}
				className="relative h-full w-full"
				ref={mapRef}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<div
					className="absolute top-2 right-2 z-[400] flex h-8 w-8 cursor-pointer items-center justify-center rounded-md border-2 border-black/50 bg-white transition-colors hover:bg-slate-200"
					onClick={recenter}
				>
					<AiOutlineAim size={17} />
				</div>

				{displayedPolylines.map((item) => (
					<Fragment key={item.id}>
						<Polyline
							positions={item.positions}
							color={item.color}
							weight={item.isApp ? 3 : 6}
						/>

						{item.isApp && (
							<>
								{item.positions[0][0] ==
									item.positions[item.positions.length - 1][0] &&
								item.positions[0][1] ==
									item.positions[item.positions.length - 1][1] ? (
									<Marker
										position={[item.positions[0][0], item.positions[0][1]]}
										title={`${item.name} - Départ & Arrivée`}
										icon={CustomStartEndMarker()}
									/>
								) : (
									<>
										<Marker
											position={[item.positions[0][0], item.positions[0][1]]}
											title={`${item.name} - Départ`}
											icon={CustomStartMarker()}
										/>

										<Marker
											position={[
												item.positions[item.positions.length - 1][0],
												item.positions[item.positions.length - 1][1]
											]}
											title={`${item.name} - Arrivée`}
											icon={CustomEndMarker()}
										/>
									</>
								)}
							</>
						)}
					</Fragment>
				))}

				{children}
			</MapContainer>
		</div>
	);
};

export default AppMap;
