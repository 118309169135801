import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdClose } from "react-icons/md";

interface Modal {
	children: JSX.Element;
	button?: boolean;
	titleButton?: string;
	titleCancelButton?: string;
	width?: string;
	height?: string;
	deleteBtn?: boolean;
	cancelBtn?: boolean;
	rounded?: boolean;
	isLoading?: boolean;
	style?: React.CSSProperties | undefined;
	onClickButton?: () => void;
	onClickCancelButton?: () => void;
	closeModal: () => void;
}

const Modal = ({
	children,
	button,
	titleButton,
	width,
	height,
	deleteBtn,
	cancelBtn,
	rounded,
	isLoading,
	titleCancelButton,
	style,
	onClickCancelButton,
	onClickButton,
	closeModal
}: Modal) => {
	return (
		<div className="fixed inset-0 z-10 flex h-full w-full items-center justify-center">
			{/* Background overlay */}
			<div
				className="absolute inset-0 bg-[rgba(49,49,49,0.6)]"
				onClick={closeModal}
			/>

			{/* Modal content */}
			<div
				style={style}
				className={`absolute flex flex-col items-center justify-center bg-white p-4
          ${height ? `h-[${height}]` : "h-[80%]"} 
          min-h-[200px] w-full min-w-[300px] md:min-w-[400px] 
          ${rounded ? "rounded" : ""}
          left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
          sm:w-[70%] md:w-full lg:w-[70%]`}
			>
				{/* Children */}
				{children}

				{/* Buttons */}
				<div className="flex w-[50%] flex-row">
					{isLoading && (
						<AiOutlineLoading3Quarters className="animate-spin" size={20} />
					)}
					{cancelBtn && (
						<button
							onClick={onClickCancelButton}
							className="m-2 flex w-full cursor-pointer flex-row items-center justify-center gap-1 rounded-md border border-primary bg-primary py-2 px-2 text-xs text-white duration-150 hover:bg-white hover:text-primary md:uppercase"
						>
							{titleCancelButton}
						</button>
					)}
					{button && (
						<button
							onClick={onClickButton}
							className="m-2 flex w-full cursor-pointer flex-row items-center justify-center gap-1 rounded-md border border-primary bg-primary py-2 px-2 text-xs text-white duration-150 hover:bg-white hover:text-primary md:uppercase"
						>
							{titleButton}
						</button>
					)}
					{deleteBtn && (
						<button
							onClick={onClickButton}
							className="m-2 flex w-full cursor-pointer flex-row items-center justify-center gap-1 rounded-md border border-red-500 bg-red-500 py-2 px-2 text-xs text-white duration-150 hover:bg-white hover:text-red-500 md:uppercase"
						>
							{titleButton}
						</button>
					)}
				</div>

				{/* Close button */}
				<button
					className="absolute top-2 right-2 z-10 rounded bg-white p-2 text-2xl"
					onClick={closeModal}
				>
					<MdClose />
				</button>
			</div>
		</div>
	);
};

export default Modal;
