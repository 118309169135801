import md5 from "md5";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from "react";
import { AiOutlineClose, AiOutlineMan, AiOutlineWoman } from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { API } from "../../api/APIClient";
import { getEventRunsConfig } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const SubscriptionToAnotherRace = ({
	subscription,
	setOpenedSubscriptionToAnotherRace,
	refetch
}: {
	subscription: any;
	setOpenedSubscriptionToAnotherRace: Dispatch<SetStateAction<any>>;
	refetch: Function;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const { liveConfig } = useContext(LiveContext);
	const GENDERS: any = {
		1: <AiOutlineMan size={20} color="rgb(0, 150, 255)" />,
		2: <AiOutlineWoman size={20} color="rgb(222, 49, 99)" />
	};
	const CHOICE: any = ["Oui", "Non"];
	const [availableRaces, setAvailableRaces] = useState<any>();
	const [newRace, setNewRace] = useState<number>(-1);
	const [link, setLink] = useState<string>("");

	const initialValues = () => {
		setOpenedSubscriptionToAnotherRace(null);
		setAvailableRaces([]);
	};

	const { data: races, isLoading: isLoadingRaces } = useQuery({
		queryKey: ["races", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false
	});

	useEffect(() => {
		if (!races || races?.length <= 0) return;

		const temp = races.filter(
			(race: any) => race.name != subscription.calendrier.nom
		);
		setAvailableRaces(temp);
	}, [races]);

	const closeChangeRace = () => setOpenedSubscriptionToAnotherRace(null);

	const handleNewRaceChange = async (value: any) => {
		// change etat new bib in new race
		setNewRace(parseInt(value));
	};

	useEffect(() => {
		if (newRace >= 0) {
			const race = races.find((r: any) => r.id == newRace);
			if (race) {
				const idCourse = race.id_produit;
				const idTarif = race.prices[0].id_tarif;
				const hash = md5(
					subscription.ide_personne.toString() + process.env.REACT_APP_HASH_KEY
				);
				const b64data = btoa(
					JSON.stringify({
						idPersonne: subscription.ide_personne,
						newIdProduitCourse: idCourse,
						bypass: true,
						hash: hash
					})
				);
				const link = `${process.env.REACT_APP_CHRONO_URL}/challengeRegistration/${slug}/tarif/${idCourse}/${idTarif}?b64data=${b64data}`;
				setLink(link);
			}
		}
	}, [newRace]);

	const sendFormUrl = async () => {
		try {
			if (newRace == -1) {
				Toast.error(trad[lang].requiredRace);
				return;
			}

			if (subscription && link && newRace >= 0) {
				const race = races.find((r: any) => r.id == newRace);
				const body = {
					to_mail: subscription.person.email,
					ide_personne: subscription.ide_personne,
					link: link,
					eventName: race.name,
					personName: subscription.person.name
				};
				const { data } = await API.post(
					`/subscription/${subscription.id}/sendSubscriptionLink`,
					body
				);
				Toast.success(trad[lang].newRaceUpdateSuccess);
			}
			refetch();
			initialValues();
		} catch (error) {
			Toast.error(trad[lang].newRaceUpdateError);
		}
	};

	const onClickFormURL = async () => {
		if (link) {
			window.open(link, "_blank");
		}
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-w-screen max-h-[90vh] rounded-md border bg-white">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="inline-flex items-center text-xl font-semibold text-gray-900">
						Envoyer un lien d'inscription à {subscription.person.firstname}{" "}
						{subscription.person.lastname}
						<span className="mx-1">{GENDERS[subscription.person.sex]}</span>
					</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={closeChangeRace}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				{isLoadingRaces ? (
					// LOADING SPINNER
					<div className="flex items-center justify-center p-6">
						<div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-primary"></div>
					</div>
				) : (
					<div className="overflow-y-auto overflow-x-hidden px-4 py-6 text-center">
						<h3 className="inline-flex items-center"></h3>
						{/* Race */}
						{availableRaces?.length ? (
							<div className="m-auto w-[50%]">
								Choisir la course
								<select
									id="race"
									name="race"
									className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
									value={newRace}
									onChange={(e) => {
										handleNewRaceChange(e.target.value);
									}}
								>
									<option value="-1">{trad[lang].select_race}</option>
									{availableRaces &&
										availableRaces.map((race: any, index: number) => (
											<option key={index} value={race.id}>
												{race.name}
											</option>
										))}
								</select>
							</div>
						) : (
							trad[lang].cannotChangeRace
						)}

						{newRace > 0 && (
							<button
								className="block w-full cursor-pointer pt-1 text-base font-medium text-primary hover:text-primarymedium"
								onClick={() => {
									onClickFormURL();
								}}
							>
								Prévisualiser le formulaire
							</button>
						)}
					</div>
				)}

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-6">
					<button
						type="button"
						className="ml-auto rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
						onClick={closeChangeRace}
					>
						{trad[lang].close_documents}
					</button>
					{availableRaces?.length != 0 && (
						<button
							type="button"
							className="mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
							onClick={sendFormUrl}
						>
							Envoyer le lien
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default SubscriptionToAnotherRace;
