import { Dialog, Transition } from "@headlessui/react";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	Tooltip,
	Typography
} from "@material-tailwind/react";
import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
	BsFillExclamationTriangleFill,
	BsFillPersonFill
} from "react-icons/bs";
import { HiOutlineXMark } from "react-icons/hi2";
import { useQuery } from "react-query";
import { useDebounce } from "usehooks-ts";
import { API } from "../../api/APIClient";
import { addSub, getEventRunsConfig } from "../../api/event";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import { FFAErrorCode } from "../../types/FFA";
import { ILang } from "../../types/Lang";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";
import SelectCountry from "../common/SelectCountry";
import RegistrationEditChoices from "./RegistrationEditChoices";
import RegistrationEditOption from "./RegistrationEditOption";

const requiredInput = {
	color: "red"
};

function AddSubModal({
	open,
	setOpen,
	lang,
	raceData,
	slug,
	refetch,
	bibList
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	raceData: {
		id: number;
		configuration_calendrier: { nb_equipier: number };
		nom: string;
		slug: string;
	};
	slug: string | undefined;
	refetch: any;
	bibList: number[];
}) {
	const { liveConfig } = useContext(LiveContext);
	const maxBibNumber = 999999;
	const [canSave, setCanSave] = useState(false);
	const [canNext, setCanNext] = useState(false);
	const [openRunner, setOpenRunner] = useState(0);
	const [birthdateError, setBirthdateError] = useState<boolean>(false);
	const [minMaxAgeErrorMessage, setMinMaxAgeErrorMessage] =
		useState<string>("");
	const [editForm, setEditForm] = useState<any>({});
	const [team, setTeam] = useState<string>("");
	const [stepName, setStepName] = useState<string>("team");
	const [mails, setMails] = useState<string[]>([]);
	const debouncedMails = useDebounce(mails, 300);
	const [editFormOptions, setEditFormOptions] = useState<any>({});
	const [editFormChoices, setEditFormChoices] = useState<any>({});
	const [licenceError, setLicenceError] = useState<string>("");
	const [ppsError, setPpsError] = useState<string>("");
	const [checkUsedMail, setCheckUsedMail] = useState<boolean>(false);
	const [identicalEmails, setIdenticalEmails] = useState<boolean>(false);
	const [duplicateEmails, setDuplicateEmails] = useState<string[]>([]);

	const {
		data: RunsInfos = [],
		isLoading: RunsLoading,
		refetch: RefetchRuns
	} = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const getReferenceDate = (
		ageControl: number,
		run: any,
		editForm: any
	): string => {
		const eventStartDate = run?.startDate;
		const registrationDate = editForm?.dateInscription;

		switch (ageControl) {
			case 4:
				return dayjs(eventStartDate).startOf("year").format("YYYY");
			case 2:
				return eventStartDate;
			case 3:
				return registrationDate;
			default:
				return dayjs().format("YYYY-MM-DD");
		}
	};

	const run = RunsInfos.find((item: any) => item.id === raceData.id);
	const referenceDate = getReferenceDate(
		run?.prices[0]?.ageControl,
		run,
		editForm
	);

	let runnerAge = 0;

	switch (run?.prices[0]?.ageControl) {
		case 4:
			runnerAge = dayjs(referenceDate, "YYYY").diff(
				dayjs(editForm?.list?.[0]?.birthdate),
				"years"
			);
			break;
		case 3:
		case 2:
		default:
			runnerAge = dayjs(referenceDate).diff(
				dayjs(editForm?.list?.[0]?.birthdate),
				"years"
			);
			break;
	}

	const minAge = run?.prices[0]?.minAge;
	const maxAge = run?.prices[0]?.maxAge;
	const isMinAgeAvailable = minAge ? runnerAge >= minAge : runnerAge > 0;
	const isMaxAgeAvailable = maxAge ? runnerAge <= maxAge : runnerAge > 0;
	const isAgeUnavailable = !isMinAgeAvailable || !isMaxAgeAvailable;

	console.log(runnerAge, isMinAgeAvailable, isMaxAgeAvailable);

	const observation = JSON.parse(liveConfig?.observation);

	const requiredFields: string[] = [
		"lastName",
		"firstName",
		"birthdate",
		"gender"
	];

	useEffect(() => {
		if (!isMinAgeAvailable && minAge > 0 && run?.prices[0]?.ageControl === 2) {
			setMinMaxAgeErrorMessage(`L'âge minimum requis est de ${minAge} ans.`);
		} else if (
			!isMaxAgeAvailable &&
			maxAge > 0 &&
			run?.prices[0]?.ageControl === 2
		) {
			setMinMaxAgeErrorMessage(`L'âge maximum requis est de ${maxAge} ans.`);
		} else if (
			(!isMaxAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 4) ||
			(!isMinAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 4)
		) {
			setMinMaxAgeErrorMessage(
				`L'année de naissance doit être comprise entre ${
					parseInt(dayjs(run?.startDate).format("YYYY")) - maxAge - 1
				} et ${
					parseInt(dayjs(run?.startDate).format("YYYY")) - minAge - 1
				} inclus.`
			);
		} else if (
			(!isMaxAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 3) ||
			(!isMinAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 3)
		) {
			setMinMaxAgeErrorMessage(
				`Vous devez avoir au moins ${minAge} ans ou au plus ${maxAge} ans au moment de l'inscription.`
			);
		} else {
			setMinMaxAgeErrorMessage("");
		}
	}, [isMaxAgeAvailable, isMinAgeAvailable]);

	useEffect(() => {
		if (raceData) {
			if (raceData.configuration_calendrier.nb_equipier > 1) {
				setStepName("team");
			} else {
				setStepName("sub1");
			}
		}
	}, [raceData]);

	useEffect(() => {
		if (editFormOptions.index !== undefined) {
			updateForm(editFormOptions.index, "options", editFormOptions);
		}
	}, [editFormOptions]);

	useEffect(() => {
		updateForm(
			editFormChoices?.length ? editFormChoices[0].subscriptionIndex || 0 : 0,
			"inscription_choix",
			editFormChoices
		);
	}, [editFormChoices]);

	useEffect(() => {
		let canSave = true;
		Array.from(
			{ length: raceData?.configuration_calendrier.nb_equipier || 1 },
			(_, i) => i
		).map((index) => {
			let valid: boolean = checkForm(index);
			if (valid === false) {
				canSave = false;
				return;
			}
		});

		setCanSave(canSave);
	}, [editForm]);
	const onSaveClick = async () => {
		if (slug) {
			let data: any = {};
			data.teamName = team;
			data.raceId = raceData.id;
			data.list = editForm.list;

			try {
				let formValid = true;
				if (!!editForm?.list[0]?.ppsNumber) {
					formValid = await validatePPS();
				}

				if (!!editForm?.list[0]?.licence) {
					formValid = await validateLicence();
				}

				if (!formValid) {
					Toast.error(trad[lang].insertError);
					return false;
				}
				if (identicalEmails) {
					Toast.error("Les mails sont identiques");
					return false;
				}
				if (isAgeUnavailable) {
					Toast.error(minMaxAgeErrorMessage);
					return false;
				}
				const emailUsed = await isEmailUsed(0, editForm.list[0]?.email);
				if (!emailUsed) {
					await addSub(slug, data);
					setEditForm({});
					setOpen(false);
					refetch();
					Toast.success(trad[lang].insertSuccess);
				} else {
					Toast.error(trad[lang].insertError);
				}
			} catch (error) {
				console.error(error);
				Toast.error(trad[lang].insertError);
			}
		}
	};

	// VALIDATE PPS ----------------------------------------------------------
	const getObsData = (property: string) => {
		if (observation) {
			return observation[property];
		}
		return undefined;
	};

	const validatePPS = async () => {
		const getFormData = editForm.list[0];

		try {
			// Prepare data to send
			// Get and format birthdate
			const regex = /^\d{4}-\d{2}-\d{2}$/;

			let ddn = getFormData?.birthdate;

			if (regex.test(ddn)) {
				var parts = ddn.split("-");
				var year = parts[0];
				var month = parts[1];
				var day = parts[2];

				// Construct the new date format DD/MM/YYYY
				ddn = day + "/" + month + "/" + year;
			}

			const body: any = {};
			body.nom = getFormData?.lastName;
			body.prenom = getFormData?.firstName;
			body.numrel = getFormData?.ppsNumber;
			body.sexe = getFormData?.gender === "H" ? "M" : "F";
			body.cnilCode = 1;
			body.cmpcod = getObsData("CMPCOD");
			body.cmpdate = getObsData("CMPDATE");
			body.dateNaissance = ddn;

			const headers =
				typeof window !== "undefined" && localStorage.getItem("token")
					? {
							headers: {
								authorization: localStorage.getItem("token") as string
							}
					  }
					: {};
			const res = await API.post(
				`event/interest/slug/sendDataFFA`,
				body,
				headers
			);
			const data = res.data;
			let message = data[data?.length - 1].match(/PROx(\d+)/);
			let m = message;
			let errorCode: FFAErrorCode = m?.[1];

			if (!data || data[0] != "O" || message || body.numrel != undefined) {
				if (getFormData?.pps && message) {
					let m = message;
					let errorCode: FFAErrorCode = m[1];
					setPpsError(trad[lang][`ffa_error_${errorCode}`]);
					return false;
				}
				setPpsError(trad[lang][`ffa_error_${errorCode}`]);
				return false;
			}
			setPpsError("");
			return true;
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].error_ask_validation);
		}

		return false;
	};

	// VALIDATE LICENCE ----------------------------------------------------------
	const validateLicence = async () => {
		const getFormData = editForm.list[0];

		try {
			// Prepare data to send
			// Get and format birthdate
			const regex = /^\d{4}-\d{2}-\d{2}$/;

			let ddn = getFormData?.birthdate;

			if (regex.test(ddn)) {
				var parts = ddn.split("-");
				var year = parts[0];
				var month = parts[1];
				var day = parts[2];

				// Construct the new date format DD/MM/YYYY
				ddn = day + "/" + month + "/" + year;
			}

			const body: any = {};
			body.nom = getFormData?.lastName;
			body.prenom = getFormData?.firstName;
			body.numrel = getFormData?.licence;
			body.sexe = getFormData?.gender === "H" ? "M" : "F";
			body.cnilCode = 1;
			body.cmpcod = getObsData("CMPCOD");
			body.cmpdate = getObsData("CMPDATE");
			body.dateNaissance = ddn;

			const headers =
				typeof window !== "undefined" && localStorage.getItem("token")
					? {
							headers: {
								authorization: localStorage.getItem("token") as string
							}
					  }
					: {};

			const res = await API.post(
				`event/interest/slug/sendDataFFA`,
				body,
				headers
			);
			const data = res.data;
			let message = data[data?.length - 1].match(/PROx(\d+)/);
			let m = message;
			let errorCode: FFAErrorCode = m?.[1];

			if (!data || data[0] != "O" || message || body.numrel != undefined) {
				if (getFormData?.licence && message) {
					setLicenceError(trad[lang][`ffa_error_${errorCode}`]);
					return false;
				}
				setLicenceError(trad[lang][`ffa_error_${errorCode}`]);
				return false;
			}
			setLicenceError("");
			return true;
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].error_ask_validation);
		}

		return false;
	};

	const onNextClick = () => {
		if (stepName == "team") {
			setStepName("sub1");
		} else if (stepName.match(/sub[0-9]+/)) {
			setStepName("sub" + (parseInt(stepName.substr(3)) + 1));
		}
	};

	const onPreviousClick = () => {
		if (stepName.match(/sub[0-9]+/)) {
			setStepName("team");
		}
	};

	useEffect(() => {
		if (stepName == "team") {
			setCanNext(team?.length > 0);
		} else if (stepName.match(/sub[0-9]+/)) {
			let index = parseInt(stepName.substr(3));
			setCanNext(checkForm(index));
		}
	}, [team]);

	// HANDLE OPEN RUNNER
	const handleOpenRunner = async (value: any) => {
		setOpenRunner(openRunner === value ? -1 : value);
	};

	// HANDLE EMAIL CHECK

	const updateForm = function (index: number, id: string, value: any) {
		let list = editForm.list || [];

		if (!list[index]) list[index] = {};
		list[index][id] = value;
		setEditForm({
			...editForm,
			list: list
		});
	};

	useEffect(() => {
		const getDuplicateEmails = () => {
			const emails = editForm?.list?.map(
				(item: { email: string }) => item.email
			);
			if (!emails || emails.length < 2) return [];

			const emailCounts: Record<string, number> = emails.reduce(
				(acc: { [x: string]: any }, email: string | number) => {
					acc[email] = (acc[email] || 0) + 1;
					return acc;
				},
				{} as Record<string, number>
			);

			return Object.entries(emailCounts)
				.filter(([_, count]) => count > 1)
				.map(([email]) => email);
		};

		const duplicateEmails = getDuplicateEmails();

		if (duplicateEmails.length > 0) {
			setDuplicateEmails(duplicateEmails);
			setIdenticalEmails(true);
		} else {
			setIdenticalEmails(false);
		}
	}, [editForm]);

	const isEmailUsed = async (index: number, email: string) => {
		let result: boolean = true;
		if (!email || email?.length === 0) result = false;
		else {
			try {
				let listSameMails: any[] | undefined = editForm.list.filter(
					(item: any) => item.email === email
				);
				let personForm = editForm.list[index];
				if (!personForm) result = false;
				else if (listSameMails !== undefined && listSameMails?.length > 1)
					result = true;
				else {
					const { data } = await API.get(
						`/user/checkDuplicateEmail/${email}/${personForm.lastName}/${personForm.firstName}`
					);
					result = data;
				}
			} catch (error) {}
		}
		setCheckUsedMail(result);
		return result;
	};

	const isEmailValid = (index: number, email: string) => {
		let result: boolean = false;

		if (email?.length === 0) result = true;
		else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
			result = true;

		// Save result in form
		updateForm(index, "isEmailValid", result);

		return result;
	};

	const checkForm = function (idx: number) {
		let sub = editForm.list ? editForm.list[idx] || {} : {};
		let missingFields = requiredFields.filter((field) => !sub[field]);

		let isMailValid = editForm.list?.[idx]?.isEmailValid || true;

		let bibIsUndefined: boolean =
			editForm.list?.[idx]?.bib === undefined ||
			editForm.list?.[idx]?.bib === null ||
			editForm.list?.[idx]?.bib?.length === 0;
		let isBibValid =
			editForm.list?.[idx]?.bibConflict === false || bibIsUndefined;

		return missingFields?.length === 0 && isMailValid && isBibValid;
	};

	const getCountryfield = function (
		index: number,
		id: string,
		label: string,
		value: string,
		className: string = ""
	) {
		return (
			<div className={`max-w-[210px] ${className} flex-col justify-between`}>
				<label className="mb-1 block text-left text-sm font-medium text-gray-700">
					{label}
				</label>
				<SelectCountry
					lang={lang}
					listTailwind="max-h-[140px]"
					absolute={false}
					onChange={(country: any) => {
						updateForm(index, id, country?.idWindev);
					}}
					defaultCountryCode={value}
				/>
			</div>
		);
	};

	const getTextfield = function (
		index: number,
		id: string,
		label: string,
		value: string,
		className: string = "",
		error?: string
	) {
		let conflict: boolean =
			id === "bib" && editForm.list?.[index]?.bibConflict === true;
		let errorLicence: boolean | "" | undefined =
			id === "licence" &&
			editForm.list?.[index]?.licence &&
			licenceError &&
			licenceError != "";
		let mailInvalid: boolean =
			id === "email" && editForm.list?.[index]?.isEmailValid === false;
		let mailUsed: boolean =
			id === "email" && checkUsedMail === true && mailInvalid === false;
		let areIdenticalEmails: boolean =
			id === "email" && identicalEmails === true;
		let isPhoneNumberInvalid: boolean =
			id === "phone" &&
			isNaN(editForm.list?.[index]?.phone) &&
			editForm.list?.[index]?.phone?.length > 0
				? true
				: false;
		let isEmergencyPhoneNumberInvalid: boolean =
			id === "emergency_phone" &&
			editForm.list?.[index]?.emergency_phone ===
				editForm.list?.[index]?.phone &&
			editForm.list?.[index]?.emergency_phone?.length > 0
				? true
				: false;

		let isValidTypeEmergencyPhoneNumber: boolean =
			id === "emergency_phone" &&
			editForm.list?.[index]?.emergency_phone?.length > 0 &&
			isNaN(editForm.list?.[index]?.emergency_phone);
		return (
			<div className={className}>
				<label
					className={classNames(
						conflict ||
							mailInvalid ||
							errorLicence ||
							mailUsed ||
							isPhoneNumberInvalid ||
							isEmergencyPhoneNumberInvalid ||
							isValidTypeEmergencyPhoneNumber ||
							areIdenticalEmails
							? "text-red-500"
							: "text-gray-700",
						"block text-left text-sm font-medium text-gray-700"
					)}
				>
					{mailUsed || areIdenticalEmails ? (
						<div className="flex flex-row">
							<label
								className={`block text-left text-sm font-medium text-orange-500`}
							>
								{duplicateEmails.length > 0
									? `(${duplicateEmails}) ${
											duplicateEmails.length > 1 ? "sont" : "est"
									  } déjà utilisé`
									: trad[lang].mailUsed}
							</label>
							<Tooltip
								className="text-md z-50 max-w-xs bg-white text-center text-black drop-shadow-lg"
								content={<Typography>{trad[lang].mailUsedTooltip}</Typography>}
							>
								<p>
									<AiOutlineInfoCircle className="ml-1 mt-0.5 h-4 w-4 fill-orange-500" />
								</p>
							</Tooltip>
						</div>
					) : (
						label + (mailInvalid ? " (" + trad[lang].invalid + ")" : "")
					)}

					{requiredFields.includes(id) && <span style={requiredInput}> *</span>}
				</label>
				<div className="mt-1">
					<input
						// disabled={id === "email" ? true : false}
						type="text"
						className={classNames(
							conflict ||
								errorLicence ||
								isPhoneNumberInvalid ||
								isEmergencyPhoneNumberInvalid ||
								isValidTypeEmergencyPhoneNumber ||
								areIdenticalEmails
								? "border-red-500 text-red-500 focus:border-red-500"
								: "border-gray-300 focus:border-gray-300",
							"block w-full rounded-md shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm"
						)}
						defaultValue={value}
						onChange={(e: any) => {
							if (id === "bib") {
								e.target.value = e.target.value.replace(/[^0-9]/g, "");
								if (parseInt(e.target.value) >= maxBibNumber)
									e.target.value = maxBibNumber - 1;
								updateForm(
									index,
									"bibConflict",
									bibList.includes(parseInt(e.target.value))
								);
							}
							if (id === "email") {
								mails[index] = e.target.value || "";
								setMails([...mails]);
								let res: boolean = isEmailValid(index, e.target.value);
							}
							if (id === "lastName" || id === "firstName") {
								mails[index] = editForm.list?.[index]?.email || "";
								setMails([...mails]);
							}

							updateForm(index, id, e.target.value);
						}}
					/>
					{id === "licence" &&
						errorLicence &&
						editForm.list?.[index]?.licence != "" && (
							<span className="text-xs text-red-500">{error}</span>
						)}
					{id === "phone" && isPhoneNumberInvalid && (
						<span className="text-xs text-red-500">
							Un numéro ne doit comporter que des chiffres
						</span>
					)}
					{id === "emergency_phone" && isEmergencyPhoneNumberInvalid && (
						<span className="text-xs text-red-500">
							Le numéro d'urgence ne peut pas être identique au numéro de
							téléphone
						</span>
					)}
					{id === "emergency_phone" && isValidTypeEmergencyPhoneNumber && (
						<span className="text-xs text-red-500">
							Un numéro ne doit comporter que des chiffres
						</span>
					)}
				</div>
			</div>
		);
	};

	const getPpsfield = function (
		index: number,
		id: string,
		label: string,
		value: string,
		className: string = "",
		error?: string
	) {
		let conflict: boolean =
			id === "bib" && editForm.list?.[index]?.bibConflict === true;
		let errorPps: boolean | string | undefined =
			id === "ppsNumber" &&
			editForm.list?.[index]?.ppsNumber != "" &&
			ppsError &&
			ppsError != "";
		let mailInvalid: boolean =
			id === "email" && editForm.list?.[index]?.isEmailValid === false;
		let mailUsed: boolean =
			id === "email" &&
			editForm.list?.[index]?.isEmailUsed &&
			mailInvalid === false;
		return (
			<div className={className}>
				<label
					className={classNames(
						conflict || mailInvalid || errorPps
							? "text-red-500"
							: "text-gray-700",
						"block text-left text-sm font-medium text-gray-700"
					)}
				>
					{mailUsed ? (
						<div className="flex flex-row">
							<label
								className={`block text-left text-sm font-medium text-orange-500`}
							>
								{trad[lang].mailUsed}
							</label>
							<Tooltip
								className="text-md z-50 max-w-xs bg-white text-center text-black drop-shadow-lg"
								content={<Typography>{trad[lang].mailUsedTooltip}</Typography>}
							>
								<p>
									<AiOutlineInfoCircle className="ml-1 mt-0.5 h-4 w-4 fill-orange-500" />
								</p>
							</Tooltip>
						</div>
					) : (
						label + (mailInvalid ? " (" + trad[lang].invalid + ")" : "")
					)}

					{requiredFields.includes(id) && <span style={requiredInput}> *</span>}
				</label>
				<div className="mt-1">
					<input
						// disabled={id === "email" ? true : false}
						type="text"
						className={classNames(
							conflict || errorPps
								? "border-red-500 text-red-500 focus:border-red-500"
								: "border-gray-300 focus:border-gray-300",
							"block w-full rounded-md shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm"
						)}
						defaultValue={value}
						onChange={(e: any) => {
							if (id === "bib") {
								e.target.value = e.target.value.replace(/[^0-9]/g, "");
								if (parseInt(e.target.value) >= maxBibNumber)
									e.target.value = maxBibNumber - 1;
								updateForm(
									index,
									"bibConflict",
									bibList.includes(parseInt(e.target.value))
								);
							}
							if (id === "email") {
								mails[index] = e.target.value || "";
								setMails([...mails]);
								let res: boolean = isEmailValid(index, e.target.value);
							}
							if (id === "lastName" || id === "firstName") {
								mails[index] = editForm.list?.[index]?.email || "";
								setMails([...mails]);
							}
							updateForm(index, id, e.target.value);
						}}
					/>
					{id === "ppsNumber" &&
						errorPps &&
						editForm.list?.[index]?.ppsNumber != "" && (
							<span className="text-xs text-red-500">{error}</span>
						)}
				</div>
			</div>
		);
	};

	const getDatefield = function (
		index: number,
		id: string,
		label: string,
		value: string,
		className: string = ""
	) {
		return (
			<div className={`w-full pt-1 ${className}`}>
				<label
					className={classNames(
						"block text-left text-sm font-medium",
						birthdateError || (isAgeUnavailable && maxAge > 0)
							? "text-red-500"
							: "text-gray-700"
					)}
				>
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="relative flex flex-grow items-stretch focus-within:z-10">
					<input
						type="date"
						className={classNames(
							birthdateError || (isAgeUnavailable && maxAge > 0)
								? "border-red-500 fill-red-500 text-red-500 focus:ring-red-500"
								: "border-gray-300 focus:ring-primarydark",
							"w-full rounded-md border px-4 text-sm focus:border-transparent focus:outline-none focus:ring-2"
						)}
						max={
							new Date(new Date().setFullYear(new Date().getFullYear() - 3))
								.toISOString()
								.split("T")[0]
						}
						defaultValue={value}
						onChange={(e) => {
							// Save result in form
							updateForm(index, id, e.target.value);
						}}
					/>
				</div>
			</div>
		);
	};

	const getGenderfield = function (
		index: number,
		id: string,
		label: string,
		value: string,
		className: string = ""
	) {
		return (
			<div className={`${className} pt-1`}>
				<label className="block text-left text-sm font-medium text-gray-700">
					{label}
					{requiredFields.includes(id) ? (
						<span style={requiredInput}> *</span>
					) : (
						""
					)}
				</label>
				<div className="relative flex flex-grow items-stretch focus-within:z-10">
					<select
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
						defaultValue={value}
						onChange={(e) => {
							// Save result in form
							updateForm(index, id, e.target.value);
						}}
					>
						<option key="" value="">
							{""}
						</option>
						<option key="H" value="H">
							{trad[lang].genderH}
						</option>
						<option key="F" value="F">
							{trad[lang].genderF}
						</option>
						<option key="X" value="X">
							{trad[lang].genderX}
						</option>
					</select>
				</div>
			</div>
		);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block max-h-[80vh] transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle md:max-w-2xl lg:max-w-4xl">
							<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<button
									type="button"
									className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
									onClick={() => setOpen(false)}
								>
									<span className="sr-only">Close</span>
									<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-2 flex justify-center">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium uppercase leading-6 text-gray-900"
								>
									{trad[lang].add_subscription}
								</Dialog.Title>
							</div>

							<Dialog.Title className="mt-1 flex justify-center font-normal">
								({raceData.nom.toUpperCase()})
							</Dialog.Title>

							{raceData &&
								raceData.configuration_calendrier.nb_equipier > 1 && (
									<div className="mt-8 mb-4 w-full text-center">
										<label
											className={classNames(
												false ? "text-red-500" : "text-gray-700",
												"block font-medium text-gray-700"
											)}
										>
											{trad[lang].teamName}
											<span style={requiredInput}> *</span>
										</label>
										<div className="mt-1 text-center">
											<input
												type="text"
												disabled={stepName !== "team"}
												className={classNames(
													false
														? "border-red-500 text-red-500 focus:border-red-500"
														: "border-gray-300 focus:border-gray-300",
													"rounded-md text-center shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm"
												)}
												defaultValue={""}
												onChange={(e: any) => {
													setTeam(e.target.value);
												}}
											/>
										</div>
									</div>
								)}

							{stepName !== "team" && (
								<>
									{Array.from(
										{
											length:
												raceData?.configuration_calendrier.nb_equipier || 1
										},
										(_, i) => i
									).map((index) => (
										<Fragment key={index}>
											<Accordion open={openRunner === index}>
												<AccordionHeader
													onClick={() => {
														handleOpenRunner(index);
													}}
												>
													<div className="mt-2 flex text-lg font-medium">
														{trad[lang].subscription}{" "}
														<span className="ml-2 mr-1 inline-block rounded bg-sky-200 py-2  px-3 text-xs font-medium uppercase text-sky-800 last:mr-0">
															<p>{index + 1}</p>
														</span>
														<span
															className={classNames(
																checkForm(index)
																	? checkUsedMail === true
																		? "bg-orange-100 text-orange-800"
																		: "bg-green-100 text-green-800"
																	: "bg-red-500 text-white",
																"ml-1 mr-1 inline-block rounded  py-2 px-3 text-xs font-medium uppercase last:mr-0"
															)}
														>
															<p>
																{checkForm(index)
																	? trad[lang].valid
																	: trad[lang].invalid}
															</p>
														</span>
													</div>
												</AccordionHeader>
												<AccordionBody>
													<div className="rounded-lg py-2">
														<div className="flex flex-row">
															<BsFillExclamationTriangleFill className="mr-2 h-4 w-4 fill-red-500" />
															<div className="mb-1  font-medium text-red-500 underline">
																Champs obligatoires (*)
															</div>
															<div className="ml-8 text-left text-sm font-medium italic text-red-500">
																{minMaxAgeErrorMessage}
															</div>
														</div>
														<div className="flex w-full flex-col pt-1 md:flex-row md:items-center md:gap-4">
															{getTextfield(
																index,
																"lastName",
																trad[lang].lastname,
																editForm.list?.[index]?.lastName || "",
																"basis-4/12"
															)}
															{getTextfield(
																index,
																"firstName",
																trad[lang].firstname,
																editForm.list?.[index]?.firstName || "",
																"basis-4/12"
															)}
															{getDatefield(
																index,
																"birthdate",
																trad[lang].birthDate,
																editForm.list?.[index]?.birthdate?.split(
																	"T"
																)[0] || "",
																"basis-2/12"
															)}
															{getGenderfield(
																index,
																"gender",
																trad[lang].gender,
																editForm.list?.[index]?.gender || "",
																"basis-2/12"
															)}
														</div>
														<div className="mt-5 flex flex-row ">
															<BsFillPersonFill className="mr-2 h-4 w-4 fill-black" />
															<div className="mb-1 font-medium text-black underline">
																Champs optionnels
															</div>
														</div>
														<div className="flex w-full flex-col pt-1 md:flex-row md:items-start md:gap-4 ">
															{getTextfield(
																index,
																"email",
																trad[lang].email,
																editForm.list?.[index]?.email || "",
																"basis-4/12"
															)}
															{getTextfield(
																index,
																"city",
																trad[lang].city,
																editForm.list?.[index]?.city || "",
																"basis-3/12"
															)}
															{getTextfield(
																index,
																"postalCode",
																trad[lang].zipCode,
																editForm.list?.[index]?.postalCode || "",
																"basis-2/12"
															)}
															{getCountryfield(
																index,
																"countryWinDev",
																trad[lang].country_home,
																editForm.list?.[index]?.countryWinDev || "",
																"basis-3/12"
															)}
														</div>
														<div className="flex w-full flex-col flex-wrap pt-1 md:flex-row md:items-start md:gap-4">
															{getTextfield(
																index,
																"phone",
																trad[lang].phone,
																editForm.list?.[index]?.phone || "",
																"basis-4/12"
															)}
															{getTextfield(
																index,
																"emergency_phone",
																trad[lang].emergency_phone,
																editForm.list?.[index]?.emergencyPhone || "",
																"basis-4/12"
															)}
															{getTextfield(
																index,
																"bib",
																trad[lang].bib,
																(editForm.list?.[index]?.bib &&
																	parseInt(editForm.list?.[index]?.bib) > 0) ||
																	"",
																"basis-3/12"
															)}
															{getTextfield(
																index,
																"licence",
																trad[lang].event_field_numLicence_label,
																editForm.list?.[index]?.licence || "",
																"basis-4/12",
																licenceError
															)}
															{!observation?.CMPCOD &&
																getPpsfield(
																	index,
																	"ppsNumber",
																	trad[lang].pps_number,
																	editForm.list?.[index]?.ppsNumber || "",
																	"basis-4/12",
																	ppsError
																)}
															{getTextfield(
																index,
																"clubName",
																trad[lang].club,
																editForm.list?.[index]?.clubName || "",
																"basis-3/12"
															)}
															{getCountryfield(
																index,
																"nationalityWinDev",
																trad[lang].nationality,
																editForm.list?.[index]?.nationalityWinDev || "",
																"basis-3/12"
															)}
														</div>

														<div className="mt-5 flex flex-row">
															<BsFillPersonFill className="mr-2 h-4 w-4 fill-black" />
															<div className="mb-1 font-medium text-black underline">
																Options (si configurées)
															</div>
														</div>
														<RegistrationEditOption
															slug={raceData.slug}
															index={index}
															editFormOptions={editFormOptions}
															setEditFormOptions={setEditFormOptions}
														/>
														<div className="mt-5 flex flex-row">
															<BsFillPersonFill className="mr-2 h-4 w-4 fill-black" />
															<div className="mb-1 font-medium text-black underline">
																{trad[lang].additional_information}
															</div>
														</div>
														<RegistrationEditChoices
															setEditFormChoices={setEditFormChoices}
															raceSlug={raceData.slug}
															eventSlug={slug}
															index={index}
														/>
													</div>
												</AccordionBody>
											</Accordion>
										</Fragment>
									))}
								</>
							)}

							<div className="mt-16 sm:mt-14 sm:flex sm:flex-row-reverse">
								{stepName !== "team" && (
									<button
										type="button"
										disabled={!canSave || isAgeUnavailable}
										className={classNames(
											canSave
												? checkUsedMail === true
													? "bg-orange-400 hover:bg-orange-500"
													: "bg-secondary hover:bg-primary"
												: "bg-gray-300",
											"inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
										)}
										onClick={onSaveClick}
									>
										{trad[lang].save}
									</button>
								)}
								{stepName === "team" && (
									<button
										type="button"
										disabled={!canNext}
										className={classNames(
											canNext ? "bg-secondary hover:bg-primary" : "bg-gray-300",
											"inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
										)}
										onClick={() => onNextClick()}
									>
										{trad[lang].next}
									</button>
								)}
								{stepName !== "team" &&
									raceData?.configuration_calendrier.nb_equipier > 1 && (
										<button
											type="button"
											disabled={!canNext}
											className={classNames(
												canNext
													? "bg-secondary hover:bg-primary"
													: "bg-gray-300",
												"inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
											)}
											onClick={() => onPreviousClick()}
										>
											{trad[lang].previous}
										</button>
									)}
								<button
									type="button"
									className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
									onClick={() => {
										setOpen(false);
									}}
								>
									{trad[lang].cancel}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default AddSubModal;
