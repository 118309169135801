import { useContext } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/navigation/Layout";
import { AdminConfig } from "../../config/AdminConfig";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

function Admin() {
	const { lang } = useContext(AppContext);

	return (
		<Layout active_key="admin">
			{/* Title */}
			<h1 className="mb-6 mt-8 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
				{trad[lang].admin}
			</h1>

			<div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
				{AdminConfig(lang).map((item, index: number) => (
					<Link
						key={index}
						to={item.href}
						className="w-full rounded-md bg-white p-6 shadow-md transition-colors hover:bg-gray-100 sm:max-w-xs"
					>
						<h2 className="flex items-center gap-2 text-xl">
							<item.icon size={25} />
							{item.name}
						</h2>
						<hr className="my-4" />
						<p>{item.description}</p>
					</Link>
				))}
			</div>
		</Layout>
	);
}

export default Admin;
