import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventIdentityConfig, getEventRouteConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import SportDeleteEtapeModal from "../components/event_configuration/Sport/SportDeleteEtapeModal";
import SportStepModal from "../components/event_configuration/Sport/SportStepModal";
import EventStepModule from "../components/liveResults/EventStepModule";
import Layout from "../components/navigation/Layout";
import { BASE_STEP } from "../config/EventForms/Sport/SportStep";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const LiveRoutesConfig = () => {
	const { slug, route } = useParams();
	const { lang } = useContext(AppContext);
	const [routeState, setRouteState] = useState([]);
	const [openedStep, setOpenedStep] = useState<typeof BASE_STEP | null>(null);
	const [deleteStep, setDeleteStep] = useState<{
		id: number;
		title: string;
	} | null>(null);

	const { data: EventInfos, isLoading: EventInfosLoading } = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data, isLoading, refetch } = useQuery({
		queryKey: ["event_segment", slug, route],
		queryFn: () => getEventRouteConfig(slug as string, route as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug && !!route
	});

	const openStep = (route: any) => setOpenedStep(route || { ...BASE_STEP });
	const closeModal = () => setOpenedStep(null);

	const handleConfirmDeleteStep = (id: number, title: string) =>
		setDeleteStep({ id, title });

	const handleDeleteStep = (id: string) => {};

	useEffect(() => {
		if (data) {
			setRouteState(data.etapes);
		}
	}, [data]);

	if (isLoading || EventInfosLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout active_key="live">
			<BreadCrumb
				items={[
					{ key: "live_results", to: "/live", active: false },
					{
						key: "event",
						to: `/${slug}/live`,
						active: false
					},
					{
						key: "manage_steps",
						to: "#",
						active: true
					},
					{
						key: "manage_parcours",
						to: "#",
						active: false
					},
					{
						key: "manage_segments",
						to: "#",
						active: false
					}
				]}
			/>

			<h1 className="mb-6 mt-24 text-2xl font-bold text-gloom md:mb-8 md:mt-16 md:text-3xl">
				{trad[lang].sport_event_steps}
			</h1>

			{routeState.map((route: any) => (
				<EventStepModule
					route={route}
					key={route.idEtape}
					openStep={openStep}
					deleteStep={handleConfirmDeleteStep}
					timezone={EventInfos.timezone}
				/>
			))}

			<div className="my-6 flex w-full">
				<button
					className="ml-8 flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
					onClick={openStep}
				>
					{trad[lang].create_step}
				</button>
			</div>

			{openedStep && (
				<SportStepModal
					step={openedStep}
					closeModal={closeModal}
					refetch={refetch}
					timezone={EventInfos.timezone}
				/>
			)}

			{deleteStep && (
				<SportDeleteEtapeModal
					etape={deleteStep}
					setConfirmDeleteStep={setDeleteStep}
					refetch={refetch}
				/>
			)}

			<BottomBarNavigation back_to={`/${slug}/live`} />
		</Layout>
	);
};

export default LiveRoutesConfig;
