import { useContext, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { IoIosFlag, IoIosTimer } from "react-icons/io";
import { useParams } from "react-router-dom";
import { deleteSegment, updateSegment } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const BASE_SEGMENT = {
	idSegment: null,
	libelle: "",
	idPointageDebut: 0,
	idPointageFin: 0,
	NumeroPassageDebut: 1,
	NumeroPassageFin: 1,
	ordre: null
};

interface ISegment {
	idSegment: number | null;
	libelle: string;
	idPointageDebut: number | null;
	idPointageFin: number | null;
	NumeroPassageDebut: number;
	NumeroPassageFin: number;
	ordre: number | null;
}

const LiveConfigurationSegments = ({
	liveConfig,
	segments,
	points,
	refetchSegments
}: {
	liveConfig: any;
	segments: any;
	points: any;
	refetchSegments: any;
}) => {
	const { lang } = useContext(AppContext);
	const { slug, idcalendrier } = useParams();
	const [openedSegment, setOpenedSegment] = useState<ISegment>({
		...BASE_SEGMENT
	});
	const [openDeleteSegment, setOpenDeleteSegment] = useState(0);
	const [loading, setLoading] = useState(false);

	const openSegment = (segment: any) => {
		setOpenedSegment({
			idSegment: segment.idSegment,
			libelle: segment.libelle,
			idPointageDebut: segment.idPointageDebut,
			idPointageFin: segment.idPointageFin,
			NumeroPassageDebut: segment.NumeroPassageDebut,
			NumeroPassageFin: segment.NumeroPassageFin,
			ordre: segment.ordre
		});
	};

	const handleClose = () => {
		setOpenedSegment({ ...BASE_SEGMENT });
	};

	const setSegmentValue = (key: keyof typeof openedSegment, value: any) => {
		setOpenedSegment((old) => ({ ...old, [key]: value }));
	};

	const handleSubmit = async () => {
		try {
			if (!slug || !idcalendrier) return;

			setLoading(true);

			if (
				openedSegment.idPointageDebut == openedSegment.idPointageFin &&
				openedSegment.NumeroPassageDebut == openedSegment.NumeroPassageFin
			) {
				Toast.error(trad[lang].runFormPointSameError);
				return;
			}

			if (!openedSegment.idSegment == null) {
				Toast.error(trad[lang].runFormSegmentNotComplete);
				return;
			}

			// @ts-ignore
			await updateSegment(slug, idcalendrier, openedSegment);
			Toast.success(trad[lang].runFormUpdateSegmentSuccess);

			await refetchSegments();
			handleClose();
		} catch (error) {
			Toast.error(trad[lang].runFormSegmentErrorHappened);
		} finally {
			setLoading(false);
		}
	};

	const addSegment = () => {
		setOpenedSegment({ ...BASE_SEGMENT, idSegment: 0 });
	};

	const openDeleteSegmentModal = (idSegment: number) => {
		setOpenDeleteSegment(idSegment);
	};

	const handleDeleteSegment = async (idSegment: number) => {
		try {
			if (!slug || !idcalendrier) return;

			setLoading(true);

			await deleteSegment(slug, idcalendrier, idSegment);
			Toast.success(trad[lang].runFormDeleteSegmentSuccess);

			await refetchSegments();

			setOpenDeleteSegment(0);
		} catch (error) {
			Toast.error(trad[lang].runFormSegmentErrorHappened);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="grid w-full grid-cols-2 gap-5 p-5 transition-opacity">
				<div className="col-span-2 flex flex-col gap-2">
					<p className="font-bold">{trad[lang].calendarRunSegments}</p>

					<div className="grid grid-cols-1 gap-5 lg:grid-cols-2 2lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
						{segments.map((item: any) => (
							<div key={item.idSegment}>
								<div
									className="group relative col-span-1 mb-2 flex h-36 flex-col justify-between rounded-md p-4 shadow-md transition"
									key={item.id}
								>
									<p className="text-lg font-bold">{item.libelle}</p>
									<div className="flex select-none items-center justify-between">
										<p
											className="flex items-center gap-1 font-normal"
											title={trad[lang].startDetectionPoint}
										>
											<IoIosFlag size={18} color="#4CAF50" />
											{item.nomDebut
												? `${item.ligneDebut} ${item.nomDebut}`
												: ""}
										</p>
										<p
											className="flex w-12 items-center gap-1"
											title={trad[lang].numberPass}
										>
											<IoIosTimer size={18} color="#000000" />
											n°{item.NumeroPassageDebut}
										</p>
									</div>

									<div className="flex select-none items-center justify-between">
										<p
											className="flex items-center gap-1 font-normal"
											title={trad[lang].endDetectionPoint}
										>
											<IoIosFlag size={18} color="#FF5733" />
											{item.nomFin ? `${item.ligneFin} ${item.nomFin}` : ""}
										</p>
										<p
											className="flex w-12 items-center gap-1"
											title={trad[lang].numberPass}
										>
											<IoIosTimer size={18} color="#000000" />
											n°{item.NumeroPassageFin}
										</p>
									</div>

									<div className="absolute -top-2 -right-2 flex flex-row gap-2 md:hidden md:group-hover:flex">
										<button
											className="rounded-md bg-primary p-2 shadow-sm hover:bg-primarymedium"
											onClick={() => openSegment(item)}
										>
											<AiOutlineEdit size={18} color="#ffffff" />
										</button>

										{liveConfig?.type != 5 && (
											<button
												className="rounded-md bg-red-600 p-2 shadow-sm hover:bg-red-700"
												onClick={() => openDeleteSegmentModal(item.idSegment)}
											>
												<AiOutlineDelete size={18} color="#ffffff" />
											</button>
										)}
									</div>
								</div>
							</div>
						))}

						{liveConfig?.type != 5 && (
							<button
								onClick={addSegment}
								className="col-span-1 flex h-36 flex-col items-center justify-center rounded-md bg-gray-400 text-white transition hover:bg-gray-500"
							>
								<AiOutlinePlus size={40} />
								{trad[lang].calendarRunAddSegment}
							</button>
						)}
					</div>
				</div>
			</div>

			{openedSegment.idSegment != null && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="max-h-[80vh] w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden overflow-y-auto rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">
								{trad[lang].calendarRunConfigureSegment}
							</h2>
						</div>

						<div className="grid grid-cols-2 gap-5 p-5">
							<div className="col-span-2 flex flex-col gap-2">
								<p className="font-bold">{trad[lang].name}</p>

								<input
									type="string"
									value={openedSegment.libelle}
									onChange={(e) => setSegmentValue("libelle", e.target.value)}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<div className="flex items-center gap-1">
									<IoIosFlag size={18} color="#4CAF50" />
									<p className="font-bold">{trad[lang].startDetectionPoint}</p>
								</div>

								<select
									value={openedSegment.idPointageDebut || 0}
									onChange={(e) =>
										setSegmentValue("idPointageDebut", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								>
									<option value={0}>Choisissez un point de détection</option>

									{points.map((item: any) => (
										<option
											value={item.id}
											key={item.id}
										>{`${item.name} - n°${item.ligne}`}</option>
									))}
								</select>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<div className="flex items-center gap-1">
									<IoIosTimer size={18} color="#000000" />
									<p className="font-bold">{trad[lang].numberPass}</p>
								</div>

								<input
									type="number"
									min={1}
									value={openedSegment.NumeroPassageDebut}
									onChange={(e) =>
										setSegmentValue("NumeroPassageDebut", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<div className="flex items-center gap-1">
									<IoIosFlag size={18} color="#FF5733" />
									<p className="font-bold">{trad[lang].endDetectionPoint}</p>
								</div>

								<select
									value={openedSegment.idPointageFin || 0}
									onChange={(e) =>
										setSegmentValue("idPointageFin", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								>
									<option value={0}>Choisissez un point de détection</option>
									{points.map((item: any) => (
										<option
											value={item.id}
											key={item.id}
										>{`${item.name} - n°${item.ligne}`}</option>
									))}
								</select>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<div className="flex items-center gap-1">
									<IoIosTimer size={18} color="#000000" />
									<p className="font-bold">{trad[lang].numberPass}</p>
								</div>

								<input
									type="number"
									min={1}
									value={openedSegment.NumeroPassageFin}
									onChange={(e) =>
										setSegmentValue("NumeroPassageFin", e.target.value)
									}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>

							<div className="col-span-1 flex flex-col gap-2">
								<div className="flex items-center gap-1">
									<IoIosTimer size={18} color="#000000" />
									<p className="font-bold">{trad[lang].order}</p>
								</div>

								<input
									type="number"
									min={1}
									value={openedSegment.ordre || undefined}
									onChange={(e) => setSegmentValue("ordre", e.target.value)}
									className="h-12 rounded-md border border-gray-300 pl-2 focus:outline-none"
								/>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
								onClick={handleClose}
								disabled={loading}
							>
								{trad[lang].close}
							</button>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
								onClick={handleSubmit}
								disabled={loading}
							>
								{trad[lang].validate}
							</button>
						</div>
					</div>
				</div>
			)}

			{openDeleteSegment != 0 && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
					<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h2 className="text-center text-2xl">
								{trad[lang].calendarRunDeleteSegment}
							</h2>
						</div>

						<div className="grid grid-cols-2 gap-5 p-5">
							<div className="col-span-2 flex flex-col gap-2 text-center">
								<p className="font-normal">
									{trad[lang].runFormDeleteSegmentConfirmation}
								</p>
							</div>
						</div>

						<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
								onClick={() => setOpenDeleteSegment(0)}
								disabled={loading}
							>
								{trad[lang].close}
							</button>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
								onClick={() => handleDeleteSegment(openDeleteSegment)}
								disabled={loading}
							>
								{trad[lang].delete}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LiveConfigurationSegments;
