import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSatisfactionResponses } from "../../../api/communication";

type ResponseData = {
	id: number;
	id_calendrier: number;
	id_inscription: number;
	id_question: number;
	reponse: string;
	titre_question: string;
};

type QuestionStats = {
	questionId: number;
	questionTitle: string;
	totalResponses: number;
	responseCounts: Record<string, number>;
};

const SatisfactionResponses = () => {
	const { slug } = useParams();
	const [stats, setStats] = useState<QuestionStats[]>([]);
	const [loading, setLoading] = useState(false);
	const [uniqueRespondents, setUniqueRespondents] = useState(0);

	const getSatisfactionResponsesData = async () => {
		try {
			setLoading(true);
			const res = await getSatisfactionResponses(slug as string);
			calculateStats(res);
			countUniqueRespondents(res);
		} catch (error) {
			console.error("Error getting satisfaction data:", error);
		} finally {
			setLoading(false);
		}
	};

	const calculateStats = (data: ResponseData[]) => {
		const groupedData = data.reduce<Record<number, QuestionStats>>(
			(acc, response) => {
				const { id_question, titre_question, reponse } = response;

				if (!acc[id_question]) {
					acc[id_question] = {
						questionId: id_question,
						questionTitle: titre_question,
						totalResponses: 0,
						responseCounts: {}
					};
				}

				let parsedResponse;
				try {
					parsedResponse = JSON.parse(reponse);
				} catch {
					parsedResponse = reponse;
				}

				if (Array.isArray(parsedResponse)) {
					parsedResponse.forEach((item: any) => {
						if (item.reponse && Array.isArray(item.reponse)) {
							item.reponse.forEach((answer: string) => {
								acc[id_question].responseCounts[answer] =
									(acc[id_question].responseCounts[answer] || 0) + 1;
								acc[id_question].totalResponses++;
							});
						}
					});
				} else {
					acc[id_question].responseCounts[parsedResponse] =
						(acc[id_question].responseCounts[parsedResponse] || 0) + 1;
					acc[id_question].totalResponses++;
				}

				return acc;
			},
			{}
		);
		setStats(Object.values(groupedData));
	};

	useEffect(() => {
		getSatisfactionResponsesData();
	}, [slug]);

	const countUniqueRespondents = (data: ResponseData[]) => {
		const uniqueIds = new Set(data.map((response) => response.id_inscription));
		setUniqueRespondents(uniqueIds.size);
	};

	return (
		<div className="mx-auto max-w-4xl py-10 px-4">
			<h1 className="mb-6 text-3xl font-bold text-gray-800">
				<p className="mb-6 text-lg text-gray-700">
					Nombre total de répondants :{" "}
					<span className="font-semibold">{uniqueRespondents}</span>
				</p>
			</h1>
			{loading ? (
				<p className="text-center text-gray-500">Chargement des réponses...</p>
			) : stats.length > 0 ? (
				<div className="space-y-6">
					{stats.map((stat) => (
						<div
							key={stat.questionId}
							className="rounded-lg border border-gray-300 bg-white p-4 shadow-sm"
						>
							<h2 className="mb-2 text-xl font-semibold text-gray-800">
								{stat.questionTitle}
							</h2>
							<p className="mb-4 text-sm text-gray-500">
								Total de réponses : {stat.totalResponses}
							</p>
							<ul className="space-y-2">
								{Object.entries(stat.responseCounts).map(([answer, count]) => {
									const percentage = (
										(count / stat.totalResponses) *
										100
									).toFixed(2);
									return (
										<li
											key={answer}
											className="flex items-center justify-between"
										>
											<span className="text-gray-700">{answer}</span>
											<span className="text-gray-500">
												{count} réponse(s) ({percentage}%)
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					))}
				</div>
			) : (
				<p className="text-center text-gray-500">Aucune réponse disponible.</p>
			)}
		</div>
	);
};

export default SatisfactionResponses;
