import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEventLists } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

function RegistrationEditChoices({
	editedSubscription,
	eventSlug,
    raceSlug,
	setEditFormChoices,
    index
}: {
	editedSubscription?: any | null;
	eventSlug: string | undefined;
	raceSlug: string | undefined;
	setEditFormChoices: any;
    index?: number;
}) {
	const { lang } = useContext(AppContext);
	const [form, setForm] = useState<any>();

    // Load possible choices for current race event (editedSubscription?.calendrier?.slug)
    const { data: dataChoicesLists, isLoading: isLoadingChoicesLists } = useQuery({
        queryKey: ["race_choices", raceSlug],
		queryFn: () => getEventLists(eventSlug || '', raceSlug || ''),
		refetchOnWindowFocus: false,
		enabled: !!eventSlug && !!raceSlug
	});

    // On data loaded, set form
	useEffect(() => {
		if (
			isLoadingChoicesLists === false &&
			dataChoicesLists &&
			eventSlug &&
            raceSlug
		) {
            if (editedSubscription?.inscription_choix)
                setForm(JSON.parse(JSON.stringify(editedSubscription.inscription_choix)));
            else
                setForm([]);
		}
	}, [dataChoicesLists, isLoadingChoicesLists, editedSubscription, eventSlug, raceSlug]);

    // On form change, update editFormChoices if changes occured
	useEffect(() => {
		if (form && dataChoicesLists) {
            // Editing registration
			if (editedSubscription && 
				JSON.stringify(form) !==
				JSON.stringify(editedSubscription.inscription_choix)
			) {
				setEditFormChoices(form);
			}
            // Create new registration
            else if (JSON.stringify(form) !== JSON.stringify([]))
            {
                setEditFormChoices(form);
            }
		}
	}, [form]);

	return (
		<>
			{(!isLoadingChoicesLists && !isLoadingChoicesLists) && (
				<div className="w-full">
					<div key="choices">
						<div className="flex w-full flex-col items-center gap-4 pt-1">
							{dataChoicesLists?.length == 0 && (
								<div className="text-center">{trad[lang].noAdditionalInformation}</div>
							)}
                            {dataChoicesLists?.map((list: any) => {
                                return (
                                    <div className="w-full pt-1" key={list.id}>
                                        <label className="block text-left text-sm font-medium text-gray-700">
                                            {list.libelle}
                                        </label>
                                        <select
                                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
                                            defaultValue={editedSubscription?.inscription_choix?.find((item:any) => item?.idList == list.id)?.id}
                                            onChange={(e) => {
                                                let choixInForm = form?.find((item:any) => item?.idList == list.id);

                                                // Add choice to form if does not exists
                                                if (!choixInForm)
                                                {
                                                    choixInForm = {};
                                                    choixInForm.idList = list.id;
                                                    choixInForm.libelleList = list.libelle;
                                                    form.push(choixInForm);
                                                }
                                                // Set selected choice id and label
                                                choixInForm.id = parseInt(e.target.value);
                                                choixInForm.libelle = list.choix?.find((item:any) => item.id == e.target.value)?.libelle;
                                                choixInForm.subscriptionIndex = index;

                                                // Update form, so user can save changes
                                                setForm([...form]);
                                            }}
                                        >
                                            <option value="">- - -</option>
                                            {list.choix?.map((choix: any) => {
                                                return (
                                                    <option key={choix.id} value={choix.id}>
                                                        {choix.libelle}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                );
                            })}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default RegistrationEditChoices;
