import dayjs from "dayjs";
import {
	Dispatch,
	Fragment,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from "react";
import {
	AiOutlineClose,
	AiOutlineFieldNumber,
	AiOutlineLoading3Quarters,
	AiOutlineMan,
	AiOutlineWoman
} from "react-icons/ai";
import { FaRunning } from "react-icons/fa";
import { HiArrowDown } from "react-icons/hi2";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { API } from "../../api/APIClient";
import { getEventRunsConfig, getTeammates } from "../../api/event";
import { checkDuplicatesBib } from "../../api/live";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const ChangeRaceModal = ({
	subscription,
	setOpenedChangeRace,
	refetch
}: {
	subscription: any;
	setOpenedChangeRace: Dispatch<SetStateAction<any>>;
	refetch: Function;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const { liveConfig } = useContext(LiveContext);
	const GENDERS: any = {
		1: <AiOutlineMan size={20} color="rgb(0, 150, 255)" />,
		2: <AiOutlineWoman size={20} color="rgb(222, 49, 99)" />
	};
	const CHOICE: any = ["Oui", "Non"];
	const [availableRaces, setAvailableRaces] = useState<any>();
	const [newRace, setNewRace] = useState<number>(-1);
	const [raceName, setRaceName] = useState<string>("");
	const [fisrtBibMessage, setFirstBibMessage] = useState<any>(false);
	const [secondBibMessage, setSecondBibMessage] = useState<any>(false);
	const [firstBibChoice, setFirstBibChoice] = useState<any>("");
	const [secondBibChoice, setSecondBibChoice] = useState<any>("");
	const [duplicateBib, setDuplicateBib] = useState<any>(false);
	const [duplicateBibUser, setDuplicateBibUser] = useState<any>("");
	const [bibAssignment, setBibAssignment] = useState<any>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [minMaxAgeErrorMessage, setMinMaxAgeErrorMessage] =
		useState<string>("");

	const initialValues = () => {
		setOpenedChangeRace({});
		setFirstBibMessage(false);
		setSecondBibMessage(false);
		setFirstBibChoice("");
		setSecondBibChoice("");
		setDuplicateBib(false);
		setDuplicateBibUser("");
		setAvailableRaces([]);
	};

	const {
		data: RunsInfos = [],
		isLoading: RunsLoading,
		refetch: RefetchRuns
	} = useQuery({
		queryKey: ["runs_info", slug],
		queryFn: () => getEventRunsConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data: teammates,
		isLoading: isLoadingTeammates,
		error: errorTeammates
	} = useQuery({
		queryKey: ["teammates", slug],
		queryFn: () =>
			getTeammates(
				slug as string,
				subscription.equipe?.nom as string,
				subscription.person.id as number
			),
		refetchOnWindowFocus: false,
		enabled: !!subscription
	});

	const getReferenceDate = (
		ageControl: number,
		run: any,
		birthdate: any
	): string => {
		const eventStartDate = run?.startDate;

		switch (ageControl) {
			case 4:
				return dayjs(eventStartDate).startOf("year").format("YYYY");
			case 2:
				return eventStartDate;
			case 3:
				return birthdate;
			default:
				return dayjs().format("YYYY-MM-DD");
		}
	};

	const run = RunsInfos.find((item: any) => item.id === newRace);
	const referenceDate = getReferenceDate(
		run?.prices[0]?.ageControl,
		run,
		subscription?.person?.birthdate
	);

	let runnerAge = 0;

	switch (run?.prices[0]?.ageControl) {
		case 4:
			runnerAge = dayjs(referenceDate, "YYYY").diff(
				dayjs(subscription?.person?.birthdate),
				"years"
			);
			break;
		case 3:
			runnerAge = dayjs(referenceDate).diff(
				dayjs(subscription?.person?.birthdate),
				"years"
			);
			break;
		case 2:
			runnerAge = dayjs(referenceDate).diff(
				dayjs(subscription?.person?.birthdate),
				"years"
			);
			break;
		default:
			runnerAge = 0;
	}
	const minAge = run?.prices[0]?.minAge;
	const maxAge = run?.prices[0]?.maxAge;
	const isMinAgeAvailable = runnerAge < minAge && minAge > 0;
	const isMaxAgeAvailable = runnerAge > maxAge && maxAge > 0;
	const isAgeAvailable = isMinAgeAvailable || isMaxAgeAvailable;

	useEffect(() => {
		if (!isMinAgeAvailable && minAge > 0 && run?.prices[0]?.ageControl === 2) {
			setMinMaxAgeErrorMessage(`L'âge minimum requis est de ${minAge} ans.`);
		} else if (
			!isMaxAgeAvailable &&
			maxAge > 0 &&
			run?.prices[0]?.ageControl === 2
		) {
			setMinMaxAgeErrorMessage(`L'âge maximum requis est de ${maxAge} ans.`);
		} else if (
			(!isMaxAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 4) ||
			(!isMinAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 4)
		) {
			setMinMaxAgeErrorMessage(
				`L'année de naissance doit être comprise entre ${
					parseInt(dayjs(run?.startDate).format("YYYY")) - maxAge - 1
				} et ${
					parseInt(dayjs(run?.startDate).format("YYYY")) - minAge - 1
				} inclus.`
			);
		} else if (
			(!isMaxAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 3) ||
			(!isMinAgeAvailable && maxAge > 0 && run?.prices[0]?.ageControl === 3)
		) {
			setMinMaxAgeErrorMessage(
				`Vous devez avoir au moins ${minAge} ans ou au plus ${maxAge} ans au moment de l'inscription.`
			);
		} else {
			setMinMaxAgeErrorMessage("");
		}
	}, [isMaxAgeAvailable, isMinAgeAvailable, subscription?.person?.birthdate]);

	useEffect(() => {
		if (!liveConfig?.calendrier_child?.length) return;

		// TODO : Revoir le filtre pour les évènements
		const temp = liveConfig.calendrier_child?.filter(
			(race: any) =>
				race.nom != subscription.calendrier.nom &&
				race.configuration_calendrier.nb_equipier ==
					subscription.calendrier.configuration_calendrier.nb_equipier
		);
		setAvailableRaces(temp);
	}, [liveConfig]);

	const closeChangeRace = () => setOpenedChangeRace(null);

	const handleNewRaceChange = async (value: any) => {
		// change etat new bib in new race
		setNewRace(parseInt(value));

		// Bib messages
		if (parseInt(value) == -1) {
			setFirstBibMessage(false);
			setSecondBibMessage(false);
			setDuplicateBib(false);
		} else {
			setFirstBibMessage(true);
		}

		// Check if current bib doesnt exist in coming race
		if (subscription.bib) {
			// Check the bib assignment
			setDuplicateBib(false);

			const bibAssignmentRace = liveConfig.calendrier_child?.filter(
				(race: any) => race.idCourse == value
			);
			setBibAssignment(bibAssignmentRace.bibAssignment);

			const data = await checkDuplicatesBib(
				slug as string,
				subscription.id,
				subscription.bib
			);

			if (data && data.length) {
				setDuplicateBib(true);
				setFirstBibMessage(false);
				setSecondBibMessage(false);
				setDuplicateBibUser("PRENOM NOM");
				//   data.isBibExist[0].user.prenomUtilisateur +
				//     " " +
				//     data.isBibExist[0].user.nomUtilisateur
				// );
			}

			// if (teammates.length && data.bibs.length) {
			//   teammates.map((mate: any) => {
			//     if (data.bibs[0].ListeDossards == mate.bib) {
			//       setDuplicateBib(true);
			//       setFirstBibMessage(false);
			//       setSecondBibMessage(false);
			//       setDuplicateBibUser(mate.teammate);
			//     }
			//   });
			// } else {
			//   setDuplicateBib(false);
			// }
		}
	};

	const handleFirstBibChoice = async (value: any) => {
		setFirstBibChoice(value);

		// Si premier choix
		if (newRace > 0 && value == CHOICE[1]) {
			setSecondBibMessage(true);
		}
		if (newRace > 0 && value == CHOICE[0]) {
			setSecondBibMessage(false);
			setFirstBibMessage(true);
		}
	};

	const submitNewRace = async () => {
		try {
			setIsLoading(true);
			if (newRace == -1) {
				Toast.error(trad[lang].requiredRace);
				return;
			}

			if (subscription.bib && !duplicateBib && !firstBibChoice) {
				Toast.error(trad[lang].bibChangeRaceChoice);
				return;
			}
			if (
				subscription.bib &&
				!duplicateBib &&
				firstBibChoice == CHOICE[1] &&
				!secondBibChoice
			) {
				Toast.error(trad[lang].bibChangeRaceChoice);
				return;
			}

			if (isAgeAvailable) {
				Toast.error("Erreur concernant l'age");
				return;
			}
			subscription["newRaceID"] = newRace;
			subscription["firstBibChoice"] = firstBibChoice;
			subscription["secondBibChoice"] = secondBibChoice;
			subscription["duplicateBib"] = duplicateBib;

			// New race
			const { data } = await API.post(
				`/event/${slug}/inscription/${subscription.id}/changeRace`,
				subscription
			);

			if (!data) {
				Toast.error(trad[lang].newRaceUpdateError);
			}

			Toast.success(trad[lang].newRaceUpdateSuccess);
			refetch();
			initialValues();
			setIsLoading(false);
		} catch (error) {
			Toast.error(trad[lang].newRaceUpdateError);
			setIsLoading(false);
		}
	};

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-w-screen max-h-[90vh] rounded-md border bg-white">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="inline-flex items-center text-xl font-semibold text-gray-900">
						{trad[lang].changeRaceModalTitle}
						{subscription.person.firstname} {subscription.person.lastname}
						<span className="mx-1">{GENDERS[subscription.person.sex]}</span>
						{subscription.bib && (
							<span className="inline-flex items-center">
								{" "}
								- {trad[lang].bib}{" "}
								<AiOutlineFieldNumber className="mx-1 text-3xl" />{" "}
								{subscription.bib}
							</span>
						)}
						{subscription.equipe && subscription.equipe.length && (
							<span className="mx-1">
								{" "}
								- {trad[lang].team} : {subscription.equipe?.nom}
							</span>
						)}
					</h3>
					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={closeChangeRace}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>
				<div className="overflow-y-auto overflow-x-hidden pt-4 text-center">
					{isAgeAvailable ? (
						<div className="w-full p-4 text-center">
							<p className="italic text-red-500">*{minMaxAgeErrorMessage}*</p>
						</div>
					) : (
						""
					)}
				</div>

				<div className="overflow-y-auto overflow-x-hidden px-4 py-6 text-center">
					<h3 className="inline-flex items-center">
						{trad[lang].currentlyRegistredFor}
						<span className="mx-1 font-bold">
							{subscription.calendrier.nom}
						</span>
						<span className="inline-flex items-center">
							{" "}
							- {
								subscription.calendrier.configuration_calendrier.nb_equipier
							}{" "}
							<FaRunning className="ml-[1px]" />
						</span>
					</h3>
					{subscription.equipe && subscription.equipe?.nom && (
						<>
							<h3>
								{trad[lang].teammatesWith}
								{teammates?.length &&
									teammates.map((mate: any, index: number) => (
										<div
											className={
												`${
													mate.sexe == 1
														? "bg-blue-300 text-blue-800 "
														: "bg-rose-300 text-rose-800 "
												}` +
												"m-3 inline-flex items-center gap-x-2 rounded-full p-2 text-sm hover:shadow-sm"
											}
											key={index}
										>
											{mate.teammate}
											<span className="ml-0.5">{GENDERS[mate.sexe]}</span>
											{mate.bib ? "- #" + mate.bib : ""}
										</div>
									))}
							</h3>
						</>
					)}

					<HiArrowDown className="my-5 w-full text-[75px]" />

					{/* Race */}
					{availableRaces?.length ? (
						!isLoading ? (
							<div className="m-auto w-[50%]">
								{trad[lang].newRace}
								<select
									id="race"
									name="race"
									className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
									value={newRace}
									onChange={(e) => {
										const selectedRaceId = e.target.value;
										handleNewRaceChange(selectedRaceId);
										const selectedRace = availableRaces.find(
											(race: any) => race.id === parseInt(selectedRaceId)
										);
										if (selectedRace) {
											setRaceName(selectedRace.nom);
										}
									}}
								>
									<option value="-1">{trad[lang].select_race}</option>
									{availableRaces &&
										availableRaces.map((race: any, index: number) => (
											<option key={index} value={race.id}>
												{race.nom}
											</option>
										))}
								</select>
							</div>
						) : (
							<div className="flex w-full items-center justify-center">
								<AiOutlineLoading3Quarters
									style={{ width: "20px", height: "20px" }}
									className="animate-spin"
								/>
							</div>
						)
					) : (
						trad[lang].cannotChangeRace
					)}

					{/* First Bib Choice */}
					{fisrtBibMessage && subscription.bib && (
						<div className="mt-5 text-left">
							<label>{trad[lang].bibMessageChangeRun}</label>
							{CHOICE.map((choice: string, index: number) => (
								<Fragment key={index}>
									<input
										className="mx-2"
										type="radio"
										value={choice}
										required
										name="bibFirstChoice"
										onChange={(e) => {
											handleFirstBibChoice(e.target.value);
										}}
									/>
									{choice}
								</Fragment>
							))}
						</div>
					)}

					{/* Second Bib Choice */}
					{secondBibMessage && subscription.bib && (
						<div className="mt-5 text-left">
							<label>{trad[lang].automaticBibChangeRun}</label>
							{CHOICE.map((choice: string, index: number) => (
								<Fragment key={index}>
									<input
										className="mx-2"
										type="radio"
										value={choice}
										required
										name="bibSecondChoice"
										onChange={(e) => {
											setSecondBibChoice(e.target.value);
										}}
										key={index}
									/>
									{choice}
								</Fragment>
							))}
						</div>
					)}

					{/* Duplicated bib in new race */}
					{duplicateBib && subscription.bib && (
						<div className="mt-5">
							<h3 className="m-auto inline-flex w-[50%] items-center text-left font-bold text-red-500">
								{duplicateBibUser} {trad[lang].bibAlreadyExistInNewRace}
							</h3>
						</div>
					)}

					{/* No bib in new race */}
					{firstBibChoice == CHOICE[1] &&
						secondBibChoice == CHOICE[1] &&
						subscription.bib && (
							<div className="mt-5 text-left">
								<h3 className="m-auto inline-flex w-[50%] items-center font-bold text-red-500">
									{duplicateBibUser} {trad[lang].noBibInNewRace}
								</h3>
							</div>
						)}

					{/* No BIB Assignment in new race */}
					{!bibAssignment &&
						firstBibChoice == CHOICE[1] &&
						secondBibChoice == CHOICE[0] &&
						subscription.bib && (
							<div className="mt-5 text-left">
								<h3 className="m-auto inline-flex w-[50%] items-center font-bold text-red-500">
									{duplicateBibUser} {trad[lang].noBibAssignmentInNewRace}
								</h3>
							</div>
						)}
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-6">
					<button
						type="button"
						className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
						onClick={closeChangeRace}
					>
						{trad[lang].close_documents}
					</button>
					{availableRaces?.length != 0 && (
						<button
							type="button"
							className="mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
							onClick={submitNewRace}
							disabled={isAgeAvailable}
						>
							{trad[lang].save}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ChangeRaceModal;
