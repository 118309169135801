import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useMemo } from "react";
import { HiOutlineBars3, HiOutlineXMark } from "react-icons/hi2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logOut } from "../../api/auth";
import Logo from "../../assets/logo_solo.png";
import { NavigationConfig } from "../../config/NavigationConfig";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";
import { getUserInitials } from "../../utils/UserInitials";
import LangToggle from "../common/LangToggle";
import AccessGuard, { AccessType } from "./AccessGuard";

function MobileNavBar({ active_key }: { active_key: string }) {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const { user, setUser } = useContext(UserContext);
	const { slug } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const userIsAdmin = useMemo(() => {
		if (!user) return false;
		return user?.roles?.includes("ROLE_ADMIN");
	}, [user]);

	const username = useMemo(() => {
		if (!user) return "";

		return `${user.firstname} ${user.lastname}`;
	}, [user]);

	const logout = async () => {
		try {
			if (unsavedChangesRef.current) {
				let userDiscarded = window.confirm(trad[lang].discard_changes);

				if (!userDiscarded) return;
			}

			unsavedChangesRef.current = false;

			await logOut();

			localStorage.removeItem("newConnexion");
			localStorage.removeItem("token");
			if (process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER) {
				localStorage.removeItem(process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER);
			}

			setUser(undefined);

			navigate("/");
			Toast.success(trad[lang].logoutSuccess);
		} catch (error) {
			Toast.error(trad[lang].logoutError);
		}
	};

	const NavigationItems = NavigationConfig(lang, userIsAdmin).filter(
		(item) => item.visible && item.accessible
	);

	useEffect(() => {
		unsavedChangesRef.current = false;
	}, [location]);

	return (
		<Disclosure
			as="nav"
			className="fixed top-0 z-50 w-full max-w-[100vw] bg-white shadow md:hidden"
		>
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
						<div className="relative flex h-16 justify-between">
							<div className="absolute inset-y-0 left-0 flex items-center md:hidden">
								{/* Mobile menu button */}
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<HiOutlineXMark
											className="block h-6 w-6"
											aria-hidden="true"
										/>
									) : (
										<HiOutlineBars3
											className="block h-6 w-6"
											aria-hidden="true"
										/>
									)}
								</Disclosure.Button>
							</div>
							<div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
								<div className="flex flex-shrink-0 items-center">
									<img
										className="block h-8 w-auto lg:hidden"
										src={Logo}
										alt="Chronometrage.com"
									/>
								</div>
							</div>
							<div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
								{/* Profile dropdown */}
								<Menu as="div" className="relative ml-3">
									<div>
										<Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2">
											<span className="sr-only">Open user menu</span>
											<div className="flex h-8 w-8 items-center justify-center rounded-full bg-gloom text-base font-bold text-darkerBg">
												{getUserInitials(username)}
											</div>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<Menu.Item>
												{({ active }) => (
													// <Link
													//   to="#"
													//   className={classNames(
													//     active ? "bg-gray-100" : "",
													//     "block px-4 py-2 text-sm text-gray-700"
													//   )}
													// >
													//   {trad[lang].myAccount}
													// </Link>
													<p className="block px-4 py-2 text-sm text-gray-700 opacity-50">
														{trad[lang].myAccount}
													</p>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														className={classNames(
															active ? "bg-gray-100" : "",
															"block px-4 py-2 text-sm text-gray-700"
														)}
														onClick={logout}
													>
														{trad[lang].logout}
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>
					<Disclosure.Panel className="z-[999999] h-screen md:hidden">
						<div className="relative h-[calc(100vh-64px)] space-y-1 pt-2 pb-4">
							{NavigationItems.map((item) => (
								<Fragment key={`mobile_${item.name}`}>
									{!item.needEvent && (
										<Disclosure.Button
											as="a"
											href={item.href}
											className={classNames(
												active_key == item.key
													? "border-primary bg-darkerBg text-primary"
													: "border-transparent text-gray-500",
												"flex flex-row items-center border-l-4 py-2 pl-3 pr-4 text-base font-medium"
											)}
										>
											<item.icon
												className={classNames(
													active_key == item.key
														? "text-primary"
														: "text-gray-500 group-hover:text-gloom",
													"mr-3 h-6 w-6 flex-shrink-0"
												)}
												aria-hidden="true"
											/>
											{item.name}
										</Disclosure.Button>
									)}

									{item.needEvent && slug && (
										<Disclosure.Button as="a" href={`/${slug}${item.href}`}>
											<AccessGuard
												permissionKey={item.permissionKey}
												type={AccessType.BUTTON}
											>
												<button
													className={classNames(
														active_key == item.key
															? "border-primary bg-darkerBg text-primary"
															: "border-transparent text-gray-500",
														"flex w-full flex-row items-center border-l-4 py-2 pl-3 pr-4 text-base font-medium disabled:cursor-not-allowed disabled:opacity-50"
													)}
												>
													<item.icon
														className={classNames(
															active_key == item.key
																? "text-primary"
																: "text-gray-500 group-hover:text-gloom",
															"ml-9 mr-3 h-6 w-6 flex-shrink-0"
														)}
														aria-hidden="true"
													/>
													{item.name}
												</button>
											</AccessGuard>
										</Disclosure.Button>
									)}
								</Fragment>
							))}
							{/* Lang Toggle */}
							<div className="absolute right-4 bottom-4">
								<LangToggle />
							</div>
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}

export default MobileNavBar;
