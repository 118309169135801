import { useContext } from "react";
import { IoMdArrowRoundForward } from "react-icons/io";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getEventIdentityConfig } from "../api/event";
import { getLiveConfig } from "../api/live";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import PageLoader from "../components/common/PageLoader";
import GlobalLiveConfig from "../components/liveResults/GlobalLiveConfig";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function LiveDetails() {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const {
		data: EventInfos,
		isLoading: EventInfosLoading,
		refetch: RefetchEvent
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data: LiveConfig,
		isLoading: LiveConfigLoading,
		refetch: RefetchLiveConfig
	} = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	if (EventInfosLoading || LiveConfigLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout
			active_key="live"
			bottomBar={
				<BottomBarNavigation
					go_to_trad="Coureurs"
					go_to={`/${slug}/manage-runners`}
					scd_go_to_trad="Détections"
					scd_go_to={`/${slug}/manage-detections`}
				/>
			}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: EventInfos?.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{
						key: "live_results",
						to: `/${slug}/live`,
						active: false
					},
					{
						key: "liveConfiguration",
						to: "#",
						active: true
					}
				]}
			/>

			{/* Page heading */}
			<DetailsPageHeading pageTitle={`${trad[lang].liveConfiguration}`} />

			<div className="w-full">
				<GlobalLiveConfig
					idCalendrier={LiveConfig.id}
					credential={LiveConfig.credential}
					idPrestation={LiveConfig.prestation}
					date_fin_prestation={LiveConfig.date_fin_prestation}
					timezone={LiveConfig.timezone}
					refetch={RefetchLiveConfig}
				/>

				<h2 className="mt-10 mb-4 text-2xl font-bold text-gloom md:mt-20 md:text-3xl">
					{trad[lang].whichRunConfigure}
				</h2>

				{LiveConfig?.calendrier_child?.map((run: any) => (
					<Link to={`${run.id}`} key={run.id}>
						<div className="flex flex-row items-center justify-between border-b border-b-gray-300 p-5 hover:bg-gray-100">
							<div>
								<h2 className="text-lg font-bold">{run.nom}</h2>
							</div>
							<div>
								<IoMdArrowRoundForward size={30} className="text-gray-300" />
							</div>
						</div>
					</Link>
				))}
			</div>
		</Layout>
	);
}

export default LiveDetails;
