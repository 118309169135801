import React, { Dispatch, SetStateAction, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getMessages } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";

const MessageHistory = ({
	idInscription,
	receiver,
	bib,
	setOpenedHistory
}: {
	idInscription: number;
	receiver?: string;
	bib?: string;
	setOpenedHistory: Dispatch<SetStateAction<any>>;
}) => {
	const { lang } = useContext(AppContext);
	const { userIsAdmin } = useContext(UserContext);
	const { slug } = useParams();

	const { data } = useQuery({
		queryKey: ["message", slug, idInscription],
		queryFn: () => getMessages(slug as string, idInscription),
		refetchOnWindowFocus: false
	});

	const closeHistory = () => setOpenedHistory(null);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[600px] max-w-screen-lg rounded-md border bg-white">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h3 className="text-xl font-semibold text-gray-900">
						{trad[lang].messages_history} {" - "}
						<span>{receiver}</span>
						{"  "}
						<span>{bib}</span>
					</h3>

					<button
						type="button"
						className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
						onClick={closeHistory}
					>
						<AiOutlineClose size={16} />
						<span className="sr-only">Close modal</span>
					</button>
				</div>

				<div className="max-h-[420px] overflow-y-auto overflow-x-hidden px-4 py-6">
					{data?.length > 0 ? (
						<div className="overflow-x-auto border border-gray-200">
							<table className="w-full divide-y divide-gray-200">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-center align-top text-sm font-normal text-gray-500"
										>
											<p>Type</p>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-center align-top text-sm font-normal text-gray-500"
										>
											<p>Date</p>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-center align-top text-sm font-normal text-gray-500"
										>
											<p>Expéditeur</p>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											<p>{trad[lang].title}</p>
										</th>
										<th
											scope="col"
											className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500"
										>
											<p>Message</p>
										</th>
									</tr>
								</thead>
								<tbody className="max-h-[300px] divide-y divide-gray-200 overflow-y-auto overflow-x-hidden bg-white">
									{data.map((item: any, index: number) => (
										<tr key={index}>
											<td className="p-2">
												<div className="flex flex-row items-center justify-center text-center align-middle">
													{item.collective ? (
														<HiUserGroup />
													) : (
														<BsFillPersonFill />
													)}
												</div>
											</td>
											<td className="p-2 text-center">
												{new Date(item.date).toLocaleDateString(lang, {
													day: "numeric",
													month: "numeric",
													year: "numeric",
													hour: "2-digit",
													minute: "2-digit",
													second: "2-digit"
												})}
											</td>
											<td className="p-2 text-center">{item.userSend}</td>
											<td className="p-2">
												{/* @ts-ignore */}
												{trad[lang][item.title] || item.title}
											</td>
											<td className="whitespace-pre-wrap break-words p-2">
												{item.message
													.replace(/(<([^>]+)>)/gi, "")
													?.split("\n")
													.map((line: any, index: any) => (
														<React.Fragment key={index}>
															{line}
															<br />
														</React.Fragment>
													))}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p className="py-6 text-center">{trad[lang].no_messages}</p>
					)}
				</div>

				{userIsAdmin && (
					<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
						<button
							type="button"
							className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300"
							onClick={closeHistory}
						>
							{trad[lang].close_history}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageHistory;
