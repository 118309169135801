import { LatLngExpression, Map, Polyline as PolyType } from "leaflet";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import React, { Ref, useMemo, useRef } from "react";
import { MapContainer, TileLayer, Polyline, Marker } from "react-leaflet";
import xml2js from "xml2js";

interface GpxMapProps {
	file: string;
	index: number;
}

const GpxMapView = ({ file, index }: GpxMapProps) => {
	const trackPoints = useMemo(() => {
		let trackpoints: LatLngExpression[] = [];

		xml2js.parseString(file, (err, result: any) => {
			const track = result?.gpx?.trk;

			if (!track || track.length === 0) {
				return;
			}

			const trackSegments = track[0].trkseg;

			if (!trackSegments || trackSegments.length === 0) {
				return;
			}

			const points = trackSegments[0].trkpt;

			if (!points || points.length === 0) {
				return;
			}

			trackpoints = points.map((point: any) => [
				parseFloat(point.$.lat),
				parseFloat(point.$.lon)
			]);
		});

		return trackpoints;
	}, []);

	const polylineRef: Ref<PolyType> | undefined = useRef<PolyType>(null);
	const mapRef: Ref<Map> | undefined = useRef<Map>(null);

	if (polylineRef.current && mapRef.current) {
		mapRef.current.fitBounds(polylineRef.current.getBounds());
	}

	return (
		<MapContainer
			center={[0, 0]}
			ref={mapRef}
			style={{
				height: "70%",
				width: "100%",
				borderRadius: 0,
				padding: 20,
				zIndex: 1
			}}
			scrollWheelZoom={true}
		>
			<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
			<Polyline
				pathOptions={{ color: "#8884d8" }}
				positions={trackPoints}
				ref={polylineRef}
			/>
			<Marker position={trackPoints[index || 0]} />
		</MapContainer>
	);
};

export default GpxMapView;
