import { API } from "./APIClient";

export const getRoles = async () => {
	try {
		const { data } = await API.get(`/accesses/roles`);
		return data;
	} catch (error) {
		console.warn(["[getRoles error]"]);
		throw error;
	}
};

export const setAccess = async (slug: string, body: any) => {
	try {
		const { data } = await API.post(`/accesses/${slug}`, body);
		return data;
	} catch (error) {
		console.warn(["[setAccess error]"]);
		throw error;
	}
};

export const updateAccess = async (slug: string, body: any) => {
	try {
		const { data } = await API.patch(`/accesses/${slug}`, body);
		return data;
	} catch (error) {
		console.warn(["[updateAccess error]"]);
		throw error;
	}
};

export const deleteAccess = async (slug: string, body: any) => {
	try {
		const { data } = await API.delete(`/accesses/${slug}`, { data: body });
		return data;
	} catch (error) {
		console.warn(["[deleteAccess error]"]);
		throw error;
	}
};

export const getEventAccesses = async (slug: string | undefined) => {
	try {
		const { data } = await API.get(`/accesses/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getEventAccesses error]", error);
		throw error;
	}
};
