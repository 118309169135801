import React from "react";

interface TableHeaderProps {
	children: React.ReactNode;
	color?: string;
}

const TableHeader = ({ children, color }: TableHeaderProps) => {
	return (
		<th
			className={`border border-gray-300 px-4 py-2 text-left font-semibold ${
				color && color
			}`}
		>
			{children}
		</th>
	);
};

export default TableHeader;
