import { useContext, useMemo } from "react";
import {
	AiFillCloseCircle,
	AiOutlineCaretDown,
	AiOutlineCaretUp,
	AiOutlineClose
} from "react-icons/ai";
import { AppContext } from "../../contexts/AppContext";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import RegistrationsTableHeaderFilter from "./RegistrationsTableHeaderFilter";

const RegistrationsTableHeader = () => {
	const { liveConfig } = useContext(LiveContext);

	const {
		lang,
		subscriptionsFilters,
		setSubscriptionsFilter,
		setSubscriptionsSorting,
		subscriptionsSorting
	} = useContext(AppContext);

	const keys: {
		key: string;
		title: string;
		icon?: (() => JSX.Element) | undefined;
		visible: boolean;
		list?: { label: string; value: any }[] | undefined;
		filters?: boolean;
		active: boolean;
		classes?: string;
		sort?: boolean;
	}[] = useMemo(
		() => [
			{
				key: "date",
				title: trad[lang].date,
				visible: true,
				filters: false,
				active: true,
				sort: true,
				classes: "hidden md:table-cell w-[120px]"
			},
			{
				key: "bib",
				title: trad[lang].bib,
				visible: true,
				filters: false,
				active: true,
				sort: true,
				classes: "hidden md:table-cell w-[120px]"
			},
			{
				key: "name",
				title: trad[lang].name,
				visible: true,
				filters: false,
				active: true,
				sort: true,
				classes: ""
			},
			{
				key: "run",
				title: trad[lang].run,
				visible: true,
				filters: true,
				active: true,
				sort: true,
				list:
					liveConfig?.calendrier_child?.map((race: any) => ({
						label: race.nom,
						value: race.nom
					})) || [],
				classes: "hidden md:table-cell"
			},
			{
				key: "status",
				title: trad[lang].status,
				visible: true,
				list: [
					{ label: trad[lang].waiting, value: 0 },
					{ label: trad[lang].valid, value: 1 },
					{ label: trad[lang].invalid, value: 2 },
					{ label: trad[lang].reimbursed, value: 3 }
				],
				filters: true,
				active: true,
				sort: true,
				classes: "w-[175px]"
			},
			{
				key: "documents",
				title: trad[lang].documents,
				visible: true,
				active: true,
				classes: "w-[150px]"
			},
			{
				key: "edit_subscription",
				title: trad[lang].edit_subscription,
				visible: false,
				active: true,
				classes: "w-[100px]"
			},
			{
				key: "actions",
				title: trad[lang].actions,
				visible: false,
				active: true,
				classes: "w-[100px]"
			}
		],
		[lang, liveConfig]
	);

	const handleClick = (key: string) => {
		const currentValue =
			subscriptionsSorting[key as keyof typeof subscriptionsSorting];

		const nextValue = currentValue == 1 ? -1 : currentValue == -1 ? null : 1;

		setSubscriptionsSorting((old) => ({
			...old,
			[key]: nextValue
		}));
	};

	const resetSort = () => {
		setSubscriptionsSorting({
			bib: null,
			run: null,
			contact: null,
			name: null,
			status: null,
			date: null
		});

		setSubscriptionsFilter({
			bib: "",
			run: "",
			contact: "",
			name: "",
			status: "",
			date: ""
		});
	};

	const removeFilter = (key: string) =>
		setSubscriptionsFilter((old) => ({ ...old, [key]: "" }));

	return (
		<thead className="bg-gray-50">
			<tr>
				{keys
					.filter((item) => item.active)
					.map((item, index: number) => (
						<th
							scope="col"
							className={`select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 ${item.classes}`}
							key={index}
						>
							<div className="flex">
								<div
									className="flex cursor-pointer items-center gap-x-2"
									onClick={() => handleClick(item.key)}
								>
									{item.sort && (
										<div className="hidden lg:block">
											{subscriptionsSorting[
												item.key as keyof typeof subscriptionsSorting
											] == 1 ? (
												<AiOutlineCaretUp color="#404040" />
											) : (
												<AiOutlineCaretUp color="lightgrey" />
											)}

											{subscriptionsSorting[
												item.key as keyof typeof subscriptionsSorting
											] == -1 ? (
												<AiOutlineCaretDown color="#404040" />
											) : (
												<AiOutlineCaretDown color="lightgrey" />
											)}
										</div>
									)}

									<span>{item.visible ? item.title : ""}</span>

									{item.icon && <item.icon />}
								</div>

								{item.filters ? (
									<RegistrationsTableHeaderFilter item={item} />
								) : (
									<div className="hidden h-[30px] w-[30px] lg:block"></div>
								)}
							</div>

							{item.visible &&
								item.filters &&
								subscriptionsFilters[
									item.key as keyof typeof subscriptionsFilters
								] !== "" && (
									<div
										className="group relative mx-auto mt-2 flex w-full items-center justify-center rounded-xl border bg-white py-1 px-5"
										onClick={() => removeFilter(item.key)}
									>
										{item.list ? (
											<span>
												{
													item.list.filter(
														(list: any) =>
															list.value ==
															subscriptionsFilters[
																item.key as keyof typeof subscriptionsFilters
															]
													)[0]?.label
												}
											</span>
										) : (
											<span>
												{
													subscriptionsFilters[
														item.key as keyof typeof subscriptionsFilters
													]
												}
											</span>
										)}
										<span className="invisible absolute right-2 group-hover:visible">
											<AiFillCloseCircle color="grey" size={12} />
										</span>
									</div>
								)}

							{item.key == "options" && (
								<div
									className="flex h-[30px] w-[30px] cursor-pointer items-center justify-center"
									onClick={resetSort}
								>
									<AiOutlineClose size={18} />
								</div>
							)}
						</th>
					))}
			</tr>
		</thead>
	);
};

export default RegistrationsTableHeader;
