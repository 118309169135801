import { Tooltip, Typography } from "@material-tailwind/react";
import dayjs, { Dayjs } from "dayjs";
import { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useQuery } from "react-query";
import {
	getEventFinanceDetails,
	validateEventCommission,
	validateEventFacture
} from "../../api/event";
import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import DateInput from "../../components/common/DateInput";
import Layout from "../../components/navigation/Layout";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";

const FinancesResume = () => {
	const { lang } = useContext(AppContext);
	const [startDate, setStartDate] = useState(() => {
		const date = new Date();
		date.setMonth(date.getMonth() - 1);

		return dayjs(date);
	});
	const [endDate, setEndDate] = useState(dayjs());
	const [validatedDates, setValidatedDates] = useState({
		start: startDate,
		end: endDate
	});
	const [validationLoading, setValidationLoading] = useState(false);

	const {
		data = [],
		isLoading,
		refetch
	} = useQuery({
		queryKey: ["event_finances", validatedDates],
		queryFn: ({ signal }) =>
			getEventFinanceDetails(
				validatedDates.start.toISOString(),
				validatedDates.end.toISOString(),
				signal
			),
		enabled: !!validatedDates.start && !!validatedDates.end,
		refetchOnWindowFocus: false
	});

	const validate = () => {
		setValidatedDates({ start: startDate, end: endDate });
	};

	const validateCom = async (idCalendrier: number) => {
		try {
			setValidationLoading(true);

			await validateEventCommission(idCalendrier);

			await refetch();

			Toast.success(trad[lang].toastPaymentSuccess);
		} catch (error) {
			Toast.error(trad[lang].toastPaymentError);
		} finally {
			setValidationLoading(false);
		}
	};

	const validateOrga = async (idFacture: number) => {
		try {
			setValidationLoading(true);

			await validateEventFacture(idFacture);

			await refetch();

			Toast.success(trad[lang].toastPaymentSuccess);
		} catch (error) {
			Toast.error(trad[lang].toastPaymentError);
		} finally {
			setValidationLoading(false);
		}
	};

	return (
		<Layout
			active_key="admin"
			bottomBar={<BottomBarNavigation back_to={`/admin`} />}
		>
			{/* Title */}
			<h1 className="mb-6 mt-8 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
				{trad[lang].finances_resume}
			</h1>

			<div className="flex w-full items-center justify-center gap-5">
				<DateInput
					handleChange={(e) => setStartDate(e as Dayjs)}
					value={startDate}
					label={trad[lang].StartPeriode}
					seconds={true}
				/>

				<DateInput
					handleChange={(e) => setEndDate(e as Dayjs)}
					value={endDate}
					label={trad[lang].EndPeriode}
					seconds={true}
				/>

				<button
					className="flex h-full flex-row items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primary hover:text-white md:uppercase"
					onClick={validate}
				>
					{trad[lang].validate}
				</button>
			</div>

			{isLoading && (
				<div className="relative flex h-screen w-full items-center justify-center">
					<AiOutlineLoading3Quarters size={45} className="ml-2 animate-spin" />
				</div>
			)}

			{data?.length > 0 && (
				<table className="mt-4 w-full divide-y divide-gray-200">
					<thead className="rounded-lg bg-gray-50">
						<th
							scope="col"
							className="w-max-[400px] select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
						>
							{trad[lang].invoice}
						</th>
						<th
							scope="col"
							className="w-max-[400px] hidden select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right xl:table-cell"
						>
							{trad[lang].nbSubs}
						</th>
						<th
							scope="col"
							className="hidden select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right md:table-cell"
						>
							{trad[lang].dag_share_ht}
						</th>
						<th
							scope="col"
							className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
						>
							{trad[lang].orga_share_ht}
						</th>
						<th
							scope="col"
							className="hidden select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right lg:table-cell"
						>
							{trad[lang].total_ht}
						</th>
						<th
							scope="col"
							className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
						>
							{trad[lang].com_ht}
						</th>
						<th
							scope="col"
							className="hidden select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right sm:table-cell"
						>
							{trad[lang].bill_orga}
						</th>
						<th
							scope="col"
							className="select-none py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
						>
							{trad[lang].bill_com}
						</th>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{data.map((item: any, index: number) => (
							<tr
								className={classNames(index % 2 ? "bg-gray-50" : "")}
								key={item.id}
							>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									<Tooltip
										className="text-md z-50 text-center"
										content={<Typography>{item.nom}</Typography>}
									>
										<div className="max-w-[100px] gap-x-2 overflow-hidden text-ellipsis whitespace-nowrap md:max-w-[150px] lg:max-w-[200px]">
											{item.nom}
										</div>
									</Tooltip>
								</td>
								<td className="hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 xl:table-cell">
									<div className="gap-x-2">{item.nbSubs}</div>
								</td>
								<td className="hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 md:table-cell">
									<div className="gap-x-2">{`${parseFloat(item.DagHT).toFixed(
										2
									)} €`}</div>
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									<div className="gap-x-2">{`${parseFloat(item.OrgaHT).toFixed(
										2
									)} €`}</div>
								</td>
								<td className="hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 lg:table-cell">
									<div className="gap-x-2">{`${parseFloat(item.EventHT).toFixed(
										2
									)} €`}</div>
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									<div className="gap-x-2">{`${parseFloat(item.ComHT).toFixed(
										2
									)} €`}</div>
								</td>
								<td className="hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 sm:table-cell">
									<div className="gap-x-2">
										{!item.EnCours && (
											<p>{`Facturee le ${new Date(
												item.factureeLe
											).toLocaleString()}`}</p>
										)}
										{item.DateModification && (
											<p>{`Dernière modification le ${new Date(
												item.DateModification
											)}`}</p>
										)}
									</div>
								</td>
								<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
									<div className="flex items-center gap-x-2">
										{item.observation.length > 0 &&
										JSON.parse(item.observation).virementCommission == 1 ? (
											<p>{trad[lang].paymentDone}</p>
										) : (
											<button
												className="flex h-full flex-row items-center gap-1 rounded-md border border-primary bg-white py-3 px-3 text-xs text-primary duration-150 hover:bg-primary hover:text-white"
												onClick={() => validateCom(item.id)}
												disabled={validationLoading}
											>
												{trad[lang].validatePayment}
											</button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</Layout>
	);
};

export default FinancesResume;
