import { useContext, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoIosPerson, IoIosPersonAdd } from "react-icons/io";
import { IoReceiptOutline } from "react-icons/io5";
import { RxReset } from "react-icons/rx";
import { searchSubscription } from "../../../api/admin";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import GlobalSearchResultsTable from "./GlobalSearchResult";

const SearchForm = () => {
	const { lang } = useContext(AppContext);
	/* Gestion de la recherche */
	const [results, setResults] = useState([]);

	/* Gestion des tabs */
	const [selectedTab, setSelectedTab] = useState(1);

	const [isLoading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState({
		firstname: "",
		lastname: "",
		email: "",
		resultsNbr: 150
	});

	const [currentPage, setCurrentPage] = useState(1);

	const handleInputChange = (field: string, value: string | number) => {
		setSearchTerm((prev) => ({ ...prev, [field]: value }));
	};

	const handleSearch = async () => {
		try {
			setLoading(true);

			const res = await searchSubscription(searchTerm);

			setResults(res);
			setSelectedTab(1);
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false);
		}
	};

	const resetForm = () => {
		setSearchTerm({
			firstname: "",
			lastname: "",
			email: "",
			resultsNbr: 150
		});
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [selectedTab]);

	return (
		<>
			<div className="mx-auto max-w-3xl rounded-md bg-gray-100 p-4 shadow-md">
				<h2 className="mb-4 text-xl font-semibold text-gray-700">
					{trad[lang].global_search}
				</h2>

				<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
					<div>
						<label className="block text-sm font-medium text-gray-600">
							{trad[lang].name}
						</label>
						<input
							type="text"
							value={searchTerm.lastname}
							onChange={(e) => handleInputChange("lastname", e.target.value)}
							placeholder="Nom"
							className="mt-1 w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring focus:ring-primary"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleSearch();
								}
							}}
						/>
					</div>

					<div>
						<label className="block text-sm font-medium text-gray-600">
							{trad[lang].firstname}
						</label>
						<input
							type="text"
							value={searchTerm.firstname}
							onChange={(e) => handleInputChange("firstname", e.target.value)}
							placeholder="Prénom"
							className="mt-1 w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring focus:ring-primary"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleSearch();
								}
							}}
						/>
					</div>

					<div className="col-span-full">
						<label className="block text-sm font-medium text-gray-600">
							{trad[lang].email}
						</label>
						<input
							type="text"
							value={searchTerm.email}
							onChange={(e) => handleInputChange("email", e.target.value)}
							placeholder="jean.bon@chronometrage.com"
							className="mt-1 w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring focus:ring-primary"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleSearch();
								}
							}}
						/>
					</div>
				</div>

				{/* Boutons */}
				<div className="mt-4 flex items-center justify-center gap-4">
					<label className="block text-center text-sm font-medium text-gray-600">
						{trad[lang].results}
					</label>
					<input
						type="text"
						value={searchTerm.resultsNbr}
						onChange={(e) =>
							handleInputChange("resultsNbr", parseInt(e.target.value))
						}
						className="w-14 rounded-md border border-gray-300 p-2 focus:outline-none focus:ring focus:ring-primary"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleSearch();
							}
						}}
					/>
					<button
						onClick={resetForm}
						className="flex items-center gap-2 rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
					>
						<RxReset />
						{trad[lang].reset}
					</button>
					<button
						onClick={handleSearch}
						className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
					>
						<CiSearch />
						{trad[lang].search}
					</button>
				</div>
			</div>

			<div className="mt-4 flex w-full justify-center gap-4">
				<button
					onClick={() => {
						setSelectedTab(0);
					}}
					className={`${
						selectedTab === 0
							? "border-2 border-blue-700 bg-blue-200 text-blue-700"
							: "border bg-blue-500 text-blue-200"
					} flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium hover:bg-blue-200 hover:text-blue-700`}
				>
					<IoIosPersonAdd /> {trad[lang].registrations}
				</button>
				<button
					onClick={() => {
						setSelectedTab(1);
					}}
					className={`${
						selectedTab === 1
							? "border-2 border-blue-700 bg-blue-200 text-blue-700"
							: "border bg-blue-500 text-blue-200"
					} flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium hover:bg-blue-200 hover:text-blue-700`}
				>
					<IoIosPerson /> {trad[lang].persons}
				</button>
				<button
					onClick={() => {
						setSelectedTab(2);
					}}
					className={`${
						selectedTab === 2
							? "border-2 border-blue-700 bg-blue-200 text-blue-700"
							: "border bg-blue-500 text-blue-200"
					} flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium hover:bg-blue-200 hover:text-blue-700`}
				>
					<IoReceiptOutline /> {trad[lang].commands}
				</button>
			</div>

			<GlobalSearchResultsTable
				results={results}
				selectedTab={selectedTab}
				isLoading={isLoading}
				currentPage={currentPage}
				searchTerm={searchTerm}
				setCurrentPage={setCurrentPage}
			/>
		</>
	);
};

export default SearchForm;
