import { useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";

const TableSurvey = ({
	label,
	questions,
	setQuestions,
	headers,
	setHeaders,
	displayMode
}: any) => {
	// const [headers, setHeaders] = useState<string[]>([]);

	// const [questions, setQuestions] = useState<string[]>([]);

	const [responses, setResponses] = useState<Record<number, string | string[]>>(
		{}
	);

	const [isValidated, setIsValidated] = useState(false);

	const [multipleChoices, setMultipleChoices] = useState(false);

	const addHeader = () => {
		const title = prompt("Entrez le titre de la colonne (header) :");
		if (title) {
			setHeaders([...headers, title]);
		}
	};

	const addQuestion = () => {
		const title = prompt("Entrez le texte de la question :");
		if (title) {
			setQuestions([...questions, title]);
		}
	};

	const deleteHeader = (index: number) => {
		setHeaders((prev: any[]) => prev.filter((_, i) => i !== index));
	};

	const deleteQuestion = (index: number) => {
		setQuestions((prev: any[]) => prev.filter((_, i) => i !== index));
		setResponses((prev) => {
			const newResponses = { ...prev };
			delete newResponses[index];
			return newResponses;
		});
	};

	const handleResponseChange = (questionIndex: number, header: string) => {
		setResponses((prev) => {
			const currentResponses = prev[questionIndex];

			if (multipleChoices) {
				const updatedResponses = Array.isArray(currentResponses)
					? currentResponses
					: [];
				if (updatedResponses.includes(header)) {
					return {
						...prev,
						[questionIndex]: updatedResponses.filter((item) => item !== header)
					};
				} else {
					return {
						...prev,
						[questionIndex]: [...updatedResponses, header]
					};
				}
			} else {
				return {
					...prev,
					[questionIndex]: header
				};
			}
		});
	};

	return (
		<div className="p-4">
			{/* Tableau */}
			<table className="min-w-full table-auto border-collapse rounded-lg border border-gray-300 bg-white shadow-md">
				<thead className="bg-gray-100">
					<tr>
						<th className="border border-gray-300 p-4 text-left text-sm font-semibold text-gray-600">
							{label}
						</th>
						{headers.map((header: any, index: number) => (
							<th
								key={index}
								className="relative border border-gray-300 p-4 text-center text-sm font-semibold text-gray-600"
							>
								{header}
								{!displayMode && (
									<>
										{!isValidated && (
											<button
												onClick={() => deleteHeader(index)}
												className="relative bottom-7 rounded-full bg-red-100 p-1 text-red-500 transition hover:bg-red-500 hover:text-white"
											>
												<HiOutlineXMark />
											</button>
										)}
									</>
								)}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{questions.map((question: any, questionIndex: number) => (
						<tr
							key={questionIndex}
							className={`hover:bg-gray-50 ${
								questionIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
							}`}
						>
							<td className="relative border border-gray-300 p-4 text-gray-700">
								{!displayMode && (
									<>
										{!isValidated && (
											<button
												onClick={() => deleteQuestion(questionIndex)}
												className="relative left-[-30px] rounded-full bg-red-100 p-1 text-red-500 transition hover:bg-red-500 hover:text-white"
											>
												<HiOutlineXMark />
											</button>
										)}
									</>
								)}
								{question}
							</td>

							{/* Radios */}
							{headers.map((header: any, headerIndex: number) => (
								<td
									key={headerIndex}
									className="border border-gray-300 p-4 text-center"
								>
									<input
										type={multipleChoices ? "checkbox" : "radio"}
										name={`question-${questionIndex}${
											multipleChoices ? `-${headerIndex}` : ""
										}`}
										value={header}
										checked={
											multipleChoices
												? responses[questionIndex]?.includes(header)
												: responses[questionIndex] === header
										}
										onChange={() => handleResponseChange(questionIndex, header)}
										className=" rounded-full border border-gray-300 text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			{!displayMode && (
				<>
					{!isValidated && (
						<div className="mt-4 space-x-4">
							<button
								onClick={addHeader}
								className="rounded bg-primary px-4 py-2 text-white"
							>
								Ajouter une colonne
							</button>
							<button
								onClick={addQuestion}
								className="rounded bg-primary px-4 py-2 text-white"
							>
								Ajouter une ligne
							</button>
							<button
								onClick={() => setMultipleChoices(!multipleChoices)}
								className="rounded bg-primary px-4 py-2 text-white"
							>
								{multipleChoices ? "Choix uniques" : "Choix multiples"}
							</button>
							<button
								onClick={() => {
									setIsValidated(true);
								}}
								className="rounded bg-green-600 px-4 py-2 text-white"
							>
								Valider
							</button>
						</div>
					)}
					{isValidated && (
						<div className="mt-4 flex justify-center space-x-4">
							<button
								onClick={() => setIsValidated(false)}
								className="rounded bg-yellow-600 px-4 py-2 text-white"
							>
								Modifier
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default TableSurvey;
