export const BASE_STEP = {
	dateDebut: new Date().toISOString().slice(0, -5),
	nom: "",
	distance: 0
	//miniTime: "00:00:00"
};

export const BASE_PARCOURS = {
	nom: "",
	file: null
};

export const BASE_SEGMENT = {
	id: 0,
	libelleSegment: "",
	checkpointDebut: { idCheckpoint: 0 },
	checkpointFin: { idCheckpoint: 0 }
};
