import { API } from "./APIClient";

export const publishStatus = async (slug: string, status: boolean) => {
	try {
		await API.patch(`event/slug/${slug}/publishStatus`, {
			status: status
		});
	} catch (error) {
		console.warn("[publishStatus error]", error);
		throw error;
	}
};

export const getDatabaseSubs = async (slug: string) => {
	try {
		const { data } = await API.get(`/admin/event/${slug}/getDatabaseSubs`, {
			headers: { Authorization: localStorage.getItem("token") }
		});
		return data;
	} catch (error) {
		console.warn("[getDatabaseSubs error]", error);
		throw error;
	}
};

export const delDatabaseSubs = async (slug: string) => {
	try {
		const { data } = await API.post(
			`/admin/event/${slug}/deleteDatabaseSubs`,
			null,
			{ headers: { Authorization: localStorage.getItem("token") } }
		);
		return data;
	} catch (error) {
		console.warn("[deleteDatabaseSubs error]", error);
		throw error;
	}
};

export const getClients = async (search: string) => {
	try {
		const { data } = await API.get(`/user/clients?search=${search}`, {
			headers: { Authorization: localStorage.getItem("token") }
		});

		return data;
	} catch (error) {
		console.warn("[getClients error]", error);
		throw error;
	}
};

export const createLicence = async (IDCommande: string) => {
	try {
		const { data } = await API.post(
			`/configuration/createLicence/${IDCommande}`,
			{},
			{
				headers: { Authorization: localStorage.getItem("token") }
			}
		);

		return data;
	} catch (error) {
		console.warn("[createLicense error]", error);
		throw error;
	}
};

export const createEncodingKey = async (
	IDPersonne: number,
	quantity: number
) => {
	try {
		const { data } = await API.post(
			`/configuration/createCleEncodage/${IDPersonne}`,
			{ quantite: quantity },
			{
				headers: { Authorization: localStorage.getItem("token") }
			}
		);

		return data;
	} catch (error) {
		console.warn("[createLicense error]", error);
	}
};

export const deleteOneSub = async (inscriptionId: string) => {
	try {
		await API.delete(`admin/deleteSub/${inscriptionId}`, {
			headers: { Authorization: localStorage.getItem("token") }
		});
	} catch (error) {
		console.warn(["[deleteOneSub error]"]);
		throw error;
	}
};

export const searchSubscription = async (searchTerm: Record<string, any>) => {
	try {
		if (!searchTerm || Object.keys(searchTerm).length === 0) {
			throw new Error("No search parameters provided");
		}

		const { data } = await API.post(`/admin/search_subscriptions`, searchTerm, {
			headers: { Authorization: localStorage.getItem("token") }
		});

		return data;
	} catch (error) {
		console.warn(["[searchSubscription error]"]);
		throw error;
	}
};
