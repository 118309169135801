import { useContext, useRef, useState } from "react";
import {
	AiOutlineCheck,
	AiOutlineClose,
	AiOutlineCopy,
	AiOutlineMinus,
	AiOutlinePlus
} from "react-icons/ai";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { useQuery } from "react-query";
import { useDebounce } from "usehooks-ts";
import { createEncodingKey, createLicence, getClients } from "../../api/admin";
import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import Layout from "../../components/navigation/Layout";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";

const Clients = () => {
	const { lang } = useContext(AppContext);
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 1000);
	const [copiedIdPersonne, setCopiedIdPersonne] = useState<
		number | undefined
	>();
	const [copiedIdClient, setCopiedIdClient] = useState<number>();
	const [openedLicense, setOpenedLicense] = useState<boolean>();
	const [licenseLoading, setLicenceLoading] = useState<boolean>(false);
	const [idcommande, setIdcommande] = useState("");
	const [openedLine, setOpenedLine] = useState<number>();
	const [addKey, setAddKey] = useState({ IDPersonne: 0, amount: 0 });

	const closeAddKey = () => {
		setAddKey({ IDPersonne: 0, amount: 0 });
	};

	const {
		data: clients = [],
		isLoading,
		refetch
	} = useQuery<any[]>({
		queryKey: ["clients", debouncedSearch],
		queryFn: () => getClients(debouncedSearch),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false
	});

	const timeOutIDClient = useRef<NodeJS.Timeout>();
	const timeOutIDPersonne = useRef<NodeJS.Timeout>();

	const copyValue = (
		value: string,
		type: "IDClient" | "IDPersonne",
		indexCopy: number
	) => {
		navigator.clipboard.writeText(value);

		if (type === "IDClient") {
			setCopiedIdClient(indexCopy);
			if (timeOutIDClient.current) clearTimeout(timeOutIDClient.current);

			timeOutIDClient.current = setTimeout(() => {
				setCopiedIdClient(undefined);
			}, 1000);
		} else {
			setCopiedIdPersonne(indexCopy);
			if (timeOutIDPersonne.current) clearTimeout(timeOutIDPersonne.current);

			timeOutIDPersonne.current = setTimeout(() => {
				setCopiedIdPersonne(undefined);
			}, 1000);
		}
	};

	const createLicense = async () => {
		try {
			setLicenceLoading(true);

			await createLicence(idcommande);

			await refetch();
			setOpenedLicense(false);
			setIdcommande("");
		} catch (error) {
			throw error;
		} finally {
			setLicenceLoading(false);
		}
	};

	const createKey = async () => {
		try {
			setLicenceLoading(true);

			await createEncodingKey(addKey.IDPersonne, addKey.amount);

			await refetch();
			closeAddKey();
		} catch (error) {
			throw error;
		} finally {
			setLicenceLoading(false);
		}
	};

	return (
		<>
			<Layout
				active_key="admin"
				bottomBar={<BottomBarNavigation back_to="/admin" />}
			>
				{/* Title */}
				<h1 className="mt-8 text-2xl font-bold text-gloom md:mb-8 md:text-3xl">
					{trad[lang].clients}
				</h1>

				<div className="w-full">
					<div className="flex flex-col gap-5 lg:flex-row">
						<div
							className={`relative w-full rounded-md border border-gray-300 py-2 shadow-sm  ${
								isLoading && "bg-gray-100 opacity-70"
							}`}
						>
							<div className="relative mt-1 rounded-md">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<HiOutlineMagnifyingGlass
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="searchinput"
									id="searchinput"
									className="block w-full rounded-md border-transparent bg-transparent py-0 pl-10 text-sm focus:border-transparent focus:ring-0"
									onChange={(e) => setSearch(e.target.value)}
									value={search}
									placeholder={trad[lang].search_client}
									autoComplete="off"
									disabled={isLoading}
								/>
							</div>
						</div>

						<button
							type="button"
							onClick={() => setOpenedLicense(true)}
							className="flex h-11 w-full items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 text-white shadow-md transition-colors hover:bg-primarymedium lg:h-auto lg:w-fit"
						>
							{trad[lang].add_licence}
						</button>
					</div>

					<div className="mt-5 overflow-hidden rounded-md border border-gray-400">
						<div className="w-full overflow-x-scroll">
							<div className="flex min-w-[1280px] flex-col lg:w-full">
								<div
									className={`flex w-full divide-x divide-gray-400 ${
										clients.length > 0 ? "border-b" : ""
									} border-gray-400 bg-gray-100`}
								>
									<p className="min-h-14 flex w-1/12 items-center justify-center py-2 text-center">
										IDClient <br />
										(Cust. IDResort)
									</p>
									<p className="min-h-14 flex w-1/12 items-center justify-center py-2 text-center">
										IDPersonne <br />
										(Alias Personne)
									</p>
									<p className="min-h-14 flex w-3/12 items-center justify-center py-2 text-center">
										{trad[lang].event_raisonSocial_label}
									</p>
									<p className="min-h-14 flex w-2/12 items-center justify-center py-2 text-center">
										{trad[lang].name}
									</p>
									<p className="min-h-14 flex w-3/12 items-center justify-center py-2 text-center">
										{trad[lang].email}
									</p>
									<p className="min-h-14 flex w-1/12 items-center justify-center py-2 text-center">
										{trad[lang].udc_licence_remaining}
									</p>
									<p className="w-1/12"></p>
								</div>

								<div className="divide-y divide-gray-200">
									{clients.map((item, index) => (
										<div key={item.IDClient}>
											<div
												className={`flex w-full divide-x divide-gray-200 ${
													openedLine === index ? "border-b bg-slate-50" : ""
												} transition-colors`}
											>
												<p
													className="group relative flex w-1/12 select-none items-center justify-center py-2 transition-colors hover:cursor-pointer hover:bg-slate-100"
													onClick={() =>
														copyValue(item.IDClient, "IDClient", index)
													}
												>
													{item.IDClient}

													{copiedIdClient == index ? (
														<AiOutlineCheck
															size={14}
															className="absolute top-2 right-2 text-green-500"
														/>
													) : (
														<AiOutlineCopy
															size={14}
															className="absolute top-2 right-2 text-gray-400 opacity-0 transition-opacity group-hover:opacity-100"
														/>
													)}
												</p>
												<p
													className="group relative flex w-1/12 select-none items-center justify-center py-2 transition-colors hover:cursor-pointer hover:bg-slate-100"
													onClick={() =>
														copyValue(item.IDPersonne, "IDPersonne", index)
													}
												>
													{item.IDPersonne}

													{copiedIdPersonne == index ? (
														<AiOutlineCheck
															size={14}
															className="absolute top-2 right-2 text-green-500"
														/>
													) : (
														<AiOutlineCopy
															size={14}
															className="absolute top-2 right-2 text-gray-400 opacity-0 transition-opacity group-hover:opacity-100"
														/>
													)}
												</p>
												<p className="flex w-3/12 items-center justify-center py-2 text-center">
													{item.RaisonSociale}
												</p>
												<p className="flex w-2/12 items-center justify-center py-2 text-center">
													{item.nom}
												</p>
												<p className="flex w-3/12 items-center justify-center py-2 text-center">
													{item.email}
												</p>
												<div className="flex w-1/12 flex-row items-center justify-center py-2">
													<p>{item.nb_activation || "ø"}</p>
												</div>

												<p
													className="flex w-1/12 cursor-pointer items-center justify-center py-2 transition-colors hover:bg-slate-100"
													onClick={() =>
														setOpenedLine((old) => (old === index ? -1 : index))
													}
												>
													{openedLine === index ? (
														<AiOutlineMinus size={20} />
													) : (
														<AiOutlinePlus size={20} />
													)}
												</p>
											</div>
											<div
												className={`${
													openedLine === index ? "h-auto py-4" : "h-0 py-0"
												} overflow-hidden px-4`}
											>
												<div className="flex flex-row items-center">
													<h2 className="mb-4 flex-1 font-bold">
														{item.RaisonSociale} - {item.nom}
														<em className="font-normal text-gray-700">{` (${item.email})`}</em>
													</h2>
													<button
														className="flex h-12 w-fit items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 text-white shadow-md transition-colors hover:bg-primarymedium"
														onClick={() =>
															setAddKey({
																IDPersonne: item.IDPersonne,
																amount: 0
															})
														}
													>
														Ajouter une clé d'encodage
													</button>
												</div>

												<div className="flex flex-row flex-wrap gap-y-2">
													{item.cles?.map((cle: any) => (
														<div key={cle.id} className="w-1/4">
															<div className="flex flex-row gap-2">
																<h2 className="font-bold">Clé {cle.id}</h2>

																<em className="font-normal text-gray-700">
																	(NB Credit : {cle.nb_credit})
																</em>
															</div>
															<div className="flex flex-row gap-2">
																<p>Crédits Restant : {cle.credits_restants}</p>
																<p>{"-"}</p>
																<p>
																	{cle.active == 0
																		? "Active : Non"
																		: "Active : Oui"}
																</p>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>

			{openedLicense && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="w-4/5 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-3/5 lg:w-2/5 ">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold">Création d'une licence</h3>
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={() => setOpenedLicense(false)}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col items-center gap-5 p-5 lg:flex-row">
							<p>Quel est le numéro de commande pour cette licence :</p>

							<div
								className={`relative rounded-md border border-gray-300 py-2 shadow-sm  ${
									licenseLoading && "bg-gray-100 opacity-70"
								}`}
							>
								<input
									type="text"
									name="licenseinput"
									id="licenseinput"
									className="block rounded-md border-transparent bg-transparent py-1 px-2 text-sm focus:border-transparent focus:ring-0"
									onChange={(e) => setIdcommande(e.target.value)}
									value={idcommande}
									placeholder="IDCommande"
									autoComplete="off"
									disabled={licenseLoading}
								/>
							</div>
						</div>

						<div className="flex flex-col items-center gap-2 rounded-b border-t border-gray-200 p-4 lg:flex-row">
							<button
								type="button"
								className={`h-12 w-full rounded-lg border border-red-600 bg-white px-5 py-2.5 text-center text-sm font-medium text-red-600 focus:outline-none lg:h-auto lg:w-fit ${
									licenseLoading ? "opacity-50" : ""
								}`}
								onClick={() => {
									setIdcommande("");
									setOpenedLicense(false);
								}}
								disabled={licenseLoading}
							>
								Annuler
							</button>
							<button
								type="button"
								className={`h-12 w-full rounded-lg border bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-primarymedium focus:outline-none lg:h-auto lg:w-fit ${
									licenseLoading ? "opacity-50" : ""
								}`}
								onClick={createLicense}
								disabled={licenseLoading}
							>
								Creer une licence
							</button>
						</div>
					</div>
				</div>
			)}

			{addKey.IDPersonne > 0 && (
				<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
					<div className="w-4/5 min-w-[300px] max-w-screen-lg rounded-md border bg-white md:w-3/5 lg:w-2/5 ">
						<div className="flex items-start justify-between rounded-t border-b p-4">
							<h3 className="text-xl font-semibold">
								Création d'une clé d'encodage
							</h3>
							<button
								type="button"
								className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
								onClick={closeAddKey}
							>
								<AiOutlineClose size={16} />
								<span className="sr-only">Close modal</span>
							</button>
						</div>

						<div className="flex flex-col items-center gap-5 p-5 lg:flex-row">
							<p>Combien de crédit souhaitez vous ajouter ?</p>

							<div
								className={`relative rounded-md border border-gray-300 py-2 shadow-sm  ${
									licenseLoading && "bg-gray-100 opacity-70"
								}`}
							>
								<input
									type="text"
									name="licenseinput"
									id="licenseinput"
									className="block rounded-md border-transparent bg-transparent py-1 px-2 text-sm focus:border-transparent focus:ring-0"
									onChange={(e) =>
										setAddKey((old) => ({
											...old,
											amount: parseInt(e.target.value)
										}))
									}
									value={addKey.amount}
									placeholder="Montant"
									autoComplete="off"
									disabled={licenseLoading}
								/>
							</div>
						</div>

						<div className="flex flex-col items-center gap-2 rounded-b border-t border-gray-200 p-4 lg:flex-row">
							<button
								type="button"
								className={`h-12 w-full rounded-lg border border-red-600 bg-white px-5 py-2.5 text-center text-sm font-medium text-red-600 focus:outline-none lg:h-auto lg:w-fit ${
									licenseLoading ? "opacity-50" : ""
								}`}
								onClick={closeAddKey}
								disabled={licenseLoading}
							>
								Annuler
							</button>
							<button
								type="button"
								className={`h-12 w-full rounded-lg border bg-primary px-5 py-2.5 text-center text-sm font-medium text-white transition-all hover:bg-primarymedium focus:outline-none lg:h-auto lg:w-fit ${
									licenseLoading ? "opacity-50" : ""
								}`}
								onClick={createKey}
								disabled={licenseLoading}
							>
								Creer une clé d'encodage
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Clients;
