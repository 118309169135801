import { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { logIn } from "../../api/auth";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { Credentials } from "../../types/Credentials";
import { classNames } from "../../utils/Classes";
import { handleReturnKey } from "../../utils/ReturnKey";
import Toast from "../../utils/Toasts";
import LangToggle from "../common/LangToggle";

const LoginForm = () => {
	const navigate = useNavigate();
	const { lang } = useContext(AppContext);
	const { getUser } = useContext(UserContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);

	const login = async () => {
		try {
			if (!email || !password) return Toast.error(trad[lang].loginMissing);

			setLoading(true);
			const creds: Credentials = { username: email, password, rememberMe };

			const res = await logIn(creds);

			if (res) {
				await localStorage.setItem("token", res.token);

				await getUser();

				if (res.newAuthSystem) {
					const result = await localStorage.getItem("newConnexion");
					if (!result) {
						await localStorage.setItem("newConnexion", true.toString());
					}
				}

				Toast.success(trad[lang].loginSuccess);

				if (localStorage.getItem("invitationSlug")) {
					navigate(
						"/" + localStorage.getItem("invitationSlug") + "/event-details"
					);
					localStorage.removeItem("invitationSlug");
				}
			}
		} catch (error) {
			Toast.error(trad[lang].loginError);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="mt-8 flex w-4/5 flex-col gap-4 sm:w-[480px]">
			{/* Email */}
			<div>
				<label
					htmlFor="email"
					className="block text-sm font-medium text-gray-700"
				>
					{trad[lang].email}
				</label>
				<div className="mt-1">
					<input
						id="email"
						name="email"
						type="email"
						autoComplete="email"
						required
						className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						onKeyDown={(e) => handleReturnKey(e, login)}
					/>
				</div>
			</div>

			{/* Password */}
			<div>
				<label
					htmlFor="password"
					className="block text-sm font-medium text-gray-700"
				>
					{trad[lang].password}
				</label>
				<div className="mt-1">
					<input
						id="password"
						name="password"
						type="password"
						autoComplete="password"
						required
						className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						onKeyDown={(e) => handleReturnKey(e, login)}
					/>
				</div>
			</div>

			{/* Remember me */}
			<div className="flex flex-col items-center justify-between gap-2 md:flex-row">
				<div className="flex items-center">
					<input
						id="remember-me"
						name="remember-me"
						type="checkbox"
						className="h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-0"
						onChange={() => setRememberMe((old) => !old)}
						checked={rememberMe}
					/>
					<label
						htmlFor="remember-me"
						className="ml-2 block text-sm text-gray-900"
					>
						{trad[lang].rememberMe}
					</label>
				</div>
			</div>

			{/* Submit */}
			<div>
				<button
					type="button"
					className={classNames(
						"flex w-full items-center justify-center rounded-md border  border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm duration-150 focus:outline-none focus:ring-0 focus:ring-offset-0",
						loading ? "bg-gray-700" : "bg-primary hover:bg-primarymedium"
					)}
					onClick={login}
					disabled={loading}
				>
					{loading ? (
						<>
							{trad[lang].loading}
							<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
						</>
					) : (
						trad[lang].submit
					)}
				</button>
			</div>

			<div>
				<div className="flex flex-col text-center">
					<Link to="/forgotten-password" className="mb-2 text-primary">
						{trad[lang].forgotten_password}
					</Link>
					<Link to="/signup" className="text-primary">
						{trad[lang].signup}
					</Link>
				</div>
			</div>

			{/* Lang Toggle */}
			<div className="flex w-full flex-row justify-center px-4">
				<LangToggle />
			</div>
		</div>
	);
};

export default LoginForm;
