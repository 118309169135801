import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";

const SportDeleteRunModal = ({
	setConfirmDeleteRun,
	deleteRun,
	title
}: {
	setConfirmDeleteRun: any;
	deleteRun: any;
	title: string;
}) => {
	const { lang } = useContext(AppContext);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="max-h-[80vh] w-4/5 min-w-[320px] max-w-screen-lg overflow-y-auto rounded-md border bg-white p-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{`${trad[lang].delete_run_title} : ${title}`}
				</h2>

				<div className="mt-3 flex flex-row justify-center gap-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={() => setConfirmDeleteRun(false)}
					>
						{trad[lang].cancel}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={deleteRun}
					>
						{trad[lang].delete}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SportDeleteRunModal;
