import { useContext, useState } from "react";
import { AiOutlineUp } from "react-icons/ai";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const ACTIVE_SLUG = ["sport"];

const TypeCard = ({ type }: { type: any }) => {
	const { lang } = useContext(AppContext);
	const [opened, setOpened] = useState(false);

	const displayed_types = type.types?.slice(0, 6) || [];

	return (
		<div className="h-fit w-full rounded-md border p-4 shadow-sm">
			<h2 className="mb-4 text-center text-2xl">{type.title}</h2>

			{ACTIVE_SLUG.includes(type.slug) ? (
				<Link
					className="block rounded-md bg-primary py-2 text-center text-white transition-colors hover:bg-primarymedium"
					to={type.slug}
					state={{ newEvent: true }}
				>
					{trad[lang].select}
				</Link>
			) : (
				<p className="block rounded-md bg-primary py-2 text-center text-white opacity-50 transition-colors">
					{trad[lang].select}
				</p>
			)}

			<p
				className="mt-4 flex cursor-pointer flex-row items-center justify-center gap-2 text-center"
				onClick={() => setOpened((old) => !old)}
			>
				<AiOutlineUp
					className={classNames(
						"transition-transform",
						opened ? "" : "rotate-180"
					)}
				/>
				{trad[lang].type_details}
			</p>

			{opened && (
				<ul className="mt-2 px-4">
					{displayed_types.map((item: any) => (
						<li className="list-disc" key={item.id}>
							{item.title}
						</li>
					))}

					<li className="list-disc">...</li>
				</ul>
			)}
		</div>
	);
};

export default TypeCard;
