import React, { useContext, useEffect } from "react";
import {
	AiOutlineCalendar,
	AiOutlineDollarCircle,
	AiOutlineLoading3Quarters,
	AiOutlineTrophy,
	AiOutlineUser
} from "react-icons/ai";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEvent, getEventDonations, getExportDonations } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

export type ASSOCIATION = {
	nomAssociation: string;
	mailAssociation: string;
	idProduits: number[];
};

export type DONATION = {
	nom: string;
	prenom: string;
	montant: number;
	date: Date;
	association: ASSOCIATION;
	idProduit: number;
	course: string;
};

export type DONATION_DATA = {
	donations: DONATION[];
	listAssociations: ASSOCIATION[];
};

const Donations = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const { data, isLoading } = useQuery({
		queryKey: [slug],
		queryFn: () => getEvent(slug),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const [listAssociations, setListAssociations] = React.useState<ASSOCIATION[]>(
		[]
	);
	const [listDonations, setListDonations] = React.useState<DONATION[]>([]);
	const [selectedAssociation, setSelectedAssociation] =
		React.useState<ASSOCIATION | null>(null);
	const [
		listDonationsOfSelectedAssociation,
		setListDonationsOfSelectedAssociation
	] = React.useState<DONATION[]>([]);

	useEffect(() => {
		setListDonationsOfSelectedAssociation(
			listDonations.filter((donation) => {
				return selectedAssociation
					? donation.association.nomAssociation ===
							selectedAssociation.nomAssociation
					: true;
			})
		);
	}, [selectedAssociation]);

	const [isExporting, setIsExporting] = React.useState(false);

	const { data: donationsData, isLoading: loadingDonations } = useQuery({
		queryKey: ["donations", slug],
		queryFn: () => getEventDonations(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	useEffect(() => {
		if (donationsData) {
			setListDonations(donationsData.donations);
			setListAssociations(donationsData.listAssociations);
			if (!selectedAssociation) {
				setSelectedAssociation(donationsData.listAssociations[0] || null);
			}
		}
	}, [donationsData]);

	if (isLoading || loadingDonations) {
		return <PageLoader menu_key="configuration" />;
	}

	const onClickExport = async () => {
		if (selectedAssociation) {
			setIsExporting(true);
			const { data, headers } = await getExportDonations(
				slug as string,
				selectedAssociation.nomAssociation
			);

			const blob = new Blob([data], {
				type: "application/octet-stream"
			});

			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			// const eventName = dataRuns.nom;
			const extension = "xlsx";
			link.href = url;
			link.download = `${slug}_Dons_${selectedAssociation.nomAssociation}.${extension}`;
			link.download = `dons.${extension}`;
			document.body.appendChild(link);
			link.click();

			URL.revokeObjectURL(url);
			link.remove();

			setIsExporting(false);
		}
	};

	return (
		<Layout
			active_key="configuration"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: data.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "donations", to: "#", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].donation_list}
			</h1>

			{donationsData?.donations.length === 0 &&
			donationsData?.listAssociations.length === 0 ? (
				<div className="w-full py-2 text-center text-gray-500">
					{trad[lang].no_run_with_donation}
				</div>
			) : (
				<>
					<div className="flex flex-row items-center justify-center text-center align-middle">
						<label className="mr-2 font-bold">Association :</label>
						<select
							className="block rounded-md border-gray-300 py-2 pl-3 pr-8 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
							defaultValue={selectedAssociation?.nomAssociation}
							onChange={(e) => {
								const association = listAssociations?.find(
									(association) => association.nomAssociation === e.target.value
								);
								setSelectedAssociation(association || null);
							}}
						>
							{listAssociations?.map((association, index) => (
								<option key={index} value={association.nomAssociation}>
									{association.nomAssociation}
								</option>
							))}
						</select>
						<span className="ml-4 rounded-full bg-primary px-3 py-1 align-middle text-sm font-bold text-background">
							{/* Sum of montant */}
							{listDonationsOfSelectedAssociation.reduce(
								(acc, donation) => acc + donation.montant,
								0
							)}{" "}
							€
						</span>
						{listDonationsOfSelectedAssociation?.length > 0 && (
							<button
								id="dropdownDividerButton"
								onClick={() => {
									onClickExport();
								}}
								className="ml-6 flex w-full flex-row items-center justify-center gap-1 self-start rounded-md border border-secondary py-1.5 px-2 text-secondary duration-150 hover:border-primary hover:text-primary sm:w-auto"
								type="button"
							>
								{trad[lang].export}
								{/* Loader */}
								{isExporting && (
									<AiOutlineLoading3Quarters
										className="animate-spin"
										size={20}
									/>
								)}
							</button>
						)}
					</div>

					<table className="mt-8 w-full divide-y divide-gray-200 border border-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="select-none gap-1 py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
								>
									<div className="flex flex-row gap-1">
										{trad[lang].runner}
										<AiOutlineUser size={18} />
									</div>
								</th>
								<th
									scope="col"
									className="select-none gap-1 py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
								>
									<div className="flex flex-row gap-1">
										{trad[lang].donation_value}
										<AiOutlineDollarCircle size={18} />
									</div>
								</th>
								<th
									scope="col"
									className="select-none gap-1 py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
								>
									<div className="flex flex-row gap-1">
										{trad[lang].date}
										<AiOutlineCalendar size={18} />
									</div>
								</th>
								<th
									scope="col"
									className="select-none gap-1 py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right"
								>
									<div className="flex flex-row gap-1">
										{trad[lang].run}
										<AiOutlineTrophy size={18} />
									</div>
								</th>
							</tr>
						</thead>
						<tbody className="">
							{/* Select donation with selectedAssociation */}
							{listDonationsOfSelectedAssociation?.length === 0 ? (
								<tr>
									<td
										colSpan={4}
										className="w-full py-2 text-center text-gray-500"
									>
										{trad[lang].no_donation_for_association}
									</td>
								</tr>
							) : (
								listDonationsOfSelectedAssociation.map((donation, index) => (
									<tr>
										<td className="py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											{donation.nom} {donation.prenom}
										</td>
										<td className="py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											{donation.montant} €
										</td>
										<td className="py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											{new Date(donation.date).toLocaleDateString(lang)}
										</td>
										<td className="py-3.5 px-4 text-left align-top text-sm font-normal text-gray-500 rtl:text-right">
											{donation.course}
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</>
			)}
		</Layout>
	);
};

export default Donations;
