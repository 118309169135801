import { useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
	createSatisfactionForm,
	getSatisfactionResponses
} from "../../api/communication";
import Layout from "../../components/navigation/Layout";
import { UserContext } from "../../contexts/UserContext";
import SatisfactionResponses from "./components/SatisfactionResponses";
import TableSurvey from "./components/TableSurvey";

const Communication = () => {
	const { slug } = useParams();
	const { user } = useContext(UserContext);
	const [userResponses, setUserResponses] = useState<any>([]);
	const [questions, setQuestions] = useState<any[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [tableSurveyQuestions, setTableSurveyQuestions] = useState<any[]>([]);
	const [multipleChoices, setMultipleChoices] = useState<boolean>(false);
	const [sliderRangerValue, setSliderRangerValue] = useState<number>(10);
	const [disabled, setDisabled] = useState<boolean>(true);
	const [responses, setResponses] = useState<Record<number, string | string[]>>(
		{}
	);
	const [newQuestion, setNewQuestion] = useState({
		questionText: "",
		questionType: "scale",
		allowMultipleChoices: multipleChoices,
		options: [] as string[]
	});
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
		useState<boolean>(true);

	const handleAddQuestion = () => {
		setQuestions([...questions, newQuestion]);
		setNewQuestion({
			questionText: "",
			questionType: "scale",
			options: [],
			allowMultipleChoices: false
		});
	};

	const handleQuestionTypeChange = (
		e: React.ChangeEvent<HTMLSelectElement>
	) => {
		const type = e.target.value;
		setNewQuestion({
			...newQuestion,
			questionType: type,
			options: type === "qcm" ? ["Option 1", "Option 2"] : []
		});
	};

	const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewQuestion({
			...newQuestion,
			questionText: e.target.value
		});
		if (e.target.value) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const handleOptionChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const updatedOptions = [...newQuestion.options];
		updatedOptions[index] = e.target.value;
		setNewQuestion({
			...newQuestion,
			options: updatedOptions
		});
	};

	const getEmojiAndText = (value: number) => {
		switch (value) {
			case 1:
				return { emoji: "😠", text: "Très insatisfait" };
			case 2:
				return { emoji: "😤", text: "Insatisfait" };
			case 3:
				return { emoji: "😟", text: "Plutôt insatisfait" };
			case 4:
				return { emoji: "😐", text: "Plutôt satisfait" };
			case 5:
				return { emoji: "🙃", text: "Assez satisfait" };
			case 6:
				return { emoji: "🙂", text: "Satisfait" };
			case 7:
				return { emoji: "😊", text: "Assez content" };
			case 8:
				return { emoji: "😀", text: "Content" };
			case 9:
				return { emoji: "😁", text: "Très content" };
			case 10:
				return { emoji: "😍", text: "Très satisfait" };
			default:
				return { emoji: "😐", text: "Satisfait" };
		}
	};

	const combinedData = tableSurveyQuestions.map(
		(question: any, index: number) => ({
			question: question,
			header: headers[index] || ""
		})
	);

	const handleSubmit = async () => {
		try {
			await Promise.all(
				questions.map(async (question: any) => {
					await createSatisfactionForm({
						userId: user?.IDPersonne,
						slug: slug,
						idTypeQuestion: question.questionType,
						question: question.questionText || null,
						allowMultipleChoices: question.allowMultipleChoices,
						options:
							question.questionType === "tcm" ? combinedData : question.options,
						tableData: question.questionType === "tcm" ? combinedData : null
					});
				})
			);
		} catch (error) {
			console.error("Error creating forms:", error);
		}
	};

	const getSatisfactionResponsesData = async () => {
		try {
			const res = await getSatisfactionResponses(slug as string);
			setUserResponses(res.length > 0);
		} catch (error) {
			console.error("Error getting satisfaction data:", error);
		}
	};

	useEffect(() => {
		getSatisfactionResponsesData();
	}, []);

	const handleResponseChange = (questionIndex: number, header: string) => {
		setResponses((prev) => {
			const currentResponses = prev[questionIndex];

			if (multipleChoices) {
				const updatedResponses = Array.isArray(currentResponses)
					? currentResponses
					: [];
				if (updatedResponses.includes(header)) {
					return {
						...prev,
						[questionIndex]: updatedResponses.filter((item) => item !== header)
					};
				} else {
					return {
						...prev,
						[questionIndex]: [...updatedResponses, header]
					};
				}
			} else {
				return {
					...prev,
					[questionIndex]: header
				};
			}
		});
	};

	useEffect(() => {
		questions.length === 0
			? setIsSubmitButtonDisabled(true)
			: setIsSubmitButtonDisabled(false);
		newQuestion.questionText == "" ? setDisabled(true) : setDisabled(false);
	}, [questions, newQuestion]);

	return (
		<Layout>
			<h1 className="mb-6 mt-6 text-center text-2xl font-bold text-gloom md:text-3xl">
				Marketing et Communication
			</h1>
			{userResponses ? (
				<SatisfactionResponses />
			) : (
				<div className="mx-auto rounded-xl bg-white p-8 shadow-md">
					<h2>Configurer mon formulaire de satisfaction</h2>
					{/* Question Configuration Form */}
					<div className="mb-8">
						<h2 className="mb-4 text-2xl font-semibold text-gray-800">
							Ajouter une question
						</h2>

						{/* Question Input */}
						<div className="mb-6">
							<label className="block text-lg font-medium text-gray-700">
								Question :
							</label>
							<input
								type="text"
								value={newQuestion.questionText}
								onChange={handleQuestionChange}
								className="w-full rounded-lg border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
								placeholder="Entrez votre question ici..."
							/>
						</div>

						{/* Question Type */}
						<div className="mb-6">
							<label className="block text-lg font-medium text-gray-700">
								Type de question :
							</label>
							<select
								value={newQuestion.questionType}
								onChange={handleQuestionTypeChange}
								className="w-full rounded-lg border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
							>
								<option value="scale">Échelle de satisfaction</option>
								<option value="qcm">Question à choix multiple</option>
								<option value="tcm">Tableau à choix multiple</option>
								<option value="text">Question ouverte</option>
							</select>
							{newQuestion.questionType === "qcm" && (
								<div className="mt-2">
									<label>Choix multiple</label>
									<input
										type="checkbox"
										onChange={() => {
											setMultipleChoices(!multipleChoices);
										}}
									/>
								</div>
							)}
						</div>

						{/* QCM Options */}
						{newQuestion.questionType === "qcm" && (
							<div className="mb-6">
								<label className="block text-lg font-medium text-gray-700">
									Options de réponse :
								</label>
								{newQuestion.options.map((option, index) => (
									<input
										key={index}
										type="text"
										value={option}
										onChange={(e) => handleOptionChange(e, index)}
										className="mb-2 w-full rounded-lg border p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
										placeholder={`Option ${index + 1}`}
									/>
								))}
								<button
									onClick={() =>
										setNewQuestion({
											...newQuestion,
											options: [...newQuestion.options, ""]
										})
									}
									className="text-blue-600 hover:underline"
								>
									Ajouter une option
								</button>
							</div>
						)}

						{/* Add Question Button */}
						<button
							onClick={handleAddQuestion}
							className={`mt-4 w-full rounded-lg ${
								disabled
									? "hover:none bg-blue-100"
									: "bg-blue-600 hover:bg-blue-700"
							} py-3 text-white shadow-md transition`}
							disabled={disabled}
						>
							Ajouter
						</button>
					</div>
					{/* Questions Preview */}
					{questions.length > 0 && (
						<div className="rounded border border-gray-200 p-6">
							<h2 className="mb-4 text-2xl font-semibold text-gray-800">
								Aperçu
							</h2>
							{questions.map((question, index) => (
								<div className="flex">
									<div
										key={index}
										className="mb-6 w-full rounded-lg bg-gray-100 p-4 shadow-sm"
									>
										<h3 className="text-xl font-medium text-gray-800">
											{question.questionType !== "tcm" && question.questionText}
										</h3>

										{/* SCALE */}
										{question.questionType === "scale" && (
											<div className="mt-4">
												<label className="block text-lg text-gray-700">
													{[...Array(10).keys()].map((_, index) => (
														<div
															key={index}
															className="flex items-center justify-center"
														>
															{index + 1 === sliderRangerValue && (
																<span>
																	{getEmojiAndText(sliderRangerValue).text}
																	{getEmojiAndText(index + 1).emoji}
																</span>
															)}
														</div>
													))}
												</label>
												<input
													type="range"
													min="1"
													max="10"
													className="w-full"
													value={sliderRangerValue}
													onChange={(e) => {
														setSliderRangerValue(parseInt(e.target.value));
													}}
												/>
												<div className="mt-2 text-sm text-gray-600"></div>
											</div>
										)}

										{/* QCM */}
										{question.questionType === "qcm" && (
											<div className="mt-4">
												{question.options.map((option: string, idx: number) => (
													<div key={idx} className="mb-2">
														<label className="flex items-center space-x-2">
															<input
																type={multipleChoices ? "checkbox" : "radio"}
																name={`question-${index}${
																	multipleChoices ? `-${idx}` : ""
																}`}
																value={option}
																checked={
																	multipleChoices
																		? responses[index]?.includes(option)
																		: responses[index] === option
																}
																onChange={() =>
																	handleResponseChange(index, option)
																}
																className=" rounded-full border border-gray-300 text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
															/>
															<span className="text-gray-700">{option}</span>
														</label>
													</div>
												))}
											</div>
										)}

										{/* TEXT */}
										{question.questionType === "text" && (
											<div className="mt-4">
												<textarea
													className="w-full rounded-lg border p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
													rows={3}
													placeholder="Votre réponse..."
												/>
											</div>
										)}

										{/* TCM */}
										{question.questionType === "tcm" && (
											<div className="mt-4">
												<TableSurvey
													label={question.questionText}
													questions={tableSurveyQuestions}
													headers={headers}
													setQuestions={setTableSurveyQuestions}
													setHeaders={setHeaders}
												/>
											</div>
										)}
									</div>
									<button
										className="p-4"
										onClick={() =>
											setQuestions(questions.filter((_, i) => i !== index))
										}
									>
										<FaTrash size={20} color="red" />
									</button>
								</div>
							))}
						</div>
					)}

					{/* Submit Form Button */}
					{questions.length > 0 && (
						<button
							onClick={handleSubmit}
							className={`mt-6 w-full rounded-lg ${
								isSubmitButtonDisabled
									? "hover:none bg-green-200"
									: "bg-green-600 hover:bg-green-700"
							} py-3 text-white shadow-md transition `}
							disabled={isSubmitButtonDisabled}
						>
							Enregistrer
						</button>
					)}
				</div>
			)}
		</Layout>
	);
};

export default Communication;
