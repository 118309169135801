import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IContact } from "../../types/EventForms/EventContacts";

const SportEventContact = ({ contact }: { contact: IContact }) => {
	const { lang } = useContext(AppContext);
	const socialReason = contact.raisonSocial?.split(" ")?.slice(0, 4)?.join(" ");
	const displayName = contact.raisonSocial
		? socialReason
		: `${contact.nom} ${contact.prenom}`;

	return (
		<div className="flex h-full flex-col gap-3 overflow-hidden rounded-md border border-gray-300 bg-white p-3 shadow-md">
			<p>{displayName}</p>
			<p>
				<span>{`${trad[lang].event_telephone1_label} : `}</span>
				{contact.telephone1}
			</p>
			<p>
				<span>{`${trad[lang].event_mail_label} : `}</span>
				{contact.mail}
			</p>
			<p>
				<span>{`${trad[lang].event_siteWeb_label} : `}</span>
				{contact.siteWeb}
			</p>
		</div>
	);
};

export default SportEventContact;
