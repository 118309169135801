import { useState } from "react";
import { AiOutlineDelete, AiOutlineDown } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { deleteEventList } from "../../api/event";
import ListDeleteConfirm from "./ListDeleteConfirm";
import ListDetail from "./ListDetail";

interface ListItemProps {
	list: {
		id: number;
		libelle: string;
		ordre: number;
		choix: { id: number; libelle: string; ordre: number }[];
	};
	refetch: () => Promise<any>;
}

const ListItem = ({ list, refetch }: ListItemProps) => {
	const { slug, slug_race } = useParams();
	const [opened, setOpened] = useState(false);
	const [openedConfirm, setOpenedConfirm] = useState(false);

	const closeDelete = () => setOpenedConfirm(false);

	const deleteList = async () => {
		try {
			if (!slug || !slug_race) return;

			await deleteEventList(slug, slug_race, list.id);
			refetch();
		} catch (error) {
			throw error;
		}
	};

	return (
		<>
			<div className="flex w-full flex-row gap-2">
				<div
					className={`mb-4 w-full select-none overflow-hidden rounded-md border border-primary bg-primary`}
				>
					<div
						className="flex w-full cursor-pointer flex-row items-center justify-between py-2 px-4 text-white"
						onClick={() => setOpened(!opened)}
					>
						<h2>{list.libelle}</h2>
						<div className="flex flex-row items-center gap-5">
							<AiOutlineDown
								color="white"
								size={18}
								className={`${opened ? "rotate-180" : ""} transition-transform`}
							/>
						</div>
					</div>

					{opened && <ListDetail list={list} refetch={refetch} />}
				</div>

				<div
					className="mt-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-red-500 text-white transition-colors hover:bg-red-700"
					onClick={() => setOpenedConfirm(true)}
				>
					<AiOutlineDelete size={18} />
				</div>
			</div>

			{openedConfirm && (
				<ListDeleteConfirm
					close={closeDelete}
					refetch={refetch}
					name={list.libelle}
					submit={deleteList}
				/>
			)}
		</>
	);
};

export default ListItem;
