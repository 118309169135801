import { Breadcrumbs } from "@material-tailwind/react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { IBreadcrumbKeys } from "../../types/Lang";

const BreadCrumb = ({
	items,
	margin = true
}: {
	items: { key: string; to: string; active: boolean; text?: string }[];
	margin?: boolean;
}) => {
	const { lang } = useContext(AppContext);
	return (
		<div className={`mt-12 w-fit md:mt-0 ${margin ? "mr-auto" : ""}`}>
			<Breadcrumbs className="rounded-md bg-slate-100">
				{items.map((item) =>
					item.to == "#" ? (
						<p className={item.active ? "" : "opacity-60"} key={item.key}>
							{`${trad[lang][item.key as IBreadcrumbKeys]}${
								item.text ? ` - ${item.text}` : ""
							}`}
						</p>
					) : (
						<Link
							to={item.to}
							className={item.active ? "" : "opacity-60 hover:opacity-100"}
							key={item.key}
						>
							{`${trad[lang][item.key as IBreadcrumbKeys]}${
								item.text ? ` - ${item.text}` : ""
							}`}
						</Link>
					)
				)}
			</Breadcrumbs>
		</div>
	);
};

export default BreadCrumb;
