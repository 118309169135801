import { useContext, useState } from "react";
import { AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";
import { useLocation, useRouteError } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Layout from "../navigation/Layout";

const GlobalError = () => {
	const error: any = useRouteError();
	const path = useLocation();
	const { lang } = useContext(AppContext);
	const [copied, setCopied] = useState(false);

	const handleCopy = async () => {
		try {
			const type = "text/plain";
			const blob = new Blob(
				[
					JSON.stringify({
						path: path.pathname,
						message: error?.message,
						stack: error?.stack
					})
				],
				{ type }
			);

			const data = new ClipboardItem({
				[type]: blob
			});

			await navigator.clipboard.write([data]);

			setCopied(true);

			setTimeout(() => setCopied(false), 2000);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Layout>
			{/* Title */}
			<h1 className="text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].error_boundary_title}
			</h1>

			<h2 className="text-lg">{trad[lang].error_boundary_support}</h2>

			<div className="relative w-3/4 rounded-md border border-red-200 bg-red-50 p-4 shadow-sm">
				<p className="font-bold">{path.pathname}</p>
				<p className="font-bold">{error?.message}</p>
				<p className="text-sm">{error?.stack}</p>

				<button
					className="absolute top-3 right-3 rounded-md border border-red-200 bg-white p-2 transition-colors hover:border-red-300 hover:bg-slate-50"
					onClick={handleCopy}
					disabled={copied}
				>
					{copied ? <AiOutlineCheck size={20} /> : <AiOutlineCopy size={20} />}
				</button>
			</div>
		</Layout>
	);
};

export default GlobalError;
