import { Tooltip, Typography } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { unstable_useBlocker, useBeforeUnload } from "react-router-dom";
import { createPrice, editPrice } from "../../../api/event";
import { BASE_FEES } from "../../../config/Fees";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import {
	IRunForm,
	IRunFormConfig,
	IRunPrice
} from "../../../types/EventForms/ISportRun";
import { classNames } from "../../../utils/Classes";
import { clampISODate, secureUTCDate } from "../../../utils/DateFormater";
import { FEES_TYPES } from "../../../utils/Prices";
import { REGEXP } from "../../../utils/RegExp";
import Toast from "../../../utils/Toasts";
import DateTimePickerTZ from "../../common/DateTimePickerTZ";
import SportEventRunFormModal from "./SportEventRunFormModal";

const SportEventPriceModal = ({
	idCalendrier,
	run,
	price,
	closePriceModal,
	fields,
	refetch,
	timezone
}: {
	price: IRunPrice;
	run: IRunForm;
	idCalendrier: number;
	closePriceModal: any;
	fields: IRunFormConfig[];
	refetch: any;
	timezone: string;
}) => {
	const { lang, unsavedChangesRef } = useContext(AppContext);
	const [localPrice, setLocalPrice] = useState(price);
	const [errors, setErrors] = useState<string[]>([]);
	const [openedPriceForm, setOpenedPriceForm] = useState<any>(null);
	const [isLoading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const cannotModify = price.has_subscriptions || !!price.id_tarif;

	// Unsaved changes ? (Display prompt if true)
	const isPromptConsumedRef = useRef(false);

	unstable_useBlocker((args) => {
		if (unsavedChangesRef.current && isPromptConsumedRef.current === false) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			return !userDiscarded;
		}
		return false;
	});

	useBeforeUnload(
		useCallback(
			(e) => {
				if (unsavedChangesRef.current) {
					e.preventDefault();
					return (e.returnValue = "");
				}
			},
			[unsavedChangesRef.current]
		)
	);

	const closeModal = () => {
		if (unsavedChangesRef.current === true) {
			let userDiscarded = window.confirm(trad[lang].discard_changes);
			if (userDiscarded) isPromptConsumedRef.current = true;
			if (userDiscarded === false) return;

			if (!userDiscarded) {
				unsavedChangesRef.current = false;
			}
		}
		closePriceModal();
	};

	const handleChange = (key: string, value: any) => {
		unsavedChangesRef.current = true;
		setErrors((old) => {
			const newErrors = [...old];

			const index = old.indexOf(key);

			if (index >= 0) {
				newErrors.splice(index, 1);
			}

			return newErrors;
		});

		setLocalPrice((old) => ({ ...old, [key]: value }));
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	const openFormModal = () =>
		setOpenedPriceForm({
			form:
				Object.values(localPrice.form).length > 0 ? localPrice.form : run.form
		});

	const closeFormModal = () => setOpenedPriceForm(null);
	const editLocalPriceForm = ({ form }: any) => {
		setLocalPrice((old) => ({ ...old, form }));
		closeFormModal();
	};

	// Prices Calculation
	const float_price = parseFloat(localPrice?.price?.split(",")?.join(".")) || 0;
	const float_client_fees =
		parseFloat(localPrice?.amountClientFixedFees?.split(",")?.join(".")) || 0;
	const local_dag_fees = Math.max(
		parseFloat(localPrice?.dag_fees?.split(",")?.join(".")) || 0,
		BASE_FEES(float_price)
	);

	const calc_percentage_fees = parseFloat(
		(float_price * (price.amountClientPercentageFees / 100)).toFixed(2)
	);

	const total_price =
		localPrice.feesType == FEES_TYPES.EXCLUDED
			? float_price + local_dag_fees + float_client_fees
			: float_price;

	const base_fees = (
		local_dag_fees +
		float_client_fees +
		calc_percentage_fees
	).toFixed(2);

	const submit = async () => {
		try {
			const local_errors = [];

			if (localPrice.designation.length < 3) {
				local_errors.push("designation");
			}

			const dateEqual =
				new Date(localPrice.startDate).toISOString().split("T")[0] ==
				new Date(localPrice.endDate).toISOString().split("T")[0];

			if (!localPrice.startDate || dateEqual) {
				local_errors.push("startDate");
			}

			if (!localPrice.endDate || dateEqual) {
				local_errors.push("endDate");
			}

			if (dateEqual) {
				Toast.error(trad[lang].ShouldBeNotSameDate);
			}

			if (
				!localPrice.free &&
				(total_price < 0 ||
					!REGEXP.price.test(localPrice.price) ||
					total_price < parseFloat(base_fees))
			) {
				local_errors.push("price");
			}

			if (localPrice.ageControl > 0 && localPrice.minAge > localPrice.maxAge) {
				local_errors.push("minAge");
			}

			if (
				localPrice.ageControl > 0 &&
				(localPrice.maxAge < localPrice.minAge || localPrice.maxAge == 0)
			) {
				local_errors.push("maxAge");
			}

			if (
				localPrice.ageControl > 0 &&
				(localPrice.minAge == null || Number.isNaN(localPrice.minAge))
			) {
				local_errors.push("minAge");
				setErrorMessage("L'âge minimum est requis");
			}

			if (
				localPrice.ageControl > 0 &&
				(localPrice.maxAge == null || Number.isNaN(localPrice.maxAge))
			) {
				local_errors.push("maxAge");
				setErrorMessage("L'âge maximum est requis");
			}

			if (
				float_client_fees < 0 ||
				!REGEXP.price.test(localPrice.amountClientFixedFees)
			) {
				local_errors.push("amountClientFixedFees");
			}

			if (local_errors.length > 0) {
				setErrors(local_errors);
				return;
			}

			const formatedFields = Object.fromEntries(
				fields.map((item) => [
					item.type.nom_champs,
					{
						visible: item.type.visible,
						required: item.type.obligatoire,
						id: item.type.id
					}
				])
			);

			const new_form =
				JSON.stringify(formatedFields) == JSON.stringify(localPrice.form)
					? {}
					: localPrice.form;

			const price_object = {
				...localPrice,
				form: new_form,
				price: float_price.toString(),
				amountClientFixedFees: float_client_fees.toString()
			};

			if (price_object.startDate)
				price_object.startDate = secureUTCDate(price_object.startDate);
			if (price_object.endDate)
				price_object.endDate = secureUTCDate(price_object.endDate);

			setLoading(true);
			if (price.id_tarif) {
				await editPrice(price.id_tarif_produit, price_object);
			} else {
				await createPrice(idCalendrier, price_object);
			}

			unsavedChangesRef.current = false;
			Toast.success(trad[lang].success_creating_price);
			refetch();
			setLoading(false);
			closeModal();
		} catch (error) {
			setLoading(false);
			Toast.error(trad[lang].error_creating_price);
		}
	};

	const calculateFees = (total_fees: string) => {
		if (localPrice.feesType == FEES_TYPES.INCLUDED) return;
		const floatFees = parseFloat(total_fees);

		const base_fees = BASE_FEES(float_price);
		const base_split = base_fees * 2;

		let total_dag_fees = 0;
		let total_remaining_fees = 0;

		if (floatFees > base_split) {
			const base_calc = floatFees - base_split;

			const dag_calc_fees = parseFloat((0.3 * base_calc).toFixed(2));
			const remaining_calc_fees = base_calc - dag_calc_fees;

			total_dag_fees = base_fees + dag_calc_fees;
			total_remaining_fees = base_fees + remaining_calc_fees;
		} else {
			total_dag_fees = base_fees;
			total_remaining_fees = floatFees - total_dag_fees;
		}

		handleChange("total_fees", parseFloat(total_fees).toFixed(2));
		handleChange("dag_fees", total_dag_fees.toFixed(2));
		handleChange("amountClientFixedFees", total_remaining_fees.toFixed(2));
	};

	return (
		<>
			<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
				<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white py-3 xl:w-3/5 2xl:w-2/5">
					<h2 className="mb-3 px-3 text-center text-2xl font-bold">
						{trad[lang].edit_tarif_title}
					</h2>

					<div className="flex max-h-[60vh] flex-col gap-3 overflow-y-auto px-3 md:grid md:grid-cols-2">
						<div
							className={`col-span-2 flex flex-row ${
								errorMessage ? "justify-between" : "justify-end"
							}`}
						>
							{errorMessage && (
								<span className="italic text-red-500">*{errorMessage}*</span>
							)}
							<button
								className={classNames(
									"flex h-full cursor-pointer flex-row items-center gap-1 rounded-md py-3 px-3 text-xs duration-150  md:uppercase",
									Object.values(localPrice.form).length > 0
										? "bg-green-500 text-white hover:bg-green-600"
										: "border border-primary bg-white text-primary hover:bg-primary hover:text-white"
								)}
								onClick={openFormModal}
							>
								{trad[lang].event_configure_sub_form}
							</button>
						</div>

						{/* Désignation du tarif */}
						<div className="flex flex-col">
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_designation"
							>
								{trad[lang].event_run_designation_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_price_name}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<input
								type="text"
								id="event_run_designation"
								className={classNames(
									"rounded-md",
									errors.includes("designation")
										? "border-transparent outline outline-2 outline-red-500"
										: ""
								)}
								onChange={(e) => handleChange("designation", e.target.value)}
								value={localPrice.designation}
							/>
						</div>

						{/* Type de contrôle d'âge */}
						<div className="flex flex-col">
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_age_control"
							>
								{trad[lang].event_run_age_control_label}
								<Tooltip
									className="text-md z-50 text-center"
									content={<Typography>{trad[lang].event_age}</Typography>}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<select
								className="rounded-md"
								id={"event_run_age_control"}
								onChange={(e) => handleChange("ageControl", e.target.value)}
								value={localPrice.ageControl}
							>
								<option value={0}>
									{trad[lang].event_run_age_control_option}
								</option>
								<option value={2}>
									{trad[lang].event_run_type_control_event}
								</option>
								<option value={3}>
									{trad[lang].event_run_type_control_subscription}
								</option>
								<option value={4}>
									{trad[lang].event_run_type_control_year_event}
								</option>
							</select>
						</div>

						{localPrice.ageControl > 0 && (
							<>
								{/* Age minimum */}
								<div className="flex flex-col">
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_age_min"
									>
										{trad[lang].event_run_age_min_label}
										<RequiredComponent />
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>{trad[lang].event_min_age}</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>

									<input
										type="number"
										id="event_run_age_min"
										className={classNames(
											"rounded-md",
											errors.includes("minAge")
												? "border-transparent outline outline-2 outline-red-500"
												: ""
										)}
										min={0}
										onChange={(e) =>
											handleChange("minAge", parseInt(e.target.value))
										}
										value={localPrice.minAge}
									/>
								</div>

								{/* Age maximum */}
								<div className="flex flex-col">
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_age_max"
									>
										{trad[lang].event_run_age_max_label}
										<RequiredComponent />
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>{trad[lang].event_max_age}</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>

									<input
										type="number"
										id="event_run_age_max"
										className={classNames(
											"rounded-md",
											errors.includes("maxAge")
												? "border-transparent outline outline-2 outline-red-500"
												: ""
										)}
										min={1}
										onChange={(e) =>
											handleChange("maxAge", parseInt(e.target.value))
										}
										value={localPrice.maxAge}
									/>
								</div>
							</>
						)}

						{/* Date de début */}
						<div className="flex flex-col">
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_price_start"
							>
								{trad[lang].event_run_start_date_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_price_start_date}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<DateTimePickerTZ
								handleChange={(e) => {
									if (e) {
										handleChange("startDate", e);
										if (dayjs(localPrice.endDate).isBefore(dayjs(e)))
											handleChange("endDate", e);
									}
								}}
								error={errors.includes("startDate")}
								value={dayjs(secureUTCDate(localPrice.startDate))}
								timezone={timezone}
							/>
						</div>

						{/* Date de fin */}
						<div className="flex flex-col">
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_price_end"
							>
								{trad[lang].event_run_end_date_label}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_price_end_date}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<DateTimePickerTZ
								handleChange={(e) => {
									if (e) {
										handleChange("endDate", e);
									}
								}}
								error={errors.includes("endDate")}
								timezone={timezone}
								value={dayjs(
									clampISODate(
										localPrice.endDate,
										localPrice.startDate,
										localPrice.endDate
									)
								)}
								minDateTime={dayjs(localPrice.startDate)}
							/>
						</div>

						{/* Course gratuite ? */}
						<div className="col-span-2 flex flex-col">
							<label
								className="mb-1 flex flex-row items-center gap-1 text-lg"
								htmlFor="event_run_fees_type"
							>
								{trad[lang].event_run_free_run}
								<RequiredComponent />
								<Tooltip
									className="text-md z-50 text-center"
									content={
										<Typography>{trad[lang].event_price_free}</Typography>
									}
								>
									<p>
										<AiOutlineInfoCircle />
									</p>
								</Tooltip>
							</label>

							<select
								className={classNames(
									"rounded-md",
									cannotModify ? "border-gray-500 text-gray-500" : ""
								)}
								id={"event_run_fees_type"}
								onChange={(e) => handleChange("free", parseInt(e.target.value))}
								value={localPrice.free}
								disabled={cannotModify}
							>
								<option value={0}>{trad[lang].event_no_free_run}</option>
								<option value={1}>{trad[lang].event_free_run}</option>
							</select>
						</div>

						{localPrice.free == 0 && (
							<>
								{/* Prix de la course */}
								<div className="flex flex-col">
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_price"
									>
										{trad[lang].event_run_price}
										<RequiredComponent />
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>{trad[lang].event_price_price}</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>
									<input
										type="text"
										id="event_run_price"
										min={0}
										className={classNames(
											"rounded-md",
											errors.includes("price")
												? "border-transparent outline outline-2 outline-red-500"
												: "",
											price.has_subscriptions
												? "border-gray-400 text-gray-400"
												: ""
										)}
										disabled={price.has_subscriptions}
										onChange={(e) =>
											(REGEXP.price.test(e.target.value) || !e.target.value) &&
											handleChange("price", e.target.value)
										}
										value={localPrice.price}
									/>
								</div>
								<div className="flex flex-col">
									<label className="mb-1 flex flex-row items-center gap-1 text-lg">
										{trad[lang].totalPriceOfRun}
									</label>
									<p className="flex h-[42px] items-center font-bold">
										{float_price * run.teamNumber} €
									</p>
								</div>

								<h2 className="col-span-2 mt-3 flex flex-col text-2xl font-bold">{`${trad[lang].event_fees} (${trad[lang].see_contract})`}</h2>

								{/* Type de frais */}
								<div className="flex flex-col">
									<label
										className="mb-1 flex flex-row items-center gap-1 text-lg"
										htmlFor="event_run_fees_type"
									>
										{trad[lang].event_run_fees_type_label}
										<RequiredComponent />
										<Tooltip
											className="text-md z-50 text-center"
											content={
												<Typography>
													{trad[lang].event_price_fees_type}
												</Typography>
											}
										>
											<p>
												<AiOutlineInfoCircle />
											</p>
										</Tooltip>
									</label>

									<select
										className={classNames(
											"rounded-md",
											cannotModify ? "border-gray-500 text-gray-500" : ""
										)}
										id={"event_run_fees_type"}
										onChange={(e) => {
											handleChange("feesType", e.target.value);
										}}
										value={localPrice.feesType}
										disabled={cannotModify}
									>
										<option value={0}>{trad[lang].event_fees_inclued}</option>
										<option value={1}>{trad[lang].event_fees_exclued}</option>
									</select>
								</div>

								{/* Tableau de gestion des frais */}
								<div className="col-span-2 flex flex-col border border-gray-200">
									<table className="divide-y divide-gray-200">
										<thead className="rounded-lg bg-gray-50">
											<tr>
												{/* Titre frais d'inscription TTC */}
												<th className="select-none py-3.5 px-4 text-left align-top font-normal text-gray-500">
													<p className="flex flex-row items-center gap-1">
														{trad[lang].subscription_fees_total}

														<Tooltip
															className="text-md z-50 text-center"
															content={
																<Typography>
																	{trad[lang].event_price_fees_calc}
																</Typography>
															}
														>
															<p>
																<AiOutlineInfoCircle />
															</p>
														</Tooltip>
													</p>
												</th>
												{/* PART APPORTEUR */}
												<th className="select-none py-3.5 px-4 text-left align-top font-normal text-gray-500">
													<p className="flex flex-row items-center gap-1">
														{`${trad[lang].subscription_fees_sup}`}

														<Tooltip
															className="text-md z-50 text-center"
															content={
																<Typography>
																	{trad[lang].event_price_fees_sup}
																</Typography>
															}
														>
															<p>
																<AiOutlineInfoCircle />
															</p>
														</Tooltip>
													</p>
												</th>
												{/* PART DAG */}
												<th className="select-none py-3.5 px-4 text-left align-top font-normal text-gray-500">
													<p className="flex flex-row items-center gap-1">
														{trad[lang].dag_fees}

														<Tooltip
															className="text-md z-50 text-center"
															content={
																<Typography>
																	{trad[lang].event_price_fees}
																</Typography>
															}
														>
															<p>
																<AiOutlineInfoCircle />
															</p>
														</Tooltip>
													</p>
												</th>
												{/* REVENU APPORTEUR */}
												<th className="select-none py-3.5 px-4 text-left align-top font-normal text-gray-500">
													<p className="flex flex-row items-center gap-1">
														{trad[lang].subscription_income}

														<Tooltip
															className="text-md z-50 text-center"
															content={
																<Typography>
																	{trad[lang].event_price_fees}
																</Typography>
															}
														>
															<p>
																<AiOutlineInfoCircle />
															</p>
														</Tooltip>
													</p>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="whitespace-nowrap px-4 py-4 font-medium text-gray-700">
													<input
														type="text"
														id="event_run_price"
														min={0}
														className={classNames(
															"w-40 rounded-md",
															errors.includes("amountClientFixedFees")
																? "border-transparent outline outline-2 outline-red-500"
																: "",
															price.has_subscriptions ||
																localPrice.feesType == FEES_TYPES.INCLUDED
																? "border-gray-400 text-gray-400"
																: ""
														)}
														onChange={(e) => {
															(REGEXP.price.test(e.target.value) ||
																!e.target.value) &&
																calculateFees(e.target.value);
														}}
														defaultValue={parseFloat(
															(float_client_fees + local_dag_fees).toFixed(2)
														)}
														disabled={
															price.has_subscriptions ||
															localPrice.feesType == FEES_TYPES.INCLUDED
														}
													/>{" "}
													€
												</td>
												<td className="whitespace-nowrap px-4 py-4 font-medium text-gray-700">
													<p className="flex flex-row items-center gap-1">
														{(localPrice.feesType == 1
															? float_client_fees
															: 0
														).toFixed(2)}
														€
													</p>
												</td>
												<td className="whitespace-nowrap px-4 py-4 font-medium text-gray-700">
													<p className="flex flex-row items-center gap-1">
														{(localPrice.feesType == 1
															? local_dag_fees
															: BASE_FEES(float_price)
														).toFixed(2)}
														€
													</p>
												</td>
												<td className="whitespace-nowrap px-4 py-4 font-medium text-gray-700">
													<p className="flex flex-row items-center gap-1">
														{(localPrice.feesType == 1
															? float_client_fees / 1.2
															: 0
														).toFixed(2)}
														€
													</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
						)}
					</div>

					<div className="mt-3 flex flex-row items-center gap-3 px-3">
						<button
							className={classNames(
								"flex h-full flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase",
								isLoading ? "opacity-50" : "cursor-pointer"
							)}
							onClick={closeModal}
							disabled={isLoading}
						>
							{isLoading ? <>{trad[lang].loading}</> : trad[lang].close}
						</button>
						<button
							className={classNames(
								"flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase",
								isLoading ? "opacity-50" : "cursor-pointer"
							)}
							onClick={submit}
							disabled={isLoading}
						>
							{isLoading ? (
								<>
									{trad[lang].loading}{" "}
									<AiOutlineLoading3Quarters
										size={15}
										className="ml-2 animate-spin"
									/>
								</>
							) : (
								trad[lang].validate
							)}
						</button>

						<h2 className="col-span-2 text-2xl font-bold">{`${
							trad[lang].event_sport_price
						} : ${total_price.toFixed(2)}€ TTC ${trad[lang].bySubscriber}`}</h2>
					</div>
				</div>
			</div>

			{openedPriceForm != null && (
				<SportEventRunFormModal
					runForm={openedPriceForm.form}
					handleForm={editLocalPriceForm}
					closeModal={closeFormModal}
					fields={fields}
					idCalendrier={run.id}
					refetch={refetch}
				/>
			)}
		</>
	);
};

export default SportEventPriceModal;
