import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSubscriptionOrder } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import Layout from "../components/navigation/Layout";
import RegistrationsOrderTable from "../components/registrations/RegistrationsOrderTable";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function RegistrationOrder() {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const { idInscription } = useParams();

	const { data } = useQuery({
		queryKey: ["subscription_order", idInscription],
		queryFn: () => getSubscriptionOrder(parseInt(idInscription || "0")),
		refetchOnWindowFocus: false,
		enabled: !!idInscription
	});

	const commandID = data
		? data.map((item: any) => {
				return item.rows[0].IDCommande;
		  })
		: "";

	return (
		<Layout active_key="registrations">
			{/* Page heading */}
			<DetailsPageHeading
				pageTitle={trad[lang].registration_order + " - nº " + commandID}
			/>

			{data?.length > 0 && <RegistrationsOrderTable data={data} />}

			<BottomBarNavigation back_to={`/${slug}/registrations-details`} />
		</Layout>
	);
}

export default RegistrationOrder;
