import dayjs from "dayjs";
import { padNumber } from "./Pad";

export function formatWithoutOffset(date: Date) {
	const offset = date.getTimezoneOffset();

	date.setMinutes(date.getMinutes() + offset * -1);

	return date;
}

export function formatDate(dateString: string) {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return `${day.toString().padStart(2, "0")}/${month
		.toString()
		.padStart(2, "0")}/${year} ${hours.toString().padStart(2, "0")}h${minutes
		.toString()
		.padStart(2, "0")}`;
}

export function formatLikeISO(date: Date, noSeconds = false) {
	const day = padNumber(date.getDate().toString(), 2);
	const month = padNumber((date.getMonth() + 1).toString(), 2);
	const year = padNumber(date.getFullYear().toString(), 2);
	const hours = padNumber(date.getHours().toString(), 2);
	const minutes = padNumber(date.getMinutes().toString(), 2);
	const seconds = padNumber(date.getSeconds().toString(), 2);
	const miliseconds = padNumber(date.getMilliseconds().toString(), 3);

	const formated_date = `${year}-${month}-${day.toString()}T${hours}:${minutes}:${
		noSeconds ? "00" : seconds
	}.${noSeconds ? "000" : miliseconds}`;

	return formated_date;
}

export function formatLikeISOWithZ(date: Date, noSeconds = false) {
	const day = padNumber(date.getDate().toString(), 2);
	const month = padNumber((date.getMonth() + 1).toString(), 2);
	const year = padNumber(date.getFullYear().toString(), 2);
	const hours = padNumber(date.getHours().toString(), 2);
	const minutes = padNumber(date.getMinutes().toString(), 2);
	const seconds = padNumber(date.getSeconds().toString(), 2);
	const miliseconds = padNumber(date.getMilliseconds().toString(), 3);

	const formated_date = `${year}-${month}-${day.toString()}T${hours}:${minutes}:${
		noSeconds ? seconds : "00"
	}.${noSeconds ? miliseconds : "000"}Z`;

	return formated_date;
}

export function formatDaylight(date: Date) {
	const jan = new Date(date.getFullYear(), 0, 1);
	const jul = new Date(date.getFullYear(), 6, 1);
	const stdTimezoneOffset = Math.max(
		jan.getTimezoneOffset(),
		jul.getTimezoneOffset()
	);

	const isDstObserved = date.getTimezoneOffset() < stdTimezoneOffset;

	date.setMinutes(date.getMinutes() + (isDstObserved ? 0 : -60));

	return date;
}

export const calculateDateDifference = (start: Date, end: Date) => {
	const time = end.getTime() - start.getTime();

	const total_seconds = Math.floor(Math.abs(time) / 1000);
	const total_minutes = Math.floor(total_seconds / 60);
	const total_hours = Math.floor(total_minutes / 60);
	const total_days = Math.floor(total_hours / 24);
	const total_months = Math.floor(total_days / 30);
	const total_years = Math.floor(total_months / 12);

	const milliseconds = Math.abs(time) % 1000;
	const seconds = total_seconds % 60;
	const minutes = total_minutes % 60;
	const hours = total_hours % 24;

	return {
		sign: time >= 0 ? "+" : "-",
		total_seconds,
		total_minutes,
		total_hours,
		total_days,
		total_months,
		total_years,
		milliseconds,
		seconds,
		minutes,
		hours
	};
};

export const StringToTimeSpan = (value: string) => {
	const values = value.split("--").join("").split(":");
	const filtered_empty = values.filter((item) => item);

	const formated = [...filtered_empty, "--", "--", "--"];
	formated.length = 3;

	return formated.join(":");
};

export const calculateAge = (birthdate: string) => {
	const bd = dayjs(birthdate);

	return dayjs().diff(bd, "years");
};

export const milisecondsISODate = (Date: string) => {
	let newDate = Date;

	while (newDate.length < 23) {
		newDate += "0";
	}

	return newDate;
};

export const formatDateString = (dateString: any) => {
	const date = new Date(dateString);

	// Extraire les éléments de la date
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = String(date.getSeconds()).padStart(2, "0");
	const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

	return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

/*
 ** Ensure that date has a .000Z at the end
 ** Otherwise it would be parsed as local time
 ** ALL DATES MUST BE UTC as input
 ** displayed as local time
 */
export const secureUTCDate = (date: string) => {
	if (typeof date === "string") {
		if (!date.match(/\.000Z$/)) date += ".000Z";
	}
	return date;
};

export const clampISODate = (
	date: string,
	minDate: string,
	maxDate: string
) => {
	const min = dayjs(minDate);
	const max = dayjs(maxDate);
	const d = dayjs(date);

	if (d.isBefore(min)) {
		return secureUTCDate(minDate);
	}

	if (d.isAfter(max)) {
		return secureUTCDate(maxDate);
	}

	return secureUTCDate(date);
};
