import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrderRows } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import Layout from "../components/navigation/Layout";
import RegistrationsRefundTable from "../components/registrations/RegistrationsRefundTable";
import { AppContext } from "../contexts/AppContext";
import { UserContext } from "../contexts/UserContext";
import trad from "../lang/traduction";

function RegistrationRefund() {
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const { slug } = useParams();
	const { idInscription } = useParams();

	const { data, refetch } = useQuery({
		queryKey: ["order_rows", idInscription],
		queryFn: () => getOrderRows(parseInt(idInscription || "0")),
		refetchOnWindowFocus: false,
		enabled: !!idInscription
	});

	return (
		<Layout active_key="registrations">
			{/* Page heading */}
			<DetailsPageHeading pageTitle={trad[lang].registration_refund} />

			{(data?.order_rows?.length > 0 || data?.refund_order != null) && (
				<RegistrationsRefundTable
					rows={data.order_rows}
					refund={data.refund_order}
					refetch={refetch}
				/>
			)}

			<BottomBarNavigation back_to={`/${slug}/registrations-details`} />
		</Layout>
	);
}

export default RegistrationRefund;
