import { useEffect, useRef, useState } from "react";
import Select from "react-tailwindcss-select";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import {
	ICountry,
	countriesList,
	getCountryByWindevId
} from "../../utils/CountriesList";

function SelectCountry({
	lang,
	onChange,
	listTailwind,
	absolute = true,
	defaultCountryCode,
	disabled
}: {
	lang: ILang;
	onChange: Function;
	listTailwind?: string;
	absolute?: boolean;
	defaultCountryCode?: string;
	disabled?: boolean;
}) {
	const listCountries = useRef<any[]>(
		countriesList.map((item) => ({
			...item,
			value: item.name[lang == "fr" ? "fr" : "en"],
			label: item.name[lang == "fr" ? "fr" : "en"]
		}))
	);

	const [country, setCountry] = useState<any>();

	useEffect(() => {
		let country: ICountry | undefined;
		if (defaultCountryCode) {
			// If windev ID
			if (
				typeof defaultCountryCode === "number" ||
				defaultCountryCode.match(/^[0-9]+$/)
			) {
				country = getCountryByWindevId(parseInt(defaultCountryCode));
				// Add value & label for custom select
				if (country) {
					(country as any).value = country.name[lang == "fr" ? "fr" : "en"];
					(country as any).label = (country as any).value;
				}
			}
			// If ISO code
			else
				country = listCountries.current.find(
					(country: ICountry) => country?.code == defaultCountryCode
				);
		}
		setCountry(country);
	}, [defaultCountryCode]);

	const handleCountryChange = function (value: any) {
		setCountry(value);
		onChange(value);
	};

	return (
		<Select
			placeholder={trad[lang].select_country}
			searchInputPlaceholder={trad[lang].select_countrySearch}
			noOptionsMessage={trad[lang].select_countryNoResult}
			options={listCountries.current}
			value={country}
			onChange={handleCountryChange}
			primaryColor={"cyan"}
			isSearchable={true}
			classNames={{
				menu:
					"z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700 " +
					(absolute === true ? "absolute" : ""),
				list: "overflow-y-auto " + listTailwind
			}}
			isDisabled={disabled}
			formatOptionLabel={(data) => (
				<li
					className={`flex cursor-pointer select-none list-none flex-row items-center gap-2  truncate rounded px-2 py-2 transition duration-200 ${
						data.isSelected
							? `bg-blue-500 text-white`
							: `bg-white text-gray-900`
					}}`}
				>
					<img
						src={(data as any).flag}
						alt={(data as any).name.fr}
						className="h-4 w-6"
					/>{" "}
					{data.label}
				</li>
			)}
		/>
	);
}

export default SelectCountry;
