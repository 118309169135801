import { API } from "./APIClient";

export const createSatisfactionForm = async (body: any) => {
	try {
		const { data } = await API.post(`/communication/satisfaction`, body);

		return data;
	} catch (error) {
		console.warn("[createSatisfaction error]", error);
		throw error;
	}
};

export const getSatisfactionForm = async (slug: string) => {
	try {
		const { data } = await API.get(`/communication/satisfaction/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getSatisfactionForm error]", error);
		throw error;
	}
};

export const getSatisfactionResponses = async (slug: string) => {
	try {
		const { data } = await API.get(
			`/communication/satisfactionResponses/${slug}`
		);

		return data;
	} catch (error) {
		console.warn("[getSatisfactionForm error]", error);
		throw error;
	}
};
