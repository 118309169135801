import { Dispatch, SetStateAction, useContext, useState } from "react";
import { deleteOneSub } from "../../api/admin";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import Toast from "../../utils/Toasts";

const DeleteModal = ({
	subscription,
	setOpenedDelete,
	refetch
}: {
	subscription: any;
	setOpenedDelete: Dispatch<SetStateAction<any>>;
	refetch: Function;
}) => {
	const { lang } = useContext(AppContext);
	const [loading, setLoading] = useState(false);

	const deleteSubscription = async (inscriptionId: any) => {
		try {
			setLoading(true);
			await deleteOneSub(inscriptionId);
			await refetch();
			setOpenedDelete(null);
			Toast.success(trad[lang].deletionSuccess);
		} catch {
			Toast.error(trad[lang].deletionError);
		} finally {
			setLoading(false);
		}
	};

	if (!subscription) return null;

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-[rgba(0,0,0,.5)]">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg overflow-hidden rounded-md border bg-white xl:w-3/5 2xl:w-2/5">
				<div className="flex items-start justify-between rounded-t border-b p-4">
					<h2 className="text-center text-2xl">{trad[lang].deleteSub}</h2>
				</div>

				<div className="grid grid-cols-2 gap-5 p-5">
					<div className="col-span-2 flex flex-row gap-2 text-center">
						<p className="font-normal">
							{trad[lang].confirmDeleteSub}
							{}
						</p>
						<p className="font-semibold"> {subscription.person.firstname}</p>
						<p className="font-semibold">{subscription.person.lastname} ?</p>
					</div>
				</div>

				<div className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-4">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={() => setOpenedDelete({})}
						disabled={loading}
					>
						{trad[lang].close}
					</button>
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={() => deleteSubscription(subscription.id)}
						disabled={loading}
					>
						{trad[lang].delete}
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteModal;
