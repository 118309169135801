import { useContext, useEffect, useState } from "react";
import { AiFillWarning, AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../api/APIClient";
import Logo from "../assets/logo_solo.png";
import LangToggle from "../components/common/LangToggle";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";
import { classNames } from "../utils/Classes";
import { handleReturnKey } from "../utils/ReturnKey";
import Toast from "../utils/Toasts";

function ResetPassword() {
	const { lang } = useContext(AppContext);
	const navigate = useNavigate();
	const [pwd1, setPwd1] = useState("");
	const [pwd2, setPwd2] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string>("");
	const { token } = useParams();

	const onSubmit = async () => {
		setLoading(true);

		if (pwd1.length < 8) {
			setError(trad[lang].password_too_short);
			setLoading(false);
			return;
		}

		if (pwd1 !== pwd2) {
			setError(trad[lang].passwords_not_match);
			setLoading(false);
			return;
		}

		try {
			const { data } = await API.post("/auth/orga/resetPassword", {
				resetPasswordToken: token,
				password: pwd1,
				passwordConfirmation: pwd2
			});
			Toast.success(trad[lang].passsword_reset_success);
			navigate("/login");
		} catch (e: any) {
			Toast.error(trad[lang].updateError);
			if (e.response?.status === 401) {
				setError(trad[lang].resetExpired);
			} else {
				setError(trad[lang].updateError);
			}

			setLoading(false);
		}
	};

	useEffect(() => {
		setError("");
	}, [pwd1, pwd2]);

	return (
		<div className="flex h-screen flex-col items-center justify-center">
			{/* Logo */}
			<Link to="/" className="text-primary">
				<div className="flex flex-row items-center gap-1">
					<img src={Logo} alt="" />
					<h1 className="text-2xl font-bold text-primary">Chronometrage.com</h1>
				</div>
			</Link>

			{/* Title */}
			<h2 className="text-center font-bold">{trad[lang].reset_password}</h2>

			{/* Form */}
			<div className="mt-8 flex w-4/5 flex-col gap-4 sm:w-[480px]">
				{/* Password */}
				<div>
					<label className="block text-sm font-medium text-gray-700">
						{trad[lang].new_password}
					</label>
					<div className="mt-1">
						<input
							id="pwd1"
							name="pwd1"
							type="password"
							required
							className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
							onChange={(e) => setPwd1(e.target.value)}
							value={pwd1}
							placeholder={trad[lang].new_password}
							onKeyDown={(e) => handleReturnKey(e, onSubmit)}
						/>
					</div>
				</div>

				{/* Password confirm */}
				<div>
					<label className="block text-sm font-medium text-gray-700">
						{trad[lang].new_password_confirm}
					</label>
					<div className="mt-1">
						<input
							id="pwd2"
							name="pwd2"
							type="password"
							required
							className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
							onChange={(e) => setPwd2(e.target.value)}
							value={pwd2}
							placeholder={trad[lang].new_password_confirm}
							onKeyDown={(e) => handleReturnKey(e, onSubmit)}
						/>
					</div>
					{error && (
						<div className="mt-2 flex flex-row">
							<AiFillWarning className="h-6 w-6 text-red-500" />
							<label className="mt-0.5 ml-2 block text-sm font-medium text-red-500">
								{error}
							</label>
						</div>
					)}
				</div>

				{/* Submit */}
				<div>
					<button
						type="button"
						className={classNames(
							"flex w-full items-center justify-center rounded-md border  border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm duration-150 focus:outline-none focus:ring-0 focus:ring-offset-0",
							loading ? "bg-gray-700" : "bg-primary hover:bg-primarymedium"
						)}
						onClick={onSubmit}
						disabled={loading}
					>
						{loading ? (
							<>
								{trad[lang].loading}
								<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
							</>
						) : (
							trad[lang].save
						)}
					</button>
				</div>

				<div>
					<div className="flex flex-col text-center">
						<Link to="/" className="mb-2 text-primary">
							{trad[lang].back_to_home}
						</Link>
					</div>
				</div>

				{/* Lang Toggle */}
				<div className="flex w-full flex-row justify-center px-4">
					<LangToggle />
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
