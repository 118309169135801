import { AiOutlineTrophy } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";
import {
	HiOutlineCalendar,
	HiOutlineCog6Tooth,
	HiOutlineCurrencyEuro,
	HiOutlineHome,
	HiOutlinePresentationChartLine,
	HiOutlineUserCircle,
	HiOutlineUsers
} from "react-icons/hi2";
import { MdOutlineShoppingCart } from "react-icons/md";
import trad from "../lang/traduction";
import { ILang } from "../types/Lang";

export const NavigationConfig = (lang: ILang, isAdmin: boolean) => [
	{
		key: "dashboard",
		name: trad[lang].dashboard,
		icon: HiOutlineHome,
		href: "/",
		accessible: true,
		visible: true
	},
	{
		key: "configuration",
		name: trad[lang].event,
		icon: HiOutlineCog6Tooth,
		href: "/event-details",
		permissionKey: "event",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "registrations",
		name: trad[lang].registrations,
		icon: HiOutlineUsers,
		href: "/registrations-details",
		permissionKey: "subscriptions",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "live",
		name: trad[lang].liveResults,
		icon: HiOutlineCalendar,
		href: "/live",
		permissionKey: "live",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "results",
		name: trad[lang].menuResults,
		icon: AiOutlineTrophy,
		href: "/results",
		permissionKey: "live",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "statistics",
		name: trad[lang].statistics,
		icon: HiOutlinePresentationChartLine,
		href: "/statistics",
		permissionKey: "stats",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "jetcodes",
		name: trad[lang].jetcodes,
		icon: HiOutlineCurrencyEuro,
		href: "/jetcodes",
		permissionKey: "jetcodes",
		accessible: true,
		visible: true,
		needEvent: true
	},
	{
		key: "permissions",
		name: trad[lang].permissions,
		icon: CgUserList,
		href: "/permissions",
		permissionKey: "permissions",
		accessible: true,
		visible: true,
		needEvent: true
	},
	// {
	// 	key: "marketing-communications",
	// 	name: "Communication",
	// 	icon: VscFeedback,
	// 	href: "/marketing-communications",
	// 	permissionKey: "marketing-communications",
	// 	accessible: true,
	// 	visible: true,
	// 	true: true,
	// 	needEvent: true
	// },
	{
		key: "shop",
		name: trad[lang].shop,
		icon: MdOutlineShoppingCart,
		href: "/shop",
		accessible: true,
		visible: true
	},
	{
		key: "admin",
		name: trad[lang].admin,
		icon: HiOutlineUserCircle,
		href: "/admin",
		accessible: isAdmin,
		visible: isAdmin
	}
];
