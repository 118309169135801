import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventIdentityConfig } from "../api/event";
import {
	getLiveConfig,
	getLiveConfigCalendar,
	getLiveConfigPoints,
	getLiveConfigSegments
} from "../api/live";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import PageLoader from "../components/common/PageLoader";
import LiveConfigurationCalendar from "../components/liveResults/LiveConfigurationCalendar";
import LiveConfigurationPoints from "../components/liveResults/LiveConfigurationPoints";
import LiveConfigurationSegments from "../components/liveResults/LiveConfigurationSegments";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

function RunLiveConfig() {
	const { slug, idcalendrier } = useParams();
	const { lang } = useContext(AppContext);

	const {
		data: EventInfos,
		isLoading: EventInfosLoading,
		refetch: RefetchEvent
	} = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data: LiveConfig = [],
		isLoading: LiveConfigLoading,
		refetch: RefetchLiveConfig
	} = useQuery({
		queryKey: ["live_config", slug, idcalendrier],
		queryFn: () =>
			getLiveConfigCalendar(slug as string, idcalendrier as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const {
		data: runLiveConfig,
		isLoading: runLiveConfigLoading,
		refetch: RefetchrunLiveConfig
	} = useQuery({
		queryKey: ["live_config", slug],
		queryFn: () => getLiveConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const { data: Segments = [], refetch: refetchSegments } = useQuery({
		queryKey: ["segments", slug, idcalendrier],
		queryFn: () =>
			getLiveConfigSegments(slug as string, idcalendrier as string),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!idcalendrier
	});

	const { data: Points = [], refetch: refetchPoints } = useQuery({
		queryKey: ["points", slug, idcalendrier],
		queryFn: () => getLiveConfigPoints(slug as string, idcalendrier as string),
		refetchOnWindowFocus: false,
		enabled: !!slug && !!idcalendrier
	});

	if (EventInfosLoading || LiveConfigLoading) {
		return <PageLoader menu_key="live" />;
	}
	const filteredPoints = Points.filter(
		(p: any) => p.type === 1 || p.type === 3
	);

	return (
		<Layout
			active_key="live"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/live-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: EventInfos.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{
						key: "live_results",
						to: `/${slug}/live`,
						active: false
					},
					{
						key: "liveConfiguration",
						to: "#",
						active: true
					}
				]}
			/>

			{/* Page heading */}
			<DetailsPageHeading
				pageTitle={`${trad[lang].liveConfiguration} - ${LiveConfig.name}`}
			/>

			<LiveConfigurationCalendar
				liveConfig={LiveConfig}
				runLiveConfig={runLiveConfig}
				refetchLiveConfig={RefetchLiveConfig}
				refetchPoints={refetchPoints}
				refetchSegments={refetchSegments}
				segment={Segments}
				point={Points}
			/>

			<LiveConfigurationPoints
				points={Points}
				refetchPoints={refetchPoints}
				refetchSegments={refetchSegments}
				segments={Segments}
			/>

			<LiveConfigurationSegments
				liveConfig={LiveConfig}
				segments={Segments}
				points={Points}
				refetchSegments={refetchSegments}
			/>
		</Layout>
	);
}

export default RunLiveConfig;
