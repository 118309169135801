import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Dispatch, SetStateAction, useContext } from "react";
import {
	AiFillEye,
	AiOutlineDelete,
	AiOutlineFileAdd,
	AiOutlineLoading3Quarters
} from "react-icons/ai";
import { GrDocumentVerified } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import { countriesList } from "../../utils/CountriesList";
import { clampISODate } from "../../utils/DateFormater";
import { QUILL_FORMATS } from "../../utils/Quill";
import DateTimePickerTZ from "../common/DateTimePickerTZ";
dayjs.extend(utc);

const EventInput = ({
	config,
	handleChange,
	handleFiles,
	handleRulesFile,
	values,
	error,
	deleteFiles,
	timezone,
	timezoneLoading,
	startDateISO,
	endDateISO,
	onClickMobileConfirm,
	setOpenedFile
}: {
	config: any;
	handleChange: any;
	handleFiles?: any;
	handleRulesFile?: any;
	deleteFiles?: any;
	values: any;
	error?: boolean;
	timezone?: string;
	timezoneLoading?: boolean;
	startDateISO?: string;
	endDateISO?: string;
	onClickMobileConfirm?: () => void;
	setOpenedFile?: Dispatch<SetStateAction<any>>;
}) => {
	const { lang } = useContext(AppContext);

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	if (config.type == "country") {
		return (
			<div className={`flex flex-col col-span-${config.col}`}>
				<label className="mb-1 text-lg" htmlFor={config.id}>
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_label`]}
					{config.required && <RequiredComponent />}
				</label>

				<select
					id="country"
					className="rounded-md"
					disabled={config.disable}
					onChange={(e) =>
						handleChange(config.key, e.target.value, values.index)
					}
					value={values[config.key]}
				>
					<option></option>
					{countriesList.map((item, index) => (
						<option value={item.code} key={index}>
							{item.name[lang == "fr" ? "fr" : "en"]}
						</option>
					))}
				</select>
			</div>
		);
	}

	if (config.type == "select") {
		return (
			<div className={`flex flex-col col-span-${config.col}`}>
				<label className="mb-1 text-lg" htmlFor={config.id}>
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_label`]}
					{config.required && <RequiredComponent />}
				</label>

				<select
					className={classNames(
						"rounded-md",
						error ? "border-transparent outline outline-2 outline-red-500" : "",
						config.disable ? "opacity-50" : ""
					)}
					id={config.id}
					onChange={(e) =>
						handleChange(config.key, e.target.value, values.index)
					}
					value={values[config.key]}
					disabled={config.disable}
				>
					{config.options.noDefault != true && (
						<option value={""}>
							{/* @ts-ignore */}
							{trad[lang][`${config.id}_option`]}
						</option>
					)}

					{config.options.values.map((item: any, index: number) => (
						<option value={item[config.options.key]} key={index}>
							{config.options.lang
								? item[config.options.label][lang == "fr" ? "fr" : "en"]
								: item[config.options.label]}
						</option>
					))}
				</select>

				{error && (
					<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_error`]}
					</p>
				)}
			</div>
		);
	}

	if (config.type == "images") {
		return (
			<div className={`relative col-span-${config.col} flex flex-col`}>
				<label className="mb-1 text-lg" htmlFor="event_file">
					{/* @ts-ignore */}
					{`${trad[lang][`${config.id}_label`]} ${
						values[config.key].filter(
							(item: any) => item.type == config.image_type
						)?.length
					}/${config.max_image}`}
				</label>
				<div className="flex flex-row gap-4">
					{values[config.key]
						.filter((item: any) => item.type == config.image_type)
						.map((item: any) => (
							<div className="group relative" key={item.name}>
								<img
									src={item.file}
									className="h-32 w-32 rounded-md object-cover"
								/>

								<button
									className="absolute -top-2 -right-2 flex rounded-md bg-red-600 p-2 group-hover:flex md:hidden"
									onClick={() => deleteFiles(item.type)}
								>
									<AiOutlineDelete size={18} color="#ffffff" />
								</button>
							</div>
						))}

					{!(
						values[config.key].filter(
							(item: any) => item.type == config.image_type
						)?.length >= config.max_image
					) && (
						<label
							className="flex h-32 w-32 cursor-pointer items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
							htmlFor={config.id}
						>
							<AiOutlineFileAdd color="#ffffff" size={64} />
						</label>
					)}
				</div>

				<input
					type="file"
					id={config.id}
					onChange={(e) =>
						handleFiles(e.target.files, config.image_type, config.id)
					}
					hidden
					disabled={
						values.medias.filter((item: any) => item.type == config.image_type)
							?.length >= config.max_image
					}
					accept="image/png, image/jpeg"
				/>

				{error && (
					<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_error`]}
					</p>
				)}
			</div>
		);
	}

	if (config.type == "textarea") {
		return (
			<div className={`flex flex-col col-span-${config.col}`}>
				<label className="mb-1 text-lg" htmlFor={config.id}>
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_label`]}
					{config.required && <RequiredComponent />}
				</label>

				<div className="relative w-full">
					<textarea
						id={config.id}
						className={classNames(
							"h-36 w-full resize-none rounded-md lg:h-20",
							error
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) =>
							handleChange(config.key, e.target.value, values.index)
						}
						maxLength={config.max}
						value={values[config.key] || ""}
					></textarea>
					<p className="absolute bottom-2 right-2 font-bold text-gray-500">{`${
						values[config.key]?.length || 0
					}/${config.max}`}</p>
				</div>

				{error && (
					<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_error`]}
					</p>
				)}
			</div>
		);
	}

	if (config.type == "quill") {
		return (
			<div className="col-span-2 mb-8 flex flex-col">
				<label className="mb-1 text-lg" htmlFor={config.id}>
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_label`]}
					{config.required && <RequiredComponent />}
				</label>
				<ReactQuill
					theme="snow"
					onChange={(e) => handleChange(config.key, e, values.index)}
					value={values[config.key] || ""}
					className="mb-10 max-h-60"
					placeholder={trad[lang].enter_text}
					formats={QUILL_FORMATS}
				/>
				{error && (
					<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_error`]}
					</p>
				)}
			</div>
		);
	}

	if (config.type == "rules") {
		return (
			<>
				<div className="col-span-2 flex flex-col">
					<label className="mb-1 text-lg">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_label`]}
						{config.required && <RequiredComponent />}
					</label>
					<table className="flex table-fixed items-center justify-center sm:block">
						<tbody>
							<tr className="mb-3 flex flex-col items-center  sm:table-row">
								{/* FILE UPLOAD */}
								<td className="flex-col items-center justify-center text-center">
									{!values[config.fileKey] || !values[config.fileKey].file ? (
										<label
											className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
											htmlFor={config.fileKey}
										>
											<div>
												<AiOutlineFileAdd color="#ffffff" size={40} />
												<label className="font-medium text-white">PDF</label>
											</div>
										</label>
									) : (
										<label
											className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-md bg-cyan-700 bg-opacity-30 transition-colors hover:bg-cyan-600 hover:bg-opacity-30"
											htmlFor={config.fileKey}
										>
											<GrDocumentVerified size={40} className="GRwhite" />
										</label>
									)}

									<input
										type="file"
										id={config.fileKey}
										onChange={(e) => {
											handleRulesFile(e.target.files, config.fileKey);
										}}
										hidden
										disabled={
											values.medias.filter(
												(item: any) => item.type == config.image_type
											).length >= config.max_image
										}
										accept="application/pdf"
									/>

									<div className="mt-2 flex flex-row gap-x-2">
										{/* BUTTONS */}
										{values[config.fileKey] && values[config.fileKey].file && (
											<>
												{/* EYE */}
												<button
													className="flex h-7 w-7 items-center justify-center rounded-full bg-cyan-700 bg-opacity-30"
													onClick={() => {
														// If file start with http, it's a link, open in tab (to avoid CORS from media server)
														let file = values[config.fileKey].file;
														if (file.startsWith("http")) {
															window.open(
																values[config.fileKey].file,
																"_blank"
															);
														} else if (setOpenedFile) {
															setOpenedFile({
																file: values[config.fileKey].file
															});
														}
													}}
												>
													<AiFillEye className="h-5 w-5 fill-white" />
												</button>
												{/* EDIT */}
												<label
													htmlFor={config.fileKey}
													className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-cyan-700 bg-opacity-30"
												>
													<MdOutlineEdit className="h-5 w-5 fill-white" />
												</label>
												{/* DELETE */}
												<button
													className="flex h-7 w-7 items-center justify-center rounded-full bg-red-400"
													onClick={() => {
														handleRulesFile(null, config.fileKey);
													}}
												>
													<AiOutlineDelete className="h-5 w-5 fill-white" />
												</button>
											</>
										)}
									</div>
								</td>
								{/* SEPARATOR */}
								<td>
									<div className="ml-3 mr-4 flex h-32 w-10 flex-col items-center justify-center">
										<div className="h-10 w-[2px] rounded-md bg-gray-300"></div>
										<p className="text-center font-medium">ou</p>
										<div className="h-10 w-[2px] rounded-md bg-gray-300"></div>
									</div>
								</td>
								{/* TEXT EDITOR */}
								<td className="w-full">
									<div className="h-52">
										<ReactQuill
											theme="snow"
											onChange={(e) =>
												handleChange(config.key, e, values.index)
											}
											value={values[config.key] || ""}
											style={{ height: "calc(100% - 52px)" }}
											placeholder={trad[lang].enter_text}
											formats={QUILL_FORMATS}
										/>
									</div>
								</td>
							</tr>

							{/* ACTIVE LABEL */}
							<tr className="mt-8 flex sm:mt-0 sm:table-row">
								<td className="text-center">
									<label
										className={`rounded-md px-4 py-1 text-sm font-medium ${
											!values[config.fileKey] || !values[config.fileKey].file
												? "bg-gray-100 text-gray-400"
												: "bg-green-100 text-green-800"
										}`}
									>
										{trad[lang].active}
									</label>
								</td>
								<td></td>
								<td>
									<label
										className={`rounded-md px-4 py-1 text-sm font-medium ${
											values[config.fileKey]?.file ||
											!values[config.key] ||
											values[config.key].replace(/<.*?>/g, "").length <= 0
												? "bg-gray-100 text-gray-400"
												: "bg-green-100 text-green-800"
										}`}
									>
										{trad[lang].active}
									</label>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="mt-10 flex w-full items-center justify-center">
					<button
						className="flex h-full w-[50%] cursor-pointer flex-row items-center justify-center gap-1 rounded-md bg-primary py-3 px-3 text-center text-xs text-white duration-150 hover:bg-primarymedium sm:hidden sm:uppercase"
						onClick={onClickMobileConfirm}
					>
						{trad[lang].validate}
					</button>
				</div>
			</>
		);
	}

	if (config.type == "datetime-local") {
		return (
			<div className={`flex flex-col col-span-${config.col}`}>
				<label className="mb-1 text-lg" htmlFor={config.id}>
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_label`]}
					{config.required && <RequiredComponent />}
				</label>

				{timezoneLoading ? (
					<AiOutlineLoading3Quarters size={45} className="animate-spin" />
				) : (
					<DateTimePickerTZ
						handleChange={(e) => {
							if (e) {
								handleChange(config.key, e);
							}
						}}
						value={
							config.clamp
								? dayjs(
										clampISODate(
											values[config.key] || "",
											typeof values[config.clampKey] === "object"
												? values[config.clampKey]
												: values[config.clampKey] || "",
											endDateISO || ""
										)
								  )
								: values[config.key] || ""
						}
						timezone={timezone}
						minDateTime={
							config.clamp ? dayjs(values[config.clampKey]) : undefined
						}
					/>
				)}

				{error && (
					<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
						{/* @ts-ignore */}
						{trad[lang][`${config.id}_error`]}
					</p>
				)}
			</div>
		);
	}

	return (
		<div className={`flex flex-col col-span-${config.col}`}>
			<label className="mb-1 text-lg" htmlFor={config.id}>
				{/* @ts-ignore */}
				{trad[lang][`${config.id}_label`]}
				{config.required && <RequiredComponent />}
			</label>
			<input
				type={config.type}
				id={config.id}
				min={config.min}
				max={config.max}
				step={config.step}
				className={classNames(
					"rounded-md",
					error ? "border-transparent outline outline-2 outline-red-500" : ""
				)}
				onChange={(e) => handleChange(config.key, e.target.value, values.index)}
				value={values[config.key] || ""}
			/>
			{error && (
				<p className="mt-2 rounded-md bg-red-100 py-2 px-2 text-sm text-red-600">
					{/* @ts-ignore */}
					{trad[lang][`${config.id}_error`]}
				</p>
			)}
		</div>
	);
};

export default EventInput;
