import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getEventSegmentsConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import PageLoader from "../components/common/PageLoader";
import SportDeleteSegmentModal from "../components/event_configuration/Sport/SportDeleteSegmentModal";
import SportSegmentModal from "../components/event_configuration/Sport/SportSegmentModal";
import EventSegmentModule from "../components/liveResults/EventSegmentModule";
import Layout from "../components/navigation/Layout";
import { BASE_SEGMENT } from "../config/EventForms/Sport/SportStep";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const LiveSegmentsConfig = () => {
	const { lang } = useContext(AppContext);
	const { slug, idparcours, route, idetape } = useParams();
	const [segmentState, setSegmentState] = useState([]);
	const [openedSegment, setOpenedSegment] = useState(null);
	const [deleteSegment, setDeleteSegment] = useState<{
		id: number;
		title: string;
	} | null>(null);

	const { data, isLoading, refetch } = useQuery({
		queryKey: ["segments", slug, route, idetape, idparcours],
		queryFn: () =>
			getEventSegmentsConfig(
				slug as string,
				route as string,
				idetape as string,
				idparcours as string
			),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		enabled: !!slug && !!idparcours && !!route && !!idetape
	});

	const openSegment = (segment: any) =>
		setOpenedSegment(segment || BASE_SEGMENT);

	const closeModal = () => setOpenedSegment(null);

	const handleConfirmDeleteSegment = (id: number, title: string) =>
		setDeleteSegment({ id, title });

	useEffect(() => {
		if (data?.length) {
			setSegmentState(data);
		}
	}, [data]);

	if (isLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout active_key="live">
			<BreadCrumb
				items={[
					{ key: "live_results", to: "/live", active: false },
					{
						key: "event",
						to: `/${slug}/live`,
						active: false
					},
					{
						key: "manage_steps",
						to: `/${slug}/manage-routes/${route}`,
						active: false
					},
					{
						key: "manage_parcours",
						to: `/${slug}/manage-routes/${route}/${idetape}`,
						active: false
					},
					{
						key: "manage_segments",
						to: "#",
						active: true
					}
				]}
			/>

			<h1 className="mb-6 mt-24 text-2xl font-bold text-gloom md:mb-8 md:mt-16 md:text-3xl">
				{trad[lang].sport_event_segments}
			</h1>

			{segmentState.map((segment: any) => (
				<EventSegmentModule
					segment={segment}
					key={segment.idSegment}
					openSegment={openSegment}
					deleteSegment={handleConfirmDeleteSegment}
				/>
			))}

			<div className="my-6 flex w-full">
				<button
					className="ml-8 flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
					onClick={openSegment}
				>
					{trad[lang].create_segment}
				</button>
			</div>

			{openedSegment && (
				<SportSegmentModal
					segment={openedSegment}
					closeModal={closeModal}
					refetch={refetch}
				/>
			)}

			{deleteSegment && (
				<SportDeleteSegmentModal
					segment={deleteSegment}
					refetch={refetch}
					setConfirmDeleteSegment={setDeleteSegment}
				/>
			)}

			<BottomBarNavigation
				back_to={`/${slug}/manage-routes/${route}/${idetape}`}
			/>
		</Layout>
	);
};

export default LiveSegmentsConfig;
