import { useContext, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
	getEventContactsConfig,
	getEventIdentityConfig
} from "../../../api/event";
import BottomBarNavigation from "../../../components/common/BottomBarNavigation";
import BreadCrumb from "../../../components/common/BreadCrumb";
import PageLoader from "../../../components/common/PageLoader";
import SportEventContact from "../../../components/event_configuration/EventContact";
import SportEventContactFormModal from "../../../components/event_configuration/EventContactFormModal";
import SportDeleteContactModal from "../../../components/event_configuration/EventDeleteContactModal";
import Layout from "../../../components/navigation/Layout";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IConfirmDeleteContact } from "../../../types/EventForms/EventContacts";

const BASE_CONTACT = {
	index: 0,
	raisonSocial: "",
	nom: "",
	prenom: "",
	adresse1: "",
	adresse2: "",
	ville: "",
	codePostal: "",
	pays: "",
	telephone1: "",
	telephone2: "",
	mail: "",
	siteWeb: "",
	observation: "",
	facebook: "",
	twitter: "",
	instagram: "",
	delete: false
};

const SportEventContacts = () => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [openedModal, setOpenedModal] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [confirmDeleteContact, setConfirmDeleteContact] =
		useState<IConfirmDeleteContact | null>(null);

	// Si un slug est présent, récupération des infos existante
	const {
		data: ContactsInfos,
		isLoading: ContactLoading,
		refetch: RefetchContacts
	} = useQuery({
		queryKey: ["contacts_info", slug],
		queryFn: () => getEventContactsConfig(slug as string),
		refetchOnWindowFocus: false,
		enabled: !!slug
	});

	const { data: EventInfos, isLoading: EventInfosLoading } = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	const openContactModal = (values: any) => setOpenedModal(values);
	const closeContactModal = () => setOpenedModal(null);

	if (ContactLoading || EventInfosLoading) {
		return <PageLoader menu_key="configuration" />;
	}

	return (
		<Layout
			active_key="configuration"
			bottomBar={<BottomBarNavigation back_to={`/${slug}/event-details`} />}
		>
			<BreadCrumb
				items={[
					{
						key: "event",
						text: EventInfos.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "event_contacts", to: "#", active: true }
				]}
			/>

			{/* Title */}
			<h1 className="mb-6 mt-6 text-2xl font-bold text-gloom md:text-3xl">
				{trad[lang].sport_event_contacts}
			</h1>

			<div className="flex w-full flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
				{ContactsInfos.map((item: any) => (
					<div className="group relative" key={item.index}>
						<SportEventContact contact={item} />

						<div className="absolute -top-2 -right-2 flex flex-row gap-2 md:hidden md:group-hover:flex">
							<button
								className="rounded-md bg-primary p-2 shadow-sm hover:bg-primarymedium"
								onClick={() => openContactModal(item)}
							>
								<AiOutlineEdit size={18} color="#ffffff" />
							</button>

							<button
								className="rounded-md bg-red-600 p-2 shadow-sm hover:bg-red-700"
								onClick={() =>
									setConfirmDeleteContact({
										id: item.id,
										title: item.raisonSocial || `${item.nom} ${item.prenom}`
									})
								}
							>
								<AiOutlineDelete size={18} color="#ffffff" />
							</button>
						</div>
					</div>
				))}

				<button
					className="flex min-h-[162px] cursor-pointer flex-col items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
					onClick={() => openContactModal(BASE_CONTACT)}
				>
					<AiOutlinePlus size={36} color="#ffffff" />
					<span className="text-white">{trad[lang].event_add_contact}</span>
				</button>
			</div>

			{openedModal != null && (
				<SportEventContactFormModal
					closeModal={closeContactModal}
					contact={openedModal}
					refetch={RefetchContacts}
				/>
			)}

			{confirmDeleteContact != null && (
				<SportDeleteContactModal
					confirmDeleteContact={confirmDeleteContact}
					refetch={RefetchContacts}
					setConfirmDeleteContact={setConfirmDeleteContact}
				/>
			)}
		</Layout>
	);
};

export default SportEventContacts;
