import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDetectionsResume } from "../api/detection";
import { getEventIdentityConfig } from "../api/event";
import BottomBarNavigation from "../components/common/BottomBarNavigation";
import BreadCrumb from "../components/common/BreadCrumb";
import DetailsPageHeading from "../components/common/DetailsPageHeading";
import PageLoader from "../components/common/PageLoader";
import DetectionResumeCard from "../components/detections/DetectionResumeCard";
import Layout from "../components/navigation/Layout";
import { AppContext } from "../contexts/AppContext";
import trad from "../lang/traduction";

const DetectionsDashboard = () => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);

	const { data, isLoading, error } = useQuery({
		queryKey: ["detections", slug],
		queryFn: () => getDetectionsResume(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false
	});

	const { data: EventInfos, isLoading: EventInfosLoading } = useQuery({
		queryKey: ["event_info", slug],
		queryFn: () => getEventIdentityConfig(slug as string),
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: !!slug
	});

	if (EventInfosLoading) {
		return <PageLoader menu_key="live" />;
	}

	return (
		<Layout active_key="live">
			<BreadCrumb
				items={[
					{
						key: "event",
						text: EventInfos.name,
						to: `/${slug}/event-details`,
						active: false
					},
					{ key: "live_results", to: `/${slug}/live`, active: false },
					{
						key: "detection_dashboard",
						to: "#",
						active: true
					}
				]}
			/>

			<DetailsPageHeading pageTitle={trad[lang].detections_dashboard} />

			<div className="flex w-full flex-col items-center gap-6  pb-8">
				{data?.map((item: any) => (
					<DetectionResumeCard
						data={item}
						key={item.idCourse}
						timezone={EventInfos.timezone}
					/>
				))}
			</div>

			<BottomBarNavigation back_to={`/${slug}/live`} />
		</Layout>
	);
};

export default DetectionsDashboard;
