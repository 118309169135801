import React from "react";
import MobileNavBar from "./MobileNavBar";
import Sidebar from "./Sidebar";

function Layout({
	children,
	active_key = "dashboard",
	forceReduced = false,
	bottomBar,
	full
}: {
	children: React.ReactNode;
	active_key?: string;
	forceReduced?: boolean;
	bottomBar?: React.ReactNode;
	full?: boolean;
}) {
	return (
		<div className="grid h-screen grid-cols-[auto_1fr] grid-rows-[1fr_auto]">
			<Sidebar active_key={active_key} forceReduced={forceReduced} />
			<MobileNavBar active_key={active_key} />

			<div
				className={`col-start-2 col-end-2 mt-16 w-full overflow-auto ${
					full ? "" : "px-5 md:pt-5"
				} md:col-span-1 md:mt-0`}
			>
				{children}
			</div>

			<div className="col-start-2 col-end-2 mt-auto md:col-span-1">
				{bottomBar}
			</div>
		</div>
	);
}

export default Layout;
