interface TextFieldProps {
	id?: string | number;
	label: string;
	value: string;
	hasError?: boolean;
	errorMessage?: string;
	unavailable?: string;
	index?: number;
	conflict?: boolean;
	disabled?: boolean;
	step?: number;
	min?: number;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const TextField = ({
	id,
	label,
	value,
	hasError,
	unavailable,
	step,
	min,
	conflict,
	errorMessage,
	disabled,
	onChange,
	index
}: TextFieldProps): JSX.Element => {
	return (
		<div className="w-full">
			<label
				className={`${
					conflict || hasError ? "text-red-500" : "text-gray-700"
				} block text-left text-sm font-medium text-gray-700`}
			>
				{label}
			</label>
			<div className="mt-1">
				<input
					disabled={disabled}
					type="text"
					className={`${
						conflict || hasError
							? "border-red-500 text-red-500 focus:border-red-500"
							: "border-gray-300 focus:border-gray-300"
					} block w-full rounded-md shadow-sm focus:ring-transparent disabled:bg-gray-100 sm:text-sm`}
					min={min ? min : 0}
					step={step}
					defaultValue={value}
					onChange={onChange}
				/>
				{errorMessage && (
					<span className="text-xs text-red-500">{errorMessage}</span>
				)}
			</div>
		</div>
	);
};

export default TextField;
