import dayjs from "dayjs";
import { useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../api/auth";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import { countriesList } from "../../utils/CountriesList";
import { REGEXP } from "../../utils/RegExp";
import { handleReturnKey } from "../../utils/ReturnKey";
import Toast from "../../utils/Toasts";
import LangToggle from "../common/LangToggle";

const SignupForm = () => {
	const navigate = useNavigate();
	const { lang } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [signupForm, setSignupForm] = useState({
		firstname: "",
		lastname: "",
		birthdate: "",
		sex: 1,
		email: "",
		password: "",
		confirmPassword: "",
		adresse: "",
		city: "",
		country: "",
		postal_code: "",
		social_reason: "",
		acceptCGV: false
	});
	const [errors, setErrors] = useState<string[]>([]);

	const handleChange = (key: string, value: any) => {
		const IndexError = errors.indexOf(key);

		if (IndexError >= 0) {
			const newErrors = [...errors];
			newErrors.splice(IndexError, 1);

			setErrors(newErrors);
		}

		setSignupForm((old) => ({ ...old, [key]: value }));
	};

	const requiredInput = {
		color: "red"
	};

	const getLabel = function (id: string, required: boolean = false) {
		return (
			<label htmlFor={id} className="block text-sm font-medium text-gray-700">
				{(trad[lang] as any)[id]}
				{required && <span style={requiredInput}> *</span>}
			</label>
		);
	};

	const checkErrors = () => {
		const {
			firstname,
			lastname,
			birthdate,
			sex,
			adresse,
			city,
			country,
			postal_code,
			email,
			password,
			confirmPassword,
			acceptCGV
		} = signupForm;

		const localErrors = [];

		if (!firstname || firstname.length < 2) localErrors.push("firstname");
		if (!lastname || lastname.length < 2) localErrors.push("lastname");
		if (!password || password.length < 6) localErrors.push("password");
		if (!confirmPassword || password != confirmPassword)
			localErrors.push("confirmPassword");
		if (
			!birthdate ||
			dayjs(dayjs()).diff(birthdate, "years") < 13 ||
			dayjs(dayjs()).diff(birthdate, "years") > 100
		)
			localErrors.push("birthdate");
		if (!sex) localErrors.push("sex");
		if (!email || !REGEXP.mail.test(email)) localErrors.push("email");
		if (!adresse || adresse.length < 2) localErrors.push("adresse");
		if (!city || city.length < 2) localErrors.push("city");
		if (!country) localErrors.push("country");
		if (!postal_code || postal_code.length < 2) localErrors.push("postal_code");
		if (!acceptCGV) localErrors.push("acceptCGV");

		setErrors(localErrors);

		if (localErrors.length) {
			return true;
		}
	};

	const submit = async () => {
		try {
			const errors = checkErrors();

			if (errors) {
				return;
			}

			setLoading(true);
			await signUp(signupForm);

			if (localStorage.getItem("invitationSlug")) {
				navigate(
					"/" + localStorage.getItem("invitationSlug") + "/event-details"
				);
				localStorage.removeItem("invitationSlug");
			} else {
				navigate("/");
			}

			Toast.success(trad[lang].success_signup);
		} catch (error) {
			Toast.error(trad[lang].error_signup);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="mt-8 flex w-4/5 flex-col gap-4 sm:w-[480px]">
			<div className="flex flex-row">
				<BsFillExclamationTriangleFill className="mr-2 h-4 w-4 fill-red-500" />
				<div className="mb-1 font-medium text-red-500 underline">
					Champs obligatoires (*)
				</div>
			</div>
			{/* Prénom */}
			<div>
				{getLabel("firstname", true)}
				<div className="mt-1">
					<input
						id="firstname"
						name="firstname"
						type="text"
						autoComplete="firstname"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("firstname")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("firstname", e.target.value)}
						value={signupForm.firstname}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("lastname") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_firstname}
					</p>
				)}
			</div>

			{/* Nom */}
			<div>
				{getLabel("lastname", true)}
				<div className="mt-1">
					<input
						id="lastname"
						name="lastname"
						type="text"
						autoComplete="lastname"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("lastname")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("lastname", e.target.value)}
						value={signupForm.lastname}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("lastname") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_lastname}
					</p>
				)}
			</div>

			{/* Date de naissance */}
			<div>
				{getLabel("birthdate", true)}
				<div className="mt-1">
					<input
						type="date"
						id="birthdate"
						name="birthdate"
						autoComplete="birthdate"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("birthdate")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						max={new Date().toISOString().split("T")[0]}
						onChange={(e) => handleChange("birthdate", e.target.value)}
						value={signupForm.birthdate}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("birthdate") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_birthdate}
					</p>
				)}
			</div>

			{/* Sexe */}
			<div>
				{getLabel("sex", true)}
				<div className="mt-1">
					<select
						id="sex"
						name="sex"
						autoComplete="sex"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("sex")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("sex", e.target.value)}
						value={signupForm.sex}
					>
						<option value={1}>Homme</option>
						<option value={2}>Femme</option>
						<option value={0}>Autre</option>
					</select>
				</div>
			</div>

			{/* Email */}
			<div>
				{getLabel("email", true)}

				<div className="mt-1">
					<input
						id="email"
						name="email"
						type="email"
						autoComplete="email"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("email")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("email", e.target.value)}
						value={signupForm.email}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("email") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_email}</p>
				)}
			</div>

			{/* Raison sociale */}
			<div>
				{getLabel("social_reason", true)}

				<div className="mt-1">
					<input
						id="social_reason"
						name="social_reason"
						type="text"
						autoComplete="social_reason"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
						)}
						onChange={(e) => handleChange("social_reason", e.target.value)}
						value={signupForm.social_reason}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("adresse") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_adresse}</p>
				)}
			</div>
			{/* Adresse */}
			<div>
				{getLabel("adresse", true)}

				<div className="mt-1">
					<input
						id="adresse"
						name="adresse"
						type="text"
						autoComplete="adresse"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("adresse")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("adresse", e.target.value)}
						value={signupForm.adresse}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("adresse") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_adresse}</p>
				)}
			</div>

			{/* Code postal */}
			<div>
				{getLabel("postal_code", true)}

				<div className="mt-1">
					<input
						id="postal_code"
						name="postal_code"
						type="text"
						autoComplete="postal_code"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("postal_code")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("postal_code", e.target.value)}
						value={signupForm.postal_code}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("postal_code") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_postal_code}
					</p>
				)}
			</div>

			{/* Ville */}
			<div>
				{getLabel("city", true)}

				<div className="mt-1">
					<input
						id="city"
						name="city"
						type="text"
						autoComplete="city"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("city")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("city", e.target.value)}
						value={signupForm.city}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("city") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_city}</p>
				)}
			</div>

			{/* Pays */}
			<div>
				{getLabel("country", true)}

				<div className="mt-1">
					<select
						id="country"
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("country")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("country", e.target.value)}
						value={signupForm.country}
					>
						<option></option>
						{countriesList.map((item) => (
							<option value={item.code} key={item.code}>
								{item.name[lang == "fr" ? "fr" : "en"]}
							</option>
						))}
					</select>
				</div>

				{errors.includes("country") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_country}</p>
				)}
			</div>

			{/* Mot de passe */}
			<div>
				{getLabel("password", true)}
				<div className="mt-1">
					<input
						id="password"
						name="password"
						type="password"
						autoComplete="password"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("password")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("password", e.target.value)}
						value={signupForm.password}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("password") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_password}
					</p>
				)}
			</div>

			{/* Confirmation Mot de passe */}
			<div>
				{getLabel("confirmPassword", true)}

				<div className="mt-1">
					<input
						id="confirmPassword"
						name="confirmPassword"
						type="password"
						autoComplete="confirmPassword"
						required
						className={classNames(
							"block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-0 sm:text-sm",
							errors.includes("confirmPassword")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={(e) => handleChange("confirmPassword", e.target.value)}
						value={signupForm.confirmPassword}
						onKeyDown={(e) => handleReturnKey(e, submit)}
					/>
				</div>

				{errors.includes("confirmPassword") && (
					<p className="mt-1 text-red-600">
						{trad[lang].signup_error_passwordConfirm}
					</p>
				)}
			</div>

			{/* CGU */}
			<div className="flex flex-col">
				<div className="flex items-center">
					<input
						id="acceptCGV"
						name="acceptCGV"
						type="checkbox"
						className={classNames(
							"h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-0",
							errors.includes("acceptCGV")
								? "border-transparent outline outline-2 outline-red-500"
								: ""
						)}
						onChange={() => handleChange("acceptCGV", !signupForm.acceptCGV)}
						checked={signupForm.acceptCGV}
					/>
					<Link
						to="https://dashboard-v1.chronometrage.com/uploads/cgv/organisateurs.pdf"
						target="_blank"
					>
						<label className="ml-2 block cursor-pointer text-sm text-primary">
							{trad[lang].acceptCGV}
							<span style={requiredInput}> *</span>
						</label>
					</Link>
				</div>

				{errors.includes("acceptCGV") && (
					<p className="mt-1 text-red-600">{trad[lang].signup_error_CGU}</p>
				)}
			</div>

			{/* Submit */}
			<div>
				<button
					type="button"
					className={classNames(
						"flex w-full items-center justify-center rounded-md border  border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm duration-150 focus:outline-none focus:ring-0 focus:ring-offset-0",
						loading ? "bg-gray-700" : "bg-primary hover:bg-primarymedium"
					)}
					onClick={submit}
					disabled={loading}
				>
					{loading ? (
						<>
							{trad[lang].loading}
							<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
						</>
					) : (
						trad[lang].signup_button
					)}
				</button>
			</div>

			<div className="text-center">
				<Link to="/" className="text-primary">
					{trad[lang].already_account}
				</Link>
			</div>

			{/* Lang Toggle */}
			<div className="flex w-full flex-row justify-center px-4">
				<LangToggle />
			</div>
		</div>
	);
};

export default SignupForm;
