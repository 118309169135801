export const BASE_FEES = (price: number) =>
	[
		{ min: 0, max: 80, fees: 0.6 },
		{ min: 80, max: 150, fees: 1.2 },
		{ min: 150, max: 230, fees: 1.8 },
		{ min: 230, max: 310, fees: 2.4 },
		{ min: 310, max: 480, fees: 3.6 },
		{ min: 480, max: 640, fees: 4.8 },
		{ min: 640, max: 10000, fees: 8.4 }
	].find((item) => price >= item.min && price < item.max)?.fees || 0;
