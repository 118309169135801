import { useContext } from "react";
import trad from "../../lang/traduction";
import { AppContext } from "../../contexts/AppContext";

function DetailsPageHeading({
	pageTitle,
	eventName,
	count
}: {
	pageTitle: string;
	eventName?: string;
	count?: number;
}) {
	const { lang } = useContext(AppContext);
	return (
		<div className="flex w-full flex-col items-center">
			<div className="mt-6 mb-4 flex w-full flex-col items-center md:mb-8 md:flex-row md:justify-between">
				{/* Title */}
				<h1 className="mt-6 text-2xl font-bold text-gloom md:mt-0 md:text-3xl">
					{pageTitle}
					{count != undefined && (
						<span className="ml-4 rounded-full bg-primary px-3 py-1 align-middle text-sm text-background">
							{count}
						</span>
					)}
				</h1>
			</div>

			{/* Event Name */}
			<h2 className="text-xl font-medium italic text-primary md:text-2xl">
				{eventName}
			</h2>
		</div>
	);
}

export default DetailsPageHeading;
