export const EventContactConfig = [
	{
		type: "text",
		id: "event_raisonSocial",
		key: "raisonSocial",
		col: 2,
		required: true
	},
	{
		type: "text",
		id: "event_nom",
		key: "nom",
		col: 1,
		required: true
	},
	{
		type: "text",
		id: "event_prenom",
		key: "prenom",
		col: 1,
		required: true
	},
	{
		type: "text",
		id: "event_adresse1",
		key: "adresse1",
		col: 2
	},
	{
		type: "text",
		id: "event_adresse2",
		key: "adresse2",
		col: 2
	},
	{
		type: "text",
		id: "event_ville",
		key: "ville",
		col: 1
	},
	{
		type: "text",
		id: "event_codePostal",
		key: "codePostal",
		col: 1
	},
	{
		type: "country",
		id: "event_pays",
		key: "pays",
		col: 1
	},
	{
		type: "text",
		id: "event_telephone1",
		key: "telephone1",
		col: 1
	},
	{
		type: "text",
		id: "event_telephone2",
		key: "telephone2",
		col: 1
	},
	{
		type: "text",
		id: "event_mail",
		key: "mail",
		col: 1
	},
	{
		type: "text",
		id: "event_siteWeb",
		key: "siteWeb",
		col: 1
	},
	{
		type: "text",
		id: "event_facebook",
		key: "facebook",
		col: 1
	},
	{
		type: "text",
		id: "event_twitter",
		key: "twitter",
		col: 1
	},
	{
		type: "text",
		id: "event_instagram",
		key: "instagram",
		col: 1
	}
];
