import dayjs from "dayjs";
import { useContext, useState } from "react";
import {
	AiFillDelete,
	AiOutlineMan,
	AiOutlineMore,
	AiOutlineWoman,
	AiTwotoneEdit
} from "react-icons/ai";
import { RxDotFilled } from "react-icons/rx";
import { useParams } from "react-router-dom";
import { deleteAccess } from "../../api/accesses";
import { AppContext } from "../../contexts/AppContext";
import { UserContext } from "../../contexts/UserContext";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import trad from "../../lang/traduction";
import { IConfirmationModalConfig } from "../../types/Modals";
import Toast from "../../utils/Toasts";
import ConfirmationModal from "../modals/ConfirmationModal";

const DOCUMENT_TYPES: any = {
	licence: 2,
	certificat: 4,
	autorisationParentale: 5
};

const GENDERS: any = {
	1: <AiOutlineMan size={20} color="rgb(0, 150, 255)" />,
	2: <AiOutlineWoman size={20} color="rgb(222, 49, 99)" />
};

const PermissionsTableItem = ({
	jsonInvite,
	refetch,
	setInviteBeingEdited,
	setShowInviteModal,
	showInviteModal
}: {
	jsonInvite: any;
	refetch: Function;
	setInviteBeingEdited: Function;
	setShowInviteModal: Function;
	showInviteModal: boolean;
}) => {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const [openedDetails, setOpenedDetails] = useState(false);
	const [confirmModalConfig, setConfirmModalConfig] =
		useState<IConfirmationModalConfig>();

	const toggleOptions = (e: any) => {
		e.stopPropagation();
		setIsComponentVisible((old) => !old);
	};

	const openEdit = (e: any) => {
		e.stopPropagation();
		setInviteBeingEdited(jsonInvite);
		setShowInviteModal(true);
	};

	const openDetails = () => setOpenedDetails((old) => true);
	const closeDetails = () => setOpenedDetails((old) => false);

	const getNumDaysElapsed = function (): number {
		let deltaSeconds: number = 0;
		if (jsonInvite)
			deltaSeconds = dayjs().unix() - dayjs(jsonInvite?.invitationDate).unix();
		if (deltaSeconds < 86400) return 0;
		return Math.floor(deltaSeconds / 86400);
	};

	const onClickDeleteInvite = async function () {
		setConfirmModalConfig({
			setter: setConfirmModalConfig,
			opened: true,
			title: trad[lang].delete_title,
			description:
				"Êtes-vous sûr de vouloir supprimer l'accès et les droits de \n" +
				jsonInvite?.mail +
				"\n à votre évènement ?",
			onConfirm: async () => {
				if (jsonInvite && slug) {
					try {
						await deleteAccess(slug, jsonInvite);
						Toast.success(
							"Permissions de " + jsonInvite.mail + " révoqués avec succès"
						);
						refetch();
					} catch (e) {
						Toast.error(
							"Impossible de révoquer les permissions de " + jsonInvite.mail
						);
					}
				}
			}
		});
	};

	return (
		<>
			<tr
				className={`cursor-pointer transition-colors hover:bg-gray-50 ${
					openedDetails && "bg-gray-50"
				}`}
				onClick={openedDetails ? closeDetails : openDetails}
			>
				<td className="hidden whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700 lg:table-cell">
					<div className="flex items-center gap-x-2">
						<h2 className="font-medium text-gray-800">{jsonInvite?.mail}</h2>
						{jsonInvite?.ide_personne === user?.IDPersonne && (
							<div className="rounded-lg bg-green-300 p-1.5 text-xs">
								mon compte
							</div>
						)}
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div className="flex items-center gap-x-2">
						<h2 className="flex flex-row items-center font-medium text-gray-800">
							<p className="mr-2">
								{jsonInvite?.lastname + " " + jsonInvite?.firstname}
							</p>
						</h2>
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div
						className={`inline-flex items-center gap-x-0 rounded-lg ${
							jsonInvite?.ide_personne
								? "bg-cyan-100 text-cyan-800 hover:bg-cyan-300 hover:text-cyan-900 hover:shadow-sm"
								: ""
						} text-sm`}
					>
						<div className="w-fit min-w-[135px] border-none border-transparent bg-transparent py-1 px-0 text-center text-sm focus:border-transparent focus:shadow-none focus:ring-0">
							{jsonInvite?.ide_personne ? (
								<>Actif</>
							) : (
								<>
									<div className="rounded-lg bg-yellow-100 p-1 text-yellow-800 hover:bg-yellow-300 hover:text-yellow-900 hover:shadow-sm">
										En attente
									</div>
									<div className="pt-1">
										Depuis{" "}
										{getNumDaysElapsed() < 1
											? "aujourd'hui"
											: getNumDaysElapsed() + " jours"}
									</div>
								</>
							)}
						</div>
					</div>
				</td>

				<td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
					<div className="flex items-center gap-x-2">
						<h2 className="items-left flex flex-col font-medium text-gray-800">
							{jsonInvite?.roles
								.filter((item: any) => item.active)
								.map((role: any, index: number) => (
									<div key={index} className="row flex items-center">
										<RxDotFilled />
										{/* hard check for the moment, will use traduction this week */}
										<p className="mr-2">
											{role.label === "Folomi"
												? "Live / Chronometrage"
												: role.label}
										</p>
									</div>
								))}
						</h2>
					</div>
				</td>

				{jsonInvite?.ide_personne !== user?.IDPersonne &&
					jsonInvite?.ids_type_droit.indexOf(2) === -1 && (
						<td
							className="relative whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700"
							ref={ref}
						>
							<div className="flex items-center justify-center gap-x-2">
								<button onClick={(e) => toggleOptions(e)}>
									<AiOutlineMore size={25} />
								</button>
							</div>

							{/* Options modal handling */}
							{isComponentVisible && showInviteModal === false && (
								<>
									<div className="absolute right-0 z-20 mt-2 origin-top-right rounded-md border bg-white py-2 shadow-xl">
										<button
											className="block flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100 disabled:opacity-50"
											onClick={openEdit}
										>
											Modifier
											<AiTwotoneEdit className="ml-2 h-5 w-5" />
										</button>
										<button
											className="block flex w-full flex-row items-center justify-end py-3 px-3 text-left text-sm text-gray-600 duration-300 hover:bg-gray-100"
											onClick={onClickDeleteInvite}
										>
											Supprimer l'ayant droit
											<AiFillDelete className="ml-2 h-5 w-5" />
										</button>
									</div>
								</>
							)}
						</td>
					)}
			</tr>

			{confirmModalConfig && confirmModalConfig.opened === true && (
				<ConfirmationModal config={confirmModalConfig} />
			)}
		</>
	);
};

export default PermissionsTableItem;
