import BottomBarNavigation from "../../components/common/BottomBarNavigation";
import Layout from "../../components/navigation/Layout";
import GlobalSearchForm from "./components/GlobalSearchForm";

const GlobalAdminSearch = () => {
	return (
		<Layout
			active_key="admin"
			bottomBar={<BottomBarNavigation back_to="/admin" />}
		>
			<div className="flex-col items-center p-5">
				{/* TITLE */}
				<h1 className="mb-6 mt-6 text-center text-2xl font-bold text-gloom md:text-3xl">
					Recherche globale
				</h1>
			</div>
			<GlobalSearchForm />
		</Layout>
	);
};
export default GlobalAdminSearch;
