interface SelectFieldProps {
	label: string;
	value: string;
	id?: string;
	error?: boolean;
	defaultValue?: string;
	onChange?: React.ChangeEventHandler<HTMLSelectElement>;
	options: { key: string; title: string }[];
}

const SelectField = ({
	id,
	label,
	value,
	options,
	defaultValue,
	onChange
}: SelectFieldProps) => {
	const calculatedDefaultValue =
		value === "3" ? "X" : value === "2" ? "F" : "H";
	return (
		<div className="w-full pt-1">
			<label className="block text-left text-sm font-medium text-gray-700">
				{label}
			</label>
			<div className="relative flex flex-grow items-stretch focus-within:z-10">
				<select
					id={id}
					className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm"
					defaultValue={defaultValue || calculatedDefaultValue}
					onChange={onChange}
				>
					{options.map(({ key, title }) => (
						<option key={key} value={key}>
							{title}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SelectField;
