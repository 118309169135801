import React from "react";
import { CiNoWaitingSign } from "react-icons/ci";

interface TableCellProps {
	value?: string | number | null | React.ReactNode;
	placeholderIcon?: React.ReactNode;
	color?: string;
}

const GlobalSearchCell = ({
	value,
	placeholderIcon = <CiNoWaitingSign size={24} />,
	color
}: TableCellProps) => {
	return (
		<td className={`border border-gray-300 px-4 py-2 ${color && color}`}>
			{value ? value : placeholderIcon}
		</td>
	);
};

export default GlobalSearchCell;
