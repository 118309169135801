import dayjs from "dayjs";
import { useContext } from "react";
import { AiOutlineForm } from "react-icons/ai";
import { AppContext } from "../../../contexts/AppContext";
import trad from "../../../lang/traduction";
import { IRunPrice } from "../../../types/EventForms/ISportRun";
import { secureUTCDate } from "../../../utils/DateFormater";
import { FEES_TYPES } from "../../../utils/Prices";

const SportEventTarif = ({
	teamMembers,
	price,
	timezone
}: {
	teamMembers: number;
	price: IRunPrice;
	timezone: string;
}) => {
	const { lang } = useContext(AppContext);

	const float_price = parseFloat(price.price) || 0;

	const dag_fees = parseFloat(price.dag_fees) || 0;
	const client_fees = parseFloat(price.amountClientFixedFees) || 0;
	const client_percentage_fees =
		float_price * (price.amountClientPercentageFees / 100);

	const fees =
		price.feesType == FEES_TYPES.EXCLUDED
			? dag_fees + client_fees + client_percentage_fees
			: parseFloat(price?.dag_fees || "0");

	return (
		<div className="flex h-full flex-col justify-between rounded-md border border-gray-300 bg-white p-3 shadow-md">
			<div className="mb-2 flex flex-row items-baseline justify-between">
				<p>{price.designation}</p>
				<p className="text-sm">
					{price.feesType == 0
						? trad[lang].fees_inclued
						: trad[lang].fees_exclued}
				</p>
			</div>

			<div className="mb-2">
				<p className="text-sm">
					{`${trad[lang].event_starting_date} ${dayjs(
						secureUTCDate(price.startDate)
					)
						.tz(timezone)
						.format("DD/MM/YYYY HH:mm")}`}
				</p>
				<p className="text-sm">{`${trad[lang].event_ending_date} ${dayjs(
					secureUTCDate(price.endDate)
				)
					.tz(timezone)
					.format("DD/MM/YYYY HH:mm")}`}</p>
			</div>

			<div className="mb-2">
				<p className="text-sm">
					{price.ageControl > 0
						? trad[lang].event_age_disponibility(price.minAge, price.maxAge)
						: trad[lang].event_run_age_control_option}
				</p>
			</div>

			<div className="flex flex-row items-end justify-between">
				{price.free == 0 ? (
					<p className="whitespace-nowrap">
						<span className="text-xl font-bold">
							{parseFloat(price.price).toFixed(2)}€
						</span>{" "}
						<span className="text-sm">
							{price.feesType == FEES_TYPES.EXCLUDED
								? trad[lang].event_price_fee_exclued(fees)
								: trad[lang].event_price_fee_inclued(fees)}
							{teamMembers > 1 ? (
								<span className="font-bold">{trad[lang].bySubscriber}</span>
							) : (
								""
							)}
						</span>
					</p>
				) : (
					<p>{trad[lang].event_free_run_text}</p>
				)}
				{Object.values(price.form).length > 0 && (
					<p
						className="grid h-6 w-6 place-items-center rounded-md bg-green-500"
						title={trad[lang].event_tooltip_form}
					>
						<AiOutlineForm size={16} color="#ffffff" />
					</p>
				)}
			</div>
		</div>
	);
};

export default SportEventTarif;
