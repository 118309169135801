import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiOutlineXMark } from "react-icons/hi2";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { updateLiveTrackingUrl } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import Toast from "../../utils/Toasts";

function LiveTrackingUrlModal({
	open,
	setOpen,
	raceSlug,
	raceData,
	refetch
}: {
	open: boolean;
	setOpen: any;
	raceSlug: string;
	raceData: any;
	refetch: Function;
}) {
	const { slug } = useParams();
	const { lang } = useContext(AppContext);
	const [url, setUrl] = useState<string>("");
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	useEffect(() => {
		if (raceData && raceData.observation) {
			let obs = raceData.observation.length
				? JSON.parse(raceData.observation)
				: {};
			setUrl(obs?.liveTrackingUrl || "");
		} else setUrl("");
	}, [raceData]);

	const onClose = function () {
		setOpen(false);
		setUrl("");
	};

	// HANDLE SAVE BUTTON
	const onSaveClick = async () => {
		try {
			await updateLiveTrackingUrl(raceSlug, { liveTrackingUrl: url });
			if (refetch) await refetch();
			Toast.success(trad[lang].saveOk);
			onClose();
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].saveError);
		}
	};

	const onDelLinkClick = async () => {
		try {
			setIsDeleting(true);
			await updateLiveTrackingUrl(raceSlug, {});
			if (refetch) await refetch();
			setUrl("");
			Toast.success(trad[lang].saveOk);
		} catch (error) {
			console.error(error);
			Toast.error(trad[lang].saveError);
		}
		setIsDeleting(false);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-50 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative mb-auto mt-auto inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:align-middle">
							<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<button
									type="button"
									className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
									onClick={() => onClose()}
								>
									<span className="sr-only">Close</span>
									<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mb-6 w-full sm:items-start">
								<div className="mt-3 w-full px-8 text-center sm:mt-0 sm:text-left">
									<Dialog.Title
										as="h3"
										className="text-center text-xl font-bold leading-6 text-gray-900"
									>
										{trad[lang].shareLiveTrackingUrl}
									</Dialog.Title>
								</div>

								{/* INPUT TEXT TO ENTER LIVE TRACKING URL */}
								<div className="mt-8 mb-8 w-full">
									<label
										htmlFor="url"
										className="text-normal block font-bold text-gray-700"
									>
										{trad[lang].tracking_url} (Wiclax, Site, ...)
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="url"
											id="url"
											value={url}
											onChange={(e) => setUrl(e.target.value || "")}
											placeholder="https://..."
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
										/>
									</div>
								</div>
							</div>
							<div className="mt-3 ml-auto flex flex-row justify-end">
								{url && (
									<button
										className={classNames(
											"flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-orange-500 py-3 px-3 text-xs text-white duration-150 hover:bg-orange-600 md:uppercase"
										)}
										onClick={onDelLinkClick}
									>
										Supprimer le lien
										{isDeleting === true && (
											<AiOutlineLoading3Quarters className="ml-2 animate-spin" />
										)}
									</button>
								)}
								<button
									className={classNames(
										"ml-2 flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
									)}
									onClick={onSaveClick}
								>
									{trad[lang].save}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default LiveTrackingUrlModal;
