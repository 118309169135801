import { useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { UserContext } from "../contexts/UserContext";

export const Invitation = () => {
	const { lang } = useContext(AppContext);
	const { invitationKey, invitationSlug } = useParams();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();

	const userHasAccess = useMemo(() => {
		if (!user) return false;

		if (
			user?.roles?.includes("ROLE_ORGANISATEUR") ||
			user?.roles?.includes("ROLE_ADMIN")
		)
			return true;

		return false;
	}, [user]);

	useEffect(() => {
		if (invitationKey) localStorage.setItem("invitationKey", invitationKey);
		if (invitationSlug) localStorage.setItem("invitationSlug", invitationSlug);
		if (userHasAccess)
			navigate("/" + (invitationSlug ? invitationSlug + "/event-details" : ""));
		else navigate("/");
	}, [invitationKey]);

	return <></>;
};

export default Invitation;
