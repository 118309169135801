import { useEffect, useState } from "react";
import xml2js from "xml2js";
import haversine from "haversine-distance";
import {
	AreaChart,
	Area,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer
} from "recharts";
import GpxMapView from "./GpxMapView";

interface GpxMapViewProps {
	file: any;
}

const BASE_DISTANCES = [
	{ maxDistance: 20, step: 10 },
	{ maxDistance: 30, step: 15 },
	{ maxDistance: 40, step: 20 },
	{ maxDistance: 50, step: 10 },
	{ maxDistance: 100, step: 10 }
];

const GpxChart = ({ file }: GpxMapViewProps): JSX.Element => {
	const [gpxChart, setGpxChart] = useState<unknown[]>([]);
	const [minElevation, setMinElevation] = useState<number>(0);
	const [maxElevation, setMaxElevation] = useState<number>(0);
	const [totalDistance, setTotalDistance] = useState<number>(0);
	const [hoveredIndex, setHoveredIndex] = useState<number>(0);

	useEffect(() => {
		// Xml parsin using xml2js
		xml2js.parseString(file, (err: any, result: any) => {
			if (
				!result?.gpx?.trk[0]?.trkseg ||
				!(result?.gpx?.trk[0]?.trkseg?.length > 0)
			) {
				return;
			}
			// First trkseg and trk index recovering
			const trkseg = result.gpx.trk[0].trkseg[0];

			// If trkseg does not exist => return
			if (!trkseg.trkpt || !(trkseg.trkpt.length > 0)) {
				return;
			}

			let previousElevation = 0;
			let currentDistance = 0;

			// Data initialization: lat, lng and elevation recovering
			const data = trkseg.trkpt.map((trkpt: any, index: number) => {
				if (trkpt?.ele?.length > 0) {
					previousElevation = parseInt(trkpt?.ele[0]);
				}

				// First chart element initialization
				if (index == 0) {
					return {
						distance: 0,
						elevation: previousElevation
					};
				}

				// Distance parsing from lat and long to metters, and last element recovering if the elevation value is 0
				const distance =
					haversine(
						{
							lat: trkseg.trkpt[index - 1].$.lat,
							lng: trkseg.trkpt[index - 1].$.lon
						},
						{ lat: trkpt.$.lat, lng: trkpt.$.lon }
					) / 1000;

				const coordinates = {
					lat: parseFloat(trkpt.$.lat),
					lng: parseFloat(trkpt.$.lon)
				};

				currentDistance += distance;

				return {
					distance: currentDistance,
					elevation: previousElevation,
					index: index
				};
			});

			// Computed Min / Max elevation
			const minElevationValue = Math.min(
				...data.map((item: any) => item.elevation)
			);
			const maxElevationValue = Math.max(
				...data.map((item: any) => item.elevation)
			);

			setMinElevation(minElevationValue);
			setMaxElevation(maxElevationValue);
			setGpxChart(data);
			setTotalDistance(data[data.length - 1].distance);
		});
	}, []);

	const stepCalculator = (totalDistance: number) => {
		let calculatedStep = [];

		// Looking for the first item bigger than total distance
		const step = BASE_DISTANCES.find(
			(item) => item.maxDistance > totalDistance
		);

		if (!step) return [];

		// totalDistance divided by the corresponding step
		for (let i = 0; i <= step.step; i++) {
			const nextDistance = (step.maxDistance / step.step) * i;
			if (nextDistance > totalDistance) continue;

			calculatedStep.push((step.maxDistance / step.step) * i);
		}

		if (Math.max(...calculatedStep) < totalDistance) {
			calculatedStep.push(totalDistance);
		}

		return calculatedStep;
	};

	const middleYAxisElevation = (minElevation + maxElevation) / 2;

	return (
		<>
			<GpxMapView file={file} index={hoveredIndex} />
			<ResponsiveContainer width="100%" height="30%">
				{/* Used data chart is from gpxChart */}
				<AreaChart
					data={gpxChart}
					margin={{ top: 20, right: 25, left: 10, bottom: 0 }}
					onMouseMove={(e) =>
						setHoveredIndex(e?.activePayload?.[0].payload.index)
					}
				>
					<CartesianGrid strokeDasharray="5 5" />
					<XAxis
						dataKey="distance" // Object Key from gpxChart
						domain={[]} // min and max displayed on X axis
						type="number"
						ticks={stepCalculator(Math.ceil(totalDistance))} // number of indicies on X axis
						tickFormatter={(tick, index) => (index === 0 ? tick : `${tick} km`)}
					/>
					<YAxis
						domain={[]}
						ticks={[
							minElevation.toFixed(0),
							middleYAxisElevation.toFixed(0),
							maxElevation.toFixed(0)
						]}
						tickFormatter={(tick) => `${tick} m`}
					/>
					<Tooltip
						labelFormatter={(label) =>
							"Distance:" + " " + label.toFixed(1) + " km"
						} // First displayed label
						formatter={(label) => label + " m"} // Second displayed label
					/>
					<Area
						type="monotone" // Auto smoothing effect
						dataKey="elevation" // Object Key from gpxChart
						stroke="#8884d8"
						dot={false} // Disabled dot
						strokeWidth={2}
						name="Élévation"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</>
	);
};

export default GpxChart;
