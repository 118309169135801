import { Tooltip, Typography } from "@material-tailwind/react";
import dayjs from "dayjs";
import { useContext, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { FaEye, FaRegTrashAlt } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi2";
import {
	deleteGpx,
	deleteRun,
	deleteTarif,
	getGpx,
	uploadGpx
} from "../../../api/event";
import { BASE_PRICE } from "../../../config/EventForms/Sport/SportBase";
import { AppContext } from "../../../contexts/AppContext";
import { UserContext } from "../../../contexts/UserContext";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import trad from "../../../lang/traduction";
import { IRunForm } from "../../../types/EventForms/ISportRun";
import { classNames } from "../../../utils/Classes";
import Toast from "../../../utils/Toasts";
import Modal from "../../common/Modal";
import DropDownOptions from "../DropDownOptions";
import GpxChart from "../GpxChart";
import SportDeletePriceModal from "./SportDeletePriceModal";
import SportDeleteRunModal from "./SportDeleteRunModal";
import SportEventPriceModal from "./SportEventPriceModal";
import SportEventRunFormModal from "./SportEventRunFormModal";
import SportEventTarif from "./SportEventTarif";
import SportRunSwitchOrderButton from "./SportRunSwitchOrderButton";

const SportRunModule = ({
	run,
	runs,
	index,
	editRun,
	refetch,
	formFields,
	timezone,
	setIsDisplayOrderUpdated
}: {
	run: IRunForm;
	runs: IRunForm[];
	index: number;
	editRun: any;
	refetch: any;
	formFields: any;
	timezone: string;
	setIsDisplayOrderUpdated: (isDisplayOrderUpdated: boolean) => void;
}) => {
	const { lang } = useContext(AppContext);
	const { user } = useContext(UserContext);
	const [openedDeleteConfirmation, setOpenedDeleteConfirmation] =
		useState(false);
	const [tarifDeleteConfirmation, setTarifDeleteConfirmation] = useState<{
		title: string;
		id: number;
	} | null>(null);
	const [openedPrice, setOpenedPrice] = useState<any>(null);
	const [showGpx, setShowGpx] = useState(false);
	const [showDeleteGpx, setShowDeleteGpx] = useState(false);
	const [openFormModal, setOpenFormModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useOutsideClick(false);
	const [files, setFiles] = useState<{
		[index: number]: { name: string; text: string };
	}>({});

	const handleDeleteModal = () => setOpenedDeleteConfirmation(true);

	const handleDelete = async () => {
		try {
			await deleteRun(run.id);
			refetch();

			Toast.success(trad[lang].success_deleting_run);
			setOpenedDeleteConfirmation(false);
		} catch (error: any) {
			if (error?.response?.status === 503)
				Toast.error(trad[lang].error_deleting_run_subs);
			else Toast.error(trad[lang].error_deleting_run);
		}
	};

	const openPrice = () =>
		setOpenedPrice({
			...BASE_PRICE
		});
	const editPrice = (price: any) => setOpenedPrice(price);

	const deletePrice = async (id: number) => {
		try {
			await deleteTarif(id);

			Toast.success(trad[lang].success_deleting_price);
			refetch();

			setTarifDeleteConfirmation(null);
		} catch (error) {
			Toast.error(trad[lang].error_deleting_price);
		}
	};

	const has_subscriptions = run.prices.some((item) => item.has_subscriptions);

	const hasGpx = run?.gpx?.url;

	const inputFileRef = useRef<any>();

	const handleDagFile = async (e: any) => {
		try {
			const file = e.target.files[0];
			const fileText = await file.text();
			setFiles((prevFiles) => ({
				...prevFiles,
				[0]: { name: file.name, text: fileText }
			}));
			setShowGpx(!showGpx);
			inputFileRef.current.value = "";
		} catch (err: any) {
			console.error("error uploading file", err);
		}
	};

	const sendGpxFile = async () => {
		const file = files[0].text;
		const encodedStringBtoA = btoa(file);
		try {
			await uploadGpx(encodedStringBtoA, run.id);
			await refetch();
			setShowGpx(false);
		} catch (err) {
			console.error("error uploading gpx file: ", err);
		}
	};

	const handleOpenGpx = async (url: string) => {
		try {
			const result = await getGpx(run.id);
			setFiles({ 0: { name: "", text: result } });
			setShowGpx(true);
		} catch (error) {
			console.error(error);
		}
	};

	const openedDropDown = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	const deleteGpxInDb = async () => {
		try {
			await deleteGpx(run.id);
			setLoading(true);
			await refetch();
			setShowDeleteGpx(!showDeleteGpx);
			Toast.success(trad[lang].deletedFile);
			setLoading(false);
		} catch (error) {
			console.error(error);
			Toast.error("Erreur lors de la suppression du fichier");
		}
	};

	return (
		<>
			<div
				className={classNames(
					"flex w-full flex-col gap-4 border-b py-6 px-5 even:bg-gray-100 md:mb-0 md:grid md:grid-cols-2"
				)}
			>
				<h2 className="col-span-1 text-2xl font-bold">{run.name}</h2>

				<div className="col-span-1 flex h-[48px] flex-row justify-end gap-3 ">
					<div className="max-sm:hidden">
						{runs && runs.length > 1 && (
							<SportRunSwitchOrderButton
								run={run}
								runs={runs}
								setIsDisplayOrderUpdated={setIsDisplayOrderUpdated}
								refetch={() => {}}
							/>
						)}
					</div>
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md border border-primary bg-white py-2 px-2 text-xs text-primary duration-150 hover:bg-primary hover:text-white md:uppercase"
						onClick={() => editRun(run)}
					>
						{trad[lang].event_edit_run}
					</button>
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md border border-primary bg-white py-2 px-2 text-xs text-primary duration-150 hover:bg-primary hover:text-white md:uppercase"
						onClick={() => setOpenFormModal(true)}
					>
						{trad[lang].event_configure_sub_form}
					</button>

					{/* BEGINING OF UPLOAD FILE */}

					<div>
						<label
							htmlFor={`GpxFile-${index}`}
							className="relative hidden h-full cursor-pointer items-center justify-center whitespace-nowrap rounded border border-primary bg-primary px-2 py-2 text-center text-xs text-white  transition-colors hover:bg-white hover:text-primary sm:flex md:uppercase lg:py-0"
						>
							{hasGpx ? (
								<div className="flex flex-row" ref={ref}>
									<span>{trad[lang].gpxFile}</span>
									<HiOutlineChevronDown className="ml-2 h-[18px] w-[18px]" />
									{isComponentVisible ? (
										<DropDownOptions
											firstSubtitle="visualiser"
											secondSubtitle="Supprimer"
											firstIcon={<FaEye />}
											secondIcon={<FaRegTrashAlt />}
											onClickFirstOption={() => handleOpenGpx(run.gpx.url)}
											onClickSecondOption={() =>
												setShowDeleteGpx(!showDeleteGpx)
											}
										/>
									) : null}
								</div>
							) : (
								<span>{trad[lang].addGpxFile}</span>
							)}
						</label>
						{hasGpx ? (
							<button
								onClick={() => openedDropDown()}
								id={`GpxFile-${index}`}
								name={`GpxFile-${index}`}
								className="hidden"
								ref={inputFileRef}
							/>
						) : (
							<input
								type="file"
								id={`GpxFile-${index}`}
								name={`GpxFile-${index}`}
								className="hidden"
								ref={inputFileRef}
								onChange={(e) => handleDagFile(e)}
								accept=".gpx"
							/>
						)}
					</div>

					{/*TOGGLE MAP PREVIEW & CANCEL IMPORT*/}
					{showGpx && (
						<Modal
							children={
								<>
									<GpxChart file={files[0]?.text} />
								</>
							}
							closeModal={() => setShowGpx(!showGpx)}
							button={hasGpx ? false : true}
							titleButton={"Valider"}
							onClickButton={sendGpxFile}
						/>
					)}

					{showDeleteGpx && (
						<Modal
							children={
								<div className="flex flex-col items-center justify-between p-5 text-xl md:text-xl">
									<h2 className="mb-3 text-center text-2xl font-bold">
										<h2>{trad[lang].deleteGpxFileConfirmation}</h2>
									</h2>
								</div>
							}
							closeModal={() => setShowDeleteGpx(!showDeleteGpx)}
							titleButton={"Supprimer"}
							titleCancelButton={"Annuler"}
							cancelBtn
							deleteBtn
							onClickCancelButton={() => setShowDeleteGpx(!showDeleteGpx)}
							onClickButton={deleteGpxInDb}
							style={{ width: "50%", height: "30%" }}
							rounded
							isLoading={loading}
						/>
					)}

					{has_subscriptions ? (
						<Tooltip
							className="text-md z-50 text-center"
							content={
								<Typography>
									{trad[lang].cannotDeleteRunWithSubscription}
								</Typography>
							}
						>
							<button
								className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white opacity-50 duration-150 hover:bg-red-700 md:uppercase"
								onClick={handleDeleteModal}
								disabled={true}
							>
								{trad[lang].event_delete_run}
							</button>
						</Tooltip>
					) : (
						<button
							className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
							onClick={handleDeleteModal}
						>
							{trad[lang].event_delete_run}
						</button>
					)}
				</div>

				{run.availablePlaces > 0 && (
					<h3 className="col-span-2">
						{trad[lang].event_places_label} : {run.availablePlaces}
					</h3>
				)}

				<h3 className="col-span-1">
					{trad[lang].event_run_start_date_label} :{" "}
					{dayjs(run.startDate).tz(timezone).format("DD/MM/YYYY HH:mm")}
				</h3>
				<h3 className="col-span-1">
					{trad[lang].event_run_end_date_label} :{" "}
					{dayjs(run.endDate).tz(timezone).format("DD/MM/YYYY HH:mm")}
				</h3>

				{user && user.IDPersonne === 721 && (
					<h3>{`(DEV : id_cal:${run.id} id_produit:${run.id_produit})`}</h3>
				)}

				{run.teamNumber > 1 && (
					<>
						<h3 className="col-span-1">
							{trad[lang].event_run_number_members_label} : {run.teamNumber}
						</h3>

						<h3 className="col-span-1">
							{trad[lang].event_run_same_bib_label} :{" "}
							{run.sameBibNumber ? "Oui" : "Non"}
						</h3>
					</>
				)}

				<h2 className="col-span-2 text-2xl font-bold">
					{trad[lang].event_prices}
				</h2>

				<div className="flex-column col-span-2 flex flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
					{run.prices
						.filter((item) => !item.delete)
						.map((price) => (
							<div
								className="group relative"
								key={`${run.index}_${price.index}`}
							>
								<SportEventTarif
									teamMembers={run.teamNumber}
									price={price}
									timezone={timezone}
								/>
								<div className="absolute -top-2 -right-2 flex flex-row gap-2 md:hidden md:group-hover:flex">
									<button
										className="rounded-md bg-primary p-2 shadow-sm hover:bg-primarymedium"
										onClick={() => editPrice(price)}
									>
										<AiOutlineEdit size={18} color="#ffffff" />
									</button>

									<button
										className="rounded-md bg-red-600 p-2 shadow-sm hover:bg-red-700"
										onClick={() =>
											setTarifDeleteConfirmation({
												id: price.id_tarif,
												title: price.designation
											})
										}
									>
										<AiOutlineDelete size={18} color="#ffffff" />
									</button>
								</div>
							</div>
						))}
					<button
						className="flex min-h-[162px] cursor-pointer flex-col items-center justify-center rounded-md bg-gray-400 transition-colors hover:bg-gray-500"
						onClick={openPrice}
					>
						<AiOutlinePlus size={36} color="#ffffff" />
						<span className="text-white">{trad[lang].event_run_add_price}</span>
					</button>
				</div>
			</div>

			{openedDeleteConfirmation && (
				<SportDeleteRunModal
					title={run.name}
					setConfirmDeleteRun={setOpenedDeleteConfirmation}
					deleteRun={handleDelete}
				/>
			)}

			{openedPrice != null && (
				<SportEventPriceModal
					idCalendrier={run.id}
					run={run}
					price={openedPrice}
					closePriceModal={() => setOpenedPrice(null)}
					fields={formFields}
					refetch={refetch}
					timezone={timezone}
				/>
			)}

			{tarifDeleteConfirmation != null && (
				<SportDeletePriceModal
					confirmDeletePrice={tarifDeleteConfirmation}
					setConfirmDeletePrice={setTarifDeleteConfirmation}
					toggleDeletePrice={deletePrice}
				/>
			)}

			{openFormModal && (
				<SportEventRunFormModal
					idCalendrier={run.id}
					refetch={refetch}
					runForm={run.form}
					closeModal={() => setOpenFormModal(false)}
					fields={formFields}
				/>
			)}
		</>
	);
};

export default SportRunModule;
