import { IRunPrice } from "../types/EventForms/ISportRun";
import { IRunnerRank } from "../types/ILive";
import { TimedAbortSignal } from "../utils/AxiosSignal";
import { API } from "./APIClient";

export const getEvents = async (date: string) => {
	try {
		const query = new URLSearchParams({
			isEvent: "true",
			idApplication: process.env.REACT_APP_ID_APPLICATION as string,
			isPast: date === "past" ? "true" : "false"
		});

		const { data } = await API.get(`/poi?${query}`);

		return data;
	} catch (error) {
		console.warn(["[getEvent error]"]);
		throw error;
	}
};

export const getEvent = async (slug: string | undefined) => {
	try {
		if (!slug) throw new Error("No slug gived");

		const { data } = await API.get(`/poi/slug/${slug}`);

		return data;
	} catch (error) {
		console.warn(["[getEvent error]"]);
		throw error;
	}
};

export const getEventsResume = async (
	idPersonne: number | undefined,
	date: string,
	search: string,
	signal: any
) => {
	try {
		const query = new URLSearchParams({
			date,
			search
		});

		const { data } = await API.get(
			`/event/person/${idPersonne}/resume?${query}`,
			{ signal }
		);

		return data;
	} catch (error) {
		console.warn("[getEventsResume error]", error);
		throw error;
	}
};

export const getLiveDatas = async ({ slug }: { slug: string | undefined }) => {
	try {
		const { data } = await API.get(`/live/getLiveDatas/slug/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getLiveDatas error]", error);
		throw error;
	}
};

export const getEventRunners = async (
	slug: string | undefined,
	search: string,
	nmbPerPage: string,
	page: number,
	filters: any,
	sort: any
) => {
	try {
		const { data } = await API.get(
			`/event/interest/slug/${slug}/runners?search=${search}&nmbPerPage=${nmbPerPage}&page=${page}&filters=${JSON.stringify(
				filters
			)}&sort=${JSON.stringify(sort)}`
		);

		return data;
	} catch (error) {
		console.warn("[getEventRunners error]", error);
		throw error;
	}
};

export const getEventSubscriptions = async (
	slug: string | undefined,
	search: string,
	nmbPerPage: string,
	page: number,
	filters: any,
	sort: any,
	onlyWithDocs: boolean
) => {
	try {
		const { data } = await API.get(
			`/event/interest/slug/${slug}/subscriptions?search=${search}&nmbPerPage=${nmbPerPage}&page=${page}&filters=${encodeURIComponent(
				JSON.stringify(filters)
			)}&sort=${JSON.stringify(sort)}&onlyWithDocs=${onlyWithDocs}`,
			{ paramsSerializer: {} }
		);

		return data;
	} catch (error) {
		console.warn("[getEventSubscriptions error]", error);
		throw error;
	}
};

export const updateRunnerStatus = async (idCoureur: number, status: number) => {
	try {
		const { data } = await API.patch(`/event/runner/${idCoureur}/status`, {
			etatCoureur: status
		});

		return data;
	} catch (error) {
		console.warn("[updateRunnerStatus error]", error);
		throw error;
	}
};

export const updateRegistration = async (idInscription: number, form: any) => {
	try {
		const { data } = await API.patch(
			`/event/subscription/${idInscription}/update`,
			form
		);

		return data;
	} catch (error) {
		console.warn("[updateRegistration error]", error);
		throw error;
	}
};

export const addSub = async (slug: string, form: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		const { data } = await API.post(
			`/event/${slug}/subscription/add`,
			form,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[addSub error]", error);
		throw error;
	}
};

export const exportToPDF = async (
	data: IRunnerRank[],
	sortOptionId: number
) => {
	try {
		const response = await API.post(
			"event/exportToPdf",
			{ data, sortOptionId },
			{
				responseType: "blob"
			}
		);

		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: "application/pdf" })
		);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `classement.pdf`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.warn("[exportToPDF error]", error);
		throw error;
	}
};

export const exportPdf = async (
	slug: string,
	order: string,
	direction: number,
	showSub: boolean,
	sexPageBreaker: boolean,
	idCalendrier: [],
	raceName: string
) => {
	const now = new Date();
	const options: any = {
		hour: "numeric",
		minute: "numeric",
		hour12: false
	};
	const formatter = now.toLocaleDateString("fr-FR", options);
	try {
		const response = await API.post(
			"event/exportToPdf",
			{ slug, order, direction, showSub, sexPageBreaker, idCalendrier },
			{
				responseType: "blob"
			}
		);
		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: "application/pdf" })
		);

		const onlyOneRace = idCalendrier.length < 2;

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${raceName}_${order}_${formatter}.pdf`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.open(url, "_blank");
		return response;
	} catch (error) {
		console.warn("[liveExport error]", error);
		throw error;
	}
};

export const filteredRunnerRanking = async (slug: string, run: any) => {
	try {
		const result = API.get(
			`event/subscription/filteredRunnerRanking/${slug}/${run}`
		);

		return result;
	} catch (error) {
		console.warn("[filteredRunnerRanking error]", error);
		throw error;
	}
};

export const runnerRankingList = async (slug: string) => {
	try {
		const result = API.get(`event/subscription/runnerRankingList/${slug}`);

		return result;
	} catch (error) {
		console.warn("[runnerRankingList error]", error);
		throw error;
	}
};

export const updateRegistrationOptions = async (
	idInscription: number,
	form: any
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		const { data } = await API.patch(
			`/event/subscription/${idInscription}/options/update`,
			form,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[updateRegistration error]", error);
		throw error;
	}
};

export const updateRegistrationChoices = async (
	idInscription: number,
	form: any
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		const { data } = await API.post(
			`/subscription/${idInscription}/choices`,
			form,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[updateRegistrationChoices error]", error);
		throw error;
	}
};

export const updateRunnerRaceInfos = async (idCoureur: number, form: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string }
				  }
				: {};

		const { data } = await API.patch(
			`/event/runner/${idCoureur}/raceInfos`,
			form,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[updateRunnerInfos error]", error);
		throw error;
	}
};

export const swapRunners = async (idCoureur1: number, idCoureur2: number) => {
	try {
		const { data } = await API.patch(
			`/event/runner/${idCoureur1}/swap/${idCoureur2}`
		);
		return data;
	} catch (error) {
		console.warn("[swapRunners error]", error);
		throw error;
	}
};

export const getTeamsAndClubs = async (slug: string) => {
	try {
		const { data } = await API.get(
			`/event/interest/slug/${slug}/teamsAndClubs`
		);

		return data;
	} catch (error) {
		console.warn("[getTeamsAndClubs error]", error);
		throw error;
	}
};

export const createTeam = async (slug: string, body: any) => {
	try {
		const { data } = await API.post(
			`/event/interest/slug/${slug}/teamsAndClubs`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[getTeamsAndClubs error]", error);
		throw error;
	}
};

export const getEventRuns = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/runs/slug/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getEventRuns error]", error);
		throw error;
	}
};

export const resetSubscriptions = async (slug: string) => {
	try {
		const { data } = await API.post(
			`/event/interest/slug/${slug}/resetSubscriptions`
		);
		return data;
	} catch (error) {
		console.warn("[resetSubscriptions error]", error);
		throw error;
	}
};

export const importSubscriptions = async (slug: string, body: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string },
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  }
				: {
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  };

		const { data } = await API.post(
			`/subscription/importSubscriptions`,
			body,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[importSubscriptions error]", error);
		throw error;
	}
};

export const getCheckpoints = async (params: any) => {
	try {
		const idCalendrier = params.idCalendrier as number;
		const idCourse = params.idCourse as number;

		if (!idCalendrier || !idCourse) {
			return null;
		}

		const { data } = await API.get(
			`/live/getCheckpoints/${idCalendrier}/course/${idCourse}`
		);

		return data;
	} catch (error) {
		console.warn("[getCheckpoints error]", error);
		throw error;
	}
};

export const defaultCheckpointConfigurationByClient = async (params: any) => {
	try {
		const slug = params.slug;
		const idPersonne = params.idPersonne;

		if (!slug || !idPersonne) {
			console.warn("[getCheckpoints idCalendrier : NULL]");
		}

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/live/getCheckpointsByClient/${idPersonne}/slug/${slug}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getCheckpoints error]", error);
		throw error;
	}
};

export const getCampainDetails = async (slug: any) => {
	try {
		if (!slug) {
			console.warn("[getCampainDetails]");
		}

		const { data } = await API.get(`/event/getCampainDetails/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getCampainDetails error]", error);
	}
};

export const uploadDocuments = async (documents: {
	IDPersonne: number;
	IdInscription: number;
	licence: string;
	certificat: string;
	autorisation: string;
}) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post("/document", documents, headers);

		return data;
	} catch (error) {
		console.warn("[uploadDocuments error]", error);
		throw error;
	}
};

export const updateSubscriptionVisibility = async (
	idCalendrier: number,
	visible: boolean
) => {
	try {
		const { data } = await API.put(
			`/event/updateSubscriptionVisibility/${idCalendrier}`,
			{ visible }
		);

		return data;
	} catch (error) {
		console.warn("[updateSbscriptionVisibility error]", error);
		throw error;
	}
};

export const getSubscriptionOptions = async (idInscription: number) => {
	try {
		const { data } = await API.get(
			`/event/subscription/${idInscription}/options`
		);

		return data;
	} catch (error) {
		console.warn("[getSubscriptionOptions error]", error);
		throw error;
	}
};

export const getSubscriptionAvailableOptions = async (
	idInscription: number
) => {
	try {
		const { data } = await API.get(
			`/event/subscription/${idInscription}/availableOptions`
		);

		return data;
	} catch (error) {
		console.warn("[getSubscriptionAvailableOptions error]", error);
		throw error;
	}
};

export const updateSubscriptionStatus = async (
	idInscription: number,
	body: {
		status: number;
		valid: number;
		live_status: number;
		message: string | null;
	}
) => {
	try {
		const { data } = await API.put(
			`/event/subscription/${idInscription}/status`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[updateSubscriptionStatus error]", error);
		throw error;
	}
};

export const getDocument = async (
	idPersonne: number,
	idInscription: number,
	idDocument: number,
	slug: string
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/document/${idDocument}?idPersonne=${idPersonne}&idInscription=${idInscription}&slug=${slug}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getDocument error]", error);
		throw error;
	}
};
export const downloadDocument = async (
	idPersonne: number,
	idInscription: number,
	idDocument: number,
	slug: string,
	extension: string
) => {
	try {
		const { data } = await API({
			url: `/event/document/${idDocument}?idPersonne=${idPersonne}&idInscription=${idInscription}&slug=${slug}&download=true`,
			responseType: "arraybuffer",
			headers: { Authorization: localStorage.getItem("token") }
		});

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(new Blob([data]));
		link.setAttribute(
			"download",
			`document_${slug}_${idPersonne}.${extension}`
		);

		document.body.appendChild(link);
		link.click();
	} catch (error) {
		console.warn("[getDocument error]", error);
		throw error;
	}
};

export const getImportTemplate = async (slug: string) => {
	try {
		const { data } = await API({
			url: `/subscription/${slug}/getImportTemplate`,
			responseType: "arraybuffer"
		});

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(new Blob([data]));
		link.setAttribute("download", `import_inscriptions_${slug}.xlsx`);

		document.body.appendChild(link);
		link.click();
	} catch (error) {
		console.warn("[getImportTemplate error]", error);
		throw error;
	}
};

export const getMessages = async (slug: string, idInscription: number) => {
	try {
		const { data } = await API.get(
			`/event/subscription/${slug}/${idInscription}/messageHistory`
		);

		return data;
	} catch (error) {
		console.warn("[getMessages error]", error);
	}
};

export const sendComment = async (
	slug: string,
	idInscription: number,
	body: { message: string | null; sentAt: Date; user: string }
) => {
	try {
		const { data } = await API.post(
			`/event/subscription/${slug}/${idInscription}/sendComment`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[sendComments error]", error);
	}
};

export const deleteComment = async (
	slug: string,
	idInscription: number,
	index: number
) => {
	try {
		const { data } = await API.post(
			`/event/subscription/${slug}/${idInscription}/deleteCommentHistory`,
			{ index }
		);

		return data;
	} catch (error) {
		console.warn("[deleteComments error]", error);
	}
};

export const getComments = async (slug: string, idInscription: number) => {
	try {
		const { data } = await API.get(
			`/event/subscription/${slug}/${idInscription}/commentHistory`
		);

		return data;
	} catch (error) {
		console.warn("[getComments error]", error);
	}
};

export const downloadExcel = async (slug: string) => {
	try {
		const { data } = await API({
			url: `/event/interest/slug/${slug}/excel/getEmpty`,
			responseType: "arraybuffer",
			headers: { Authorization: localStorage.getItem("token") }
		});

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(new Blob([data]));
		link.setAttribute("download", `inscriptions_${slug}.xlsx`);

		document.body.appendChild(link);
		link.click();
	} catch (error) {
		console.warn("[downloadExcel error]", error);
		throw error;
	}
};

export const getOrderRows = async (idInscription: number) => {
	try {
		if (!idInscription) throw new Error("[getOrderRows error]");

		const { data } = await API.get(
			`/event/subscription/${idInscription}/orderRows`
		);

		return data;
	} catch (error) {
		console.warn("[getOrderRows error]", error);
	}
};

export const getSubscriptionOrder = async (idInscription: number) => {
	try {
		if (!idInscription) throw new Error("[getSubscriptionOrder error]");

		const { data } = await API.get(
			`/event/subscription/${idInscription}/order`
		);

		return data;
	} catch (error) {
		console.warn("[getSubscriptionOrder error]", error);
	}
};

// GET Teammates
export const getTeammates = async (
	slug: string,
	teamName: string,
	idPersonne: number
) => {
	try {
		const { data } = await API.get(
			`/event/${slug}/getTeammates/${teamName}/runner/${idPersonne}`
		);

		return data;
	} catch (error) {
		console.warn("[getEventRuns error]", error);
		throw error;
	}
};

export const refundSubscription = async (
	idInscription: string | number,
	body: {
		refund: {
			[key: string]: { checked: boolean; amount: string; isFees: boolean };
		};
		orgaRefundFees: boolean;
		refundReason: string;
		cancelSubscription: boolean;
	}
) => {
	try {
		if (!idInscription || body.orgaRefundFees == null)
			throw new Error("[getOrderRows error]");

		const { data } = await API.post(
			`/event/subscription/${idInscription}/refund`,
			body,
			{ headers: { Authorization: localStorage.getItem("token") } }
		);

		return data;
	} catch (error) {
		console.warn("[refundSubscription error]", error);
		throw error;
	}
};

export const getEventTypes = async () => {
	try {
		const { data } = await API.get("/event/types");

		return data;
	} catch (error) {
		console.warn("[getEventTypes error]", error);
		throw error;
	}
};

export const getEventSubtypes = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/types/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getEventTypes error]", error);
		throw error;
	}
};

export const getEventIdentityConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/config/identity`);

		return data;
	} catch (error) {
		console.warn("[getEventIdentity error]", error);
		throw error;
	}
};

export const getRaceResultFiles = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/${slug}/resultFiles`);

		return data;
	} catch (error) {
		console.warn("[getRaceResultFiles error]", error);
		throw error;
	}
};

export const updateLiveTrackingUrl = async (slug: string, body: any) => {
	try {
		const { data } = await API.post(
			`/event/${slug}/updateLiveTrackingUrl`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[updateLiveTrackingUrl error]", error);
		throw error;
	}
};

export const updateRaceResultFiles = async (slug: string, body: any) => {
	try {
		const { data } = await API.post(`/event/${slug}/resultFiles`, body);

		return data;
	} catch (error) {
		console.warn("[getRaceResultFiles error]", error);
		throw error;
	}
};

export const getEventDocsConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/documents/config`);

		return data;
	} catch (error) {
		console.warn("[getEventDocsConfig error]", error);
		throw error;
	}
};

export const getEventRunsConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/config/runs`);

		return data;
	} catch (error) {
		console.warn("[getEventRunsConfig error]", error);
		throw error;
	}
};

export const getEventContactsConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/config/contacts`);

		return data;
	} catch (error) {
		console.warn("[getEventRunsConfig error]", error);
		throw error;
	}
};

export const getEventSubscriptionFormFields = async () => {
	try {
		const { data } = await API.get(`/event/config/subscriptionFields`);

		return data;
	} catch (error) {
		console.warn("[getEventSubscriptionFormFields error]", error);
		throw error;
	}
};

export const getEventRunList = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/config/runList`);

		return data;
	} catch (error) {
		console.warn("[getEventRunList error]", error);
		throw error;
	}
};

export const getAppInfos = async (idCalendrier: number) => {
	try {
		const { data } = await API.get(`/app/${idCalendrier}/infos`);

		return data;
	} catch (error) {
		console.warn("[getAppInfos error]", error);
		throw error;
	}
};

export const getAppLastPositions = async (idCalendrier: number) => {
	try {
		const { data } = await API.get(`/app/${idCalendrier}/lastPositions`);

		return data;
	} catch (error) {
		console.warn("[getAppLastPositions error]", error);
		throw error;
	}
};

export const getEventRouteConfig = async (slug: string, route: string) => {
	try {
		const { data } = await API.get(
			`/event/slug/${slug}/config/${route}/etapes`
		);

		return data;
	} catch (error) {
		console.warn("[getEventRoutesConfig error]", error);
		throw error;
	}
};

export const getEventParcoursConfig = async (
	slug: string,
	run: string,
	idetape: string
) => {
	try {
		const { data } = await API.get(
			`/event/slug/${slug}/config/${run}/etapes/${idetape}/parcours`
		);

		return data;
	} catch (error) {
		console.warn("[getEventParcoursConfig error]", error);
		throw error;
	}
};

export const getEventSegmentsConfig = async (
	slug: string,
	run: string,
	idetape: string,
	idparcours: string
) => {
	try {
		const { data } = await API.get(
			`/event/slug/${slug}/config/${run}/etapes/${idetape}/parcours/${idparcours}`
		);

		return data;
	} catch (error) {
		console.warn("[getEventParcoursConfig error]", error);
		throw error;
	}
};

export const getCheckpointsByRun = async (slug: string) => {
	try {
		const { data } = await API.get(`/live/getCheckpoints/run/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getEventParcoursConfig error]", error);
		throw error;
	}
};

export const createSegment = async (idParcours: number, segment: any) => {
	try {
		const query = new URLSearchParams({ ...segment });

		const { data } = await API.get(`/live/addSegment/${idParcours}?${query}`);

		return data;
	} catch (error) {
		console.warn("[createSegment error]", error);
		throw error;
	}
};

export const updateSegment = async (idSegment: number, segment: any) => {
	try {
		const query = new URLSearchParams({ ...segment });

		const { data } = await API.get(`/live/updateSegment/${idSegment}?${query}`);

		return data;
	} catch (error) {
		console.warn("[updateSegment error]", error);
		throw error;
	}
};

export const deleteSegment = async (idSegment: number) => {
	try {
		const { data } = await API.get(`/live/deleteSegment/${idSegment}`);

		return data;
	} catch (error) {
		console.warn("[deleteSegment error]", error);
		throw error;
	}
};

export const createParcours = async (idEtape: number, body: any) => {
	try {
		const { data } = await API.post(`/live/addParcours/${idEtape}`, body);

		return data;
	} catch (error) {
		console.warn("[createParcours error]", error);
		throw error;
	}
};

export const updateParcours = async (idSegment: number, parcours: any) => {
	try {
		const query = new URLSearchParams({ ...parcours });

		const { data } = await API.get(
			`/live/updateParcours/${idSegment}?${query}`
		);

		return data;
	} catch (error) {
		console.warn("[updateParcours error]", error);
		throw error;
	}
};

export const deleteParcours = async (idParcours: number) => {
	try {
		const { data } = await API.get(`/live/deleteParcours/${idParcours}`);

		return data;
	} catch (error) {
		console.warn("[deleteParcours error]", error);
		throw error;
	}
};

export const createEtape = async (slug: string, etape: any) => {
	try {
		const query = new URLSearchParams({ ...etape });

		const { data } = await API.get(`/live/addEtape/${slug}?${query}`);

		return data;
	} catch (error) {
		console.warn("[createEtape error]", error);
		throw error;
	}
};

export const updateEtape = async (idEtape: number, etape: any) => {
	try {
		const query = new URLSearchParams({ ...etape });

		const { data } = await API.get(`/live/updateEtape/${idEtape}?${query}`);

		return data;
	} catch (error) {
		console.warn("[updateEtape error]", error);
		throw error;
	}
};

export const deleteEtape = async (idEtape: number) => {
	try {
		const { data } = await API.get(`/live/deleteEtape/${idEtape}`);

		return data;
	} catch (error) {
		console.warn("[deleteEtape error]", error);
		throw error;
	}
};

export const createSportEventIdentity = async (body: any) => {
	try {
		const formatedBody = {
			slug: body.slug,
			eventType: body.eventType,
			eventCategory: body.eventCategory,
			subjectToVAT: false,
			masterEvent: {
				name: body.name,
				startDate: body.startDate,
				endDate: body.endDate,
				reservationStartDate: body.startDate,
				reservationEndDate: body.endDate,
				availablePlaces: body.availablePlaces,
				longDescription: body.longDescription,
				shortDescription: body.shortDescription,
				timezone: body.timezone,
				address: {
					city: body.city,
					postalCode: body.postalCode,
					address: body.address,
					country: body.country
				},
				contacts: [],
				ffa: { cmpcod: body.siffa },
				organizingRules: {
					description: body.organizingRules
				}
			},
			medias: body.medias
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			"/event/createNewEvent/step/1/basic",
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createSportEventIdentity error]", error);
		throw error;
	}
};

export const updateSportEventIdentity = async (slug: string, body: any) => {
	try {
		const formatedBody = {
			slug: body.slug,
			eventType: body.eventType,
			eventCategory: body.eventCategory,
			subjectToVAT: false,
			masterEvent: {
				name: body.name,
				startDate: body.startDate,
				endDate: body.endDate,
				reservationStartDate: body.startDate,
				reservationEndDate: body.endDate,
				availablePlaces: body.availablePlaces,
				longDescription: body.longDescription,
				shortDescription: body.shortDescription,
				timezone: body.timezone,
				address: {
					city: body.city,
					postalCode: body.postalCode,
					address: body.address,
					country: body.country
				},
				contacts: [],
				ffa: { cmpcod: body.siffa },
				organizingRules: {
					description: body.organizingRules
				}
			},
			medias: body.medias,
			ruleFile: body.ruleFile
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/updateEvent/identity/${slug}`,
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[updateSportEventIdentity error]", error);
		throw error;
	}
};

export const createRun = async (slug: string, body: any) => {
	try {
		const formatedBody = {
			...body,
			teamNumber: Math.max(1, body.teamNumber),
			formInputs: Object.entries(body.form).map((form_entry: any) => ({
				libelle: form_entry[0],
				id: form_entry[1].id,
				show: form_entry[1].visible,
				required: form_entry[1].required
			}))
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/createRun/${slug}`,
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createRun error]", error);
		throw error;
	}
};

export const editRun = async (body: any) => {
	try {
		const formatedBody = {
			...body,
			teamNumber: Math.max(1, body.teamNumber),
			formInputs: Object.entries(body.form).map((form_entry: any) => ({
				libelle: form_entry[0],
				id: form_entry[1].id,
				show: form_entry[1].visible,
				required: form_entry[1].required
			}))
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/updateRun/${body.id}`,
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createRun error]", error);
		throw error;
	}
};

export const deleteRun = async (idCalendrier: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(
			`/event/deleteRun/${idCalendrier}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createNewRun error]", error);
		throw error;
	}
};

export const createPrice = async (idCalendrier: number, body: IRunPrice) => {
	try {
		const formatedBody = {
			...body,
			formInputs: Object.entries(body.form).map((form_entry) => ({
				libelle: form_entry[0],
				id: form_entry[1].id,
				show: form_entry[1].visible,
				required: form_entry[1].required
			}))
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/createTarif/${idCalendrier}`,
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createNewRun error]", error);
		throw error;
	}
};

export const editPrice = async (idTarifProduit: number, body: IRunPrice) => {
	try {
		const formatedBody = {
			...body,
			formInputs: Object.entries(body.form).map((form_entry) => ({
				libelle: form_entry[0],
				id: form_entry[1].id,
				show: form_entry[1].visible,
				required: form_entry[1].required
			}))
		};

		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/updateTarif/${idTarifProduit}`,
			formatedBody,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[editPrice error]", error);
		throw error;
	}
};

export const deleteTarif = async (idTarif: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(`/event/deleteTarif/${idTarif}`, headers);

		return data;
	} catch (error) {
		console.warn("[deleteTarif error]", error);
		throw error;
	}
};

export const updateFormConfig = async (idCalendrier: number, fields: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const formFields = Object.entries(fields).map((form_entry: any) => ({
			libelle: form_entry[0],
			id: form_entry[1].id,
			show: form_entry[1].visible,
			required: form_entry[1].required
		}));

		const { data } = await API.post(
			`/event/updateFormConfig/${idCalendrier}`,
			formFields,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[deleteTarif error]", error);
		throw error;
	}
};

export const getEventOptionsConfig = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/slug/${slug}/config/options`);

		return data;
	} catch (error) {
		console.warn("[getEventRoutesConfig error]", error);
		throw error;
	}
};

export const updateEventOptions = async (slug_race: string, json: any) => {
	try {
		const { data } = await API.patch(
			`/event/slug/${slug_race}/config/options`,
			json
		);

		return data;
	} catch (error) {
		console.warn("[getEventRoutesConfig error]", error);
		throw error;
	}
};

export const updateEventGlobalOptions = async (slug: string, json: any) => {
	try {
		const { data } = await API.patch(
			`/event/slug/${slug}/config/globalOptions`,
			json
		);

		return data;
	} catch (error) {
		console.warn("[getEventRoutesConfig error]", error);
		throw error;
	}
};

export const createContact = async (slug: string, body: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/createContact/${slug}`,
			body,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createContact error]", error);
	}
};

export const updateContact = async (idContact: number, body: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/updateContact/${idContact}`,
			body,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createContact error]", error);
	}
};

export const deleteContact = async (idContact: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(
			`/event/deleteContact/${idContact}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[createContact error]", error);
		throw error;
	}
};

export const getFFTRIOptions = async () => {
	try {
		const { data } = await API.get(`/event/config/fftri`);

		return data;
	} catch (error) {
		console.warn("[createContact error]", error);
		throw error;
	}
};

export const getTypeRib = async () => {
	try {
		const { data } = await API.get(`/event/getTypeRib`);

		return data;
	} catch (error) {
		console.warn("[getTypeRib error]", error);
		throw error;
	}
};

export const getUserRibs = async () => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/user/ribs`, headers);

		return data;
	} catch (error) {
		console.warn("[getTypeRib error]", error);
		throw error;
	}
};

export const getRibs = async (slug: string) => {
	try {
		const { data } = await API.get(`/event/getRib/${slug}`);

		return data;
	} catch (error) {
		console.warn("[getRibs error]", error);
		throw error;
	}
};

export const addRib = async (slug: string, body: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(`/event/addRib/${slug}`, body, headers);

		return data;
	} catch (error) {
		console.warn("[addRib error]", error);
		throw error;
	}
};

export const addExistingRibApi = async (slug: string, rib: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/addRib/${slug}/${rib}`,
			{},
			headers
		);

		return data;
	} catch (error) {
		console.warn("[addRib error]", error);
		throw error;
	}
};

export const updateRib = async (idRib: number, body: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(`/event/updateRib/${idRib}`, body, headers);

		return data;
	} catch (error) {
		console.warn("[updateRib error]", error);
		throw error;
	}
};

export const deleteRib = async (idRib: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(`/event/deleteRib/${idRib}`, headers);

		return data;
	} catch (error) {
		console.warn("[addRib error]", error);
		throw error;
	}
};

export const askValidation = async (slug: string) => {
	try {
		const callHeaders: any =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data, headers } = await API.get(
			`/event/askValidation/${slug}`,
			callHeaders
		);

		return { data, headers };
	} catch (error) {
		console.warn("[askValidation error]", error);
		throw error;
	}
};

export const unpublishEvent = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/unpublish/${slug}`, headers);

		return data;
	} catch (error) {
		console.warn("[unpublishEvent error]", error);
		throw error;
	}
};

export const getExport = async (slug: string, type: string, body: any) => {
	try {
		const { data, headers } = await API.post(
			`/event/interest/slug/${slug}/exportSubscriptions/${type}`,
			body,
			{
				responseType: "blob"
			}
		);

		return { data, headers };
	} catch (error) {
		console.warn("[getExport error]", error);
		throw error;
	}
};

export const getExportElogica = async (slug: string) => {
	try {
		const { data, headers } = await API.get(
			`/live/exportFFASubscriptions/${slug}`,
			{
				responseType: "blob"
			}
		);

		return { data, headers };
	} catch (error) {
		console.warn("[getExport error]", error);
		throw error;
	}
};

export const getExportElogicaResults = async (slug: string) => {
	try {
		const { data, headers } = await API.get(`/live/exportFFAResults/${slug}`, {
			responseType: "blob"
		});

		return { data, headers };
	} catch (error) {
		console.warn("[getExport error]", error);
		throw error;
	}
};

export const getExportDonations = async (
	slug: string,
	nomAssociation: string
) => {
	try {
		const { data, headers } = await API.get(
			`/event/${slug}/donations/export/${nomAssociation}`,
			{
				responseType: "blob"
			}
		);

		return { data, headers };
	} catch (error) {
		console.warn("[getExportDonations error]", error);
		throw error;
	}
};

export const getInvoiceExport = async (slug: string) => {
	try {
		const callHeaders: any =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						responseType: "blob",
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {
						responseType: "blob"
				  };

		const { data, headers } = await API.get(
			`/event/exportInvoice/${slug}`,
			callHeaders
		);

		return { data, headers };
	} catch (error) {
		console.warn("[getInvoiceExport error]", error);
		throw error;
	}
};

export const editInvoiceExport = async (slug: string) => {
	try {
		const callHeaders: any =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						responseType: "blob",
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {
						responseType: "blob"
				  };

		const { data, headers } = await API.get(
			`/event/editInvoice/${slug}`,
			callHeaders
		);

		return { data, headers };
	} catch (error) {
		console.warn("[editInvoiceExport error]", error);
		throw error;
	}
};

export const bibPlan = async (
	slug: string,
	idCalendrier: number,
	body: any
) => {
	try {
		const { data } = await API.post(
			`/event/${slug}/bibPlan/${idCalendrier}`,
			body
		);

		return data;
	} catch (error) {
		console.warn("[bibPlan error]", error);
		throw error;
	}
};

export const getAllEvents = async () => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/all?idApplication=${process.env.REACT_APP_ID_APPLICATION}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getAllEvents error]", error);
		throw error;
	}
};

export const getRapportEvent = async (idCalendar: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string },
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  }
				: {
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  };

		const { data } = await API.get(`/event/finance/${idCalendar}`, headers);

		return data;
	} catch (error) {
		console.warn("[getRapportEvent error]", error);
		throw error;
	}
};

export const getSharesEvent = async (idCalendar: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: { authorization: localStorage.getItem("token") as string },
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  }
				: {
						timeout: 5 * 60 * 1000,
						signal: TimedAbortSignal(5 * 60 * 1000)
				  };

		const { data } = await API.get(`/event/shares/${idCalendar}`, headers);

		return data;
	} catch (error) {
		console.warn("[getSharesEvent error]", error);
		throw error;
	}
};

export const getSegmentListFromCalendar = async (
	slug: string,
	idCalendar: string
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/slug/${slug}/segment/${idCalendar}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getSegmentListFromCalendar error]", error);
		throw error;
	}
};

export const getRankingDetails = async (
	idSegment: string,
	page: string,
	search: string,
	gender: string,
	category: number
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/getRunRanking/${idSegment}?startRequired=false&filter=25&idSegment=${idSegment}&page=${page}&search=${search}&sex=${gender}&category=${category}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getSegmentListFromCalendar error]", error);
		throw error;
	}
};

export const getEventCategories = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/slug/${slug}/categories`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventCategories error]", error);
		throw error;
	}
};

export const getExportResults = async (
	idSegment: string,
	sex: string,
	category: number,
	format: string
) => {
	try {
		const headers: any =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						responseType: "blob",
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {
						responseType: "blob"
				  };

		const { data } = await API.get(
			`/event/export/${idSegment}?sex=${sex}&category=${category}&format=${format}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getExportResults error]", error);
		throw error;
	}
};

export const acceptDocument = async (idDocument: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.patch(
			`/event/document/${idDocument}/accept`,
			{},
			headers
		);

		return data;
	} catch (error) {
		console.warn("[acceptDocument error]", error);
		throw error;
	}
};

export const refuseDocument = async (idDocument: number, reason: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.patch(
			`/event/document/${idDocument}/refuse`,
			{ reason },
			headers
		);

		return data;
	} catch (error) {
		console.warn("[acceptDocument error]", error);
		throw error;
	}
};

export const getEventStatistics = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/statistics/${slug}`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventStatistics error]", error);
		throw error;
	}
};

export const getEventStatisticsById = async (id: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/statisticsById/${id}`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventStatistics error]", error);
		throw error;
	}
};

export const getEventCommissions = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/commissions/${slug}`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventCommissions error]", error);
		throw error;
	}
};

export const getEventDonations = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(`/event/${slug}/donations`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventDonations error]", error);
		throw error;
	}
};

export const getEventFinanceDetails = async (
	debouncedStart: string,
	debouncedEnd: string,
	signal: any
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						},
						...signal
				  }
				: {
						...signal
				  };

		const { data } = await API.get(
			`/event/eventsFinance?start=${debouncedStart}&end=${debouncedEnd}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[getEventFinanceDetails error]", error);
		throw error;
	}
};

export const getCurrentEventFinance = async (signal: any) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						},
						...signal
				  }
				: {
						...signal
				  };

		const { data } = await API.get(`/event/currentEventsFinance`, headers);

		return data;
	} catch (error) {
		console.warn("[getEventFinanceDetails error]", error);
		throw error;
	}
};

export const validateEventFacture = async (idFacture: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/validateFacture/${idFacture}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[validateEventFacture error]", error);
		throw error;
	}
};

export const validateEventCommission = async (idCalendrier: number) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/validateCommission/${idCalendrier}`,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[validateEventFacture error]", error);
		throw error;
	}
};

export const duplicateEvent = async (
	slug: string,
	body: { name: string; startDate: any; endDate: any }
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/duplicateEvent/${slug}`,
			body,
			headers
		);

		return data;
	} catch (error) {
		console.warn("[duplicateEvent error]", error);
		throw error;
	}
};

export const patchSubscriptionsStatus = async (slug: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.patch(
			`/event/stopSubscriptions/${slug}`,
			{},
			headers
		);

		return data;
	} catch (error) {
		console.warn("[DisableActiveSubscriptions error]", error);
		throw error;
	}
};

export const uploadGpx = async (base64: string, id: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/uploadGpx/${id}`,
			{ base64 },
			headers
		);

		return data;
	} catch (error) {
		console.warn("[duplicateEvent error]", error);
		throw error;
	}
};

export const getGpx = async (id: string) => {
	try {
		const { data } = await API.get(`/event/getGpx/${id}`);

		return data;
	} catch (error) {
		console.warn("[duplicateEvent error]", error);
		throw error;
	}
};

export const deleteGpx = async (id: string) => {
	try {
		await API.delete(`/event/deleteGpx/${id}`);
	} catch (error) {
		console.warn("[duplicateEvent error]", error);
		throw error;
	}
};

export const getEventLists = async (slug: string, slug_race: string) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.get(
			`/event/slug/${slug}/runs/${slug_race}/lists`,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};

export const createEventList = async (
	slug: string,
	slug_race: string,
	body: { name: string }
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/slug/${slug}/runs/${slug_race}/lists`,
			body,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};

export const createEventChoice = async (
	slug: string,
	slug_race: string,
	idList: number,
	body: { name: string }
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/slug/${slug}/runs/${slug_race}/lists/${idList}/choices`,
			body,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};

export const importEventChoice = async (
	slug: string,
	slug_race: string,
	idList: number,
	body: { name: string }[]
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.post(
			`/event/slug/${slug}/runs/${slug_race}/lists/${idList}/choices/import`,
			body,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteEventList = async (
	slug: string,
	slug_race: string,
	idList: number
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(
			`/event/slug/${slug}/runs/${slug_race}/lists/${idList}`,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteEventChoice = async (
	slug: string,
	slug_race: string,
	idList: number,
	idChoice: number
) => {
	try {
		const headers =
			typeof window !== "undefined" && localStorage.getItem("token")
				? {
						headers: {
							authorization: localStorage.getItem("token") as string
						}
				  }
				: {};

		const { data } = await API.delete(
			`/event/slug/${slug}/runs/${slug_race}/lists/${idList}/choices/${idChoice}`,
			headers
		);

		return data;
	} catch (error) {
		throw error;
	}
};
