import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { becomeOrga, logOut } from "../api/auth";
import Logo from "../assets/logo_solo.png";
import { AppContext } from "../contexts/AppContext";
import { UserContext } from "../contexts/UserContext";
import trad from "../lang/traduction";
import { User } from "../types/User";
import { classNames } from "../utils/Classes";
import Toast from "../utils/Toasts";

const IsNotOrga = () => {
	const navigate = useNavigate();
	const { lang } = useContext(AppContext);
	const { setUser, user, getUser } = useContext(UserContext);
	const [acceptCGV, setAcceptCGV] = useState(false);

	const logout = async () => {
		try {
			await logOut();

			localStorage.removeItem("newConnexion");
			localStorage.removeItem("token");
			if (process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER) {
				localStorage.removeItem(process.env.REACT_APP_JETCODE_LOCAL_IDENTIFIER);
			}

			setUser(undefined);

			navigate("/");
			Toast.success(trad[lang].logoutSuccess);
		} catch (error) {
			Toast.error(trad[lang].logoutError);
		}
	};

	const submit = async () => {
		try {
			if (!acceptCGV) {
				return;
			}

			const res = await becomeOrga(
				process.env.REACT_APP_ID_APPLICATION,
				(user as User).IDPersonne
			);

			await localStorage.setItem("token", res.token);

			await getUser();

			Toast.success(trad[lang].orgaSuccess);
		} catch (error) {
			Toast.error(trad[lang].orgaError);
		}
	};

	return (
		<div className="flex h-screen flex-col items-center justify-center gap-5">
			{/* Logo */}
			<div className="flex flex-row items-center">
				<img src={Logo} alt="" />
				<h1 className="text-2xl font-bold text-primary">Chronometrage.com</h1>
			</div>

			{/* Title */}
			<h2 className="text-center font-bold">{trad[lang].orga_title}</h2>

			<div className="flex items-center">
				<input
					id="acceptCGV"
					name="acceptCGV"
					type="checkbox"
					className="h-4 w-4 rounded border-gray-300 text-primary focus:border-primary focus:ring-0"
					onChange={() => setAcceptCGV((old) => !old)}
					checked={acceptCGV}
				/>

				<Link
					to="https://dashboard-v1.chronometrage.com/uploads/cgv/organisateurs.pdf"
					target="_blank"
				>
					<label className="ml-2 block cursor-pointer text-sm text-primary">
						{trad[lang].acceptCGV}
					</label>
				</Link>
			</div>

			<div className="flex flex-col justify-between gap-5 sm:w-[480px] sm:flex-row">
				<button
					type="button"
					className="flex w-full items-center justify-center rounded-md border  border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm duration-150 hover:bg-red-700 focus:outline-none focus:ring-0 focus:ring-offset-0"
					onClick={logout}
				>
					{trad[lang].logout}
				</button>

				<button
					type="button"
					className={classNames(
						"flex w-full  items-center justify-center rounded-md  border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm duration-150  focus:outline-none focus:ring-0 focus:ring-offset-0",
						!acceptCGV
							? " bg-gray-400 opacity-50"
							: "cursor-pointer bg-primary hover:bg-primarymedium"
					)}
					onClick={submit}
					disabled={!acceptCGV}
				>
					{trad[lang].signup_button}
				</button>
			</div>
		</div>
	);
};

export default IsNotOrga;
