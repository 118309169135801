import { Dialog, Transition } from "@headlessui/react";
import { Radio } from "@material-tailwind/react";
import ExcelJS, { Worksheet } from "exceljs";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { HiOutlineXMark } from "react-icons/hi2";
import { SiMicrosoftexcel } from "react-icons/si";
import { useParams } from "react-router-dom";
import "regenerator-runtime";
import {
    getImportTemplate,
    importSubscriptions,
    resetSubscriptions
} from "../../api/event";
import { LiveContext } from "../../contexts/LiveContext";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";
import { getWindevIdByCountryName } from "../../utils/CountriesList";
import { REGEXP } from "../../utils/RegExp";
import Toast from "../../utils/Toasts";

const IMPORT_TYPE: any = { REPLACE: "replace", NOUPDATE: "noupdate" };

function ImportModal({
	open,
	setOpen,
	lang,
	refetch,
	numSubs,
	dataSubs
}: {
	open: boolean;
	setOpen: any;
	lang: ILang;
	refetch: any;
	numSubs: number;
	dataSubs: any;
}) {
	const { slug } = useParams();
	const { liveConfig } = useContext(LiveContext);
	const [droppedFile, setDroppedFile] = useState<any>(null);
	const [errors, setErrors] = useState<string[]>([]);
	const [warnings, setWarnings] = useState<string[]>([]);
	const [processing, setProcessing] = useState<boolean>(false);
	const [processingCurrent, setProcessingCurrent] = useState<number>(1);
	const [processingTotal, setProcessingTotal] = useState<number>(0);
	const [processingDuplicates, setProcessingDuplicates] = useState<number>(0);
	const [runners, setRunners] = useState<any[]>([]);
	const [processingTime, setProcessingTime] = useState(0);
	const [importDone, setImportDone] = useState<boolean>(false);
	const [importError, setImportError] = useState<boolean>(false);
	const [preImport, setPreImport] = useState<boolean>(true);
	const [importType, setImportType] = useState<string>(IMPORT_TYPE.REPLACE);
	const [downloadingTemplate, setDownloadingTemplate] =
		useState<boolean>(false);
	const [isFileNotSupported, setIsFileNotSupported] = useState<boolean>(false);
	const onDrop = (files: any) => {
		setIsFileNotSupported(files.length === 0);
		setDroppedFile(files[0]);
		setErrors([]);
		setWarnings([]);
		setProcessing(false);
		setImportDone(false);
		setPreImport(true);
	};
	const dateFormatRef = useRef("FR");
	const listTeamsRef = useRef([] as any);

	const {
		getRootProps,
		getInputProps,
		acceptedFiles,
		isDragActive,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx"
			]
		}
	});

	useEffect(() => {
		if (open === false) {
			setDroppedFile(null);
			setErrors([]);
			setWarnings([]);
			setProcessing(false);
			setProcessingCurrent(0);
			setProcessingTotal(0);
			setProcessingDuplicates(0);
			setRunners([]);
			setProcessingTime(0);
			setImportDone(false);
			setPreImport(true);
			setImportType(IMPORT_TYPE.REPLACE);
			setImportError(false);
			setIsFileNotSupported(false);
		}
	}, [open]);

	// Format the elapsed time as a string
	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60000);
		const seconds = Math.floor((time % 60000) / 1000);
		if (minutes === 0) return `${seconds.toString()} sec`;
		return `${minutes} min ${seconds.toString().padStart(2, "0")} sec`;
	};

	async function asyncForEach(array: any[], callback: Function) {
		for (let index = 0; index < array.length || 0; index++) {
			await callback(array[index], index, array);
		}
	}

	const onDownloadExcelClick = async function () {
		setDownloadingTemplate(true);
		await getImportTemplate(slug as string);
		setDownloadingTemplate(false);
	};

	const sendRunnersToAPI = async (runners: any[]) => {
		const updateType: string = importType;

		// Reset subscriptions' & runners' status
		if (updateType === IMPORT_TYPE.REPLACE)
			await resetSubscriptions(slug as string);

		const startTime = new Date();
		setProcessingTime(0);
		setProcessingTotal(runners.length);
		setProcessing(true);
		setPreImport(false);
		let batch: any[] = [];
		let batchSplit: number = 375;
		let count = 1;

		try {
			await asyncForEach(runners, async (r: any) => {
				setProcessingCurrent(count);

				batch.push(r);

				// Send if batch is full or if it's the last runner
				if (count % batchSplit == 0 || count >= runners.length) {
					const body = { importType: updateType, list: batch, slug: slug };
					let result = await importSubscriptions(slug as string, body);
					batch = [];
					if (result?.warnings?.length) {
						const listWarns = result.warnings.map(
							(w: any) => w[lang] || w["fr"]
						);
						setWarnings(warnings.concat(listWarns));
					}
				}
				count++;
			});
		} catch (e) {
			setImportError(true);
			Toast.error(trad[lang].import_error);
			return;
		}

		const now = new Date();
		const elapsed = now.getTime() - startTime.getTime();
		setProcessingTime(elapsed);
		setImportDone(true);
		refetch();
	};

	const DATA_COLUMNS: any = {
		course: { label: "COURSE", id: 1 },
		nom: { label: "NOM", id: 2 },
		prenom: { label: "PRENOM", id: 3 },
		genre: { label: "GENRE", id: 4 },
		dossard: { label: "DOSSARD", id: 5 },
		ddn: { label: "DATE NAISSANCE", id: 6 },
		mail: { label: "MAIL", id: 7 },
		nationality: { label: "NATIONALITE", id: 8 },
		adresse: { label: "ADRESSE", id: 9 },
		cp: { label: "CP", id: 10 },
		ville: { label: "VILLE", id: 11 },
		pays: { label: "PAYS", id: 12 },
		tel: { label: "TEL", id: 13 },
		telUrgence: { label: "TEL URGENCE", id: 14 },
		numLicence: { label: "NUM LICENCE", id: 15 },
		club: { label: "CLUB", id: 16 },
		equipe: { label: "EQUIPE", id: 17 }
	};

	const columnIDByNameRef = useRef({} as any);

	const getColumnID = function (columnName: string): number {
		if (columnIDByNameRef.current[columnName])
			return columnIDByNameRef.current[columnName];
		return 0;
	};

	const getCellValue = function (row: any, dataColumn: any): any {
		const colID = getColumnID(dataColumn.label);
		if (colID === 0) return row.getCell(dataColumn.id).text;
		return row.getCell(colID).text;
	};

	const getNbEquipiersOfRace = function (raceId: number): number {
		const race = liveConfig.calendrier_child?.find((r: any) => r.id === raceId);
		if (!race) {
			return 1;
		}
		return race.configuration_calendrier.nb_equipier;
	};

	let emailAlreadyUsed = false;

	const onClickImport = async () => {
		// Continue import despite warnings & errors
		if (errors.length > 0 || warnings.length > 0) {
			sendRunnersToAPI(runners);
			return;
		}
		// Pre-import checks
		else {
			setProcessing(true);

			if (dataSubs && dataSubs.listTeams)
				listTeamsRef.current = [...dataSubs.listTeams];

			// Extract runs from liveConfig
			const listRacesIds = liveConfig.calendrier_child.map((r: any) => r.id);
			let reader = new FileReader();
			reader.readAsArrayBuffer(droppedFile);
			reader.onload = async () => {
				let wb = new ExcelJS.Workbook();
				let w = await wb.xlsx.load(reader.result as ArrayBuffer);
				let s: Worksheet;

				// Extract columns ID
				s = w.worksheets[0];
				columnIDByNameRef.current = {};
				s.getRow(1).eachCell((cell, colNumber) => {
					columnIDByNameRef.current[cell.text] = colNumber;
				});

				// Assign birthdate DATE format, either DD/MM/YYYY (FR) or MM/DD/YYYY (US)
				s = w.worksheets[0];
				let dateFormats = { US: 0, FR: 0 };
				s.eachRow((row, rowNumber) => {
					if (rowNumber === 1) return;
					let strDate = getCellValue(row, DATA_COLUMNS.ddn);
					strDate = strDate.trim().replaceAll("-", "/");
					if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(strDate) === false) return;
					let splits = strDate.split("/");
					if (splits.length !== 3) return;
					let day = splits[0];
					let month = splits[1];
					if (parseInt(month) > 12) dateFormats["US"]++;
					else if (parseInt(day) > 12) dateFormats["FR"]++;
				});
				dateFormatRef.current =
					dateFormats["US"] > dateFormats["FR"] ? "US" : "FR";

				// Parse races (sheet 2, index 1)
				s = w.worksheets[1];
				let races: any[] = [];
				s.eachRow((row, rowNumber) => {
					let raceLabel: string = row.getCell(1).text;
					let cell2 = row.getCell(2);
					let raceId: number = (
						cell2.result ? cell2.result : cell2.value
					) as number;
					races.push({ id: raceId, label: raceLabel });
				});

				// Parse subs
				s = w.worksheets[0];
				let runners: any[] = [];
				let listBibs: number[] =
					importType === IMPORT_TYPE.REPLACE
						? [...dataSubs?.bibOnlineList]
						: [...dataSubs?.bibList];
				let errors: string[] = [];
				let warnings: string[] = [];
				setProcessingTotal(s.actualRowCount - 1);
				s.eachRow(async (row, rowNumber) => {
					if (rowNumber === 1) return;

					let rowErrors = [];
					let rowWarnings = [];
					let cell;
					if (rowNumber === 1) return;
					setProcessingCurrent(rowNumber - 1);

					// Race check
					let raceLabel: string = getCellValue(row, DATA_COLUMNS.course);
					let raceItem: any = races.find((item: any) => {
						return item.label === raceLabel;
					});
					let raceId: number = raceItem?.id || -1;

					let lastName: string = getCellValue(row, DATA_COLUMNS.nom);
					let firstName: string = getCellValue(row, DATA_COLUMNS.prenom);

					// Cell gender
					let gender: string = getCellValue(row, DATA_COLUMNS.genre);

					// Bib check
					let bibStr: string = getCellValue(row, DATA_COLUMNS.dossard);
					let bib: number = parseInt(bibStr);
					if (listBibs.includes(bib)) rowWarnings.push(trad[lang].bibUsed);
					if (bib < 0) rowWarnings.push(trad[lang].bibInvalid + " ( < 0 )");
					listBibs.push(bib);

					// Race check
					if (!raceId || listRacesIds.indexOf(raceId) === -1)
						rowErrors.push(
							trad[lang].invalidRace +
								" (raceId/Name=" +
								(raceId != -1 ? raceId.toString() : raceLabel) +
								")"
						);

					// Birthdate
					let birthdate: string | null = getDate(
						getCellValue(row, DATA_COLUMNS.ddn)
					);
					if (!birthdate || !birthdate.match(/^\d{4}-\d{1,2}-\d{1,2}$/))
						rowErrors.push(
							`Date de naissance invalide (${getCellValue(
								row,
								DATA_COLUMNS.ddn
							)}) (format attendu: ${
								dateFormatRef.current === "FR" ? "jj/mm/aaaa" : "mm/jj/aaaa"
							})`
						);

					// Check mail
					let email: string = getCellValue(row, DATA_COLUMNS.mail);
					if (email && email.length > 0 && REGEXP.mail.test(email) === false)
						rowErrors.push(`Adresse mail invalide (${email})`);
					// Check if email is already used
					if (email && email.length > 0) {
						if (emailAlreadyUsed)
							rowErrors.push(`Adresse mail déjà utilisée (${email})`);
					}

					// Other fields
					let nationality: string = getCellValue(row, DATA_COLUMNS.nationality);
					let adress1: string = getCellValue(row, DATA_COLUMNS.adresse);
					let postalCode: string = getCellValue(row, DATA_COLUMNS.cp);
					let city: string = getCellValue(row, DATA_COLUMNS.ville);
					let country: string = getCellValue(row, DATA_COLUMNS.pays);
					let phoneNumber: string = getCellValue(row, DATA_COLUMNS.tel);
					let phoneNumberUrgency: string = getCellValue(
						row,
						DATA_COLUMNS.telUrgence
					);
					let licence: string = getCellValue(row, DATA_COLUMNS.numLicence);
					let clubName: string = getCellValue(row, DATA_COLUMNS.club);

					// Check equipe (If already complete)
					let equipe: string = getCellValue(row, DATA_COLUMNS.equipe);
					if (equipe && equipe.length > 0) {
						let teamData = listTeamsRef.current.find(
							(t: any) =>
								t.nom.toLowerCase() === equipe.toLocaleLowerCase() &&
								t.raceId === raceId
						);
						if (!teamData) {
							teamData = {
								id: 0,
								nom: equipe,
								numOnlineRegistrations: 0,
								numRegistrations: 0,
								raceId: raceId
							};
							listTeamsRef.current.push(teamData);
						}
						if (!teamData.numRegistrations)
							teamData.numRegistrations = teamData.numOnlineRegistrations;
						teamData.numRegistrations++;
						if (teamData.numRegistrations > getNbEquipiersOfRace(raceId))
							rowWarnings.push(
								`L'équipe ${equipe} est déjà complète pour la course "${raceLabel}"`
							);
					}

					// Empty fields check
					if (!raceId) rowErrors.push(trad[lang].missingRace);
					if (!bib) rowWarnings.push(trad[lang].missingBib);

					if (rowErrors.length > 0) {
						errors.push(
							"L" +
								rowNumber +
								" (" +
								(lastName || "") +
								" " +
								(firstName || "") +
								") : " +
								rowErrors.join(", ")
						);
						return;
					}
					if (rowWarnings.length > 0) {
						warnings.push(
							"L" +
								rowNumber +
								" (" +
								(lastName || "") +
								" " +
								(firstName || "") +
								") : " +
								rowWarnings.join(", ")
						);
					}

					const runnerData: any = {
						rowNumber,
						raceLabel,
						raceId,
						lastName,
						firstName,
						gender,
						nationalityWinDev: getWindevIdByCountryName(nationality),
						bib,
						birthdate,
						clubName,
						email,
						adress1,
						postalCode,
						city,
						countryWinDev: getWindevIdByCountryName(country),
						phoneNumber,
						phoneNumberUrgency,
						licence,
						equipe
					};

					// Set inscription_choix if dynamic fields are present in .XLSX
					addInscriptionChoix(runnerData, s, row, raceId);

					runners.push(runnerData);
				});

				setRunners(runners);
				setProcessingCurrent(runners.length);

				if (errors.length > 0 || warnings.length > 0) {
					setWarnings(warnings);
					setErrors(errors);
					setProcessing(false);
				} else {
					await new Promise((resolve) => setTimeout(resolve, 1000));
					sendRunnersToAPI(runners);
				}
			};
		}
	};

	const addInscriptionChoix = function (
		runnerData: any,
		s: Worksheet,
		row: any,
		raceId: number
	) {
		// List dynamic fields of .XLSX (fields that are not defined in the DATA_COLUMNS object)
		const dynamicFields: any = {};
		s.getRow(1).eachCell((cell, colNumber) => {
			let columnData: any = null;
			for (const key in DATA_COLUMNS) {
				if (DATA_COLUMNS[key].label === cell.text) {
					columnData = DATA_COLUMNS[key];
					break;
				}
			}
			// Case of a Dynamic field
			if (columnData === null) {
				// example : dynamicFields['Centre de secours'] = 'Lyon';
				dynamicFields[cell.text] = row.getCell(colNumber).text;
			}
		});

		// Set inscription_choix in function of dynamic fields
		// Example inscription_choix
		// [
		//     {
		//         "id": 58, // ide_choix
		//         "idList": 2,
		//     }
		// ]

		// Find listeChoix for raceId
		const raceData = liveConfig.calendrier_child.find(
			(r: any) => r.id === raceId
		);
		// Find match between dynamicFields and listeChoix
		if (raceData?.liste?.length) {
			for (const dynField in dynamicFields) {
				for (const listeChoix of raceData.liste) {
					if (listeChoix.libelle === dynField) {
						// Find choix matching dynamicFields[dynField]
						for (const choix of listeChoix.choix) {
							if (choix.libelle === dynamicFields[dynField]) {
								if (!runnerData.inscription_choix)
									runnerData.inscription_choix = [];
								runnerData.inscription_choix.push({
									id: choix.id,
									idList: listeChoix.id
								});
								break;
							}
						}
					}
				}
			}
		}
	};

	const getDate = function (strDate: string): string | null {
		strDate = strDate.trim().replaceAll("-", "/");
		let date: Date;

		// If date string like Thu Apr 20 1978 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		// Parse it
		if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(strDate) === false) {
			date = new Date(strDate);
		}
		// Otherwise, convert strings like "20/04/1978" to "1978-04-20" (DB format)
		else {
			let splits = strDate.split("/");
			if (splits.length !== 3) return null;
			if (splits.length !== 3) return null;

			if (splits.length !== 3) return null;

			let day = splits[dateFormatRef.current === "FR" ? 0 : 1];
			let month = splits[dateFormatRef.current === "FR" ? 1 : 0];
			let year = splits[2];
			date = new Date(month + "/" + day + "/" + year);
			if (isNaN(date.getTime())) return null;
		}

		if (date) {
			return `${date.getFullYear()}-${(date.getMonth() + 1)
				.toString()
				.padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
		}
		return null;
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={setOpen}
				>
					<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:h-screen sm:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle md:max-w-2xl lg:max-w-4xl">
								<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
									<button
										type="button"
										className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close</span>
										<HiOutlineXMark className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="mb-6 w-full sm:flex sm:items-start">
									<div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{trad[lang].importTitle}
										</Dialog.Title>
									</div>
								</div>

								<div className="mb-0 flex content-center justify-center text-center">
									<div className="flex flex-row">
										{trad[lang].toImport}
										<a
											className="ml-1 cursor-pointer font-medium text-orange-500 hover:text-orange-800"
											onClick={onDownloadExcelClick}
										>
											{trad[lang].useThisModel}
										</a>
										{downloadingTemplate === true && (
											<AiOutlineLoading3Quarters
												size={24}
												className="ml-3 animate-spin fill-orange-500"
											/>
										)}
									</div>
								</div>
								<div className="mb-4 flex content-center justify-center text-center">
									<div>
										(Microsoft Excel (<b>.XLSX</b>), Google spreadsheet)
									</div>
								</div>

								<div
									{...getRootProps({
										className:
											"flex flex-col items-center justify-center w-full h-20 border-2 border-dashed border-gray-300 rounded-md bg-gray-100 text-gray-700 outline-none transition duration-150 ease-in-out"
									})}
								>
									<input {...getInputProps()} />
									{isDragActive ? (
										<p>{trad[lang].dragHere}</p>
									) : (
										<>
											{isFileNotSupported && (
												<div className="mb-1 flex w-full items-center justify-center text-red-500">
													<BiError className="mr-2 h-5 w-5" />
													<p>{trad[lang].fileFormatNotSupported}</p>
												</div>
											)}
											<p>{trad[lang].dragHereOr}</p>
										</>
									)}
									{droppedFile ? (
										<>
											<div className="m-2 flex">
												<SiMicrosoftexcel
													color="#007700"
													className="mr-2 h-5 w-5"
												/>
												<p>{droppedFile.name}</p>
											</div>
										</>
									) : null}
								</div>

								<div className="pt-8 pb-2 text-center">
									<div>{/* <b>{numSubs}</b> {trad[lang].existingSubs} */}</div>
									<div className="pb-4">{trad[lang].whattodo}</div>
									<div className="flex justify-center gap-10">
										<Radio
											id="replace"
											name="importType"
											label={trad[lang].replace}
											defaultChecked
											onChange={() => {
												setImportType(IMPORT_TYPE.REPLACE);
											}}
										/>
										<Radio
											id="donotreplace"
											name="importType"
											label={trad[lang].donotupdate}
											onChange={() => {
												setImportType(IMPORT_TYPE.NOUPDATE);
											}}
										/>
									</div>
								</div>

								<div className="flex-col justify-center">
									{!processing && (
										<div className="flex justify-center">
											<button
												type="button"
												className={`mt-4 rounded-lg  px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 ${
													!droppedFile
														? "bg-gray-400"
														: errors.length > 0
														? "bg-orange-400 hover:bg-orange-700 focus:ring-orange-300 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
														: "bg-primary hover:bg-primarydark focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
												}
                        }`}
												onClick={onClickImport}
												disabled={!droppedFile}
											>
												{errors.length > 0
													? `${trad[lang].continue}  ( ${processingCurrent} ${trad[lang].adds} ${processingTotal} )`
													: trad[lang].import}
											</button>
										</div>
									)}

									{processing && (
										<>
											<div className="mb-1 mt-5 flex justify-center">
												{!importDone && (
													<AiOutlineLoading3Quarters className="animate-spin" />
												)}
											</div>
											<div className="flex justify-center">
												{importDone
													? `${processingTotal} ${trad[lang].nbImportedRunners}`
													: (preImport ? trad[lang].pre : "") +
													  `${trad[lang].importing} (${processingCurrent} / ${processingTotal})`}
											</div>
											{importError && (
												<div className="flex justify-center text-red-500">
													{trad[lang].importError}
												</div>
											)}
											<div className="flex justify-center">
												{importDone &&
													`${processingDuplicates} ${trad[lang].existing} / ${
														processingTotal - processingDuplicates
													} ${trad[lang].add}`}
											</div>
											<div className="flex justify-center">
												{importDone && `${formatTime(processingTime)}`}
											</div>
										</>
									)}
								</div>

								{importDone && (
									<div className="flex justify-center">
										<button
											className="mt-4 rounded-lg  bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primarydark focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
											onClick={() => setOpen(false)}
										>
											{trad[lang].close}
										</button>
									</div>
								)}

								{errors.length > 0 && (
									<div>
										<div className="mb-3 mt-6 inline-flex items-center gap-x-2 rounded-full border-2 border-solid border-red-200 bg-red-100/60 px-3 py-1 text-red-500 dark:bg-gray-800">
											<h2 className="text-sm font-medium uppercase">
												{errors.length > 1
													? trad[lang].preimportErrors
													: trad[lang].preimportError}
											</h2>
										</div>

										<div>
											<textarea
												rows={errors.length > 10 ? 10 : errors.length}
												name="description"
												id="description"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring-transparent sm:text-sm"
												value={errors.join("\n")}
												readOnly
											></textarea>
										</div>
									</div>
								)}

								{warnings.length > 0 && (
									<div>
										<div className="mb-3 mt-6 inline-flex items-center gap-x-2 rounded-full border-2 border-solid border-orange-200 bg-orange-100/60 px-3 py-1 text-orange-500 dark:bg-gray-800">
											<h2 className="text-sm font-medium uppercase">
												{trad[lang].preimportWarning}
											</h2>
										</div>

										<div>
											<textarea
												rows={warnings.length > 10 ? 10 : warnings.length}
												name="description"
												id="description"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring-transparent sm:text-sm"
												value={warnings.join("\n")}
												readOnly
											></textarea>
										</div>
									</div>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}

export default ImportModal;
