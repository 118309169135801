import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";

const EventStepModule = ({
	route,
	openStep,
	deleteStep,
	timezone
}: {
	route: any;
	openStep: any;
	deleteStep: any;
	timezone: string;
}) => {
	const { lang } = useContext(AppContext);

	return (
		<div
			className={classNames(
				"flex w-full flex-col items-center justify-between gap-4 border-b p-3 px-8 transition-colors hover:bg-slate-100 md:flex-row"
			)}
		>
			<div className="flex flex-row items-baseline gap-3">
				<h2 className="text-xl font-bold">
					{`${trad[lang].step} - ${route.nom}`}
				</h2>

				<p>{`${new Date(route.dateDebut).toLocaleString("fr-FR", {
					day: "2-digit",
					month: "2-digit",
					year: "numeric",
					hour: "2-digit",
					minute: "2-digit",
					timeZone: timezone
				})}`}</p>
			</div>

			<div className="col-span-1 flex flex-row justify-end gap-3">
				<Link
					to={`${route.idEtape}`}
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
				>
					{trad[lang].configure_routes}
				</Link>

				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
					onClick={() => openStep(route)}
				>
					{trad[lang].edit_step}
				</button>

				<button
					className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-600 py-3 px-3 text-xs text-white duration-150 hover:bg-red-700 md:uppercase"
					onClick={() => deleteStep(route.idEtape, route.nom)}
				>
					{trad[lang].delete_step}
				</button>
			</div>
		</div>
	);
};

export default EventStepModule;
