import { useContext } from "react";
import { HiChevronDown } from "react-icons/hi";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { ILang } from "../../types/Lang";

const langItems = {
	fr: "Français",
	en: "English",
	de: "Deutsch",
	es: "Español",
	nl: "Nederlands",
	it: "Italiano"
};

function LangToggle({ reduced }: { reduced?: boolean }) {
	const { lang, storeLang } = useContext(AppContext);

	return (
		<div className="relative">
			<select
				id="language"
				name="language"
				className={`block w-full cursor-pointer appearance-none rounded-md border border-gray-300 bg-white bg-none ${
					reduced ? "py-1" : "py-2"
				}  pr-10 text-base text-gloom focus:border-transparent focus:ring-primary sm:text-sm`}
				value={lang}
				onChange={(e) => {
					storeLang(e.target.value as ILang);
				}}
			>
				{Object.keys(trad).map((item) => (
					<option value={item} key={item}>
						{reduced ? item : langItems[item as ILang]}
					</option>
				))}
			</select>

			{!reduced && (
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
					<HiChevronDown className="h-4 w-4 text-gray-400" aria-hidden="true" />
				</div>
			)}
		</div>
	);
}

export default LangToggle;
