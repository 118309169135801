import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { addRib, getTypeRib, updateRib } from "../../api/event";
import { AppContext } from "../../contexts/AppContext";
import trad from "../../lang/traduction";
import { classNames } from "../../utils/Classes";
import { countriesList } from "../../utils/CountriesList";
import { REGEXP } from "../../utils/RegExp";
import Toast from "../../utils/Toasts";
import { validateIban } from "../../utils/ValidateIBAN";

const EventRibFormModal = ({
	closeModal,
	rib,
	refetch
}: {
	closeModal: any;
	rib: any;
	refetch: any;
}) => {
	const { lang } = useContext(AppContext);
	const { slug } = useParams();
	const [localForm, setLocalForm] = useState(rib);
	const [errors, setErrors] = useState<string[]>([]);

	const { data } = useQuery({
		queryKey: ["type_rib"],
		queryFn: getTypeRib,
		refetchOnWindowFocus: false
	});

	const handleChange = (key: string, value: any) => {
		const IndexError = errors.indexOf(key);

		if (IndexError >= 0) {
			const newErrors = [...errors];
			newErrors.splice(IndexError, 1);

			setErrors(newErrors);
		}

		setLocalForm((old: any) => ({ ...old, [key]: value }));
	};

	const handleChangePersonne = (key: string, value: string) =>
		setLocalForm((old: any) => ({
			...old,
			personne: { ...old.personne, [key]: value }
		}));

	const checkErrors = async () => {
		const localErrors = [];

		if (localForm.designation.length < 2) localErrors.push("designation");

		if (!localForm.ide_type_rib) localErrors.push("ide_type_rib");

		if (localForm.nomBanque.length < 2) localErrors.push("nomBanque");

		const iban_without_spaces = localForm.iban.split(" ").join("");

		if (iban_without_spaces.length < 27 || iban_without_spaces.length > 34) {
			const valid = validateIban(iban_without_spaces);

			if (!valid) {
				localErrors.push("iban");
			}
		}

		if (localForm.bic.length < 8 || localForm.bic.length > 11)
			localErrors.push("bic");

		if (!localForm.inCharge) {
			if (
				!localForm.personne.firstname ||
				localForm.personne.firstname.length < 2
			)
				localErrors.push("firstname");

			if (
				!localForm.personne.lastname ||
				localForm.personne.lastname.length < 2
			)
				localErrors.push("lastname");

			if (
				!localForm.personne.birthdate ||
				dayjs(dayjs()).diff(localForm.personne.birthdate, "years") < 13 ||
				dayjs(dayjs()).diff(localForm.personne.birthdate, "years") > 100
			)
				localErrors.push("birthdate");

			if (!localForm.personne.sex) localErrors.push("sex");

			if (
				!localForm.personne.email ||
				!REGEXP.mail.test(localForm.personne.email)
			)
				localErrors.push("email");
		}

		if (localErrors.length > 0) {
			setErrors(localErrors);
			throw Error("Form not valid");
		}
	};

	const confirmForm = async () => {
		try {
			await checkErrors();

			if (localForm.id) {
				await updateRib(localForm.id, localForm);
			} else {
				await addRib(slug as string, localForm);
			}

			await refetch();
			closeModal();

			Toast.success(trad[lang].success_updating_rib);
		} catch (error) {
			Toast.error(trad[lang].error_updating_rib);
		}
	};

	const RequiredComponent = () => (
		<span className="text-sm italic text-red-500">
			{" "}
			({trad[lang].required})
		</span>
	);

	return (
		<div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-80">
			<div className="w-4/5 min-w-[320px] max-w-screen-lg rounded-md border bg-white py-3 xl:w-3/5 2xl:w-2/5">
				<h2 className="mb-3 text-center text-2xl font-bold">
					{trad[lang].edit_rib_title}
				</h2>

				<div className="flex max-h-[60vh] flex-col gap-3 overflow-y-auto px-3 md:grid md:grid-cols-2">
					{/* Designation */}
					<div className={`col-span-1 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="designation">
							{trad[lang].designation_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="designation"
							className={classNames(
								"rounded-md",
								errors.includes("designation")
									? "border-transparent outline outline-2 outline-red-500"
									: ""
							)}
							onChange={(e) => handleChange("designation", e.target.value)}
							value={localForm.designation}
						/>
					</div>

					{/* Type RIB */}
					<div className={`col-span-1 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="ide_type_rib">
							{trad[lang].select_type_rib}
							<RequiredComponent />
						</label>

						<select
							id="ide_type_rib"
							className={classNames(
								"rounded-md",
								errors.includes("ide_type_rib")
									? "border-transparent outline outline-2 outline-red-500"
									: ""
							)}
							onChange={(e) => handleChange("ide_type_rib", e.target.value)}
							value={localForm.ide_type_rib}
						>
							<option>{trad[lang].select_type_rib}</option>
							{data?.map((item: any) => (
								<option value={item.id} key={item.id}>
									{item.libelle}
								</option>
							))}
						</select>
					</div>

					{/* Nom Banque */}
					<div className={`col-span-1 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="nomBanque">
							{trad[lang].nomBanque_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="nomBanque"
							className={classNames(
								"rounded-md",
								errors.includes("nomBanque")
									? "border-transparent outline outline-2 outline-red-500"
									: ""
							)}
							onChange={(e) => handleChange("nomBanque", e.target.value)}
							value={localForm.nomBanque}
						/>
					</div>

					{/* IBAN */}
					<div className={`col-span-1 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="iban">
							{trad[lang].iban_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="iban"
							className={classNames(
								"rounded-md",
								errors.includes("iban")
									? "border-transparent outline outline-2 outline-red-500"
									: ""
							)}
							onChange={(e) => handleChange("iban", e.target.value)}
							value={localForm.iban}
						/>
					</div>

					{/* BIC */}
					<div className={`col-span-1 flex flex-col`}>
						<label className="mb-1 text-lg" htmlFor="bic">
							{trad[lang].bic_label}
							<RequiredComponent />
						</label>

						<input
							type="text"
							id="bic"
							className={classNames(
								"rounded-md",
								errors.includes("bic")
									? "border-transparent outline outline-2 outline-red-500"
									: ""
							)}
							onChange={(e) => handleChange("bic", e.target.value)}
							value={localForm.bic}
						/>
					</div>

					{/* IN CHARGE */}
					{!localForm.id ? (
						<div className={`col-span-2 flex items-center gap-2`}>
							<input
								type="checkbox"
								id="inCharge"
								className="rounded-md"
								onClick={(e) => handleChange("inCharge", !localForm.inCharge)}
								checked={localForm.inCharge}
							/>

							<label className="text-lg" htmlFor="inCharge">
								{trad[lang].inCharge_label}
							</label>
						</div>
					) : (
						<div className={`col-span-2 mt-4`}></div>
					)}

					{!localForm.inCharge && (
						<>
							{/* Lastname */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="lastname">
									{trad[lang].lastname_label}
									<RequiredComponent />
								</label>

								<input
									type="text"
									id="lastname"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("lastname")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("lastname", e.target.value)
									}
									value={localForm.personne.lastname}
								/>
							</div>

							{/* Firstname */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="firstname">
									{trad[lang].firstname_label}
									<RequiredComponent />
								</label>

								<input
									type="text"
									id="firstname"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("firstname")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("firstname", e.target.value)
									}
									value={localForm.personne.firstname}
								/>
							</div>

							{/* Sex */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="sex">
									{trad[lang].sex_label}
									<RequiredComponent />
								</label>

								<select
									id="sex"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("sex")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) => handleChangePersonne("sex", e.target.value)}
									value={localForm.personne.sex}
								>
									<option value="">{trad[lang].select_gender}</option>
									<option value={1}>{trad[lang].genderHfull}</option>
									<option value={2}>{trad[lang].genderFfull}</option>
									<option value={0}>{trad[lang].genderXfull}</option>
								</select>
							</div>

							{/* Birthdate */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="birthdate">
									{trad[lang].birthdate_label}
									<RequiredComponent />
								</label>

								<input
									type="date"
									id="birthdate"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("birthdate")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("birthdate", e.target.value)
									}
									value={localForm.personne.birthdate}
								/>
							</div>

							{/* Email */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="email">
									{trad[lang].email_label}
									<RequiredComponent />
								</label>

								<input
									type="text"
									id="email"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("email")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("email", e.target.value)
									}
									value={localForm.personne.email}
								/>
							</div>

							{/* Address */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="adresse1">
									{trad[lang].address_label}
								</label>

								<input
									type="text"
									id="adresse1"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("adresse1")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("adresse1", e.target.value)
									}
									value={localForm.personne.adresse1}
								/>
							</div>

							{/* City */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="city">
									{trad[lang].city_label}
								</label>

								<input
									type="text"
									id="city"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("city")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) => handleChangePersonne("city", e.target.value)}
									value={localForm.personne.city}
								/>
							</div>

							{/* Postal Code */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="postal_code">
									{trad[lang].postalCode_label}
								</label>

								<input
									type="text"
									id="postal_code"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("postal_code")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("postal_code", e.target.value)
									}
									value={localForm.personne.postal_code}
								/>
							</div>

							{/* Country */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="country">
									{trad[lang].country_label}
								</label>

								<select
									id="country"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("country")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("country", e.target.value)
									}
									value={localForm.personne.country}
								>
									<option></option>
									{countriesList.map((item) => (
										<option value={item.code} key={item.code}>
											{item.name[lang == "fr" ? "fr" : "en"]}
										</option>
									))}
								</select>
							</div>

							{/* Phone */}
							<div className={`col-span-1 flex flex-col`}>
								<label className="mb-1 text-lg" htmlFor="phone">
									{trad[lang].phone_label}
								</label>

								<input
									type="text"
									id="phone"
									className={classNames(
										"rounded-md",
										localForm.id ? "opacity-50" : "",
										errors.includes("phone")
											? "border-transparent outline outline-2 outline-red-500"
											: ""
									)}
									disabled={localForm.id}
									onChange={(e) =>
										handleChangePersonne("phone", e.target.value)
									}
									value={localForm.personne.phone}
								/>
							</div>
						</>
					)}
				</div>

				<div className="mt-3 flex flex-row gap-3 px-3">
					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-red-500 py-3 px-3 text-xs text-white duration-150 hover:bg-red-600 md:uppercase"
						onClick={closeModal}
					>
						{trad[lang].close}
					</button>

					<button
						className="flex h-full cursor-pointer flex-row items-center gap-1 rounded-md bg-primary py-3 px-3 text-xs text-white duration-150 hover:bg-primarymedium md:uppercase"
						onClick={confirmForm}
					>
						{trad[lang].validate}
					</button>
				</div>
			</div>
		</div>
	);
};

export default EventRibFormModal;
